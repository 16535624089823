import React, { useState, useEffect } from "react";
import {useLocation} from "react-router-dom";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import api from "../../axios";
import "./DetalhaCA.css";
import { format,
        paperStyle,
        negativeElItem,
        styleField,
        RED_TABLE,
        PAPER_PADDING_THEME,
        formatterCurrency,
        formatterDayMonthYear,
        GREEN_SUCCESS_UX,
        WHITE_ESCRITA_THEME,
    } from "../../shared/utils";
import Header from "../../components/Titles/Header";
import Grid from "@mui/material/Grid";
import PathName from "../../components/Titles/PathName";
import DataGrid from "../../components/DataGrid/DataGrid";
import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";


export default function DetalhaCA() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [categoria, setCategoria] = useState("");
    const [valorTotal, setValorTotal] = useState(0);
    const [periodo, setPeriodo] = useState("");
    const negativeStyle = valorTotal < 0 ? {color: RED_TABLE} : {};
    const [table, setTable] = useState("");
    const [auxLojas, setAuxLojas] = useState();
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const [isRevisada, setIsRevisada] = useState(false);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const dataRequest = {
            lojas: query.get("lojas"),
            mes: query.get("columnId"),
            categoria: query.get("fieldId"),
            table: query.get("table"),
        };
        setAuxLojas(dataRequest);
        if (dataRequest.lojas.length > 1) {
            const lojasSplit = dataRequest.lojas.split(",").map(Number);
            dataRequest.lojas = lojasSplit;
        }
        setTable(query.get("table"));
        explodeCategoryCA(dataRequest);
    }, []);

    const explodeCategoryCA = (dataRequest) => {
        setLoading(true);
        api.explodeCategoryCA(dataRequest)
          .then((response) => {
            const newData = response.data.objects.map((item, index) => {
                return { ...item, id: index };
            });
            setData(newData);
            setCategoria(response.data.categoria);
            setValorTotal(response.data.total);
            setPeriodo(response.data.periodo);
            setIsRevisada(response.data.status_dre);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      };

    const columns = [
        {
            field: "data_movimentacao",
            headerName: "Data de Movimentação",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: params => formatterDayMonthYear(params)
        },
        {
            field: "data_competencia",
            headerName: "Data de Competência",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: params => formatterDayMonthYear(params)
        },
        { field: "categoria", headerName: "Categoria", flex: 1, align: "center", headerAlign: "center" },
        { field: "descricao", headerName: "Descrição", flex: 1, align:"center", headerAlign: "center" },
        { field: "fornecedor", headerName: "Fornecedor", flex: 1, align:"center", headerAlign: "center" },
        { 
            field: "valor",
            headerName: "Valor (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { field: "conta", headerName: "Conta", flex: 1, align:"center", headerAlign: "center" },
        { field: "situacao", headerName: "Situação", flex: 1, align:"center", headerAlign: "center" },
    
        ];

    const columnsDREConsolidada = [
        { field: "loja", headerName: "Loja", flex: 1, align: "center", headerAlign: "center" },
        {
            field: "data_movimentacao",
            headerName: "Data de Movimentação",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: params => formatterDayMonthYear(params)
        },
        {
            field: "data_competencia",
            headerName: "Data de Competência",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: params => formatterDayMonthYear(params)
        },
        { field: "categoria", headerName: "Categoria", flex: 1, align: "center", headerAlign: "center" },
        { field: "descricao", headerName: "Descrição", flex: 1, align:"center", headerAlign: "center" },
        { field: "fornecedor", headerName: "Fornecedor", flex: 1, align:"center", headerAlign: "center" },
        { 
            field: "valor",
            headerName: "Valor (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { field: "conta", headerName: "Conta", flex: 1, align:"center", headerAlign: "center" },
        { field: "situacao", headerName: "Situação", flex: 1, align:"center", headerAlign: "center" },
    ];

    return (
        <div className="main">
            <Header title={`Detalhando informações sobre a categorização: ${categoria}`}/>
            {loading ? (
                <Skeleton variant="rectangular" width={"100%"} height={"80vh"} />
            ) : (      
                <Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container>
                        <Grid item xs={12} sx={{...styleField, marginLeft: 0, display: "flex", alignItems:  "center"}}>
                            <PathName firstPath={table} secondPath={`${categoria}`}/>
                            {
                                isRevisada && (
                                    <Box sx={{backgroundColor: GREEN_SUCCESS_UX, borderRadius: "7px", padding: "0 5px", width: "fit-content", fontSize: 14, marginLeft: 1, fontWeight: "bold", display: "flex", alignItems:  "center", color: WHITE_ESCRITA_THEME}}>
                                        <CheckIcon sx={{width: "20%", marginRight: "2px"}} />
                                        Revisada
                                    </Box>
                                )
                            }
                        </Grid>
                    </Grid>
                    <Grid item sx={{...styleField, marginLeft: 0}}>
                            Valor Total: <b style={{...negativeStyle}}>R$ {negativeElItem(valorTotal)}{format(valorTotal)}</b>
                        </Grid>
                    <Grid item sx={{...styleField, marginLeft: 0}}>
                        Período: <b>{periodo}</b>
                    </Grid>
                    <Grid sx={{ height: "calc(100vh - 160px - 95px)", width: "100%"}}>
                        <DataGrid
                            columns={auxLojas.lojas.length > 1 ? columnsDREConsolidada : columns}
                            data={data}
                            row={(row) => (`${row.ca_id}-${row.detalha_dre_id}-${row.valor}`)}
                        />
                    </Grid>
                </Paper>
            )}
        </div>
    );
}

