import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import {GRAY_LABEL_UX, PAPER_PADDING_THEME, WHITE_THEME_BLACK} from "../../../shared/utils";
import "./PlanoModal.css";

const UpdateCAModal = (props) => {
	const {openModal, setOpenModal, testId}= props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	
	return(
		<React.Fragment>
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Paper
					className="modal"
					style={{width: "30%", height: "27%", marginTop: "7%", backgroundColor: temaEscuro && PAPER_PADDING_THEME}}
					data-testid={testId}
				>
                    <Grid container spacing={3} sx={{overflowY: "hidden"}}>
                        <Grid item xs={12}>
							<h3 style={{marginLeft: "20px"}}>Atualizando Conta Azul</h3>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: "center"}}>
                            <CircularProgress color="inherit"/>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: "center"}}>
                            <h3 style={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontWeight: 400, fontStyle: "normal", fontSize: "16px"}}>Estamos atualizando o conta azul!</h3>
                        </Grid>
                    </Grid>
				</Paper>
			</Modal>
		</React.Fragment>
	);
};

export default UpdateCAModal;