import React, {useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    styleGrid,
    WHITE_ESCRITA_THEME,
    styleDefaultText,
    muiRootStyle,
    PAPER_PADDING_THEME,
    BLUE_THEME, WHITE_THEME_BLACK, textCompleteStyleBlack, INFO_THEME, BORDER_BUTTON
} from "../../shared/utils";
import Header from "../../components/Titles/Header";
import PathName from "../../components/Titles/PathName";
import Paper from "@mui/material/Paper";
import api from "../../axios";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import moment from "moment";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import {useDispatch, useSelector} from "react-redux";
import GrayButton from "../../components/Buttons/GrayButton";
import YellowButton from "../../components/Buttons/YellowButton";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import AttachFileModal from "../../components/Modal/Ata/AttachFileModal";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import {stateFromHTML} from "draft-js-import-html";
import {showSnackMessage} from "../../actions/SnackActions";                            
import "./AddAta.css";

export default function AddAta(){
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const formatDate = (dateString) => {
        const dateParts = dateString.split("/");
        const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        return formattedDate;
    };

    const data = (location?.state?.data ?? "");
    const [dataReuniao, setDataReuniao] = useState((data !== "") ? formatDate(data) : "");
    const [mesCompetencia, setMesCompetencia] = useState(location?.state?.mesCompetencia);
    const [preparadoPor, setPreparadoPor] = useState(location?.state?.preparadoPor ?? "");
    const [participantes, setParticipantes] = useState((EditorState.createWithContent(stateFromHTML(location?.state?.participantes ?? ""))));
    const [observacoes, setObservacoes] = useState((EditorState.createWithContent(stateFromHTML(location?.state?.observacoes ?? ""))));
    const [selectedFile, setSelectedFile] = useState(null);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const storeId = useSelector((state) => state.StoreReducer.storeId);
    const isEdit = (location.state.isEdit ?? false);

    const handleAddAta = () => {
		const formData = new FormData();
		if (selectedFile !== null) {
			formData.append ("file", selectedFile);
		}
		formData.append ("ano", moment(mesCompetencia).format("YYYY"));
		formData.append ("mes", moment(mesCompetencia).format("MM"));
		formData.append ("data", moment(dataReuniao).format("YYYY-MM-DD"));
		formData.append ("preparado_por", preparadoPor);
		formData.append ("participantes", draftToHtml(convertToRaw(participantes.getCurrentContent())));
		formData.append ("observacoes",  draftToHtml(convertToRaw(observacoes.getCurrentContent())));
		api.UpdateAta(storeId, formData).then(() => {
            dispatch(showSnackMessage({message: "Ata salva com sucesso!"}));
            navigate("/ata");
		}).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            navigate("/ata");
		});
	};

    return (
        <React.Fragment>
            <div className="main">
                <Paper sx={{...styleGrid, backgroundColor: temaEscuro && PAPER_PADDING_THEME}}>
                    <Grid container spacing={3} data-testid="addAta">
                        <Grid item xs={12}>
                            {isEdit ? (
                                <React.Fragment>
                                    <Header title="Editar ata"/>
                                    <PathName firstPath="Atas de Reunião" secondPath="Editar Ata"/>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Header title="Adicionar Ata"/>
                                    <PathName firstPath="Atas de Reunião" secondPath="Adicionar Ata"/>
                                </React.Fragment>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} data-testid="date-picker">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                        <DatePicker
                                            label="Data da reunião"
                                            minDate={moment("2021-12-01").toDate()}
                                            maxDate={moment("2030-12-31").toDate()}
                                            value={moment(dataReuniao).toDate()}
                                            onChange={(newValue) => setDataReuniao(newValue)}
                                             slots={{
                                                openPickerIcon: CalendarTodayOutlinedIcon,
                                                textField: (params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{"data-testid": "date-picker-reuniao", ...params.inputProps}}
                                                        helperText={null}
                                                        sx={temaEscuro ? textCompleteStyleBlack : muiRootStyle}
                                                        style={{backgroundColor:temaEscuro && BLUE_THEME }}
                                                    />
                                                )
                                            }}
                                            slotProps={{
                                                inputAdornment: {position: "start"},
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} data-testid="month-picker">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                        <DatePicker
                                            label="Mês de competência"
                                            minDate={moment("2021-12-01").toDate()}
                                            maxDate={moment("2030-12-31").toDate()}
                                            value={moment(mesCompetencia).toDate()}
                                            onChange={(newValue) => setMesCompetencia(newValue)}
                                            slots={{
                                                openPickerIcon: CalendarTodayOutlinedIcon,
                                                textField: (params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{"data-testid": "date-picker-competencia", ...params.inputProps}}
                                                        helperText={null}
                                                        sx={temaEscuro ? textCompleteStyleBlack : muiRootStyle}
                                                        style={{backgroundColor:temaEscuro && BLUE_THEME }}
                                                    />
                                                )

                                            }}
                                            slotProps={{
                                                inputAdornment: {position: "start"},
                                            }}
                                            views={["month", "year"]}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        sx={{...(temaEscuro ? textCompleteStyleBlack : muiRootStyle), width: "20%", backgroundColor:temaEscuro && BLUE_THEME}}
                                        label={
                                            <span
                                                style={{
                                                    color: temaEscuro && BORDER_BUTTON,
                                                }}
                                            >
                                                Preparado por
                                            </span>
                                        }
                                        onChange={(e) => setPreparadoPor(e.target.value.trim())}
                                        value={preparadoPor}
                                        variant="outlined"
                                        data-testid="input-prepared-by"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} sx={{pl: 2}}>
                                        <Grid item xs={12} sx={{marginBottom: "10px"}}>
                                            <a style={{...styleDefaultText, fontWeight: 500, fontSize: "17px", color: temaEscuro && WHITE_THEME_BLACK}}>Participantes:</a>
                                        </Grid>
                                        <Grid item xs={8} sx={{...styles.textEditor, minHeight: "150px", backgroundColor: temaEscuro && BLUE_THEME, border: temaEscuro ? `1px solid ${INFO_THEME}` : "1px solid #E2E8F0", color: !temaEscuro && "black"}} className="font-header-editor">
                                            <Editor
                                                editorState={participantes}
                                                toolbar={{
                                                    options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "emoji", "history"],
                                                    inline: { inDropdown: true },
                                                    list: { inDropdown: true },
                                                    textAlign: { inDropdown: true },
                                                    link: { inDropdown: true },
                                                }}
                                                onEditorStateChange={setParticipantes}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} sx={{p: 2}}>
                                        <Grid item xs={12} sx={{marginBottom: "10px"}}>
                                            <a style={{...styleDefaultText, fontWeight: 500, fontSize: "17px",  color: temaEscuro && WHITE_THEME_BLACK}}>Observações:</a>
                                        </Grid>
                                        <Grid item xs={8} sx={{...styles.textEditor, backgroundColor:temaEscuro && BLUE_THEME, border: temaEscuro ? `1px solid ${INFO_THEME}` : "1px solid #E2E8F0", color: !temaEscuro && "black"}} className="font-header-editor">
                                            <Editor
                                                editorState={observacoes}
                                                toolbar={{
                                                    options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "emoji", "history"],
                                                    inline: { inDropdown: true },
                                                    list: { inDropdown: true },
                                                    textAlign: { inDropdown: true },
                                                    link: { inDropdown: true },
                                                }}
                                                onEditorStateChange={setObservacoes}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <AttachFileModal setSelectedFile={setSelectedFile}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={2} style={{alignItems: "end", justifyContent: "end", marginRight: "2%"}}>
                                                <Grid item>
                                                    <GrayButton
                                                            isRedirect
                                                            title={"Cancelar"}
                                                            path={"/ata"}
                                                            testId={"button-cancel"}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    {isEdit ? (
                                                        <YellowButton title={"Salvar ata"} testId="button-create-ata" onClick={handleAddAta}/>
                                                    ) : (
                                                        <YellowButton 
                                                            title={"Criar ata de reunião"}
                                                            onClick={handleAddAta}
                                                            testId="button-create-ata"/>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </React.Fragment>
    );
}

const styles = {
    textEditor: {
		borderRadius: "5px",
		minHeight: "250px",
        color: WHITE_ESCRITA_THEME
	}
};