import React from "react";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";
import {customFooterStyle} from "../../shared/utils";

import { Grid } from "@mui/material";

const PlanoContasFooter = ({categoriasList, modifiedRows, handleCellClick, sendListCategoriaRequest, dataLength}) => {

    return (
        <Grid item sx={{...customFooterStyle}}  xs={12}>
            <span><p>Número Total de Linhas: {dataLength}</p></span>
            {
                (modifiedRows && modifiedRows.length > 0) && (
                    <span><p>Número Total de Linhas Modificadas: {modifiedRows.length}</p></span>
                )
            }
            {
                (categoriasList && categoriasList.length > 0) && (
                    <GrayButton
                        title={"Deletar"}
                        onClick={() => handleCellClick()}
                    />
                )
            }
            {
                (modifiedRows && modifiedRows.length > 0) && (
                    <YellowButton
                        title={"Confirmar modificações"}
                        onClick={() => sendListCategoriaRequest()}
                    />
                )
            }
        </Grid>
    );
};

export default React.memo(PlanoContasFooter);