import React  from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
	BLUE_THEME, smallPaperStyle,
	styleText,
	styleTextStrong, WHITE_ESCRITA_THEME, WHITE_THEME_BLACK, BORDER_TABLE, BORDER_BUTTON, DASH_KPI_DATA_COLOR
} from "../../shared/utils";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";
import IntlCurrencyInput from "react-intl-currency-input";
import LoadingButton from "@mui/lab/LoadingButton";
import {useSelector} from "react-redux";

const ModalFixedValue = (props) => {
	const {
		openModalFixedValue,
		setOpenModalFixedValue,
		dateFixed,
		fixedValue,
		setFixedValue,
		sendMetadata,
		delMetadata,
		setOpenModal,
		component,
		loadingModal,
		selectedField
	} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);


	const currencyConfig = {
		locale: "pt-BR",
		formats: {
			number: {
				BRL: {
					style: "currency",
					currency: "BRL",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				},
			},
		},
	};

	const handleDeleteFixedValue = () => {
		if (component === "conciliacao"){
			delMetadata("valor_fixo");
		} else {
			delMetadata("fixed");
		}
	};

	const handleSendFixedValue = () => {
		if (component === "conciliacao"){
			sendMetadata("valor_fixo", fixedValue);
		} else {
			sendMetadata("fixed", fixedValue);
		}
	};

	const backModal = () => {
		setOpenModalFixedValue(false);
		setOpenModal(true);
	};

	return(
		<React.Fragment>
			<Modal
				open={openModalFixedValue}
				onClose={() => setOpenModalFixedValue(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				data-testid="modal-fixed-value"
			>
				<Paper className="modal" style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, padding: "20px"}}>
					<Grid item xs={10} className="start">
						{
							component === "conciliacao" ? (
								<h3 style={{...styleTextStrong, color: temaEscuro && WHITE_ESCRITA_THEME, marginTop: 0}}>Taxa ({dateFixed}):</h3>
							) : (
								<h3 style={{...styleTextStrong, color: temaEscuro && WHITE_ESCRITA_THEME, marginTop: 0}}>{selectedField}:</h3>
							)
						}
					</Grid>
					<Grid item xs={10} className="start">
						<span style={{...styleText, color: temaEscuro && WHITE_THEME_BLACK}}>Fixar um valor para o campo</span>
					</Grid>
					<Grid item className="relative" sx={{marginTop: 0.5}}>
						<div className="divCurrencyInput" style={{border: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${BORDER_BUTTON}`}}>
							<IntlCurrencyInput
								value={fixedValue}
								currency="BRL"
								config={currencyConfig}
								onChange={(event, value) => setFixedValue(value)}
								className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
								style={{...(fixedValue === 0 || fixedValue === "-" ? styleText : styleTextStrong), color: temaEscuro ? WHITE_THEME_BLACK : DASH_KPI_DATA_COLOR, border: 0}}
							/>
						</div>
					</Grid>
					<Grid container sx={{display: "flex", justifyContent: "end", gap: 2, marginTop: 5}}>
						{
							(fixedValue !== 0 && fixedValue !== "-") && (
								<Grid item>
									{loadingModal ? (
										<LoadingButton variant="outlined" loading >
											Deletar Valor
										</LoadingButton>
									) : (
										<GrayButton
											title="Deletar valor"
											onClick={() => handleDeleteFixedValue()}
											testId="modal-fixed-value-del-btn"
										/>
									)}

								</Grid>
							)
						}
						<Grid item>
							{loadingModal ? (
								<LoadingButton variant="outlined" loading >
									Cancelar
								</LoadingButton>
							) : (
								<GrayButton
									onClick={() => backModal()}
									title="Cancelar"
									testId="modal-fixed-value-cancel-btn"
								/>
							)}

						</Grid>
						<Grid item>
							{loadingModal ? (
								<LoadingButton variant="outlined" loading >
									Fixar Valor
								</LoadingButton>
							) : (
								<YellowButton
									onClick={handleSendFixedValue}
									title="Fixar valor"
									testId="modal-fixed-value-btn"
								/>
							)}
						</Grid>
					</Grid>
				</Paper>
			</Modal>
		</React.Fragment>
	);
};

export default React.memo(ModalFixedValue);