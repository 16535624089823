import React, {useState, useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import InputPassword from "../../components/Input/InputPassword";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import api from "../../axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loading from "../../components/Loading/Loading";
import VoxelLogo from "./VoxelLogo.png";
import RestaurantImage from "./RestaurantImage.png";
import CopyRight from "../../components/CopyRight/CopyRight";
import { GRAY_LABEL_UX, RED_ERROR_UX, GRAY_HEADER_UX, GREEN_SUCCESS_UX, styleYellowButton } from "../../shared/utils";
import "./Login.css";
import {showSnackMessage} from "../../actions/SnackActions";



const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Inter, cursive",
            color: GRAY_LABEL_UX,
            fontSize: "17px",
            fontWeight: "500",
        },
        button: {
            textTransform: "none",
        }
    },
});


export default function ResetPwd() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [new_pwd, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");

    useEffect(() => {
		setEmail(location.state.email);
		setCode(location.state.code);
	}, []);

	const handleSubmit = () => {
		const data = {
			email,
			new_pwd,
			code
		};
		setLoading(true);
		api.GetResetPwd(data).then(() => {
			setLoading(false);
            dispatch(showSnackMessage({message: "Sua senha foi alterada com sucesso!"}));
            navigate("/login");
		}).catch(() => {
			setLoading(false);
		});
	};

    const validatePassword = (validation) => {
		let error = false;

        if (validation === "length") {
            if (new_pwd.length < 8){
                error = true;
            }
        } else if (validation === "match") {
            if (new_pwd.length === 0 || confirmPassword !== new_pwd ){
                error = true;
            }
        }
        return error;
	};

	return (
		<React.Fragment>
            <Grid container spacing={0} sx={{overflow: "hidden", height: "100vh"}}>
                <Grid item xs={12} sm={12} md={7} lg={8} xl={8} sx={{display: "flex", alignItems: "center"}}>
                    <div style={styles.center}>
                        <Grid container spacing={0} sx={{paddingTop: 15}}>
                            <Grid item xs={12}>
                                <img src={VoxelLogo} style={styles.logo}/>
                            </Grid>
                            <Grid item xs={12}>
                                <p style={styles.header}>Crie sua nova senha</p>
                            </Grid>
                            <Grid item xs={12}>
                                <h1 style={styles.text}>Insira uma senha diferente da anterior</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <Box component="form" sx={{ mt: 1 }}>
                                <InputPassword label="Senha" password={new_pwd} handleChange={(e) => setNewPassword(e.target.value)}/>
                                <InputPassword label="Confirmar senha" password={confirmPassword} handleChange={(e) => setConfirmPassword(e.target.value)}/>
                                {
                                    (validatePassword("length") === true) ? (
                                        <Typography sx={{color: RED_ERROR_UX, marginTop: "10px"}}>
                                            *A senha deve conter mais de 8 caracteres.
                                        </Typography>
                                    ) : (
                                        <Typography sx={{color: GREEN_SUCCESS_UX, marginTop: "10px"}}>
                                            *A senha deve conter mais de 8 caracteres.
                                        </Typography>
                                    )
                                }
                                {
                                    (validatePassword("match") === true) ? (
                                        <Typography sx={{color: RED_ERROR_UX}}>
                                            *Ambos os campos devem possuir a mesma senha.
                                        </Typography>
                                    ) : (
                                        <Typography sx={{color: GREEN_SUCCESS_UX}}>
                                            *Ambos os campos devem possuir a mesma senha.
                                        </Typography>
                                    )
                                }
                                    {
                                        loading ? (
                                            <Loading />
                                        ) : (
                                            <ThemeProvider theme={theme}>
                                                { validatePassword("length") || validatePassword("match") ? (
                                                <Button
                                                    data-testid="unabled-submit-button"
                                                    disabled="true"
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                    style={{fontWeight: "600"}}
                                                >
                                                    Recuperar senha 
                                                </Button>
                                                ) : (
                                                    <Button
                                                    data-testid="submit-button"
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                    onClick={() => handleSubmit()}
                                                    style={styleYellowButton}
                                                    >
                                                        Recuperar senha
                                                    </Button>
                                                )}
                                            </ThemeProvider>
                                        )
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        <div style={styles.copyright}>
                            <CopyRight color="black"/>
                        </div>
                    </div>
                </Grid>
                <Box
                    component={Grid}
                    item
                    xs={0} 
                    sm={0}
                    md={5}
                    lg={4}
                    xl={4}
                    display={{ xs: "none", sm: "none", md: "block", lg: "block", xl: "block" }}
                >
                    <img src={RestaurantImage} className="img"></img>
                </Box>
            </Grid>
		</React.Fragment>
	);
}

const styles = {
    center: {
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
	logo: {
        userDrag: "none",
        userSelect: "none",
        pointerEvents: "none",
        width: "191px",
        height: "36px"
	},
    header: {
        width: "600px",
        height: "15px",
        fontFamily: "Kallisto",
        fontWeight: "bold",
        fontSize: "36px",
        lineHeight: "32px",
        color: GRAY_HEADER_UX
    },
    text: {
        width: "600px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: GRAY_LABEL_UX,
        textAllign: "left center",
        marginTop: "10px",
    },
    button: {
        width: "54px",
        height: "28px",
        fontStyle: "normal",
        fontWeight: "600",
    },
    copyright: {
        textAlign: "center",
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "end",
        paddingBottom: "40px"
    }
};