export const steps = [
    {
        selector: "[data-testid='filter-estabelecimentos']", 
        content: "Filtra o(s) estabelecimento(s) apresentado(s) no 'Resumo Ifood'."
    },
    {
        selector: ".faturamento-kpis", 
        content: "Valores referentes ao faturamento do período escolhido, é possível detalhar estes valores ao clicar em qualquer um dos cards."
    },
    {
        selector: ".tabela-repasses", 
        content: "Tabela com todos os repasses do período escolhido, é possível detalhar estes valores ao clicar em qualquer uma das linhas."
    },
    {
        selector: ".resumo-repasses", 
        content: "Mostra os valores totais dos repasses do período escolhido."
    }
];