import React, {useState} from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import GrayButton from "../../Buttons/GrayButton";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import YellowButton from "../../Buttons/YellowButton";
import Grid from "@mui/material/Grid";
import {showSnackMessage} from "../../../actions/SnackActions";
import {useDispatch, useSelector} from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import {PAPER_PADDING_THEME, WHITE_ESCRITA_THEME} from "../../../shared/utils";


function AttachFileModal(props){
	const dispatch = useDispatch();

	const {setSelectedFile} = props;
	const [open, setOpen] = useState(false);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);


	const fileTypes = ["JPG", "PNG", "GIF", "DOC", "DOCX", "DOTX", "DOTM", "DOCM", "PDF"];

	const handleChangeFile = (file) => {
		setSelectedFile(file);
	};

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleAppendImage = () => {
		setOpen(false);
		dispatch(showSnackMessage({message: "Arquivo anexado com sucesso!"}));
	};

	const removeAttach = () => {
		setSelectedFile();
		dispatch(showSnackMessage({message: "Anexo removido com sucesso!"}));
	};

	return(
        <React.Fragment>
            <div className="main-modal">
				<Grid container spacing={2}>
					<Grid item>
						<GrayButton title="Remover anexo" startIcon={<DeleteForeverIcon/>} onClick={removeAttach}/>
					</Grid>
					<Grid item>
						<YellowButton testId="attach-file" title="Anexar arquivo" startIcon={<AttachFileOutlinedIcon/>} onClick={handleOpen} />
					</Grid>
				</Grid>
			</div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Paper style={{...styles.modal, backgroundColor: temaEscuro && PAPER_PADDING_THEME}}>
					<Grid container>
						<Grid item xs={12}>
							<h3 className="info-text" style={{ color: temaEscuro && WHITE_ESCRITA_THEME}}>Importar Arquivos</h3>
						</Grid>
						<Grid item xs={12}>
							<FileUploader label="Busque ou arraste aqui um arquivo" hoverTitle="Arraste um arquivo" handleChange={handleChangeFile} name="file" types={fileTypes} />
						</Grid>
						<Grid item xs={12} sx={{marginTop: 2}}>
							<YellowButton title="Anexar Arquivo" testId="attach-file-modal" startIcon={<UploadFileIcon/>} onClick={handleAppendImage} />
						</Grid>
					</Grid>
				</Paper>
			</Modal>
		</React.Fragment>

	);
}
const styles = {
	main: {
		marginBottom: "0px"
	},
	modal: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "30%",
		height: "auto",
		bgcolor: "background.paper",
		padding: "20px",
	},

};

export default AttachFileModal;