import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
    styleText,
    smallPaperStyle,
    BLUE_THEME,
    WHITE_THEME_BLACK,
    styleTextStrong,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    GRAY_BORDER_UX,
    BORDER_BUTTON,
    GRAY_STRONG_UX,
    WHITE_TABLE,

} from "../../../shared/utils";
import YellowButton from "../../Buttons/YellowButton";
import IntlCurrencyInput from "react-intl-currency-input";
import TextField from "@mui/material/TextField";


const InsertProjetada = (props) => {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const { 
        openInsertProjetada, setOpenInsertProjetada, faturamentoProjetado, setFaturamentoProjetado,
        taxasimpostosProjetado, setTaxasimpostosProjetado, cmvPorcentagemProjetado, setCmvPorcentagemProjetado,
        despesasEntregasProjetado, setDespesasEntregasProjetado, despesasPessoalProjetado, setDespesasPessoalProjetado,
        despesasOperacionalProjetado, setDespesasOperacionalProjetado, postDREProjetada, isEdit
    } = props;

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    return (
        <React.Fragment>
            <Modal
                open={openInsertProjetada}
                onClose={() => setOpenInsertProjetada(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="insert-projetada-modal"
            >
                <Paper sx={{ ...smallPaperStyle, width: "45%", backgroundColor: temaEscuro ? BLUE_THEME : null }}>
                    <Grid container>
                        {isEdit || !isAdmin ? (
                            <Grid item xs={10} className="start">
                                <span style={{...styleText, fontWeight: "700", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Valores projetados para o próximo mês: </span>
                            </Grid>
                        ) : (
                            <Grid item xs={10} className="start">
                                <span style={{...styleText, fontWeight: "700", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Forneça um valor para cada campo abaixo: </span>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={1} sx={{marginTop: 4}}>
                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <span style={{ ...styleText, color: temaEscuro && WHITE_THEME_BLACK }}>Faturamento Bruto Total (R$):</span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="divCurrencyInput">
                                                <IntlCurrencyInput
                                                    value={faturamentoProjetado}
                                                    currency="BRL"
                                                    config={currencyConfig}
                                                    onChange={(event, value) => setFaturamentoProjetado(value)}
                                                    className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                                                    style={{ ...(faturamentoProjetado === 0 || faturamentoProjetado === "-" ? styleText : styleTextStrong), color: temaEscuro && WHITE_THEME_BLACK }}
                                                    data-testid="input-faturamento"
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <span style={{ ...styleText, color: temaEscuro && WHITE_THEME_BLACK }}>Taxas e Impostos sobre Vendas (R$):</span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="divCurrencyInput">
                                                <IntlCurrencyInput
                                                    value={taxasimpostosProjetado}
                                                    currency="BRL"
                                                    config={currencyConfig}
                                                    onChange={(event, value) => setTaxasimpostosProjetado(value)}
                                                    className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                                                    style={{ ...(taxasimpostosProjetado === 0 || taxasimpostosProjetado === "-" ? styleText : styleTextStrong), color: temaEscuro && WHITE_THEME_BLACK }}
                                                    data-testid="input-taxas-impostos"
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{marginTop: 3}}>
                                            <span style={{ ...styleText, color: temaEscuro && WHITE_THEME_BLACK }}>CMV Porcentagem (%):</span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                sx={{
                                                    ...styles.formControl,
                                                    fontSize: "10px",
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                            border: 0,
                                                        },
                                                    },
                                                    width: "62%",
                                                }}
                                                type="text"
                                                value={cmvPorcentagemProjetado}
                                                onChange={(e) => setCmvPorcentagemProjetado(e.target.value)}
                                                className="obs"
                                                placeholder="CMV (%)"
                                                inputProps={{style: {backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE, color: temaEscuro ? BORDER_BUTTON : GRAY_STRONG_UX}}}
                                                data-testid="input-cmv-porcentagem"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{marginTop: 3}}>
                                            <span style={{ ...styleText, color: temaEscuro && WHITE_THEME_BLACK }}>Despesas com Entregas (R$):</span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="divCurrencyInput">
                                                <IntlCurrencyInput
                                                    value={despesasEntregasProjetado}
                                                    currency="BRL"
                                                    config={currencyConfig}
                                                    onChange={(event, value) => setDespesasEntregasProjetado(value)}
                                                    className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                                                    style={{ ...(despesasEntregasProjetado === 0 || despesasEntregasProjetado === "-" ? styleText : styleTextStrong), color: temaEscuro && WHITE_THEME_BLACK }}
                                                    data-testid="input-despesas-entregas"
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{marginTop: 3}}>
                                            <span style={{ ...styleText, color: temaEscuro && WHITE_THEME_BLACK }}>Despesas com Pessoal (R$):</span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="divCurrencyInput">
                                                <IntlCurrencyInput
                                                    value={despesasPessoalProjetado}
                                                    currency="BRL"
                                                    config={currencyConfig}
                                                    onChange={(event, value) => setDespesasPessoalProjetado(value)}
                                                    className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                                                    style={{ ...(despesasPessoalProjetado === 0 || despesasPessoalProjetado === "-" ? styleText : styleTextStrong), color: temaEscuro && WHITE_THEME_BLACK }}
                                                    data-testid="input-despesas-pessoal"
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{marginTop: 3}}>
                                            <span style={{ ...styleText, color: temaEscuro && WHITE_THEME_BLACK }}>Despesas Operacionais e Administrativas (R$):</span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="divCurrencyInput">
                                                <IntlCurrencyInput
                                                    value={despesasOperacionalProjetado}
                                                    currency="BRL"
                                                    config={currencyConfig}
                                                    onChange={(event, value) => setDespesasOperacionalProjetado(value)}
                                                    className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                                                    style={{ ...(despesasOperacionalProjetado === 0 || despesasOperacionalProjetado === "-" ? styleText : styleTextStrong), color: temaEscuro && WHITE_THEME_BLACK }}
                                                    data-testid="input-despesas-operacional"
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {isAdmin ? (
                                    isEdit ? (
                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "end", marginTop: 3}}>
                                            <YellowButton
                                                title="Editar"
                                                onClick={() => postDREProjetada()}
                                                testId="modal-button-projetar"
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "end", marginTop: 3}}>
                                            <YellowButton
                                                title="Projetar"
                                                onClick={() => postDREProjetada()}
                                                testId="modal-button-projetar"
                                            />
                                        </Grid>
                                    )
                                ) : <Grid item xs={12} sx={{marginTop: "15px"}} />}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default InsertProjetada;

const styles = {
    formControl: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: GRAY_BORDER_UX
            },
        }
    }
};