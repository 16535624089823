import React  from "react";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {BLACK_TABLE, WHITE_TABLE, GRAY_STRONG_UX, avatarStyle, PURPLE_INFO_UX, styleDefaultText, imagePerfilSmall} from "../../shared/utils";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import CircleIcon from "@mui/icons-material/Circle";

const TooltipTable = (props) => {
	let {comments} = props;

	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(() => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: WHITE_TABLE,
			color: BLACK_TABLE,
			maxWidth: 420,
			border: "1px solid #dadde9",
		},
	}));

	const initals = (name) => {
		let nameSplit = name.split(" ").map((word) => word[0]).join("");
		return nameSplit;
	};

	const initalName = (name) => {
		let nameSplit = name.split(" ");
		return nameSplit[0];
	};

	const getHtmlTitle = () =>{
		return(
			<React.Fragment>
				<Grid container data-testid="tooltip-table">
					<Grid item sx={{display: "flex", alignItems: "center", marginRight: 0.5}}>
						<CircleIcon className="circles" style={{ color: PURPLE_INFO_UX, }}/>
					</Grid>
					<Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center"}}>
						<a style={{fontSize: "16px", color: GRAY_STRONG_UX, fontWeight: "700"}}>Comentário</a>
					</Grid>
				</Grid>
				{
					comments.map((item, index) => {
						return(
							<React.Fragment key={index}>
								<Grid container spacing={1} sx={{alignItems: "center", marginBottom: "10px"}}>
									<Grid item>
										<Avatar sx={{...avatarStyle, height: 24, width: 24, fontSize: 10}}>
											{
												item.image_perfil ? (
													<img src={item.image_perfil} style={{...imagePerfilSmall}}/>
												) : (
													<React.Fragment>
														{initals(item.user_comment)}
													</React.Fragment>
												)
											}
										</Avatar>
									</Grid>
									<Grid item>
										<h3 color="inherit">
											{initalName(item.user_comment)}:
										</h3>
									</Grid>
									<Grid item xs={12}>
										<a dangerouslySetInnerHTML={{__html: item.comment != null ? item.comment.replaceAll("\n","<br>") : ""}}></a>
									</Grid>
								</Grid>
							</React.Fragment>
						);
					})
				}
			</React.Fragment>
		);
	};

	return(
		<React.Fragment>
			{comments?.length ? (
				<HtmlTooltip
					title={getHtmlTitle()}
					placement="right-start">
					{props.children}
				</HtmlTooltip>
			) : (
				props.children
			)
			}

		</React.Fragment>
	);
};

export default React.memo(TooltipTable);