import React, { useCallback, useState } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
    smallPaperStyle,
    BLUE_THEME,
    styleGrid
} from "../../../shared/utils";
import YellowButton from "../../Buttons/YellowButton";
import GrayButton from "../../Buttons/GrayButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InsertProjetada from "./InsertProjetada";
import ViewProjetada from "./ViewProjetada";


const GenericProjetada = (props) => {
    const { 
        openProjetada, setOpenProjetada, selectedDate, faturamentoProjetado, setFaturamentoProjetado,
        taxasimpostosProjetado, setTaxasimpostosProjetado, cmvPorcentagemProjetado, setCmvPorcentagemProjetado,
        despesasEntregasProjetado, setDespesasEntregasProjetado, despesasPessoalProjetado, setDespesasPessoalProjetado,
        despesasOperacionalProjetado, setDespesasOperacionalProjetado, postDREProjetada, setOpenViewProjetada, setOpenInsertProjetada,
        openInsertProjetada, openViewProjetada, getDREProjetadas, faturamentoLiqTotalViewProjetado, taxasimpostosViewProjetado,
        cmvPorcentViewProjetado, despesasEntregasViewProjetado, despesasPessoalViewProjetado, despesasOperacionalViewProjetado,
        lucroPrejuizoLiqViewProjetado, lucroPrejuizoProjetado, lucroPrejuizoLiqPorcentViewProjetado, lucroPrejuizoPorcentagemProjetado,
        cmoViewProjetado, cmoProjetado, pontoEquilibrioViewProjetado, pontoEquilibrioProjetado, getMetasDREProjetada, isEdit, setIsEdit
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const [checked, setChecked] = useState("");

    const handleOpenModal = useCallback(() => {
        setOpenProjetada(false);
        if (checked === "viewProjetada") {
            getDREProjetadas();
            
        } else if (checked === "insertProjetada") {
            setIsEdit(false);
            setOpenInsertProjetada(true);
        } else if (checked === "editProjetada") {
            getMetasDREProjetada();
        }
    }, [checked, setOpenProjetada]);

    const handleChangeChecked = (event) => {
        setChecked(event.target.value);
    };

    return(
        <React.Fragment>
            <InsertProjetada
                openInsertProjetada={openInsertProjetada}
                setOpenInsertProjetada={setOpenInsertProjetada}
                faturamentoProjetado={faturamentoProjetado}
                setFaturamentoProjetado={setFaturamentoProjetado}
                taxasimpostosProjetado={taxasimpostosProjetado}
                setTaxasimpostosProjetado={setTaxasimpostosProjetado}
                cmvPorcentagemProjetado={cmvPorcentagemProjetado}
                setCmvPorcentagemProjetado={setCmvPorcentagemProjetado}
                despesasEntregasProjetado={despesasEntregasProjetado}
                setDespesasEntregasProjetado={setDespesasEntregasProjetado}
                despesasPessoalProjetado={despesasPessoalProjetado}
                setDespesasPessoalProjetado={setDespesasPessoalProjetado}
                despesasOperacionalProjetado={despesasOperacionalProjetado}
                setDespesasOperacionalProjetado={setDespesasOperacionalProjetado}
                postDREProjetada={postDREProjetada}
                isEdit={isEdit}
            />
            <ViewProjetada
                openViewProjetada={openViewProjetada}
                setOpenViewProjetada={setOpenViewProjetada}
                selectedDate={selectedDate}
                faturamentoLiqTotalViewProjetado={faturamentoLiqTotalViewProjetado}
                faturamentoProjetado={faturamentoProjetado}
                taxasimpostosViewProjetado={taxasimpostosViewProjetado}
                taxasimpostosProjetado={taxasimpostosProjetado}
                cmvPorcentViewProjetado={cmvPorcentViewProjetado}
                cmvPorcentagemProjetado={cmvPorcentagemProjetado}
                despesasEntregasViewProjetado={despesasEntregasViewProjetado}
                despesasEntregasProjetado={despesasEntregasProjetado}
                despesasPessoalViewProjetado={despesasPessoalViewProjetado}
                despesasPessoalProjetado={despesasPessoalProjetado}
                despesasOperacionalViewProjetado={despesasOperacionalViewProjetado}
                despesasOperacionalProjetado={despesasOperacionalProjetado}
                lucroPrejuizoLiqViewProjetado={lucroPrejuizoLiqViewProjetado}
                lucroPrejuizoProjetado={lucroPrejuizoProjetado}
                lucroPrejuizoLiqPorcentViewProjetado={lucroPrejuizoLiqPorcentViewProjetado}
                lucroPrejuizoPorcentagemProjetado={lucroPrejuizoPorcentagemProjetado}
                cmoViewProjetado={cmoViewProjetado}
                cmoProjetado={cmoProjetado}
                pontoEquilibrioViewProjetado={pontoEquilibrioViewProjetado}
                pontoEquilibrioProjetado={pontoEquilibrioProjetado}
            />
            <Modal
                open={openProjetada}
                onClose={() => setOpenProjetada(false)}
                aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
                data-testid="generic-modal"
            >
                <Paper style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME}}>
                    <Grid container>
                        <Grid item xs={10} className="start">
                            <h3>DRE Projetada:</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">Selecione a opção desejada:</FormLabel>
                                        <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                name="radio-buttons-group"
                                                value={checked}
                                                onChange={handleChangeChecked}
                                        >
                                            <FormControlLabel value="viewProjetada" control={<Radio />} label="Comparar projeção e realidade do mês atual" />
                                            <FormControlLabel value="editProjetada" control={<Radio />} label="Visualizar/Editar projeção para o mês seguinte" />
                                            <FormControlLabel value="insertProjetada" control={<Radio />} label="Criar projeção para o mês seguinte" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container sx={{display: "flex", justifyContent: "end"}}>
                                        <Grid item sx={styleGrid}>
                                            <GrayButton
                                                onClick={() => setOpenProjetada(false)}
                                                title="Cancelar"/>
                                        </Grid>
                                        <Grid item sx={styleGrid}>
                                            <YellowButton
                                                onClick={handleOpenModal}
                                                testId="generic-continue-btn"
                                                title="Continuar"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default React.memo(GenericProjetada);