
export const steps = [
    {
        selector: ".categoria",
        content: "Encontram-se as contas categorizadas e não categorizadas, para organização."
    },
    {
        selector: ".buttonChangeCategory",
        content: "Altera a categoria da conta."
    },
    {
        selector: ".buttonUpdateCA",
        content: "Atualiza os dados do Conta Azul."
    },
    {
        selector: ".buttonAddPlano",
        content: "Adicionar novas categorias ao plano de contas."
    },
    {
        selector: ".buttonCopyPlano",
        content: " Copia um plano de contas já existente."
    }
];