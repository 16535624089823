import React, {useState, useEffect} from "react";
import api from "../../axios";
import {useSelector} from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import SelectStore from "../../components/SelectStore/SelectStore";
import LoginInfo from "../../components/LoginInfo/LoginInfo";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
    GRAY_LABEL_UX,
    GRAY_DATE_UX,
    GRAY_BORDER_UX,
    WHITE_ESCRITA_THEME,
    copyToClipboard,
    paperStyle,
    BLUE_THEME,
    WALPAPPER_THEME,
    GRAY_BG_UX,
    BORDER_TABLE,
    BLUE_LIGHT_UX_THEME,
    WHITE_THEME_BLACK, BLUE_LIGHT_UX
} from "../../shared/utils";
import LastModified from "../../components/LastModified/LastModified";
import GrayButton from "../../components/Buttons/GrayButton";
import Grid from "@mui/material/Grid";

export default function Logins() {
    const [loading, setLoading] = useState(false);
    const [logins, setLogins] = useState([]);
    const storeId = useSelector(state => state.StoreReducer.storeId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        if(storeId !== 0){
            getLogins();
        }
    }, [storeId]);

    const getLogins = () => {
        setLoading(true);
        setLogins([]);

        const dataRequest = {
            loja_id: storeId,
        };
        api.GetLogins(dataRequest).then(response => {
            setLoading(false);
            let dataResponse = response.data;
            setLogins(dataResponse);
        }).catch(() => {
            setLoading(false);
            setLogins([]);
        });
    };

    return (
        <div style={{...styles.main, color: temaEscuro && WHITE_ESCRITA_THEME}}>
            <LastModified title="Logins"/>
            {
                loading ? (
                    <React.Fragment>
                        {[...Array(10).keys()].map((i, d) => (
                            <Skeleton height={50} key={d} />
                        ))}
                    </React.Fragment>
                ) : (
                    storeId === 0 ? (
                        <SelectStore />
                    ) : (
                        <React.Fragment>
                            <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? WALPAPPER_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "end"}}>
                                        <GrayButton testId="cadastrar-api-button" isRedirect path="/api" title="Cadastrar API" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                borderRadius: 2,
                                                border: 0.5,
                                                borderColor: temaEscuro ? BORDER_TABLE : GRAY_BORDER_UX,
                                            }}
                                            data-testid="logins"
                                        >
                                            <TableContainer sx={{backgroundColor: temaEscuro && BLUE_THEME, borderRadius: 2}}>
                                                <Table sx={{ minWidth: 650}} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell
                                                                align="left"
                                                                className="title-ca"
                                                                style={{...styles.styleTitleTable, color : temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, borderColor: temaEscuro && BORDER_TABLE}}
                                                            >
                                                                Conta
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className="title-ca"
                                                                style={{...styles.styleTitleTable, color : temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, borderColor: temaEscuro && BORDER_TABLE}}
                                                            >
                                                                Login
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className="title-ca"
                                                                style={{...styles.styleTitleTable, color : temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, borderColor: temaEscuro && BORDER_TABLE}}
                                                            >
                                                                Senha
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {logins?.map((item) => (
                                                            <TableRow key={`${item.id}-${item.descricao}-${item.plataforma}`}>
                                                                <TableCell align="left" style={{...styles.styleTable, color : temaEscuro ? WHITE_THEME_BLACK : GRAY_DATE_UX, borderColor: temaEscuro && BORDER_TABLE}}>
                                                                    <a
                                                                        href={item.url}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        style={{
                                                                            color: temaEscuro ? BLUE_LIGHT_UX_THEME : BLUE_LIGHT_UX,
                                                                            textDecoration: "none",
                                                                        }}
                                                                    >
                                                                        {item.plataforma}
                                                                    </a>
                                                                </TableCell>
                                                                <TableCell align="left" style={{...styles.styleTable, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_DATE_UX, borderColor: temaEscuro && BORDER_TABLE}}>
                                                                    <span>{item.login}</span>
                                                                    <Button
                                                                        size="small"
                                                                        data-testid="copy-login"
                                                                        onClick={() => copyToClipboard(item.login)}
                                                                    >
                                                                        <ContentCopyIcon
                                                                            style={{ color : temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX }}
                                                                        />
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell align="left" style={{...styles.styleTable, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_DATE_UX, borderColor: temaEscuro && BORDER_TABLE}}>
                                                                    <LoginInfo
                                                                        item={item}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </React.Fragment>
                    )
                )}
        </div>
    );
}

const styles = {
    main: {
        margin: "30px",
    },

    styleTitleTable: {
        color: GRAY_LABEL_UX,
        fontSize: "16px"
    },

    styleTable: {
        color: GRAY_DATE_UX,
        fontSize: "14px"
    }
};