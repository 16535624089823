import React, {useState, useEffect, useMemo} from "react";
import ReactECharts from "echarts-for-react";
import Grid from "@mui/material/Grid";
import {
	fontFamily, formatterCurrency, legend,
    DASH_LINESTYLE_COLOR, DASH_AXIS_COLOR,
    styleDefaultText, formatterThousand, DASH_LABEL_COLOR, BLUE_THEME,
	GRAY_TABLE, TEXT_THEME, WHITE_ESCRITA_THEME, BORDER_TABLE, WHITE_THEME_BLACK,
	BORDER_BUTTON, GRAY_LABEL_UX, INFO_THEME
} from "../../shared/utils";
import {useSelector} from "react-redux";

const FluxoGraph = (props) => {
	const {data} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	const lineFormatter = (params) => {
		let data = params.data;
		if (data === 0) {
			return "";
		}
		else {
			return formatterThousand(params.data);
		}
	};

    const axisLabel = {
        splitLine: {
            show: true,
            lineStyle: {
                color: temaEscuro ? TEXT_THEME : DASH_LINESTYLE_COLOR,
                type: "dashed"
            },
        },
        axisLabel: {
			fontFamily,
            color: temaEscuro ? WHITE_THEME_BLACK : DASH_AXIS_COLOR,
            formatter: formatterThousand,
        },
    };

	const [options, setOptions] = useState({
		color: ["#F26F72", "#5CB674", "#2260A9"],
		tooltip: {
			trigger: "axis",
			axisPointer: {
				type: "shadow"
			},
			fontFamily,
		},
		grid: {
			left: 0,
			right: "3%",
			bottom: 32, // padding is 2 (2*8px = 16px)
			top: 32, // padding is 2 (2*8px = 16px)
			containLabel: true
		},
		legend:{
			...legend,
			textStyle: {
				color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
			},
			inactiveColor: temaEscuro ? BORDER_BUTTON : "#ccc"
		},
		xAxis: [{
			name: "Dias",
			type: "category",
            axisLine: {
                lineStyle: {
					color: temaEscuro ? GRAY_TABLE : "#C9CDD4"
                }
			},
			axisTick: {
				alignWithLabel: true,
			},
            axisLabel: {
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK : "#8B8B92"
			},
			data: []
		}],
		yAxis: [
			{
			show: false,
			type: "value",
			name: "Pagamentos",
			position: "right",
			axisLine: {
				show: true,
				lineStyle: {
					color: "#5470C6"
				}
				
			},
            ...axisLabel
        },
			{
				show: false,
				type: "value",
				name: "Recebimentos",
				position: "right",
				offset: 80,
				...axisLabel
			},
			{
				type: "value",
				name: "(R$)",
				position: "left",
				axisLine: {
					show: false,
				},
				...axisLabel
			}
		],
		series: [
			{
				name: "Pagamentos",
				barGap: "-100%",
				barCategoryGap: "80%",
				type: "bar",
				yAxisIndex: 2,
				data: [],
				label: {
					show: true,
					position: "bottom",
					color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_LABEL_COLOR,
					fontFamily,
					formatter: lineFormatter
				},
				labelLayout: {
					hideOverlap: true,
				}
			},
			{
				name: "Recebimentos",
				type: "bar",
				yAxisIndex: 2,
				data: [],
				label: {
					show: true,
					position: "top",
					color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_LABEL_COLOR,
					fontFamily,
					formatter: lineFormatter
				},
				labelLayout: {
					hideOverlap: true,
				}
			},
			{
				name: "Saldo",
				type: "line",
				yAxisIndex: 2,
				data: [],
				smooth: true,
				symbol: "circle",
				label: {
					show: true,
					position: "top",
					color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_LABEL_COLOR,
					fontFamily,
					formatter: lineFormatter
				},
				labelLayout: {
					hideOverlap: true,
				}
			}
		]
	});
	const [key, setKey] = useState();

	useEffect(() => {
		let newOptions = {...options};
		let newSeries = newOptions?.series?.slice();
        let newXAxis = newOptions?.xAxis?.slice();
		let tooltip = {
			valueFormatter: function (value) {
				return formatterCurrency(value, 2);
			},
		};
		newSeries[0].data = data?.pagamentosFluxo;
		newSeries[1].data = data?.recebimentosFluxo;
		newSeries[2].data = data?.saldoFluxo;
		newOptions.series = newSeries;
		newXAxis[0].data = data?.axisGraph;
		newSeries[0].tooltip = tooltip;
		newSeries[1].tooltip = tooltip;
		newSeries[2].tooltip = tooltip;
		setOptions(newOptions);
		setKey(Math.random());
	}, [data]);

	const getChart = useMemo(() => {
		return(
			<ReactECharts key={key} option={options} notMerge={true}/>
		);
	}, [options]);

	return(
        <React.Fragment>
            <Grid container sx={{border: temaEscuro ? `1px solid ${INFO_THEME}` : "1px solid #E2E8F0", borderRadius: 2, backgroundColor: temaEscuro ? BLUE_THEME : null, borderColor: temaEscuro ? BORDER_TABLE : null}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <h3 style={{...styleDefaultText, fontSize: "18px", fontWeight: 500, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Fluxo de Caixa Diário</h3>
                </Grid>
                <Grid item xs={12} sx={{padding: "5px"}}>
                    {getChart}
                </Grid>
            </Grid>
        </React.Fragment>
	);
};

export default React.memo(FluxoGraph);