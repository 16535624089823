import React from "react";
import {stylePath, GRAY_STRONG_UX, GRAY_BG_UX} from "../../shared/utils";
import { useSelector } from "react-redux";


export default function PathName(props) {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    return (
        <a style={stylePath}>
            {props.firstPath}
            <b style={{color: temaEscuro ? GRAY_BG_UX : GRAY_STRONG_UX, fontWeight: 400, fontSize: "16px"}}>
                 / {props.secondPath}
            </b>
        </a>
    );
}