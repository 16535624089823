export const steps = [
    {
        selector: ".filtro",
        content: "Possibilita filtrar lançamentos de determinada competência.",
    },
    {
        selector: ".outros_filtros",
        content: "Clique nesse botão para selecionar filtros pré-determinados."
    },
    {
        selector: ".card_1", 
        content: "Soma do faturamento bruto dos meses selecionados, mostrando a média/mês e a variação comparada com o último mês."
    },
    {
        selector: ".card_2", 
        content: "Soma do faturamento líquido dos meses selecionados, mostrando a média/mês e a variação comparada com o último mês." 
    },
    {
        selector: ".card_3", 
        content: "Mostra a média do CMV dos meses selecionados e a porcentagem comparada com o último mês." 
    },
    {
        selector: ".card_4", 
        content: "Mostra a média do CMO dos meses selecionados e a porcentagem comparada com o último mês." 
    },
    {
        selector: ".card_5", 
        content: "Mostra a média do CMV (custo da mercadoria vendida) somado ao CMO (custo da mão de obra) e a variação do indicador em relação ao último mês."
    },
    
    {
        selector: ".card_6", 
        content: "Mostra o faturamento bruto e faturamento líquido mensal." 
    },
    {
        selector: ".card_7", 
        content: "Mostra, em média ponderada, o prazo de pagamento desde a compra até a quitação do pagamento."
    },
    {
        selector: ".card_8", 
        content: "Mostra, em média, o valor que a empresa precisa vender para atingir o ponto de equilíbrio." 
    },
    {
        selector: ".card_9",
        content: "Mostra uma comparação entre o ticket médio e o faturamento bruto total.",
    },
    {
        selector: ".card_10", 
        content: "Mostra o gasto médio de um cliente na loja, somando todas as plataformas (ex.: Apps delivery, Adquirentes, Vouchers, ...)."
    },
    {
        selector: ".card_11", 
        content: "Mostra o gasto médio de um cliente na loja, somando todas os Apps de Delivery (ex.: Ifood, Rappi, ...)."
    },
    {
        selector: ".card_12", 
        content: "Mostra o gasto médio de um cliente na loja, somando todas as Adquirentes e os Vouchers (ex.: Rede, Stone, Pluxee, ...)."
    },
    {
        selector: ".card_13", 
        content: "Mostra o CMV mensal, separado por categoria: Insumos, embalagens e produtos para comercialização." 
    },
    {
        selector: ".card_14", 
        content: "Mostra a relação do CMV + CMO de cada mês." 
    },
    {
        selector: ".card_15", 
        content: "Mostra, em percentual, o valor mensal gasto com taxas de cartão e aplicativos de delivery." 
    },
    {
        selector: ".card_16", 
        content: "Indica a porcentagem gasta com frete e entrega nos meses selecionados." 
    },
    {
        selector: ".card_17", 
        content: "Indica a porcentagem dos impostos nos meses selecionados." 
    },
    {
        selector: ".card_18", 
        content: "Mostra a margem de contribuição mensal comparada ao percentual de custo fixo mensal, dos meses selecionados." 
    },
    {
        selector: ".card_19", 
        content: "Indica a média da margem de contribuição com a média do custo fixo, dos meses selecionados." 
    },
    {
        selector: ".card_20",
        content: "Mostra a distribuição, em percentual, de cada categoria dos custos fixos sobre a receita mensal realizada."
    },
    {
        selector: ".card_21", 
        content: "Mostra o resultado operacional, tanto em reais como em porcentagem, dos meses selecionados." 
    },
    {
        selector: ".card_22",
        content: "Soma do valor pago em juros e multas nos meses selecionados."
    },
    {
        selector: ".card_23", 
        content: "Soma do lucro operacional dos meses filtrados, a média mensal em valor nominal e em percentual, e a variação em relação ao ultimo mês." 
    },
];