import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import api from "../../axios";
import FormControl from "@mui/material/FormControl";
import Loading from "../../components/Loading/Loading";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ResolveProblems from "../../components/ResolveProblems/ResolveProblems";
import {
	DASH_BAR_COLOR,
	GRID_BUTTONS_SPACING,
	WALPAPPER_THEME,
	BLUE_THEME,
	GRAY_LABEL_UX,
	GRAY_BG_UX,
	BORDER_TABLE,
	textCompleteStyle,
	textCompleteStyleBlack,
	WHITE_THEME_BLACK,
	paperStyleFull,
	BORDER_BUTTON
} from "../../shared/utils";
import GrayButton from "../../components/Buttons/GrayButton";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Header from "../../components/Titles/Header";
import CachedIcon from "@mui/icons-material/Cached";
import {useSelector} from "react-redux";
import "./Resolucao.css";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import Tour from "reactour";
import { steps } from "./TourReunioes";
import YellowButton from "../../components/Buttons/YellowButton";
import SelectMarca from "../../components/SelectStore/SelectMarca";
import TableResolucao from "../../components/Table/Resolucao/TableResolucao";


export default function Resolucao() {
	const navigate = useNavigate();

	const [loadingDownloads, setLoadingDownloads] = useState(false);
	const [loadingMarcas, setLoadingMarcas] = useState(false);
	const [downloads, setDownloads] = useState({});
	const [dailyDownloads, setDailyDownloads] = useState({});
	const [lojas, setLojas] = useState([]);
	const [marcaId, setMarcaId] = useState(0);
	const [marcas, setMarcas] = useState(0);
	const [periodo, setPeriodo] = useState("");
	const [dateBaixados, setDateBaixados] = useState("");

	const [open, setOpen] =  useState(false);
	const [startAt, setStartAt] = useState(0);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const stores = useSelector(state => state.StoreReducer.lojas);

	useEffect(() => {
		if (marcaId !== 0) {
			getDownloads();
		}
	}, [marcaId]);

	useEffect(() => {
		getMarcas();
	}, []);

	const getDownloads = () => {
		setLoadingDownloads(true);
		setDownloads({});
		setLojas([]);
		api.GetDownloads(marcaId).then(response => {
			setLoadingDownloads(false);
			let data = response.data;

			setDownloads(data.downloads);
			setDailyDownloads(data.downloads_diarios);
			setLojas(data.lojas);
			setPeriodo(data.periodo);
			setDateBaixados(data.data_atual);
		}).catch(() => {
			setLoadingDownloads(false);
		});
	};

	const handleChange = (obj) => {
		if (obj == null) {
			setMarcaId(0);
		} else {
			setMarcaId(obj.value);
		}
	};

	const getMarcas = () => {
		setLoadingMarcas(true);
		api.GetMarcas().then(response => {
			setLoadingMarcas(false);
			let data = response.data;
			setMarcas(data);
		}).catch(() => {
			setLoadingMarcas(false);
		});
	};

	const handleOpenUploads = () => {
		navigate("/upload_file");
	};

	const reloadTable = () => {
		getDownloads();
	};

	const changeOpen = () => {
		setStartAt(0);
		setOpen(!open);
	};

	const redirectToDocumentacao = () => {
		window.open("https://voxelgestaocom-my.sharepoint.com/:w:/g/personal/iure_brandao_voxelgestao_com/EdG69Rq7oPZJu-9HKsUtunMB1U5f_KchccnBxriISwhTYA?e=rLsRC2", "_blank");
	};

	return (
		<div className="main">
			<Header title="Resolução de Problemas"/>
			<Paper sx={{...paperStyleFull, minHeight: "50vh"}} data-testid={"resolucao"}  style={{backgroundColor: temaEscuro ? WALPAPPER_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none"}}>
				<Box sx={{marginBottom: 2}}>
					<Grid container sx={{display: "flex", alignItems: "center"}} >
						<Grid item xs={4}>
							<Grid container spacing={1} sx={{display: "flex", alignItems: "center"}} >
								{marcaId !== 0 && (
									<Grid item xs={6}>
										{
											loadingMarcas ? (
												<Loading />
											) : (
												<FormControl sx={{ width: "100%" }}>
													<Autocomplete
														size="small"
														style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
														disablePortal
														sx={{...styles.bgAutocomplete, borderColor: temaEscuro && BORDER_TABLE}}
														onChange={(event, newValue) => {
															handleChange(newValue);
														}}
														options={marcas}
														value={marcas.find((obj) => obj.value === marcaId)}
														PaperComponent={({ children }) => (
															<Paper style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}>{children}</Paper>
														)}
														renderOption={(props, option) => <span {...props} style={{ color: temaEscuro ? GRAY_BG_UX : GRAY_LABEL_UX}}>{option.label}</span>}
														renderInput={(params) => <TextField {...params} sx={temaEscuro ? textCompleteStyleBlack: textCompleteStyle} label="Marca"
																							data-testid={"select-marca"}/>}
														className="marca"
													/>
												</FormControl>
											)
										}
									</Grid>
								)
								}

							</Grid>
						</Grid>
						<Grid item xs={8}>
							<Grid container spacing={GRID_BUTTONS_SPACING} sx={{justifyContent: "end", display: "flex"}} data-testid={"buttons"}>
								<Grid item>
									<YellowButton testId="boas-praticas" onClick={() => redirectToDocumentacao()} title="Boas Práticas" />
								</Grid>
								<Grid item>
									<GrayButton
										onClick={handleOpenUploads}
										title={"Arquivo"}
										startIcon={<UploadFileIcon />}
										testId="button-arquivo"
										className="arquivo"
										style={{borderColor: temaEscuro && BORDER_BUTTON}}
									/>
								</Grid>
								<Grid item>
									<ResolveProblems
										lojas={stores}
										testId={"troubleshooting"}
									/>
								</Grid>
								<Grid item>
									<IconButton
										data-testid="resolucao-tutorial"
										style={{ background: "#ECC94B", borderRadius: "4px" }}
										onClick={() => changeOpen()}
									>
										<LightbulbIcon style={{ color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px" }} />
									</IconButton>
								</Grid>
								<Tour
									steps={steps}
									isOpen={open}
									onRequestClose={changeOpen}
									accentColor={DASH_BAR_COLOR}
									startAt={startAt}
									rounded={5}
									showNavigation={false}
									inViewThreshold={60}
									className={temaEscuro && "tour"}
									badgeContent={(curr, tot) => `${curr}/${tot}`}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Box>

				{
					marcaId === 0 ? (
						<SelectMarca marcas={marcas} setMarcaId={setMarcaId} />
					) : (
						loadingDownloads ? (
							<div style={styles.divCenter}>
								<Box sx={{ width: "100%", marginTop: "20px" }}>
									{
										[...Array(10).keys()].map((i, d) => {
											return (
												<Skeleton key={d} height={50} width={"100%"} />
											);
										})
									}
								</Box>
							</div>
						) : (
							lojas.length > 0 && (
								<React.Fragment>
									<div
										style={{color:temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}
										data-testid="table-title"
									>
										<p>Tabela de relatórios baixados para o período <b>{periodo}</b>:</p>
										<GrayButton
											onClick={reloadTable}
											title="Recarregar"
											testId="reload-resolucao-table"
											startIcon={<CachedIcon />}
										/>
									</div>

									<div data-testid="table-downloads">
										<TableResolucao
											colSpan={lojas.length + 1}
											columns={downloads.columns}
											rows={downloads.rows}
											periodo={periodo}
											reloadTable={reloadTable}
										/>
									</div>


									<div
										style={{color:temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, ...styles.divMarginTop}}
										data-testid="table-title"
									>
										<p>Tabela de relatórios baixados para o dia <b>{dateBaixados}</b>:</p>
									</div>
									<div data-testid="table-downloads-daily" style={styles.divMarginBottom}>
										<TableResolucao
											colSpan={lojas.length + 1}
											columns={dailyDownloads.columns}
											rows={dailyDownloads.rows}
											reloadTable={reloadTable}
											periodo={dateBaixados}
										/>
									</div>
								</React.Fragment>
							)
						)
					)
				}
			</Paper>
		</div>
	);
}

const styles = {
	divMarginTop: {
		marginTop: "24px"
	},
	divMarginBottom: {
		marginBottom: "24px"
	},
};