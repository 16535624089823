import React, {useEffect} from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
    PAPER_PADDING_THEME,
    WHITE_THEME_BLACK,
    BLACK_SUBITEM_NAVBAR,
    WHITE_SUBITEM_NAVBAR,
    BLUE_THEME,
    LINE_TABLE,
    formatterCurrency
} from "../../../../shared/utils";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import Skeleton from "@mui/material/Skeleton";
import { ptBR } from "@mui/x-data-grid-premium/locales"; // Supondo que estas variáveis estejam sendo importadas de algum lugar
import { useSelector } from "react-redux";

const DetailPDVPanel = ({ 
    row,
    setRowId,
    rowId,
    estabelecimentosSelected,
    selectedPortal,
    selectedFormaPagamento,
    getDetalharPDV,
    loadingDatagrid,
    dataPortalDetalhar,
    dataFrenteCaixaDetalhar,
    getDateComplete
}) => {

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const storeId = useSelector(state => state.StoreReducer.storeId);

    useEffect(() => {
        setRowId(row.id);
        if (!(rowId === row.id)) {
            const [day, monthSelected] = row.periodo.split("/");
            let dateSelected = getDateComplete(day, monthSelected);
            let dataRequest = {
                loja_id: storeId,
                estabelecimentos: estabelecimentosSelected,
                portal: selectedPortal,
                forma_pagamento: selectedFormaPagamento,
                periodo: dateSelected
            };
            getDetalharPDV(dataRequest);
        }
    }, [row, setRowId, rowId, storeId, estabelecimentosSelected, selectedPortal, selectedFormaPagamento, getDetalharPDV]);

    const columnsFrenteCaixaDetalhar = [
        {
            field: "forma_pagamento",
            align: "center",
            headerName: "Forma de Pagamento",
            flex: 1,
            headerAlign: "center",
        },
        {
            field: "valor_venda",
            headerName: "Valor Venda",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        {
            field: "taxa",
            headerName: "Taxa",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        {
            field: "valor_liquido",
            headerName: "Valor Líquido",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
    ];

    const columnsPortalDetalhar = [
        {
            field: "portal",
            align: "center",
            headerName: "Portal",
            flex: 1,
            headerAlign: "center",
        },
        {
            field: "valor_venda",
            headerName: "Valor Venda",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        {
            field: "taxa",
            headerName: "Taxa",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        {
            field: "valor_liquido",
            headerName: "Valor Líquido",
            flex: 1,
            type: "number",
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
    ];

    return (
        <Stack
            sx={{ py: 2, height: "100%", boxSizing: "border-box", backgroundColor: temaEscuro ? PAPER_PADDING_THEME : WHITE_THEME_BLACK }}
            direction="column"
        >
            <Paper sx={{ flex: 1, mx: "auto", width: "95%", p: 2, backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE, backgroundImage: "none" }}>
                {
                    loadingDatagrid ? (
                        <React.Fragment>
                            {
                                [...Array(10).keys()].map((i, d) => {
                                    return (
                                        <Skeleton height={40} key={d} />
                                    );
                                })
                            }
                        </React.Fragment>
                    ) : (
                        <Grid container xs={12} sx={{ height: "85%" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography variant="h6">{row.periodo} | {row.dia_semana}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ height: "100%", display: "flex", mt: 3 }}>
                                <DataGridPremium
                                    columns={columnsPortalDetalhar}
                                    rows={dataPortalDetalhar}
                                    hideFooter
                                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                    sx={{
                                        "& .MuiDataGrid-container--top [role=row]": {
                                            backgroundColor: temaEscuro ? BLACK_SUBITEM_NAVBAR : WHITE_SUBITEM_NAVBAR,
                                        },
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            fontSize: "13px",
                                        },
                                        "& .MuiDataGrid-cell": {
                                            fontSize: "14px",
                                        },
                                        flex: 1
                                    }}
                                />
                                <DataGridPremium
                                    columns={columnsFrenteCaixaDetalhar}
                                    rows={dataFrenteCaixaDetalhar}
                                    hideFooter
                                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                    sx={{
                                        "& .MuiDataGrid-container--top [role=row]": {
                                            backgroundColor: temaEscuro ? BLACK_SUBITEM_NAVBAR : WHITE_SUBITEM_NAVBAR,
                                        },
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            fontSize: "13px",
                                        },
                                        "& .MuiDataGrid-cell": {
                                            fontSize: "14px",
                                        },
                                        flex: 1
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            </Paper>
        </Stack>
    );
};

export default DetailPDVPanel;
