import {
    BLUE_THEME,
    BORDER_TABLE,
    GRAY_LABEL_UX,
    WHITE_TABLE,
    tableStyle,
    WHITE_THEME_BLACK
} from "../../../shared/utils";
import React from "react";
import {useSelector} from "react-redux";
import Row from "./Row";

const TableRow = (props) => {
    const {columns, rows} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const styleColor = {
        color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
        backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE,
        borderColor: temaEscuro && BORDER_TABLE
    };

    return (
        <React.Fragment>
            <table style={{...tableStyle.style, ...styleColor, ...{width: "100%"}}}>
                <thead>
                <tr>
                    <th style={{...tableStyle.firstCol, ...tableStyle.tdTh, ...styleColor}}>
                        Plataforma / Estabelecimento
                    </th>
                    {
                        columns.map((col, key) => {
                           return (
                               <th key={key} style={{...tableStyle.tdTh, ...styleColor}}>
                                   {col}
                               </th>
                           );
                        })
                    }
                </tr>
                </thead>
                <tbody>
                    {
                        rows.map((item, index) => {
                            return (
                                <Row
                                    key={index}
                                    styleColor={styleColor}
                                    plataforma={item.plataforma}
                                    colorList={item?.colors || []}
                                    msgList={item?.messages || []}
                                />
                            );
                        })
                    }
                </tbody>
            </table>

        </React.Fragment>

    )
        ;
};

// const styles = {
// };

export default React.memo(TableRow);