import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {styleLegend, formatterCurrency, BLUE_LIGHT_UX} from "../../shared/utils";
import "./Table.css";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

const RowDetailHistoric = (props) => {
	const {rows} = props;

    const columns = [
        "Data da mudança",
        "Data de Movimentação",
        "Data de Competência",
        "Categoria",
        "Descrição",
        "Fornecedor",
        "Conta",
        "Situação",
        "Alteração",
        "Valor anterior",
        "Valor Novo"
    ];

    return(
        <React.Fragment>
            <TableRow className="table-row" hover role="checkbox">
                <TableCell align="left" width="90%" colSpan={5}>
                    <TableContainer className="table-container">
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => (
                                        <TableCell key={index} align="center">{column}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={`${index}-${row.data_movimentacao}-${row.fornecedor}-${row.valor_anterior}-${row.valor_atual}`}>
                                        <TableCell align="center">{row.data}</TableCell>
                                        <TableCell align="center">{row.data_movimentacao}</TableCell>
                                        <TableCell align="center">{row.data_competencia}</TableCell>
                                        <TableCell align="center">{row.categoria}</TableCell>
                                        <TableCell align="center">{row.descricao}</TableCell>
                                        <TableCell align="center">{row.fornecedor}</TableCell>
                                        <TableCell align="center">{row.conta}</TableCell>
                                        <TableCell align="center">{row.situacao}</TableCell>
                                        <TableCell align="center">
                                            <Grid container sx={
                                                (row.alteracao === "Incluída" ? styles.incluida :
                                                    (row.alteracao === "Excluída" ? styles.excluida : 
                                                            styles.valorAlterado
                                                    )
                                                )
                                            }>
                                                <Grid item sx={{padding: "10px"}}>
                                                    {row.alteracao}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">{formatterCurrency(row.valor_anterior)}</TableCell>
                                        <TableCell align="center">{formatterCurrency(row.valor_atual)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

const styles = {
	incluida: {
        ...styleLegend.green,
		border: "3px",
        borderRadius: "5px",
        color: "white",
        fontWeight: "bold",
	},
    excluida: {
        ...styleLegend.red,
        border: "3px",
        borderRadius: "5px",
        color: "white",
        fontWeight: "bold",
    },
    valorAlterado: {
        border: "3px",
        backgroundColor: BLUE_LIGHT_UX,
        borderRadius: "5px",
        color: "white",
        fontWeight: "bold",
    }
};

export default React.memo(RowDetailHistoric);