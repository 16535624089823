import React, {useState, useMemo, useEffect} from "react";
import {BLUE_THEME, styleDefaultText, WHITE_THEME_BLACK} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import GrayButton from "../Buttons/GrayButton";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../Titles/Header";
import YellowButton from "../Buttons/YellowButton";
import FilterWithTag from "../Filter/FilterWithTag";
import {useSelector} from "react-redux";
import LoopIcon from "@mui/icons-material/Loop";


const Refresh = (props) => {
	const {handleRefresh, monthOptions, setMonthOptions, monthsFiltered, optionFilter, open, isNotDRE, disabled} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	const [openRefresh, setOpenRefresh] = useState(false);

    const handleClose = () => {
		setOpenRefresh(false);
	};

	const handleOpen = () => setOpenRefresh(true);

	const loopIcon = useMemo( () => (
		<LoopIcon/>
	), []);

	useEffect(() => {
		setOpenRefresh(open);
	}, [open]);

	return(
		<React.Fragment>
			{
				isNotDRE && (
					<div data-testid="refresh">
						<GrayButton title="Recalcular" testId="refresh-btn" startIcon={loopIcon} onClick={handleOpen} className={props.className} disabled={disabled}/>
					</div>
				)
			}
			<Modal
				open={openRefresh}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Paper className="modal" style={{width: "25%", height: "auto", marginTop: "7%", overflowY: "hidden", backgroundColor: temaEscuro && BLUE_THEME}}>
					<Grid container sx={{padding: 1}}>
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={10}>
									<Header title="Recalcular"/>
								</Grid>
								<Grid item xs={2} sx={{display: "flex", justifyContent: "end"}}>
									<CloseIcon id="close-icon" color="action" onClick={handleClose}/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={12} >
									<h3 style={{...styleDefaultText, color: temaEscuro && WHITE_THEME_BLACK}}>Selecione os meses que deseja recalcular:</h3>
								</Grid>
								<Grid item xs={12} >
									<h3 style={{...styleDefaultText, marginTop: "-5px", fontSize: "12px", fontWeight: "200", color: temaEscuro && WHITE_THEME_BLACK}}>Obs: Meses revisados não podem ser recalculados</h3>
								</Grid>
								<Grid item xs={12}>
									<FilterWithTag
										options={monthOptions} 
										setOptions={setMonthOptions}
										optionFilter={optionFilter}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} style={{display: "flex", marginTop: "3%"}}>
							<Grid container spacing={2} style={{display: "flex", alignItems: "end", justifyContent: "end", marginRight: "2%"}}>
								<Grid item>
								<GrayButton
									title="Cancelar"
									onClick={handleClose}
								/>
								</Grid>
								<Grid item>
									<YellowButton
										title="Recalcular"
										onClick={() => handleRefresh(monthsFiltered)}
										testId="recalcular"
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Modal>
		</React.Fragment>

	);
};

export default React.memo(Refresh);