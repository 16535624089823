import React  from "react";
import {styleText, smallPaperStyle, GRAY_LABEL_UX} from "../../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {BLUE_THEME, WHITE_ESCRITA_THEME, WHITE_THEME_BLACK} from "../../../shared/utils";


const MercadoModal = (props) => {
    const {isMercado, openModal, setOpenModal, segmentos} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return(
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="mercado-modal"
            >
                <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME}}>
                    <Grid item xs={10} className="start">
                        <span style={{...styleText, fontWeight: "700", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Mercado:</span>
                    </Grid>
                    <Grid sx={{marginTop: 5}}>
                        <p style={{...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
                            {isMercado ? "Consiste no somatório de todas as lojas com o(s) seguinte(s) filtro(s):" : "Não foi possível acessar o mercado com o(s) seguinte(s) filtro(s):"}
                        </p>
                    </Grid>
                    {segmentos?.length > 0 && 
                        <Grid>
                            <p style={{...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>Segmento(s): {segmentos.join(", ")}</p>
                        </Grid>
                    }
                </Paper>
            </Modal>
        </React.Fragment>
    );                  
};

export default MercadoModal;