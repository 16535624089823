import React from "react";
import {
	WHITE_THEME_BLACK,
    formatterCurrency,
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    paperDash,
    DASH_KPI_POSITIVE_COLOR,
    DASH_KPI_NEGATIVE_COLOR} from "../../../shared/utils";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const FaturamentoKPI = ({value, title, explodeFaturamentoIfood, temaEscuro, styles, isValorLiquido=false}) => {
    return (
        <Grid item xs={3}>
            <Paper sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none", "&:hover": {cursor: "pointer", outline: "1px solid"}}} onClick={explodeFaturamentoIfood}>
                <Grid sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%"}} spacing={2}>
                    <Grid sx={{display: "flex", gap: 1}}>
                        <span style={{...styles.title, ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>{title}</span>
                        <OpenInNewIcon fontSize={"inherit"}/>
                    </Grid>
                    <span style={{...styles.value, color: isValorLiquido ? value > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR : temaEscuro && WHITE_THEME_BLACK}}>
                        {formatterCurrency(value, 2)}
                    </span>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default React.memo(FaturamentoKPI);