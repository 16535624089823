import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
	BLUE_THEME,
	DASH_KPI_DATA_COLOR, formatterCurrency,
	paperDash, spanDash, LINE_TABLE,
	WHITE_THEME_BLACK
} from "../../shared/utils";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";
import { useSelector } from "react-redux";

const CardIcon = (props) => {
	const {data, suffix, currency, testId} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	return(
		<Paper data-testid={testId} sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}} className={props.className}>
			<Box sx={{mb: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
				<span style={{...spanDash, ...(temaEscuro && {color:  LINE_TABLE})}}>{props.title}</span>
				<ButtonOpenTour onClick={props.onClick}/>
			</Box>
			<Box sx={styles.grid}>
				<span style={{...styles.dataTitle, ...(temaEscuro && {color:  WHITE_THEME_BLACK})}}>
					{
						currency ? (
							formatterCurrency(data)
						) : (
							data
						)
					} {suffix}
				</span>
				{props.icon}
			</Box>
		</Paper>
	);
};

// Create styles
const styles = {
	dataTitle: {
		fontSize: "20px",
		paddingBottom: 8,
		fontWeight: 500,
		color: DASH_KPI_DATA_COLOR
	},
	grid: {
		display: "flex",
		alignItems: "center",
		textAlign: "center",
		justifyContent: "center",
		flexDirection: "column",
		flexGrow: 1
	}
};

export default React.memo(CardIcon);