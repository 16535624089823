import React, {useState, useEffect, useMemo} from "react";
import Paper from "@mui/material/Paper";
import ReactECharts from "echarts-for-react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

import {
	formatterThousand,
	paperDash, 
	spanDash,
	fontFamily, 
	DASH_TREEMAP,
	seriesColorTree, 
	formatterCurrency, 
	BLUE_THEME,
	LINE_TABLE,
	PAPER_PADDING_THEME
} from "../../shared/utils";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";

const TreeMap = (props) => {
	const {data, title, testId} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	const getLevelOption = () => {
		return [
			{
				itemStyle: {
					borderColor: temaEscuro ? BLUE_THEME : "#777",
					borderWidth: 0,
					gapWidth: 1
				},
				upperLabel: {
					show: false
				}
			},
			{
				itemStyle: {
					borderColor: temaEscuro ? BLUE_THEME : DASH_TREEMAP,
					borderWidth: 5,
					gapWidth: 1
				},
				emphasis: {
					itemStyle: {
						borderColor: temaEscuro ? PAPER_PADDING_THEME : "#ddd"
					}
				}
			},
			{
				colorSaturation: [0.35, 0.5],
				itemStyle: {
					borderWidth: 5,
					gapWidth: 1,
					borderColorSaturation: 0.6
				}
			}
		];
	};

	const formatterTree = (params) => {
		let data = params.data;
		let value = formatterThousand(data.value);
		return `${data.name} \n R$ ${value}`;
	};

	const [options, setOptions] = useState({
		series: [
			{
				type: "treemap",
				top: "2%",
				left: "0",
				right: "0",
				bottom: "0",
				roam: false,
				name: title,
				upperLabel: {
					show: true,
					height: 30
				},
				itemStyle: {
					borderColor: "#fff"
				},
				color: seriesColorTree,
				colorMappingBy: "value",
				levels: getLevelOption(),
				leafDepth: 2,
				data: []
			}
		],
		tooltip: {
			trigger: "item",
			valueFormatter: formatterCurrency,
			fontFamily,
		},
	});
	const [key, setKey] = useState();

	useEffect(() => {
		let newOptions = {...options};
		let newSeries = newOptions?.series?.slice();
		let newData = data?.slice();
		if (newData?.length){
			newData[0].children.forEach((item, index) => {
				newData[0].children[index]["label"] = {
					fontFamily,
					formatter: formatterTree,
				};
				item.children.forEach((_item, idx) => {
					newData[0].children[index].children[idx]["label"] = {
						fontFamily,
						formatter: formatterTree
					};
				});
			});
			newSeries[0].data = newData;
			newOptions.series = newSeries;
			setOptions(newOptions);
			setKey(Math.random());
		}
	}, [data]);

	const getChart = useMemo(() => {
		return (
			<ReactECharts style={{height: "100%"}} key={key} option={options} notMerge={true}/>
		);
	}, [options]);


	return(
		<Paper data-testid={testId} sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}} className={props.className}>
			<Box sx={{mb: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
				<span style={{...spanDash, ...(temaEscuro && {color:  LINE_TABLE})}}>{title}</span>
				<ButtonOpenTour onClick={props.onClick}/>
			</Box>
			{getChart}
		</Paper>
	);
};

export default React.memo(TreeMap);