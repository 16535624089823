import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./GenericModal.css";
import {
    BLUE_THEME, smallPaperStyle,
    styleGrid
} from "../../shared/utils";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";
import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import WarningIcon from "@mui/icons-material/Warning";

const CategoriasModal = (props) => {
	const {
        openModal,
        setOpenModal,
    } = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const nav = useNavigate();

    const handlePlanoContasNav = () => {
        setOpenModal(false);
        nav("/plano_contas", {state:{resolver_categorias: "Não categorizados"}});
    };

	return(
		<React.Fragment>
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
                data-testid="categoria-modal"
			>
				<Paper style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, width: "fit-content"}}>
                    <Grid container sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: 1, marginTop: "1rem"}}>
                        <WarningIcon/>
                        <Box sx={{fontWeight: "bold", fontSize: 24}}>
                            ATENÇÃO
                        </Box>
                    </Grid>
                    <Box sx={{fontSize: 24, paddingTop: 2}}>
                    Existem categorias não classificadas para esta loja.
                    </Box>
                    <Grid container sx={{display: "flex", justifyContent: "end"}}>
                        <Grid item sx={styleGrid}>
                            <GrayButton
                                onClick={() => setOpenModal(false)}
                                title="Agora não"/>
                        </Grid>
                        <Grid item sx={styleGrid}>
                            <YellowButton
                                onClick={handlePlanoContasNav}
                                testId="categoria-resolver-btn"
                                title="Resolver categorias"
                            />
                        </Grid>
                    </Grid>
				</Paper>
			</Modal>
		</React.Fragment>
	);
};

export default CategoriasModal;