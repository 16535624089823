import React, {useState, useEffect, useMemo} from "react";
import Paper from "@mui/material/Paper";
import ReactECharts from "echarts-for-react";
import Box from "@mui/material/Box";
import {
	DASH_AXIS_COLOR,
	DASH_LABEL_COLOR,
	DASH_LINESTYLE_COLOR,
	formatterThousand,
	paperDash, spanDash,
	fontFamily, grid, BLUE_THEME,
	TEXT_THEME,
	GRAY_TABLE, LINE_TABLE,
	WHITE_THEME_BLACK,
	legend, BORDER_BUTTON,
	GRAY_LABEL_UX, formatterPercentage, WHITE_ESCRITA_THEME
} from "../../shared/utils";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";
import { useSelector } from "react-redux";


const HorizontalBar = (props) => {
	const {data, testId} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	const formatterBar = (params) => {
		if(params.data >= 0.1){
			return formatterPercentage(params.data);
		}else{
			return "";
		}
	};

	const series = {
		data: [],
		type: "bar",
		label: {
			show: true,
			position: "right",
			color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_LABEL_COLOR,
			fontFamily,
			formatter: formatterBar
		},
	};

	const [options, setOptions] = useState({
        grid: {
			...grid,
			right: 32,
		},
		legend:{
			...legend,
			textStyle: {
				color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
			},
			inactiveColor: temaEscuro ? BORDER_BUTTON : "#ccc",
			height: 200,
		},
        xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
			color: temaEscuro ? GRAY_TABLE  : DASH_LABEL_COLOR,
			data: [],
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			axisLabel: {
				interval: 0,
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_LABEL_COLOR,
                formatter: formatterThousand
			},
			splitLine: {
				lineStyle: {
					type: "dashed",
					color: temaEscuro ? TEXT_THEME : DASH_LINESTYLE_COLOR
				}
			},
        },
        yAxis: {
            type: "category",
            data: data.categories,
            splitLine: {
                show: true,
                lineStyle: {
                    color: temaEscuro ? TEXT_THEME: DASH_LINESTYLE_COLOR,
                    type: "dashed"
                }
            },
            axisLabel: {
				// interval: 0,
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK  : DASH_AXIS_COLOR
			}
        },
        series: data.series_bar,
        title: {
			text: "%",
			left: "left",
			textStyle: {
				color:  temaEscuro ? WHITE_THEME_BLACK  : DASH_LABEL_COLOR,
				fontFamily,
				fontSize: 12,
				fontWeight: 400
			}
		},
        tooltip: {
			trigger: "axis",
			fontFamily,
			valueFormatter: formatterPercentage,
			axisPointer: {
				lineStyle: {
					color:  temaEscuro ? TEXT_THEME: DASH_LINESTYLE_COLOR,
				}
			}
		},
	});
	const [key, setKey] = useState();

	useEffect(() => {
		let newOptions = {...options};
		let newSeries = [];
		data?.series_bar?.map((item) => {
			newSeries.push({...series, ...item});
		});
		newOptions.yAxis.data = data.categories;
        newOptions.series = newSeries;
		setOptions(newOptions);
		setKey(Math.random());
	}, [data]);

	const getChart = useMemo(() => {
		return(
			<ReactECharts
				key={key}
				option={options}
				notMerge={true}
				style={{height: "100%"}}
			/>
		);
	}, [options]);

	return(
		<Paper data-testid={testId} sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}} className={props.className}>
			<Box sx={{mb: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
				<span style={{...spanDash, ...(temaEscuro && {color:  LINE_TABLE})}}>{props.title}</span>
				<ButtonOpenTour onClick={props.onClick}/>
			</Box>
			{getChart}
		</Paper>
	);
};

export default React.memo(HorizontalBar);