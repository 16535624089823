export const defaultFilters = [
    {
        "label": "1 mês atrás",
        "value": 1
    },
    {
        "label": "3 meses atrás",
        "value": 3
    },
    {
        "label": "6 meses atrás",
        "value": 6
    },
    {
        "label": "9 meses atrás",
        "value": 9
    },
    {
        "label": "1 ano atrás",
        "value": 12
    },
    {
        "label": "2 anos atrás",
        "value": 24
    }
];