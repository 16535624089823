import React, {useState, useCallback}  from "react";
import { useSelector } from "react-redux";
import {
    smallPaperStyle,
    BLUE_THEME,
    WHITE_ESCRITA_THEME, GRAY_LABEL_UX,
} from "../../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import GrayButton from "../../Buttons/GrayButton";
import YellowButton from "../../Buttons/YellowButton";

const ConciliacaoModal = (props) => {

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const { 
        openConciliacaoModal,
        setOpenConciliacaoModal, 
        setOpenSaveConciliacao,
        setMode
    } = props;

    const [checked, setChecked] = useState("");

    const handleChangeChecked = (event) => {
        setChecked(event.target.value);
    };

    const handleOpenModal = useCallback(() => {
        setOpenSaveConciliacao(true);
        setOpenConciliacaoModal(false);
        setMode(checked);
    }, [checked]);

    return(
        <React.Fragment>
            <Modal
                open={openConciliacaoModal}
                onClose={() => setOpenConciliacaoModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="totais-modal"
            >
                <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro ? BLUE_THEME : null}}>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel 
                                    id="demo-radio-buttons-group-label" 
                                    style={{fontSize: 23, 
                                    fontWeight: "700", 
                                    color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX,
                                    margin: "12px 0px"}}
                                >
                                    Selecione a opção desejada:
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={checked}
                                    onChange={handleChangeChecked}
                                >
                                    <FormControlLabel value="deletarConciliacao" control={<Radio />} label="Deleter Conciliação" />
                                    <FormControlLabel value="salvarConciliacao" control={<Radio />} label="Salvar Conciliacao" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <p style={{fontWeight: "400", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, margin: "16px 0px", fontStyle: "italic"}}>
                                Nota: apenas plataformas que possuem estabelecimento podem ser salvas ou deletadas.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container sx={{display: "flex", justifyContent: "end", gap: 2, marginTop: 6}}>
                        <Grid item>
                            <GrayButton
                                onClick={() => setOpenConciliacaoModal(false)}
                                title="Cancelar"
                            />
                        </Grid>
                        <Grid item>
                            <YellowButton
                                onClick={handleOpenModal}
                                testId="generic-continue-btn"
                                title="Continuar"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );                  
};

export default React.memo(ConciliacaoModal);