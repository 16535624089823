import {
	CHANGE_STORE,
	CHANGE_OPEN_COMPONENT,
	CHANGE_MODIFIED,
	CHANGE_LOJAS,
	CHANGE_SEGMENTOS,
	CHANGE_OPEN_ACCORDION_SELECTED,
	CHANGE_SCROLL_POSITION_DATA_GRID
} from "../actions/types";

const INITIAL_STATE = {
	storeId: 0,
	openComponent: false,
	openAccordionSelected: "",
	scrollPositionDataGrid: {},
	dataModified: "",
	lojas: null,
	segmentos: []
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
	case CHANGE_STORE:
		return { ...state, storeId: action.payload };
	case CHANGE_OPEN_COMPONENT:
		return { ...state, openComponent: action.payload };
	case CHANGE_MODIFIED:
		return { ...state, dataModified: action.payload };
	case CHANGE_LOJAS:
		return { ...state, lojas: action.payload };
	case CHANGE_SEGMENTOS:
		return { ...state, segmentos: action.payload };
	case CHANGE_OPEN_ACCORDION_SELECTED:
		return { ...state, openAccordionSelected: action.payload };
	case CHANGE_SCROLL_POSITION_DATA_GRID:
		return { ...state, scrollPositionDataGrid: action.payload };
	default:
		return state;
	}
};
