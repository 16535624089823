import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
    verySmallPaperStyle,
    BLUE_THEME,
    WHITE_THEME_BLACK,
} from "../../../shared/utils";
import YellowButton from "../../Buttons/YellowButton";
import GrayButton from "../../Buttons/GrayButton";
import IntlCurrencyInput from "react-intl-currency-input";
import MessageLastMonth from "./tests/MessageLastFaturamento";


const GenericLastFaturamento = (props) => {
    const { 
        setOpenModalFat, openModalFat, lastFaturamentoDate, openModalFatMessage, 
        setOpenModalFatMessage, setLastFaturamentoValue, lastFaturamentoValue, postDRELastFaturamento
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const name = useSelector(state => state.AuthReducer.name);

    const listPVD = ["Saipos", "Colibri", "Nimbus"];

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    return(
        <React.Fragment>
            <MessageLastMonth
                openModalFatMessage={openModalFatMessage}
                setOpenModalFatMessage={setOpenModalFatMessage}
            />
            <Modal
                open={openModalFat}
                onClose={() => setOpenModalFat(false)}
                aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
                data-testid="generic-last-faturamento"
            >
                <Paper style={{...verySmallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME}}>
                    <Grid container>
                        <Grid item xs={12} className="start">
                            <h3>Alerta - Faturamento bruto zerado</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sx={{display: "flex", flexDirection: "column", gap: 1, color: temaEscuro ? "#D8D8D8" : "#2D3748"}}>
                                <span>
                                    <p className="takeMarginTagP">
                                        Prezado {name}, o seu faturamento bruto do mês {lastFaturamentoDate} não está atualizado, pois o seu PDV não possui integração automática com o nosso sistema, informe abaixo o valor do faturamento bruto para que você tenha os relatórios atualizados.
                                    </p>
                                </span>
                                    <div className="divListStyle">
                                        <span><p className="takeMarginTagP">Lista de PDVs que possuem integração automática: 
                                            <div>
                                                <span><p className="takeMarginTagP">{listPVD.join(", ")}</p></span>
                                            </div>
                                        </p></span>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <div className="divCurrencyInput" style={{width: "100%"}}>
                                            <IntlCurrencyInput
                                                value={lastFaturamentoValue}
                                                currency="BRL"
                                                config={currencyConfig}
                                                onChange={(event, value) => setLastFaturamentoValue(value)}
                                                className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                                                style={{ color: temaEscuro && WHITE_THEME_BLACK}}
                                                data-testid="generic-input-money"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container sx={{display: "flex", justifyContent: "end", gap: 2, marginTop: 2}}>
                                        <Grid item>
                                            <GrayButton
                                                onClick={() => setOpenModalFat(false)}
                                                title="Cancelar"/>
                                        </Grid>
                                        <Grid item>
                                            <YellowButton
                                                onClick={() => postDRELastFaturamento(lastFaturamentoValue)}
                                                testId="generic-inserir-btn"
                                                title="Inserir"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default React.memo(GenericLastFaturamento);