import React, { useState, useEffect }  from "react";
import {
	PURPLE_INFO_UX,
	avatarStyle,
	styleTitle,
	smallPaperStyle,
	muiRootStyle, 
	BLUE_THEME, 
	WHITE_THEME_BLACK, 
	imagePerfilBig,
	BORDER_BUTTON,
	WHITE_ESCRITA_THEME,
	GRAY_STRONG_UX,
	TITLE_SIZE
} from "../../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useSelector} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import GrayButton from "../../Buttons/GrayButton";
import CircleIcon from "@mui/icons-material/Circle";
import Avatar from "@mui/material/Avatar";
import YellowButton from "../../Buttons/YellowButton";

const InsertComment = (props) => {
	const {openInsertCommentModal, setOpenInsertCommentModal, loadingModal, sendMetadata, isEdit, setIsEdit, commentId, editComment, component}= props;
	const [comment, setComment] = useState(props.comment);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const name = useSelector(state => state.AuthReducer.name);
	const imagePerfil = useSelector(state => state.AuthReducer.imagePerfil);
	const isDesktop = useSelector(state => state.AuthReducer.isDesktop);

	useEffect(() => {
		setComment(props.comment);
	}, [props.comment]);

    const initals = () => {
		let nameSplit = name.split(" ").map((word) => word[0]).join("");
		return nameSplit;
	};

	const handleSendComment = () => {
        if (component ===  "conciliacao"){
            sendMetadata("comentario", null, comment);
        } else {
            sendMetadata("comment", null, comment);
        }
    };

	const initalName = () => {
		let nameSplit = name.split(" ");
		return nameSplit[0];
	};

	return(
		<React.Fragment>
			<Modal
				open={openInsertCommentModal}
				onClose={() => setOpenInsertCommentModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				data-testid="modal-insert-comment"
			>
				<Paper className="modal" style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, padding: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container sx={{display: "flex", alignItems: "center", gap: 1, marginBottom: 1}}>
                                <Grid item sx={{display: "flex", alignItems: "center"}}>
                                    <CircleIcon className="circles" style={{ color: PURPLE_INFO_UX}}/>
                                </Grid>
                                <Grid item>
                                    <h1
										style={{
											color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX,
											fontSize: TITLE_SIZE,
											fontWeight: "bold",
											lineHeight: 1,
											marginBlock: 0,
											textAlign: isDesktop ? "left" : "center"
										}}>
											Comentário
										</h1>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={1} sx={{alignItems: "center"}}>
                                <Grid item>
                                    <Avatar sx={avatarStyle}>
										{
											imagePerfil !== "" ? (
												<img src={imagePerfil} alt="imagem perfil" style={{...imagePerfilBig}}/>
											) : (
												<React.Fragment>
													{initals()}
												</React.Fragment>
											)
										}
									</Avatar>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <b style={{...styleTitle, width: "auto", height: "auto", fontSize: "16px", color: temaEscuro && WHITE_THEME_BLACK}}>
                                            {initalName()}:
                                        </b>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
								sx={{backgroundColor: temaEscuro ? BLUE_THEME : muiRootStyle}}
                                multiline
                                value={comment}
								data-testid="input-insert-comment"
                                onChange={(e) => setComment(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                        setComment(comment + "\n");
                                    }
                                }}
                                label={
									<span
										style={{
											color: temaEscuro && BORDER_BUTTON,
										}}
									>
										Insira o Comentário
									</span>
								}
                                fullWidth
                                height="100%"
                                rows={2}
                            />
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", marginTop: "3%"}}>
							<Grid container style={{display: "flex", alignItems: "end", justifyContent: "end", gap: "16px"}}>
								<Grid item>
								<GrayButton
									title="Cancelar"
									onClick={() => setOpenInsertCommentModal(false)}
								/>
								</Grid>
								<Grid item>
									{
										loadingModal ? (
											<LoadingButton variant="outlined" loading>
												Enviar Relatório
											</LoadingButton>
										) : (
											(isEdit === true) ? (
												<YellowButton title="Editar Comentário" testId="insert-comment-edit" onClick={()=> {editComment(comment, commentId); setIsEdit;}}/>
											) : (
												<YellowButton title="Enviar Comentário" onClick={handleSendComment} testId="insert-comment-new"/>
												)
										)
									}
								</Grid>
							</Grid>
						</Grid>
                    </Grid>
				</Paper>
			</Modal>
		</React.Fragment>
	);
};

export default React.memo(InsertComment);