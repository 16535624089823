import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
    smallPaperStyle,
    BLUE_THEME,
    WHITE_THEME_BLACK,
    styleTitle,
    styleInput,
    GRAY_BG_UX,
    BORDER_BUTTON,
    textCompleteStyle,
    textCompleteStyleBlack,
    WHITE_TABLE,

} from "../../../shared/utils";
import YellowButton from "../../Buttons/YellowButton";
import GrayButton from "../../Buttons/GrayButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FilterWithTag from "../../../components/Filter/FilterWithTag";

const SaveConciliacao = (props) => {

    const {
        openSaveConciliacao,
        setOpenSaveConciliacao,
        handleSaveConciliacao,
        selectedPlataforma,
        estabelecimentos,
        monthsSaveFiltered,
        mode,
        monthsDeleteFiltered,
        plataformasRevisadas,
        setMode
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [selectedEstabelecimentos, setSelectedEstabelecimentos] = useState([]);
    const [selectedMonths, setSelectedMonths] = useState(null);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (options.length === 0) {
            setSelectedMonths(null);
            setSelectedEstabelecimentos(null);
        }
    }, [options]);

    useEffect(() => {
        if (mode === "salvarConciliacao") {
            setOptions(monthsSaveFiltered);
        } else {
            setOptions(monthsDeleteFiltered);
        }
        if (plataformasRevisadas.length === 0) {
            setMode("salvarConciliacao");
        }
    }, [mode, monthsDeleteFiltered, monthsSaveFiltered]);

    const renderSelectOptions = (options) => {
        return options.length > 0 ? (
            options.map((item) => (
                <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
            ))
        ) : (
            <MenuItem disabled>Nenhum mês disponível</MenuItem>
        );
    };
    
    return (
        <React.Fragment>
            <Modal
                open={openSaveConciliacao}
                onClose={() => setOpenSaveConciliacao(false)}
                aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
                data-testid="save-conciliacao-modal"
            >
                <Paper style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME}}>
                    <Grid container spacing={4}>
                        <Grid item>
                            {mode === "salvarConciliacao" ? (
                                <h3 style={{...styleTitle, width: "auto", color: temaEscuro && WHITE_THEME_BLACK}}>
                                    Para SALVAR a conciliação da plataforma {selectedPlataforma}, selecione abaixo um estabelecimento e um único mês:
                                </h3>
                                ) : (
                                <h3 style={{...styleTitle, width: "auto", color: temaEscuro && WHITE_THEME_BLACK}}>
                                    Para DELETAR a revisão da conciliação da plataforma {selectedPlataforma}, selecione abaixo um estabelecimento e um único mês:
                                </h3>
                            )}
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: mode === "salvarConciliacao" ? 4 : 8}}>
                            <FormControl fullWidth sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}>
                                <FilterWithTag
                                    options={estabelecimentos}
                                    setOptions={setSelectedEstabelecimentos}
                                    testIdAll={""}
                                    optionFilter
                                    handleSubmit={null}
                                    monthsFiltered={null}
                                    limitSelection={null}
                                    placeholderProp="Estabelecimento(s)"
                                    testId={"filter-estabelecimentos"}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, marginTop: 2}} size="small">
                                <InputLabel id="demo-simple-select-label" style={{...styleInput, color: temaEscuro && BORDER_BUTTON}}>Meses</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    fullWidth
                                    value={selectedMonths}
                                    onChange={(e) => setSelectedMonths(e.target.value)}
                                    data-testid="select-estabelecimento"
                                    sx={{ color: temaEscuro ? textCompleteStyleBlack : textCompleteStyle }}
                                    inputProps={{
                                        MenuProps: {
                                            MenuListProps: {
                                                sx: {
                                                    backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE,
                                                    color: temaEscuro ? textCompleteStyleBlack : textCompleteStyle
                                                }
                                            }
                                        }
                                    }}
                                >
                                    {renderSelectOptions(options)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} sx={{display: "flex", justifyContent: "end"}}>
                                <Grid item>
                                    <GrayButton
                                        onClick={() => setOpenSaveConciliacao(false)}
                                        title="Cancelar"/>
                                </Grid>
                                <Grid item>
                                    <YellowButton
                                        onClick={() => handleSaveConciliacao(selectedMonths, selectedEstabelecimentos, mode)}
                                        testId="save-conciliacao-confirm-btn"
                                        title={"Confirmar"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

            </Modal>
        </React.Fragment>
    );
};

export default React.memo(SaveConciliacao);