import React, {useState, useEffect, useRef, useCallback, useMemo} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CircleIcon from "@mui/icons-material/Circle";
import {useDispatch, useSelector} from "react-redux";
import {
	BLACK_TABLE, GRAY_TABLE,
	RED_TABLE,
	WHITE_TABLE,
	convertLabelToNumber,
	getScrollY,
	setScrollY,
	YELLOW_INFO_UX,
	PURPLE_INFO_UX,
	styleDefaultText,
	GRAY_LABEL_UX,
	waitForElm,
    LINE_TABLE,
    ROW_MAIN,
	BLUE_THEME,
    WALPAPPER_THEME,
    PAPER_PADDING_THEME,
	WHITE_ESCRITA_THEME
} from "../../shared/utils";
import RowData from "../../components/Table/RowData";
import Skeleton from "@mui/material/Skeleton";
import api from "../../axios";
import SelectStore from "../../components/SelectStore/SelectStore";
import Refresh from "../../components/Refresh/Refresh";
import Row from "../../components/Table/Row";
import { useReactToPrint } from "react-to-print";
import FilterWithTag from "../../components/Filter/FilterWithTag";
import GrayButton from "../../components/Buttons/GrayButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GenericModal from "../../components/Modal/GenericModal";
import { paperStyle } from "../../shared/utils";
import {
	changeOpenComponent
} from "../../actions/StoreActions";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import {showSnackMessage} from "../../actions/SnackActions";
import {dfcNotDetalharList} from "./DFCEnums";
import Tour from "reactour";
import { steps } from "./TourDFC";
import { DASH_BAR_COLOR, GRID_BUTTONS_SPACING } from "../../shared/utils";
import IconButton from "@mui/material/IconButton";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import "moment/locale/pt-br";
import LastModified from "../../components/LastModified/LastModified";

export default function DFC() {
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [previousMonths, setPreviousMonths] = useState([]);
	const [fixedValue, setFixedValue] = useState(0);
	const [comment, setComment] = useState("");
	const [columnId, setColumnId] = useState(0);
	const [fieldId, setFieldId] = useState("");
	const [loadingModal, setLoadingModal] = useState(false);
	const [columns, setColumns] = useState([]);
	const [atividades, setAtividades] = useState([]);
	const [rowsAtividades, setRowsAtividades] = useState([]);
	const [rowsCaixaHeader, setRowsCaixaHeader] = useState([]);
	const [rowsFinanciamento, setRowsFinanciamento] = useState([]);
	const [rowsFinanciamentoHeader, setRowsFinanciamentoHeader] = useState([]);
	const [rowsInvestimento, setRowsInvestimento] = useState([]);
	const [rowsInvestimentoHeader, setRowsInvestimentoHeader] = useState([]);
	const [monthsFiltered, setMonthsFiltered] = useState([]);
	const [monthOptions, setMonthOptions] = useState([]);
	const [openModalFixedValue, setOpenModalFixedValue] = useState(false);
	const [openViewCommentModal, setOpenViewCommentModal] = useState(false);
	const [openInsertCommentModal, setOpenInsertCommentModal] = useState(false);
	const [commentsList, setCommentsList] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [selectedField, setSelectedField] = useState("");
	const [selectedLabel, setSelectedLabel] = useState({});
	const [scrollPosition, setScrollPosition] = useState(0);
	const [openTour, setOpenTour] =  useState(false);
	const [startAt, setStartAt] = useState(0);
	const [shouldUpdateChild, setShouldUpdateChild] = useState(false);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);

	const storeId = useSelector(state => state.StoreReducer.storeId);
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
	const isManager = useSelector(state => state.AuthReducer.isManager);
	const filteredSteps = (isAdmin || isManager) ? steps : steps.filter(step => step.selector !== ".button_reload");
	const isOpenDfc = useSelector(state => state.StoreReducer.openComponent);
	const tourAtividades = useMemo(() => (["linha_2","linha_3","linha_4","linha_5","linha_6","linha_7",
	"linha_8","linha_9","linha_10","linha_11","linha_12","linha_13","linha_14","linha_15","linha_16","linha_17"
	,"linha_18","linha_19","linha_20","linha_21","linha_22","linha_23","linha_24","linha_25","linha_26","linha_27"
	,"linha_28","linha_29","linha_30","linha_31","linha_32","linha_33","linha_34","linha_35","linha_36","linha_37"
	,"linha_38","linha_39","linha_40","linha_41"]), []);
	const tourInvestimentos = useMemo(() => (["linha_43", "linha_44","linha_45"]), []);
	const tourFinanciamento = useMemo(() => (["linha_48", "linha_49","linha_50","linha_51","linha_52","linha_53","linha_54","linha_55"]), []);

	useEffect(() => {
		if(storeId !== 0){
			getDFC();
		}
	}, [storeId]);

	useEffect(() => {
		if(!loading && scrollPosition !== 0){
			waitForElm(".table-row-data").then(() => {
				setScrollY(componentRef, scrollPosition);
			});
		}
	}, [scrollPosition, loading]);

	const handleRefresh = useCallback((monthsFilter) => {
		setLoading(true);
		setScrollPosition(0);

		const dataRequest = {
			loja_id: storeId,
			months: monthsFilter
		};
		api.RefreshDFC(dataRequest).then(() => {
			setLoading(false);
			setShouldUpdateChild(prevState => !prevState);
			getDFC();
			setOpen(false);
		}).catch(() => {
			setLoading(false);
		});
	}, [storeId]);

	const handleFilter = useCallback((months) => {
		setScrollPosition(0);
		setMonthsFiltered(months);
		getDFC(months);
	}, []);

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const getDFC = (monthsFilter = [], position=0) => {
		setLoading(true);
		if (position !== 0){
			setScrollPosition(position);
		}
		const dataRequest = {
			loja_id: storeId,
			style: {
				RED_TABLE,
				WHITE_TABLE,
				BLACK_TABLE,
				GRAY_TABLE
			},
			months: monthsFilter
		};
		api.GetDFC(dataRequest).then(response => {
			setLoading(false);
			let dataResponse = response.data;
			let dfc = dataResponse.dfc;
			setPreviousMonths(dataResponse.filtered_months);
			setColumns(dataResponse.columns);
			let _months = [];
			dataResponse.months.map((month) => {
				_months.push({value: month, label: month});
			});
			setMonthOptions(_months);
			setAtividades(dfc.dfc_atividades.values);
			setRowsAtividades(dfc.dfc_atividades.data);
			setRowsCaixaHeader(dfc.dfc_caixa.values);
			setRowsFinanciamentoHeader(dfc.dfc_financiamento.values);
			setRowsFinanciamento(dfc.dfc_financiamento.data);
			setRowsInvestimentoHeader(dfc.dfc_investimento.values);
			setRowsInvestimento(dfc.dfc_investimento.data);
		}).catch(() => {
			setLoading(false);
			setColumns([]);
		});
	};

	const handleOpenModal = useCallback((data, index, field, comments) => {
		let label = columns[index + 1].label;
		let newLabel = convertLabelToNumber(label);
		setCommentsList(comments);
		setFixedValue(data.value);
		setColumnId(newLabel);
		setFieldId(field);
		setComment(data.comment);
		setSelectedField(field);
		setSelectedLabel(newLabel);
		if (isAdmin || isManager) {
			setOpenModal(true);
		} else {
			handleDetalharValor(newLabel, field);
		}
	}, [isAdmin, isManager, columns]);

	const handleDetalharValor = useCallback((columnId=selectedLabel, fieldId=selectedField) => {
		if (dfcNotDetalharList.includes(fieldId)){
			dispatch(showSnackMessage({message: "Impossivel detalhar categoria.", severity: "warning"}));
		} else {
			const url = "/detalha_ca";
			const params = { lojas: storeId, columnId, fieldId, table: "DFC"};
			const queryString = new URLSearchParams(params).toString();
			const fullUrl = `${url}?${queryString}`;
			window.open(fullUrl, "_blank");
		}
	}, [storeId, selectedLabel, selectedField]);

	const sendMetadata = useCallback((metadata, value, comment) => {
		setLoadingModal(true);
		let position = getScrollY(componentRef);
		const dataRequest = {
			loja_id: storeId ,
			field: fieldId,
			month: columnId,
			metadata: metadata,
			value: parseFloat(value),
			comment: comment
		};
		api.DFCMetadata(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModal(false);
			if (metadata === "comment"){
				dispatch(showSnackMessage({message: "Comentário salvo com sucesso"}));
				setOpenInsertCommentModal(false);
			}
			else if (metadata === "fixed"){
				dispatch(showSnackMessage({message: "Valor fixo salvo com sucesso"}));
				setOpenModalFixedValue(false);
			}
			getDFC(monthsFiltered, position);
		}).catch(() => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, [storeId, fieldId, columnId, monthsFiltered]);

	const editComment = useCallback((comment, commentId) => {
		setLoadingModal(true);
		setIsEdit(false);
		let position = getScrollY(componentRef);
		const dataRequest = {
			loja_id: storeId,
			comment: comment,
			comment_id: commentId
		};
		api.editCommentDFC(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			dispatch(showSnackMessage({message: "Comentário editado com sucesso"}));
			getDFC(monthsFiltered, position);
		}).catch(() => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, []);

	const delMetadata = useCallback((metadata, commentId) =>{
		setLoadingModal(true);
		let position = getScrollY(componentRef);
		let dataRequest = {};
		if (metadata === "comment"){
			dataRequest = {
				loja_id: storeId,
				metadata: metadata,
				comment_id: commentId
			};
		} else {
			dataRequest = {
				loja_id: storeId,
				month: columnId,
				field: fieldId,
				metadata: metadata
			};
		}
		api.delMetadataDFC(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModal(false);
			if (metadata === "comment"){
				dispatch(showSnackMessage({message: "Comentário deletado com sucesso!"}));
				setOpenViewCommentModal(false);
			}
			else if (metadata === "fixed"){
				dispatch(showSnackMessage({message: "Valor fixo deletado com sucesso!"}));
				setOpenModalFixedValue(false);
			}
			else if (metadata === "both"){
				dispatch(showSnackMessage({message: "Comentário e valor fixo deletados com sucesso!"}));
			}
			getDFC(monthsFiltered, position);
		}).catch(() => {
			setLoadingModal(false);
			setOpenModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, [storeId, columnId, fieldId]);

	const handleAbrirTodos = () => {
		dispatch(changeOpenComponent(!isOpenDfc));
	};

	const fileIcon = useMemo(() => (
		<FileDownloadIcon/>
	), []);

	const changeOpen = () => {  
		dispatch(changeOpenComponent(true));
		setStartAt(0);
		setOpenTour(!openTour);
	};

	const openTourSelected = (index) => {
		dispatch(changeOpenComponent(true));
		setStartAt(index);
		setOpenTour(!openTour);
	};

	return (
		<div className="main">
			<LastModified title="DFC - Demonstração de Fluxo de Caixa" shouldUpdate={shouldUpdateChild}/>
			{
				(!!isAdmin || !!isManager) && (
					<GenericModal
						openModal={openModal}
						setOpenModal={setOpenModal}
						fixedValue={fixedValue}
						setFixedValue={setFixedValue}
						setOpenModalFixedValue={setOpenModalFixedValue}
						openModalFixedValue={openModalFixedValue}
						openViewCommentModal={openViewCommentModal}
						openInsertCommentModal={openInsertCommentModal}
						setOpenViewCommentModal={setOpenViewCommentModal}
						setOpenInsertCommentModal={setOpenInsertCommentModal}
						commentsList={commentsList}
						comment={comment}
						setComment={setComment}
						editComment={editComment}
						loadingModal={loadingModal}
						delMetadata={delMetadata}
						sendMetadata={sendMetadata}
						isEdit={isEdit}
						setIsEdit={setIsEdit}
						handleDetalharValor={handleDetalharValor}
						selectedField={selectedField}
						component="dfc"
					/>
				)
			}

			<Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, color: temaEscuro && LINE_TABLE, backgroundImage: temaEscuro && "none"}}>
				{loading ? (

					[...Array(15).keys()].map((i,d) =>{
						return(
							<Skeleton key={d} height={50}/>
						);
					})
				) : (
					storeId === 0 ?
						(
							<SelectStore/>
						) : (
							<React.Fragment>
								<Grid container spacing={2} data-testid="dfc">
									<Grid item xs={12}>
										<Grid container sx={{display: "flex", alignItems: "center"}}>
											<Grid item sx={{width: "490px"}}>
												<FilterWithTag
													options={monthOptions}
													handleSubmit={handleFilter}
													setOptions={setMonthsFiltered}
													monthsFiltered={monthsFiltered}
													previousValue={previousMonths}
													className="button_filter"
												/>
											</Grid>
											<Grid item xs>
												<Grid container spacing={GRID_BUTTONS_SPACING} sx={{display: "flex", justifyContent: "end"}}>
													<Grid item>
														{
															!!isAdmin && (
																<Refresh 
																	handleRefresh={handleRefresh}
																	monthOptions={monthOptions}
																	setMonthOptions={setMonthsFiltered}
																	monthsFiltered={monthsFiltered}
																	className="button_reload"
																	optionFilter
																	isNotDRE
																	open={open}
																	setOpen={setOpen}
																/>
															)
														}
													</Grid>
													<Grid item>
														<GrayButton
															title="Exportar"
															startIcon={fileIcon}
															onClick={() => handlePrint()}
															className="button_export"
														/>
													</Grid>
													<Grid item>
														<IconButton style={{background: "#ECC94B", borderRadius: "4px"}} onClick={() => changeOpen()}>
															<LightbulbIcon style={{color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px"}}/>
														</IconButton>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={8}>
												<Grid container spacing={2}>
													<Grid item>
														<Grid container>
															<Grid item sx={{display: "flex", alignItems: "center"}}>
																<CircleIcon className="circles" style={{ color: YELLOW_INFO_UX, }}/>
															</Grid>
															<Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>
																<a>Valor Manual</a>
															</Grid>
														</Grid>
													</Grid>
													<Grid item>
														<Grid container>
															<Grid item sx={{display: "flex", alignItems: "center"}}>
																<CircleIcon className="circles" style={{ color: PURPLE_INFO_UX, }}/>
															</Grid>
															<Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>
																<a>Comentário</a>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Tour
											steps={filteredSteps}
											isOpen={openTour}
											onRequestClose={changeOpen}
											accentColor={DASH_BAR_COLOR}
											startAt={startAt}
											rounded={5}
                                            showNavigation={false}
                                            inViewThreshold={60}
											className={temaEscuro && "tour"}
											badgeContent={(curr, tot) => `${curr}/${tot}`}
										/>
										<TableContainer ref={componentRef} className="table-container">
											<Table stickyHeader aria-label="sticky table">
												<TableHead>
													<TableRow>
														{columns.map((column) => (
															<TableCell
																key={column.label}
																align={column.align}
																data-testid="dfc-table-row"
																style={{
																	...{ zIndex: 3 },
																	...{ fontWeight: "bold" },
																	...{ minWidth: column.minWidth },
																	...{ backgroundColor: temaEscuro && WALPAPPER_THEME},
                                                                    ...{ color: temaEscuro && LINE_TABLE }}}
															>
																{column.label}
															</TableCell>
														))}
														<TableCell
															style={{cursor: "pointer", paddingTop: "22px", backgroundColor: temaEscuro && PAPER_PADDING_THEME}}
															onClick={() => handleAbrirTodos()}
														>
															{isOpenDfc ? <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon/>}
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<Row
														isCollapse
														openTour={openTour}
														title="Atividades Operacionais"
														rows={atividades}
														columns={columns}
														handleOpenModal={handleOpenModal}
														backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
														colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
														color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
														fontWeight="bold"
														className="linha_1"
														onClick={() => openTourSelected(3)}
														isTour
													>
														<RowData
															rows={rowsAtividades}
															columns={columns}
															handleOpenModal={handleOpenModal}
															tourColumns={tourAtividades}
															backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
														/>
													</Row>
													<Row
														isCollapse
														openTour={openTour}
														title="Atividades de Investimento"
														rows={rowsInvestimentoHeader}
														columns={columns}
														handleOpenModal={handleOpenModal}
														backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
														colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
														color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
														fontWeight="bold"
														className="linha_42"
														onClick={() => openTourSelected(44)}
														isTour
													>
														<RowData
															rows={rowsInvestimento}
															columns={columns}
															handleOpenModal={handleOpenModal}
															tourColumns= {tourInvestimentos}
															backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
														/>
													</Row>
													<Row
														isCollapse
														openTour={openTour}
														title="Atividades de Financiamento"
														rows={rowsFinanciamentoHeader}
														columns={columns}
														handleOpenModal={handleOpenModal}
														backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
														colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
														color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
														fontWeight="bold"
														className="linha_47"
														onClick={() => openTourSelected(44)}
														isTour
													>
														<RowData
															rows={rowsFinanciamento}
															columns={columns}
															handleOpenModal={handleOpenModal}
															tourColumns= {tourFinanciamento}
															backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
														/>
													</Row>
													<Row
														backgroundColor={GRAY_LABEL_UX}
														colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
														color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
														title="Caixa Gerado/Consumido"
														fontWeight="bold"
														rows={rowsCaixaHeader}
														columns={columns}
														handleOpenModal={handleOpenModal}
														className="linha_56"
														onClick={() => openTourSelected(58)}
														isTour
													/>
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								</Grid>
							</React.Fragment>
						)
				)}
			</Paper>
		</div>
	);
}