export const stepsCMVSemanal = [
    {
        selector: ".tabCMVSemanal",
        content: "Aba responsável por mostrar o gráfico com o CMV Semanal dos meses filtrados."
    },
    {
        selector: ".tabCMVCalc",
        content: "Aba responsável pelos cálculos do CMV."
    },
    {
        selector: "[data-testid='filter-with-tag']",
        content: "Filtro dos meses disponíveis para o CMV."
    },
    {
        selector: ".prazo-medio",
        content: "Prazo médio dos meses selecionados, clique para saber mais sobre o prazo médio."
    },
    {
        selector: ".CMVGraph",
        content: "Gráfico com os CMVs separados por semana (Passe o cursor por cima das colunas para detalhar)."
    },
];

export const stepsCMVCalc = [
    {
        selector: ".tabCMVSemanal",
        content: "Aba responsável por mostrar o gráfico com o CMV Semanal dos meses filtrados."
    },
    {
        selector: ".tabCMVCalc",
        content: "Aba responsável pelos cálculos do CMV."
    },
    {
        selector: "[data-testid='filter-with-tag']",
        content: "Filtro dos meses disponíveis para o CMV."
    },
    {
        selector: ".prazo-medio",
        content: "Prazo médio dos meses selecionados, clique para saber mais sobre o prazo médio."
    },
    {
        selector: ".faturamentoLiquidoItens",
        content: "Faturamento Liquido de Itens dos meses selecionados."
    },
    {
        selector: ".faturamentoSemanal",
        content: "Faturamento Semanal dos meses selecionados."
    },
    {
        selector: ".estoqueInicial",
        content: "Estoque inicial desejado (Não obrigatório)."
    },
    {
        selector: ".estoqueFinal",
        content: "Estoque final desejado (Não obrigatório)."
    },
    {
        selector: ".selectSemana",
        content: "Seleciona a semana desejada para a consulta."
    },
    {
        selector: ".selectMes",
        content: "Seleciona o mês desejado para a consulta."
    },
    {
        selector: ".calcular",
        content: "Botão para calcular o CMV/Fat. Bruto, CMV Semanal e o CMV Real."
    },
    {
        selector: ".cmvFat",
        content: "CMV dividido pelo Faturamento Bruto dos meses selecionados."
    },
    {
        selector: ".cmvSemanal",
        content: "CMV da semana escolhida."
    },
    {
        selector: ".cmvMensal",
        content: "CMV do mês escolhido."
    }
];