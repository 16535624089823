import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import api from "../../axios";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GrayButton from "../../components/Buttons/GrayButton";
import {
	PAPER_PADDING_THEME,
	styleButton,
	styleDarkButton,
	styleGrid,
	GRAY_LABEL_UX,
	GRAY_BG_UX,
	LINE_TABLE,
	styleModal
} from "../../shared/utils";
import { showSnackMessage } from "../../actions/SnackActions";
import { useDispatch, useSelector } from "react-redux";
import FilterWithTag from "../../components/Filter/FilterWithTag";
import moment from "moment";


function ResolveProblems(props){
	const {testId} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const [selectedLojas, setSelectedLojas] = useState([]);
	const [selectedPlataformas, setSelectedPlataformas] = useState([]);
	const [plataformas, setPlataformas] = useState([]);
	const [months, setMonths] = useState([]);
	const [monthsAll, setMonthsAll] = useState([]);
	const lojas = useSelector(state => state.StoreReducer.lojas);

	useEffect(() => {
		getPlataformas();
		let today = moment();
		let monthList = [];
		for (let x = 1; x <= 12; x++) {
			let monthObj = {
				label: today.clone().subtract(x, "months").format("MM/YYYY"),
				value: today.clone().subtract(x, "months").format("MM/YYYY"),
			};
			monthList.push(monthObj);
		}
		setMonthsAll(monthList);
	}, []);

	const getPlataformas = () => {
		api.GetPlataformas().then(response => {
			let data = response.data;
			setPlataformas(data);
		}).catch(() => {
		});
	};

	const handleRunEtl = () => {
		let dataRequest = {
			"plataformas": selectedPlataformas,
			"lojas": selectedLojas,
			meses: months
		};
		api.SendMessageETL(dataRequest).then(() => {
			dispatch(showSnackMessage({ message: "Arquivo processado com sucesso" }));
		}).catch(() => {
			dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
		});
	};

	const handleDownloadReport = () => {
		let dataRequest = {
			"plataformas": selectedPlataformas,
			"lojas": selectedLojas,
			meses: months
		};
		api.SendMessageCrawler(dataRequest).then(response => {
			let data = response.data;
			dispatch(showSnackMessage({ message: data.message }));
		}).catch(error => {
			let data = error.response.data;
			dispatch(showSnackMessage({ message: data.message, severity: "error" }));
		});
	};

	return (
		<React.Fragment>
			<Button
				variant="contained"
				onClick={() => setOpen(true)}
				style={temaEscuro ? styleDarkButton : styleButton}
				startIcon={<SettingsSuggestIcon />}
				data-testid="button-resolve-problems"
				>
				Resolução
			</Button>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Paper
					style={{...styleModal, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none"}}
					data-testid={testId}
				>
					<h3 className ="info-text" style={{color:temaEscuro ? LINE_TABLE : GRAY_LABEL_UX}} >Resolver Problemas</h3>
					<Grid container spacing={3} sx={{display: "flex", justifyContent: "center"}}>
						<Grid item>
							<FilterWithTag 
								options={lojas}
								setOptions={setSelectedLojas}
								optionFilter
								placeholderProp="Selecione a(s) Loja(s)"
								testId="filter-lojas"
								widthSetting={"430px"}
							/>
						</Grid>
						<Grid item>
							<FilterWithTag
								options={plataformas}
								setOptions={setSelectedPlataformas}
								optionFilter
								placeholderProp="Selecione a(s) Plataforma(s)"
								limitSelection={4}
								testId="filter-plataformas"
								widthSetting={"350px"}
							/>
						</Grid>
						<Grid item>
							<FilterWithTag 
								options={monthsAll}
								setOptions={setMonths}
								optionFilter
								placeholderProp="Selecione o(s) Mes(es)"
								limitSelection={4}
								testId="filter-months"
								widthSetting={"350px"}
							/>
						</Grid>
					</Grid>
					<Grid container sx={{ display: "flex", justifyContent: "center" }} data-testid="buttons-troubleshooting" >
						<Grid item sx={styleGrid}>
							<GrayButton
								onClick={() => handleRunEtl()}
								title="Processar dados"
								startIcon={<ManageHistoryIcon/>}
								testId="button-process-data"
								disabled={selectedLojas.length === 0 || selectedPlataformas.length === 0 || months.length === 0}
								/>
						</Grid>
						<Grid item sx={styleGrid}>
							<GrayButton
								onClick={() => handleDownloadReport()}
								title="Buscar dados"
								startIcon={<FileDownloadIcon/>}
								testId="button-download-data"
								disabled={selectedLojas.length === 0 || selectedPlataformas.length === 0 || months.length === 0}
								/>
						</Grid>
					</Grid>
				</Paper>
			</Modal>
		</React.Fragment>

	);
}

export default ResolveProblems;