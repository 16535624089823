import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import api from "../../axios";
import Line from "../../components/Dash/Line";
import DualLine from "../../components/Dash/DualLine";
import Box from "@mui/material/Box";
import SelectStore from "../../components/SelectStore/SelectStore";
import KPI from "../../components/Dash/KPI";
import Bar from "../../components/Dash/Bar";
import StackedArea from "../../components/Dash/StackedArea";
import StackedBar from "../../components/Dash/StackedBar";
import BarLine from "../../components/Dash/BarLine";
import MultipleKPI from "../../components/Dash/MultipleKPI";
import FilterWithTag from "../../components/Filter/FilterWithTag";
import CardIcon from "../../components/Dash/CardIcon";
import BalanceIcon from "@mui/icons-material/Balance";
import EventAvailableTwoToneIcon from "@mui/icons-material/EventAvailableTwoTone";
import { cardIconStyle, DASH_BAR_COLOR, styleButton, BORDER_BUTTON, GRAY_LABEL_UX, formatterCurrency, cardIconStyleBlack, WHITE_THEME_BLACK, WHITE_ESCRITA_THEME, GRID_BUTTONS_SPACING } from "../../shared/utils";
import Tour from "reactour";
import { steps } from "./Tour";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import YellowButton from "../../components/Buttons/YellowButton";
import {Button} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import LastModified from "../../components/LastModified/LastModified";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import StoreIcon from "@mui/icons-material/Store";
import GrayButton from "../../components/Buttons/GrayButton";

export default function Dashboard() {

	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [startAt, setStartAt] = useState(0);
	const [monthsFiltered, setMonthsFiltered] = useState([]);
	const [previousMonths, setPreviousMonths] = useState([]);
	const [monthOptions, setMonthOptions] = useState([]);
	const storeId = useSelector(state => state.StoreReducer.storeId);

	const [optionsFatKPI, setOptionsFatKPI] = useState({});
	const [optionsFatLiquidoKPI, setOptionsFatLiquidoKPI] = useState({});
	const [optionsCMVKPI, setOptionsCMVKPI] = useState({});
	const [optionsCMOKPI, setOptionsCMOKPI] = useState({});
	const [optionsCMVOKPI, setOptionsCMVOKPI] = useState({});
	const [optionsJurosKPI, setOptionsJurosKPI] = useState({});
	const [optionsLucroKPI, setOptionsLucroKPI] = useState({});
	const [optionsLucroOpKPI, setOptionsLucroOpKPI] = useState({});
	const [optionsLucroOpPercKPI, setOptionsLucroOpPercKPI] = useState({});
	const [optionsMargemCusto, setOptionsMargemCusto] = useState({});
	const [optionsFatLine, setOptionsFatLine] = useState({});
	const [optionsCMVStacked, setOptionsCMVStacked] = useState();
	const [optionsCMVOStacked, setOptionsCMVOStacked] = useState();
	const [optionsBalance, setOptionsBalance] = useState({});
	const [optionsTaxaCartao, setOptionsTaxaCartao] = useState({});
	const [optionsFretes, setOptionsFretes] = useState({});
	const [optionsImpostos, setOptionsImpostos] = useState({});
	const [optionsMargCont, setOptionsMargCont] = useState({});
	const [optionsLucroOp, setOptionsLucroOp] = useState({});
	const [optionsPrazo, setOptionsPrazo] = useState();
	const [optionsTicketFat, setOptionsTicketFat] = useState({});
	const [optionsCustoFixoReceita, setOptionsCustoFixoReceita] = useState({});
	const [optionsTicketMedio, setOptionsTicketMedio] = useState({});
	const [optionsTicketApps, setOptionsTicketApps] = useState({});
	const [optionsTicketAdq, setOptionsTicketAdq] = useState({});
	const [isCategoryOutros, setIsCategoryOutros] = useState(false);
	const [advancedFilter, setAdvancedFilter] = useState(false);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);


	useEffect(() => {
		if (storeId !== 0) {
			getDashboard(monthsFiltered);
		}
	}, [storeId]);

	const handleFilter = () => {
		getDashboard(monthsFiltered);
	};

	const handleAdvancedFilter = () => {
		setAdvancedFilter(!advancedFilter);
	};

	const getDashboard = (monthsFilter) => {

		setLoading(true);
		const dataRequest = {
			lojas: [storeId],
			months: monthsFilter
		};

		api.getDashboard(dataRequest).then(response => {
			let data = response.data;
			if (data.category === "Restaurante") {	
				let dataDash = data.dashboard;
				let _months = [];
				data.months.map((month) => {
					_months.push({ value: month, label: month });
				});
				setMonthOptions(_months);
				setPreviousMonths(data.filtered_months);
	
				setOptionsFatKPI(dataDash.fat_kpi);
				setOptionsFatLiquidoKPI(dataDash.fat_liquido_kpi);
				setOptionsCMVKPI(dataDash.cmv_kpi);
				setOptionsCMOKPI(dataDash.cmo_kpi);
				setOptionsCMVOKPI(dataDash.cmvo_kpi);
				setOptionsJurosKPI(dataDash.juros_kpi);
				setOptionsLucroKPI(dataDash.lucro_kpi);
				setOptionsLucroOpKPI(dataDash.lucro_op_kpi);
				setOptionsLucroOpPercKPI(dataDash.lucro_op_perc_kpi);
				setOptionsMargemCusto(dataDash.margem_kpi);
				setOptionsFatLine(dataDash.fat);
				setOptionsCMVStacked(dataDash.cmv);
				setOptionsCMVOStacked(dataDash.cmvo);
				setOptionsBalance(dataDash.balance);
				setOptionsMargCont(dataDash.marg_cont);
				setOptionsLucroOp(dataDash.lucro_op);
				setOptionsTaxaCartao(dataDash.taxa_cartao);
				setOptionsFretes(dataDash.fretes);
				setOptionsImpostos(dataDash.impostos);
				setOptionsPrazo(dataDash.prazo_kpi);
				setOptionsTicketFat(dataDash.ticket_fat);
				setOptionsCustoFixoReceita(dataDash.custo_fixo_receita);
				setOptionsTicketMedio(dataDash.ticket_kpi);
				setOptionsTicketApps(dataDash.ticket_apps_kpi);
				setOptionsTicketAdq(dataDash.ticket_adq_kpi);
				setIsCategoryOutros(false);
			} else {
				setIsCategoryOutros(true);
			}

			setLoading(false);
		}).catch(() => {
			setLoading(false);
		});
	};

	const changeOpen = () => {
		setStartAt(0);
		setOpen(!open);
	};

	const openTourSelected = (index) => {
		setStartAt(index);
		setOpen(true);
	};

	return (
		<div className="main" >
			<LastModified title="Resumo"/>
			<Tour
				steps={steps}
				isOpen={open}
				onRequestClose={changeOpen}
				startAt={startAt}
				rounded={5}
				accentColor={DASH_BAR_COLOR}
				{...( temaEscuro ? {className: "tour"} : {})}
				badgeContent={(curr, tot) => `${curr}/${tot}`}
			/>
			{loading ? (
				<Grid container spacing={2} rowSpacing={4}>
					{[...Array(2).keys()].map((i, d) => {
						return (
							<Grid item xs={2} key={d}>
								<Skeleton variant="rectangular" height={50} />
							</Grid>
						);
					})}
					<Grid item xs={8} />
					{[...Array(4).keys()].map((i, d) => {
						return (
							<Grid item xs={3} key={d}>
								<Skeleton variant="rectangular" height={100} />
							</Grid>
						);
					})}
					<Grid item xs={8}>
						<Skeleton variant="rectangular" height={300} />
					</Grid>
					<Grid item xs={4}>
						<Skeleton variant="rectangular" height={300} />
					</Grid>
					{[...Array(2).keys()].map((i, d) => {
						return (
							<Grid item xs={6} key={d}>
								<Skeleton variant="rectangular" height={300} />
							</Grid>
						);
					})}
					{[...Array(3).keys()].map((i, d) => {
						return (
							<Grid item xs={4} key={d}>
								<Skeleton variant="rectangular" height={300} />
							</Grid>
						);
					})}
				</Grid>
			) : (
				storeId === 0 ?
					(
						<SelectStore />
					) : (
						isCategoryOutros ? 
						(
							<div style={{...styles.center}} data-testid="dashboard_other">
								<Grid container rowSpacing={5}>
									<Grid item xs={12}>
										<WarningAmberIcon style={{fontSize: "150px", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}/>
									</Grid>
									<Grid item xs={12}>
										<a style={{...styles.text, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Dashboard não disponível para a loja selecionada</a>
									</Grid>
								</Grid>
							</div>
						) : (
							<Grid container rowSpacing={2} data-testid="dashboard">
								<Grid item xs={12}>
									<Grid container spacing={2} sx={{display: "flex", alignItems: "flex-start", marginTop: "12px", flexWrap: "wrap"}}>
										<Grid item>
											<FilterWithTag
												optionFilter
												data-testid="filter-with-tag"
												options={monthOptions}
												setOptions={setMonthsFiltered}
												previousValue={advancedFilter ? monthsFiltered : previousMonths}
												placeholderProp="Data Competência"
												isDefaultFilter
												advancedFilter={advancedFilter}
												handleAdvancedFilter={handleAdvancedFilter}
												widthSetting={"340px"}
											/>
										</Grid>
										<Grid item>
											<Button className="filtro" data-testid="btn-filter-dashboard" variant="contained" onClick={handleFilter}
													style={{...styleButton, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, borderColor: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX}}>
												<FilterListOutlinedIcon/>
												Filtrar
											</Button>
										</Grid>
										<Grid item xs sx={{display: "flex", justifyContent: "end"}}>
											<Grid container spacing={GRID_BUTTONS_SPACING} sx={{display: "flex", justifyContent: "end"}}>
												<Grid item>
													<GrayButton
														isRedirect
														path="/insights"
														title="insights de mercado"
														testId="redirect-insights"
														startIcon={<StoreIcon/>}
													/>
												</Grid>
												<Grid item>
													<YellowButton			
														onClick={changeOpen}
														title="guia"
														testId="button-guia"
														startIcon={<LightbulbIcon/>}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={2} rowSpacing={4}>
										<Grid item sm={6} md={2.4}>
											<KPI className="card_1" isTour onClick={() => openTourSelected(2)} title="Faturamento" testId="faturamento-kpi" data={optionsFatKPI?.data} footerData={optionsFatKPI?.footerData} footerPercData={optionsFatKPI?.percData}/>
										</Grid>
										<Grid item sm={6} md={2.4}>
											<KPI className="card_2" isTour onClick={() => openTourSelected(3)} title="Fat. Líquido" testId="faturamento_liquido-kpi" data={optionsFatLiquidoKPI?.data} footerData={optionsFatLiquidoKPI?.footerData} footerPercData={optionsFatLiquidoKPI?.percData}/>
										</Grid>
										<Grid item sm={6} md={2.4}>
											<KPI className="card_3" isTour onClick={() => openTourSelected(4)} title="CMV" testId="cmv-kpi" data={optionsCMVKPI?.data} suffix="%" footerData={[]} footerPercData={optionsCMVKPI?.percData} />
										</Grid>
										<Grid item sx={6} md={2.4}>
											<KPI className="card_4" isTour onClick={() => openTourSelected(5)} title="CMO" testId="cmo-kpi" data={optionsCMOKPI?.data} suffix="%" footerData={[]} footerPercData={optionsCMOKPI?.percData} />
										</Grid>
										<Grid item sm={6} md={2.4}>
											<KPI className="card_5" isTour onClick={() => openTourSelected(6)} title="CMV + CMO" testId="cmv-cmo-kpi" data={optionsCMVOKPI?.data} suffix="%" footerData={[]} footerPercData={optionsCMVOKPI?.percData} />
										</Grid>
										
										<Grid item xs={9}>
											<Line className="card_6" onClick={() => openTourSelected(7)} title="Faturamento" testId="faturamento-line" data={optionsFatLine} />
										</Grid>
										<Grid item xs={3}>
											<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
												<Box sx={{ flex: 1 }}>
													<CardIcon
														onClick={() => openTourSelected(8)}
														className="card_7"
														title="Prazo Médio de Pagamento"
														testId="prazo-medio-cardicon"
														data={optionsPrazo?.data}
														suffix="dias"
														icon={<EventAvailableTwoToneIcon sx={temaEscuro ? cardIconStyleBlack : cardIconStyle} />}
													/>
												</Box>
												<Box sx={{ flex: 1, pt: 2 }} >
													<CardIcon
														onClick={() => openTourSelected(9)}
														className="card_8"
														currency
														title="Ponto de Equilíbrio"
														testId="ponto-equilibrio-cardicon"
														data={optionsBalance?.data}
														icon={<BalanceIcon 
														sx={temaEscuro ? cardIconStyleBlack : cardIconStyle}
														/>}
													/>
												</Box>
											</Box>
										</Grid>
										<Grid item xs={8}>
											<DualLine className="card_9" onClick={() => openTourSelected(10)} isFullHeight title="Ticket Médio vs Faturamento" testId="ticket-faturamento-line" data={optionsTicketFat} />
										</Grid>
										<Grid item xs={4}>
											<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
												<Box sx={{ flex: 1 }}>
													<CardIcon
														onClick={() => openTourSelected(11)}
														className="card_10"
														title="Ticket Médio"
														testId="ticket-medio-cardicon"
														data={formatterCurrency(optionsTicketMedio?.data)}
														icon={<CurrencyExchangeIcon 
														sx={temaEscuro ? cardIconStyleBlack : cardIconStyle}	
														/>}
													/>
												</Box>
												<Box sx={{ flex: 1, pt: 2 }}>
													<CardIcon
														onClick={() => openTourSelected(12)}
														className="card_11"
														title="Ticket Médio Apps"
														testId="ticket-medio_apps-cardicon"
														data={formatterCurrency(optionsTicketApps?.data)}
														icon={<CurrencyExchangeIcon 
														sx={temaEscuro ? cardIconStyleBlack : cardIconStyle}	
														/>}
													/>
												</Box>
												<Box sx={{ flex: 1, pt: 2 }}>
													<CardIcon
														onClick={() => openTourSelected(13)}
														className="card_12"
														title="Ticket Médio Adquirentes e Vouchers"
														testId="ticket-medio_adq-cardicon"
														data={formatterCurrency(optionsTicketAdq?.data)}
														icon={<CurrencyExchangeIcon 
														sx={temaEscuro ? cardIconStyleBlack : cardIconStyle}	
														/>}
													/>
												</Box>
												
											</Box>
										</Grid>
										<Grid item xs={6}>
											<StackedArea onClick={() => openTourSelected(14)} className="card_13" percentage title="CMV (%)" testId="cmv-percent-stacked-area" data={optionsCMVStacked} />
										</Grid>
										<Grid item xs={6}>
											<StackedBar onClick={() => openTourSelected(15)} className="card_14" percentage title="CMV + CMO (%)" testId="cmv-cmo-percent-stacked-bar" data={optionsCMVOStacked} />
										</Grid>


										<Grid item xs={4}>
											<Bar onClick={() => openTourSelected(16)} className="card_15" title="Taxas de Cartão e Apps (%)" testId="taxas-cartao-bar" percentage data={optionsTaxaCartao} />
										</Grid>
										<Grid item xs={4}>
											<Bar onClick={() => openTourSelected(17)} className="card_16" title="Frete e Entrega (%)" testId="frete-entrega-bar" percentage data={optionsFretes} />
										</Grid>
										<Grid item xs={4}>
											<Bar onClick={() => openTourSelected(18)} className="card_17" title="Impostos sobre Vendas (%)" testId="impostos-vendas-bar" percentage data={optionsImpostos} />
										</Grid>


										<Grid item xs={8}>
											<Line className="card_18" onClick={() => openTourSelected(19)} title="Margem de Contribuição e Custo Fixo (%)" testId="margem-contribuicao-line" percentage data={optionsMargCont} />
										</Grid>
										<Grid item xs={4}>
											<MultipleKPI
												className="card_19"
												onClick={() => openTourSelected(20)}
												firstData={optionsMargemCusto?.firstData}
												secData={optionsMargemCusto?.secData}
												suffix="%"
												firstFooter="Média da Margem de Contribuição"
												secFooter="Média do Custo Fixo"
												testId="medias-multiple-kpi"
											/>
										</Grid>

										<Grid item xs={12}>
											<StackedArea onClick={() => openTourSelected(21)} className="card_20" percentage title="Custos Fixos / Receita" testId="custo-fixo-receita-testid" data={optionsCustoFixoReceita} />
										</Grid>


										<Grid item xs={12}>
											<BarLine onClick={() => openTourSelected(22)} className="card_21" title="Lucro Operacional" testId="lucro-operacional-barline" data={optionsLucroOp} />
										</Grid>

										<Grid item xs={12} >
											<Grid container rowSpacing={2} spacing={2}>
												<Grid item xs={3}>
													<KPI
														className="card_22"
														title="Juros"
														testId="juros-kpi"
														data={optionsJurosKPI?.data}
														footerData={[]}
														footerPercData={optionsJurosKPI?.percData}
														onClick={() => openTourSelected(23)}
														isTour
													/>
												</Grid>
												<Grid item xs={3}>
													<KPI
														className="card_23"
														title="Lucro Operacional"
														testId="lucro-operacional-kpi"
														data={optionsLucroKPI?.data}
														footerData={optionsLucroKPI?.footerData}
														footerPercData={optionsLucroKPI?.percData}
														onClick={() => openTourSelected(24)}
														isTour
													/>
												</Grid>
												<Grid item xs={3}>
													<KPI
														title="Lucro Operacional"
														testId="lucro-op-2-kpi"
														data={optionsLucroOpKPI?.data}
														footerData={[]}
													/>
												</Grid>
												<Grid item xs={3}>
													<KPI
														title="Lucro Operacional (%)"
														suffix="%"
														testId="lucro-op-percent-kpi"
														data={optionsLucroOpPercKPI?.data}
														footerData={[]}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)
					)
			)}
		</div>
	);
}

const styles = {
	center: {
		textAlign: "center",
		margin: "auto",
		width: "70%",
		// margin is 30px
		height: "calc(100vh - 60px)",
		display: "flex",
		alignItems: "center"
	},
	text: {
		color: GRAY_LABEL_UX,
		fontWeight: 600,
		fontSize: "24px",
		lineHeight: "32px"
	}
};

