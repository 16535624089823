import React, {useState, useEffect, useMemo} from "react";
import Paper from "@mui/material/Paper";
import ReactECharts from "echarts-for-react";
import Box from "@mui/material/Box";
import {
	DASH_AXIS_COLOR,
	DASH_LABEL_COLOR,
	formatterThousand,
	formatterCurrency,
	paperDash, spanDash,
	fontFamily, BLUE_THEME,
	GRAY_TABLE, LINE_TABLE,
    WHITE_ESCRITA_THEME,
	WHITE_THEME_BLACK,
	DASH_LINESTYLE_COLOR,
	TEXT_THEME
} from "../../shared/utils";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";
import { useSelector } from "react-redux";


const BarCompras = (props) => {
	const {data,  testId} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	const formatterBar = (params) => {
		return formatterThousand(params.data);
	};
	const tooltipFormatter = (params) => {
		let data = params[0];
		return `${data.axisValue}: <b>${formatterCurrency(data.value)}</b>`;
	};

	const series = {
		data: [],
		type: "bar",
		smooth: true,
		symbol: "circle",
		label: {
			show: true,
			position: "right",
			color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_LABEL_COLOR,
			fontFamily,
			formatter: formatterBar
		},
	};


	const [options, setOptions] = useState({
        grid: {
			left: 0,
			right: 32,
			bottom: 0,
			top: 24,
			containLabel: true
		},
        xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
			color: temaEscuro ? GRAY_TABLE  : DASH_LABEL_COLOR,
            data: [],
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			axisLabel: {
				interval: 0,
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_LABEL_COLOR,
                formatter: formatterThousand
			},
			splitLine: {
                show: true,
                lineStyle: {
                    color: temaEscuro ? TEXT_THEME: DASH_LINESTYLE_COLOR,
                    type: "dashed"
                }
            },
        },
        yAxis: {
            type: "category",
            data: [],
            axisLabel: {
				interval: 0,
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK  : DASH_AXIS_COLOR,
				width: 100,
				overflow: "truncate",
				ellipsis: "..."
			},
			splitLine: {
                show: false
            },
        },
        series: [],
        title: {
			text: "R$",
			left: "left",
			textStyle: {
				color:  temaEscuro ? WHITE_THEME_BLACK  : DASH_LABEL_COLOR,
				fontFamily,
				fontSize: 12,
				fontWeight: 400
			}
		},
        tooltip: {
			trigger: "axis",
			fontFamily,
			formatter: tooltipFormatter,
			appendToBody: true,
			axisPointer: {
				lineStyle: {
					color:  temaEscuro ? TEXT_THEME: DASH_LINESTYLE_COLOR,
				}
			}
		},
	});
	const [key, setKey] = useState();

	useEffect(() => {
		let newOptions = {...options};
		newOptions.series = [];
        newOptions.yAxis.data = data.name;
		data?.series?.map((item) => {
			let newSeries = {...series, ...item};
			newOptions.series.push(newSeries);
		});
        setOptions(newOptions);
		setKey(Math.random());
	}, [data]);

	const getChart = useMemo(() => {
		return(
			<ReactECharts
				key={key}
				option={options}
				notMerge={true}
			/>
		);
	}, [options]);

	return(
		<Paper data-testid={testId} sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}} className={props.className}>
			<Box sx={{mb: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
				<span style={{...spanDash, ...(temaEscuro && {color:  LINE_TABLE})}}>{props.title}</span>
				<ButtonOpenTour onClick={props.onClick}/>
			</Box>
			{getChart}
		</Paper>
	);
};

export default React.memo(BarCompras);