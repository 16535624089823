export const steps = [
    {
        selector: ".filtro",
        content: "Possibilita filtrar lançamentos de determinada competência.",
    },
    {
        selector: ".input-text",
        content: "Possibilita a busca pelo texto informado nos lançamentos.",
    },
    {
        selector: ".card_1",
        content: "Soma das receitas em aberto para o período selecionado. Ao clicá-lo, a tabela de lançamentos será filtrada com as receitas em aberto.",
    },
    {
        selector: ".card_2", 
        content: "Soma das receitas realizadas para o período selecionado. Ao clicá-lo, a tabela de lançamentos será filtrada com as receitas realizadas."
    },
    {
        selector: ".card_3", 
        content: "Soma das despesas em aberto para o período selecionado. Ao clicá-lo, a tabela de lançamentos será filtrada com as despesas em aberto."
    },
    {
        selector: ".card_4", 
        content: "Soma das despesas realizadas para o período selecionado. Ao clicá-lo, a tabela de lançamentos será filtrada com as despesas realizadas."
    },
    {
        selector: ".card_5", 
        content: "Soma dos valores dos lançamentos para o período selecionado. Ao clicá-lo, a tabela de lançamentos será filtrada com todos os lançamentos. Por padrão, já vem selecionado."
    },
    {
        selector: ".footer_periodo", 
        content: "Período selecionado para visualização dos lançamentos."
    },
    {
        selector: ".footer_valor", 
        content: "Soma dos valores dos lançamentos para o período selecionado."
    },
];