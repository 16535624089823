import React, {useState, useEffect, useRef} from "react";
import Grid from "@mui/material/Grid";
import api from "../../axios";
import {useSelector} from "react-redux";
import {
	getLastMonth,
	getLastMonthYear,
	styleDefaultText,
	paperStyle,
	GRAY_LABEL_UX,
	PAPER_PADDING_THEME, WHITE_THEME_BLACK, WHITE_ESCRITA_THEME
} from "../../shared/utils";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import SelectStore from "../../components/SelectStore/SelectStore";
import moment from "moment";
import FilterYear from "../../components/Filter/FilterYear";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Header from "../../components/Titles/Header";
import GrayButton from "../../components/Buttons/GrayButton";
import { styleTitle } from "../../shared/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import YellowButton from "../../components/Buttons/YellowButton";
import AtaNotFound from "../../components/AtaNotFound/AtaNotFound";
import {showSnackMessage} from "../../actions/SnackActions";
import {useDispatch} from "react-redux";
import { useReactToPrint } from "react-to-print";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import Tour from "reactour";
import { DASH_BAR_COLOR, GRID_BUTTONS_SPACING } from "../../shared/utils";
import { steps } from "./TourAta";

export default function Ata() {
	const dispatch = useDispatch();

	const [isAtaNotFound, setIsAtaNotFound] = useState(false);
	const [ano, setAno] = useState(getLastMonthYear());
	const [mes, setMes] = useState(getLastMonth());
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState("");
	const [loja, setLoja] = useState("");
	const [preparadoPor, setPreparadoPor] = useState("");
	const [participantes, setParticipantes] = useState("");
	const [observacoes, setObservacoes] = useState("");
	const [arquivoUrl, setArquivoUrl] = useState("");
	const [open, setOpen] =  useState(false);
	const [startAt, setStartAt] = useState(0);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const storeId = useSelector(state => state.StoreReducer.storeId);

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
	const styleLines = {...styleDefaultText, color: temaEscuro && WHITE_THEME_BLACK};

	const handleExport = () => {
		handlePrint();
		if (arquivoUrl) {
			window.open(arquivoUrl, "_blank");
		}
	};

	useEffect(() => {
		if(storeId !== 0){
			getAta();
			setData(`${mes}/${ano}`);
		}
	}, [storeId]);

	useEffect(() => {
		if(storeId !== 0){
			getAta();
		}
	}, [ano, mes]);

	const getAta = () => {
		setLoading(true);
		api.GetAta(storeId, ano, mes).then(response => {
			setLoading(false);
			let ata = response.data;
			let formattedDate = moment(ata.data).format("DD/MM/YYYY");
			setData(formattedDate);
			setLoja(ata.loja);
			setPreparadoPor(ata.preparado_por);
			setParticipantes(ata.participantes);
			setObservacoes(ata.observacoes);
			setArquivoUrl(ata.url);
			setIsAtaNotFound(false);
		}).catch(() => {
			setLoading(false);
			setIsAtaNotFound(true);
		});
	};

	const openFile = () => {
		window.open(arquivoUrl, "_blank");
	};

	const deleteAta = () => {
		setLoading(true);
		let dataRequest = {
			ano,
			mes
		};
		api.DeleteAta(storeId, dataRequest).then(() => {
			setLoading(false);
			dispatch(showSnackMessage({message: "Ata excluída com sucesso"}));
			setData("");
			setLoja("");
			setPreparadoPor("");
			setParticipantes("");
			setObservacoes("");
			setArquivoUrl("");
			getAta();
		}).catch(() => {
			setLoading(false);
			dispatch(showSnackMessage({message: "Erro ao excluir ata", severity: "error"}));
		});
	};

	const handleMonthYear = (ano, mes) => {
		setAno(ano);
		setMes(mes);
	};

	const changeOpen = () => {
		setStartAt(0);
		setOpen(!open);
	};

	return (
		<div className="main">
			<Header title="Atas de Reunião" />
			{loading ? (
				<div style={{display: "flex", flexDirection: "column"}}>
					<Grid container>
						<Grid item xs={5}>
							<Grid container spacing={2}>
								{
									[...Array(2).keys()].map((i,d) =>{
										return(
											<Grid item key={d}>
												<Skeleton height={75} width={110} />
											</Grid>
										);
									})
								}
							</Grid>
						</Grid>
						<Grid item xs={7} sx={{display: "flex", alignItems: "center"}}>
							<Grid container spacing={2} sx={{display: "flex", justifyContent: "end"}}>
								{
									[...Array(3).keys()].map((i,d) =>{
										return(
											<Grid item key={d}>
												<Skeleton height={75} width={110} />
											</Grid>
										);
									})
								}

							</Grid>
						</Grid>
					</Grid>
					{
						[...Array(15).keys()].map((i,d) =>{
							return(
								<Skeleton height={50} key={d}/>
							);
						})
					}
				</div>
			) : (
				storeId === 0 ?
					(
						<SelectStore/>
					) : (
						<Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}}>
							<Grid container data-testid="ata" justifyContent="space-between">
								<Grid item xs={5}>
									<FilterYear optionFilter marginBottom={0} handleSubmit={handleMonthYear} prevYear={ano} prevMonth={mes}/>
								</Grid>
								<Tour
									steps={steps}
									isOpen={open}
									onRequestClose={changeOpen}
									accentColor={DASH_BAR_COLOR}
									startAt={startAt}
									rounded={5}
									showNavigation={false}
									inViewThreshold={60}
									className={temaEscuro && "tour"}
									badgeContent={(curr, tot) => `${curr}/${tot}`}
								/>
								{isAtaNotFound ?
									(
										<React.Fragment>
											<Grid item style={{display: "flex", alignItems: "center", justifyContent: "end"}}>
												<IconButton data-testid="ata-tour" style={{background: "#ECC94B", borderRadius: "4px"}} onClick={() => changeOpen()}>
													<LightbulbIcon style={{color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px"}}/>
												</IconButton>
											</Grid>
											<Grid item xs={12}>
												<AtaNotFound ano={ano} mes={mes} handleMonthYear={handleMonthYear} testId="AtaNotFound"/>
											</Grid>
										</React.Fragment>
									) : (
										<React.Fragment>
											<Grid item  style={{display: "flex", alignItems: "center"}}>
												<Grid container spacing={GRID_BUTTONS_SPACING} sx={{display: "flex", justifyContent: "end"}}>
													<Grid item>
														<GrayButton title="Exportar" onClick={handleExport} startIcon={<FileDownloadIcon />} />
													</Grid>
													<Grid item>
														<GrayButton title="Excluir" onClick={deleteAta} startIcon={<DeleteForeverIcon/>} testId={"deleteAta"}/>
													</Grid>
													<Grid item>
														<GrayButton
															isRedirect
															title="Editar"
															path="/add_ata"
															state={{
																storeId: storeId,
																mesCompetencia: `${ano}-${mes}-01`,
																data: data,
																preparadoPor: preparadoPor,
																participantes: participantes,
																observacoes: observacoes,
																isEdit: true,
															}}
															startIcon={<EditOutlinedIcon/>}
															testId={"editAta"}
														/>
													</Grid>
													<Grid item>
														<GrayButton
															isRedirect
															title="Adicionar"
															path="/add_ata"
															state={{storeId}}
															startIcon={<AddBoxOutlinedIcon/>}
															testId={"addNewAta"}
														/>
													</Grid>
													<Grid item style={{display: "flex", alignItems: "center", justifyContent: "end"}}>
														<IconButton data-testid="ata-tour" style={{background: "#ECC94B", borderRadius: "4px"}} onClick={() => changeOpen()}>
															<LightbulbIcon style={{color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px"}}/>
														</IconButton>
													</Grid>
												</Grid>
											</Grid>
											<Grid container ref={componentRef}>
												<Grid item xs={12}>
													<p style={{...styleTitle, width: "auto", color: temaEscuro && WHITE_ESCRITA_THEME}}>Ata Reunião - {loja}</p>
												</Grid>
												<Grid item xs={12}>
													<Grid container rowSpacing={2}>
														<Grid item xs={12}>
															<a style={styleLines} data-testid="date-line">Data da reunião: <b>{data}</b></a>
														</Grid>
														<Grid item xs={12}>
															<a style={styleLines} data-testid="competencia-line">Competência: <b>{mes}/{ano}</b></a>
														</Grid>
														<Grid item xs={12}>
															<a style={styleLines} data-testid="preparado-line">Preparado por: <b>{preparadoPor}</b></a>
														</Grid>
														<Grid item xs={12}>
															<a style={styleLines} data-testid="participantes-line">Participantes: <b dangerouslySetInnerHTML={{__html:participantes}}/></a>
														</Grid>
														<Grid item xs={12}>
															<a style={styleLines} data-testid="observacoes-line">Observações: <p dangerouslySetInnerHTML={{__html:observacoes}}/></a>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											{
												arquivoUrl && (
													<YellowButton
														title="Visualizar arquivo anexado"
														onClick={openFile}
														testId="file-attach-btn"
														startIcon={<VisibilityIcon/>}
													/>
												)
											}
										</React.Fragment>
									)
								}
							</Grid>
						</Paper>

					)
			)
			}
		</div>
	);
}