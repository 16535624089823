import React from "react";
import Grid from "@mui/material/Grid";
import HorizontalBar from "../../../components/Dash/HorizontalBar";
import Line from "../../../components/Dash/Line";
import BarCompras from "../../../components/Dash/BarCompras";
import TreeMap from "../../../components/Dash/TreeMap";

const ComprasDashboard = ({lineCompras, treeMap, seriesForn, dataDash}) => {
  return (
    <React.Fragment>
        <Grid container spacing={2} data-testid={"Dash"}>
            <Grid item xs={8} sx={{maxHeight: "350px"}}>
                <Line
                    title="Categorias pela Venda (%)"
                    data={lineCompras}
                    percentage={true}
                    hideLabel
                    notRedirect
                />
                <Grid container sx={{marginTop: 1}} spacing={1}>
                    <Grid item xs={6} sx={{height: "350px"}}>
                        <TreeMap
                            title="Acumulado por Categoria (R$)"
                            data={treeMap}
                        />
                    </Grid>
                    <Grid item xs={6} sx={{height: "350px"}}>
                        <BarCompras
                            title="Top 10 Fornecedores (R$)"
                            data={seriesForn}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} sx={{height:"708px", spacing: 2}}>
                <HorizontalBar
                    title="Categorias pela Venda (%)"
                    data={dataDash}
                />
            </Grid>
        </Grid>
    </React.Fragment>
  );
};

export default React.memo(ComprasDashboard);