import React from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
    DASH_KPI_POSITIVE_COLOR,
    DASH_KPI_NEGATIVE_COLOR} from "../../../shared/utils";

const FaturamentoArrow = ({value, title}) => {
    return (
        <div style={{display: "flex", alignItems: "end"}}>
            {value <= 0.0 ? (
                <React.Fragment>
                    <ArrowDownwardIcon sx={{fontSize: 22, color: DASH_KPI_NEGATIVE_COLOR}} /> <p style={{margin: "0 0 0 .2rem"}}>{title}</p>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <ArrowUpwardIcon sx={{fontSize: 22, color: DASH_KPI_POSITIVE_COLOR}} /> <p style={{margin: "0 0 0 .2rem"}}>{title}</p>
                </React.Fragment>
            )}
        </div>
    );
};

export default React.memo(FaturamentoArrow);