import React, {useState, useEffect, useMemo} from "react";
import Paper from "@mui/material/Paper";
import ReactECharts from "echarts-for-react";
import Box from "@mui/material/Box";
import {
	DASH_AXIS_COLOR,
	DASH_LABEL_COLOR,
	DASH_LINESTYLE_COLOR,
	paperDash, spanDash,
	fontFamily, grid, legend,
	seriesColor, formatterThousand, formatterPercentage,
	hexToRgb, isLightColor,
	BLUE_THEME,
	WHITE_THEME_BLACK, TEXT_THEME, WHITE_ESCRITA_THEME, BORDER_BUTTON, GRAY_LABEL_UX,
	DASH_KPI_COLOR
} from "../../shared/utils";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeOpenComponent } from "../../actions/StoreActions";


const StackedBar = (props) => {
	const {data, percentage, testId} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const title = percentage ? "%": "R$";
	const formatter = percentage ? formatterPercentage : formatterThousand;

	const stackedFormatter = (params) => {
		return formatter(params.data.toFixed(2));
	};

	const axisFormatter = (value) =>{
		return formatter(value);
	};

	const YAxis = {
		type: "value",
		splitLine: {
			show: true,
			lineStyle: {
				color: temaEscuro ? TEXT_THEME : DASH_LINESTYLE_COLOR,
				type: "dashed"
			}
		},
		axisLabel: {
			fontFamily,
			color: temaEscuro ? WHITE_THEME_BLACK : DASH_AXIS_COLOR,
			...(formatter != null && {formatter: axisFormatter})
		}
	};

	const series = {
		data: [],
		type: "bar",
		stack: "Total",
		smooth: true,
		symbol: "circle",
		emphasis: {
			focus: "series"
		},
		label: {
			show: true,
			position: "top",
			color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_LABEL_COLOR,
			formatter: stackedFormatter,
			fontFamily
		},
		triggerLineEvent: true
	};

	const [options, setOptions] = useState({
		grid,
		legend:{
			...legend,
			textStyle: {
				color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
			},
			inactiveColor: temaEscuro ? BORDER_BUTTON : "#ccc"
		},
		xAxis: {
			type: "category",
			color: DASH_LABEL_COLOR,
			data: [],
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			axisLabel: {
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_AXIS_COLOR
			}
		},
		yAxis: YAxis,
		series: [],
		title: {
			text: title,
			left: "left",
			textStyle: {
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_LABEL_COLOR,
				fontFamily,
				fontSize: 12,
				fontWeight: 400
			}
		},
		tooltip: {
			trigger: "axis",
			axisPointer: {
				type: "shadow"
			},
			fontFamily,
		},
	});
	const [key, setKey] = useState();

	useEffect(() => {
		let newOptions = {...options};
		newOptions.xAxis.data = data?.data;
		data?.series?.map((item, index) => {
			let newSeries = {...series};
			if(seriesColor?.[index + 1] && data.series?.[index + 1]){
				let nextColor = seriesColor[index + 1];
				let rgbColor = hexToRgb(nextColor);
				if (temaEscuro && isLightColor(rgbColor)){
					let newLabel = {...series.label};
					newLabel.color = DASH_KPI_COLOR;
					newSeries.label = newLabel;
				}
			}
			newSeries.color = seriesColor[index];
			newSeries.name = item.name;
			newSeries.data = item.data;
			newOptions.series.push(newSeries);
		});

		setOptions(newOptions);
		setKey(Math.random());
	}, [data]);
	
	const handleClick = (params) => {
		const innerText = params.event.event.currentTarget.offsetParent.innerText;

		// columnName = X axis
		const columnName = innerText.split("\n")[0];

		dispatch(changeOpenComponent(true));
		navigate("/dre", { state: { monthRedirectDashboard: columnName, lineRedirectDashboard: props.title }});
	};

	const getChart = useMemo(() => {
		return (
			<ReactECharts
				key={key}
				option={options}
				notMerge={true}
				onEvents={{
					click: props?.notRedirect ? null : handleClick, // Register the click event handler
				}}
			/>
		);
	}, [options]);


	return(
		<Paper data-testid={testId} sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}} className={props.className}>
			<Box sx={{mb: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
				<span style={{...spanDash, ...(temaEscuro && {color:  WHITE_ESCRITA_THEME})}}>{props.title}</span>
				<ButtonOpenTour onClick={props.onClick}/>
			</Box>
			{getChart}
		</Paper>
	);
};

export default React.memo(StackedBar);

