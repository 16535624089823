import React, {useState, useEffect} from "react";
import {GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid-pro";
import { ptBR } from "@mui/x-data-grid-pro/locales";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import api from "../../axios";
import SelectStore from "../../components/SelectStore/SelectStore";
import {
	paperStyle,
	styleButton,
	styleDefaultText,
	GRAY_LABEL_UX,
	WHITE_THEME_BLACK,
	PAPER_PADDING_THEME,
	BLUE_THEME,
	formatterCurrency,
	INFO_THEME,
} from "../../shared/utils";
import FilterWithTag from "../../components/Filter/FilterWithTag";
import FluxoGraph from "../../components/Dash/FluxoGraph";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {Button} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import GrayButton from "../../components/Buttons/GrayButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoModal from "../../components/Modal/InfoModal";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import Tour from "reactour";
import { DASH_BAR_COLOR, GRID_BUTTONS_SPACING } from "../../shared/utils";
import { steps } from "./TourFluxoCaixa";
import LastModified from "../../components/LastModified/LastModified";

function CustomToolbar() {
	return (
		<div style={{ display: "flex", justifyContent: "flex-end"}}>
			<GridToolbarContainer>
				<GridToolbarExport sx={{color: GRAY_LABEL_UX}} />
			</GridToolbarContainer>
		</div>
	);
}

export default function FluxoCaixa() {
	const [loading, setLoading] = useState(false);
	const [previousMonths, setPreviousMonths] = useState([]);
	const [monthsFiltered, setMonthsFiltered] = useState([]);
	const [monthOptions, setMonthOptions] = useState([]);
	const [monthsCompetenciaFiltered, setMonthsCompetenciaFiltered] = useState([]);
	const [previousMonthsCompetencia, setPreviousMonthsCompetencia] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [graphData, setGraphData] = useState([]);
	const [advancedFilter, setAdvancedFilter] = useState(false);
	const [liquidezSeca, setLiquidezSeca] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [open, setOpen] =  useState(false);
	const [startAt, setStartAt] = useState(0);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const storeId = useSelector(state => state.StoreReducer.storeId);

	useEffect(() => {
		if(storeId !== 0){
			getFluxoCaixa();
		}
	}, [storeId]);

	const getFluxoCaixa = (monthsFilter=[], monthsCompetenciaFilter=[]) => {
		setLoading(true);
		let dataRequest = {
			"loja_id": storeId,
			"data_venda": monthsFilter,
		};
		if (advancedFilter) {
			dataRequest["data_competencia"] = monthsCompetenciaFilter;
		}
		api.GetFluxoCaixa(dataRequest).then(response => {
			let dataResponse = response.data;
			let _graphData = dataResponse.graph_info;
			let axisGraph = Object.keys(_graphData);
			setLiquidezSeca(dataResponse.liquidez_seca);
			setPreviousMonths(dataResponse.filtered_months);
			setPreviousMonthsCompetencia(dataResponse.filtered_competencia_months);
			let _months = [];
			dataResponse.months.map((month) => {
				_months.push({value: month, label: month});
			});
			setMonthOptions(_months);
			let pagamentosFluxo = [];
			let recebimentosFluxo = [];
			let saldoFluxo = [];
			let datasTable = [];
			// Configurações do gráfico
			axisGraph.forEach(function (item) {
				pagamentosFluxo.push(_graphData[item]["pagamentos"]);
				recebimentosFluxo.push(_graphData[item]["recebimentos"]);
				saldoFluxo.push(_graphData[item]["saldo"]);
				datasTable.push(_graphData[item]["data"]);
			});
			let tableData = Object.values(_graphData);
			for (let [index] of tableData.entries()) {
				tableData[index]["id"] = index;
			}
			let options = {axisGraph, pagamentosFluxo, recebimentosFluxo, saldoFluxo};
			setGraphData(options);
			setTableData(tableData);
			showFluxoCaixa();
		}).catch(() => {
			setLoading(false);
		});
	};

	const showFluxoCaixa = () => {
		setLoading(false);
	};

	const handleFilter = () => {
		getFluxoCaixa(monthsFiltered, monthsCompetenciaFiltered);
	};

	const handleAdvancedFilter = () => {
		setAdvancedFilter(!advancedFilter);
	};

	const handleCellClick = (params) => {
		let selectedDate = params.row.data;
		let cellField = params.field;
		const url = "/detalha_fluxo";
		const urlParams = { selectedDate, storeId, cellField };
		const queryString = new URLSearchParams(urlParams).toString();
		const fullUrl = `${url}?${queryString}`;
		window.open(fullUrl, "_blank");
	};

	const columns = [
		{ field: "data", headerName: "Data", minWidth: 100, flex: 0.3 },
		{ 
			field: "recebimentos",
			headerName: "Recebimentos (R$)",
			type: "number",
			minWidth: 300,
			flex: 1,
			valueFormatter: (params) => formatterCurrency(params, 2)
		},
		{ 
			field: "pagamentos",
			headerName: "Pagamentos (R$)",
			type: "number",
			minWidth: 300,
			flex: 1,
			valueFormatter: (params) => formatterCurrency(params, 2)
		},
		{ 
			field: "saldo",
			headerName: "Saldo (R$)",
			type: "number",
			minWidth: 300,
			flex: 1,
			valueFormatter: (params) => formatterCurrency(params, 2)
		},
	];

	const handleExport = () => {
		setLoading(true);
		let dataRequest = {
			"loja_id": storeId,
			"selected_date": monthsFiltered,
		};
		api.ExportFluxoCaixa(dataRequest).then(response => {
			setLoading(false);
			let url = response.data;
			window.open(url, "_blank");
		});
	};

	const handleOpenLiquidezModal = () => {
		setOpenModal(true);
	};

	const changeOpen = () => {
        setStartAt(0);
        setOpen(!open);
    };

	return (
		<div className="main">
			<LastModified title="Fluxo de Caixa"/>
			<InfoModal
				openModal={openModal}
				setOpenModal={setOpenModal}
				infoTitle={`Liquidez Seca: ${liquidezSeca}`}
				information="Definição: Liquidez Seca é o indicador que tem a função de medir a capacidade da empresa em cumprir suas obrigações financeiras no curto prazo."
			/>
			{loading ? (
				<div>
					<Grid container>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								{
									[...Array(1).keys()].map((i,d) =>{
										return(
											<Grid item key={d}>
												<Skeleton height={75} width={400} />
											</Grid>
										);
									})
								}
								{
									[...Array(1).keys()].map((i,d) =>{
										return(
											<Grid item key={d}>
												<Skeleton height={75} width={130} />
											</Grid>
										);
									})
								}
							</Grid>
							{
								[...Array(1).keys()].map((i,d) =>{
									return(
										<Grid item key={d} >
											<Skeleton height={400} width={1610} />
										</Grid>
									);
								})
							}
							{
								[...Array(15).keys()].map((i,d) =>{
									return(
										<Skeleton key={d} height={20}/>
									);
								})
							}
						</Grid>
					</Grid>
				</div>
			) : (
				storeId === 0 ?
					(
						<SelectStore/>
					) : (
						<Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}}>
							<Grid container spacing={2} data-testid="fluxo-caixa">
								<Grid item xs={12}>
									<Grid container spacing={2}>
										<Grid item className="datacaixa">
											<FilterWithTag
												optionFilter
												options={monthOptions}
												handleSubmit={handleFilter}
												setOptions={setMonthsFiltered}
												monthsFiltered={monthsFiltered}
												previousValue={previousMonths}
												placeholderTitle="Data do Caixa"
											/>
										</Grid>
										{advancedFilter && (
											<Grid item>
												<FilterWithTag
													optionFilter
													options={monthOptions}
													setOptions={setMonthsCompetenciaFiltered}
													monthsFiltered={monthsCompetenciaFiltered}
													previousValue={previousMonthsCompetencia}
													placeholderTitle="Data de Competência"
												/>
											</Grid>
										)}
										<Grid item sx={{display: "flex", alignItems: "center"}}>
											<Button data-testid="btn-filter-fluxo" variant="contained" onClick={handleFilter}
													style={{...styleButton, color:temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, borderColor:  temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
												<FilterListOutlinedIcon style={{color:temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}/>
												Filtrar
											</Button>
										</Grid>
										<Grid item xs sx={{display: "flex", alignItems: "center", justifyContent: "end"}}>
											<Grid container spacing={GRID_BUTTONS_SPACING} sx={{justifyContent: "end"}}>
												<Grid item>
													<GrayButton  className="liquidez" title={`Liquidez seca: ${liquidezSeca}`} onClick={() => handleOpenLiquidezModal()} />
												</Grid>
												<Grid item>
													<GrayButton testId="btn-export-fluxo" title="Exportar" startIcon={<FileDownloadIcon/>} onClick={() => handleExport()} />
												</Grid>
												<Grid item>
													<IconButton style={{ background: "#ECC94B", borderRadius: "4px" }} onClick={() => changeOpen()}>
														<LightbulbIcon style={{ color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px" }} />
													</IconButton>
												</Grid>
													<Tour
														steps={steps}
														isOpen={open}
														onRequestClose={changeOpen}
														accentColor={DASH_BAR_COLOR}
														startAt={startAt}
														rounded={5}
														showNavigation={false}
														inViewThreshold={60}
														className={temaEscuro && "tour"}
														badgeContent={(curr, tot) => `${curr}/${tot}`}
													/>
											</Grid>
										</Grid>
										<Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
											<Grid container spacing={1}>
												<Grid item sx={{cursor: "pointer"}} onClick={handleAdvancedFilter} className="competencia">
													<a style={{...styleDefaultText, width: "auto", fontSize: "14px", color: temaEscuro && WHITE_THEME_BLACK}}>Filtrar Competência</a>
												</Grid>
												<Grid item sx={{cursor: "pointer", }} onClick={handleAdvancedFilter}>
													{advancedFilter ? (
														<RemoveCircleOutlineIcon sx={{fontSize: "14px", color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}/>
													) : (
														<ControlPointIcon sx={{fontSize: "14px", color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}/>
													)}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<FluxoGraph title="Fluxo de Caixa Diário" data={graphData} />
								</Grid>
								<Grid item xs={12} sx={{ height: 400, width: "100%"}}>
									<DataGridPremium
										onCellClick={handleCellClick}
										rows={tableData}
										getRowId={(row) => row.id}
										columns={columns}
										pageSize={5}
										rowsPerPageOptions={[5]}
										style={{"--DataGrid-containerBackground": temaEscuro && BLUE_THEME, "--DataGrid-pinnedBackground": temaEscuro && BLUE_THEME, backgroundColor:temaEscuro && BLUE_THEME, color: temaEscuro && WHITE_THEME_BLACK}}
										sx={{
											".MuiDataGrid-cell:focus": {
												color: temaEscuro && WHITE_THEME_BLACK,
												outline: "none"
											},
											"& .MuiDataGrid-row:hover": {
												color: temaEscuro && WHITE_THEME_BLACK,
												cursor: "pointer"
											},
											"& .MuiSvgIcon-root":{
												color: temaEscuro && WHITE_THEME_BLACK
											},
											"& .MuiButtonBase-root":{
												color: temaEscuro && WHITE_THEME_BLACK
											},
											borderRadius: 2, 
											border: temaEscuro && `1px solid ${INFO_THEME}`
										}}
										slots={{ toolbar: CustomToolbar }}
										slotProps={{toolbar: {}}}
										localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
										
									/>
								</Grid>
							</Grid>
						</Paper>
					)
			)}
		</div>
	);
}