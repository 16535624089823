import React, {useCallback, useMemo}  from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {format, negativeEl, getClassName, RED_NEGATIVE_UX, WHITE_THEME_BLACK, GRAY_STRONG_UX, BLUE_THEME, styleNegativeValue, WHITE_TABLE} from "../../shared/utils";
import "./Table.css";
import TooltipTable from "./TooltipTable";
import TooltipProvision from "./TooltipProvision";
import TooltipThirteenthSalary from "./TooltipThirteenthSalary";
import { useSelector } from "react-redux";

const RowData = (props) => {

	let {rows, columns, handleOpenModal, tourColumns, openEmptyRows, container, backgroundColor, rowsPercent, percentActive} = props;

	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	const getTourColumn = useCallback((idx) => {
		try {
			return tourColumns[idx];
		} catch (error) {
			return "";
		}
	}, [tourColumns]);

	const checkIfRowIsZero = useCallback((row) => {
		if (container !== "Painel") {
			return false;
		}
		if (openEmptyRows) {
			return false;
		} else {
			let isZero = true;
			let newRow = Object.keys(row).filter(key => key !== "periodo" && key !== "total");
			for (const element in newRow) {
				const el = newRow[element];
				if(row[el].value !== 0) {
					isZero = false;
					break;
				}
			}
			return isZero;
		}
	}, [openEmptyRows, container]);

	const tableRows = useMemo(() => {
		return rows.map((row, idx) => {
			if(checkIfRowIsZero(row)) {
				return null;
			} else
			{
				return (
					<TableRow className={`table-row-data ${getTourColumn(idx)}`} hover role="checkbox" tabIndex={-1} key={idx}>
						{columns.map((column, index) => {
							const data = row[column.id];
							const style = (data?.style) || {};
							const isEmptyStyle = Object.keys(style).length === 0;
							let styleColFixed = {};
							if(index === 0) {
								styleColFixed = {
									position: "sticky",
									left: 0,
									zIndex: 1
								};
							}
							const colorBackGround = isEmptyStyle ? (temaEscuro ? { backgroundColor: BLUE_THEME } : { backgroundColor: WHITE_TABLE }) : (backgroundColor !== undefined ? { backgroundColor: backgroundColor } : style);
							const negativeStyle = data?.value < 0 ? {color: RED_NEGATIVE_UX, fontWeight: "bold"} : {};
							const cursorStyle = !["periodo", "total"].includes(column.id) ? {cursor: "pointer"} : {};
							return (
								<TooltipTable key={index} comments={data?.comments || []}>
									<TableCell
										align={column.align}
										style={{...style, ...negativeStyle, ...cursorStyle, ...styleColFixed, ...colorBackGround}}
										className={`${getClassName(data, row.periodo, column.id)}`}
									>
										<TooltipProvision row={data}>
											<div style={{position: "relative"}}>
												<TooltipThirteenthSalary row={row.periodo} column={column.id}>
													<div
														data-testid="row-data"
														onClick={() => !["periodo", "total"].includes(column.id) && handleOpenModal(data, index-1, row["periodo"].value, data?.comments || [])}>
														{
															percentActive && rowsPercent?.[idx][column.id]?.value && !isNaN(rowsPercent[idx][column.id].value) ? (
																<React.Fragment>
																	<div style={{...typeof data.value === "number" && styleNegativeValue}}>
																		{
																			negativeEl(data)
																		}
																		{format(data?.value)}
																	</div>
																	<a style={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_STRONG_UX, fontSize: 13.5, fontWeight: "normal"}}>
																		{rowsPercent[idx][column.id].value}%
																	</a>
																</React.Fragment>
															) : (
																<div style={{...typeof data.value === "number" && styleNegativeValue}}>
																	{
																		negativeEl(data)
																	}
																	{format(data?.value)}
																</div>
															)
														}
													</div>
												</TooltipThirteenthSalary>	
											</div>
										</TooltipProvision>
										
									</TableCell>
								</TooltipTable>
							);
						})}
					</TableRow>
				);
			}
		});
	}, [rows, openEmptyRows, percentActive]);

	return(
		<React.Fragment>
			{tableRows}
		</React.Fragment>
	);
};

export default React.memo(RowData);