import React from "react";
import Grid from "@mui/material/Grid";
import DataGrid from "../../../components/DataGrid/DataGrid";

const ComprasTable = ({columns, tableData, initialColumns}) => {
  return (
    <React.Fragment>
        <Grid item xs={12}
                sx={{ height: "calc(100vh - 196px - 72px)", width: "100%"}}
                data-testid={"Table"}>
            <DataGrid
                columns={columns}
                data={tableData}
                row={(row) => row.id}
                initialColumns={initialColumns}
            />
        </Grid>
    </React.Fragment>
  );
};

export default React.memo(ComprasTable);