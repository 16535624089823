import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
    verySmallPaperStyle,
    BLUE_THEME,
} from "../../../../shared/utils";


const MessageLastFaturamento = (props) => {
    const { 
        setOpenModalFatMessage, openModalFatMessage
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const textMessage = "Todas as informações de despesas estão disponíveis com base nos lançamentos feitos no Conta Azul. Certifique-se de que a folha de pagamento tenha sido informada à sua operadora para constar corretamente na competência do mês. Por favor, note que as informações podem ainda não ter sido revisadas pelo consultor, já que essa revisão é realizada próximo à data da reunião mensal. Essa reunião pode ocorrer até o último dia do mês subsequente à competência, conforme estipulado na Cláusula 23ª - Parágrafo 1º do nosso contrato de prestação de serviços. Esperamos que aproveite ao máximo as informações. Em caso de dúvidas, fiquem à vontade para contatar o consultor responsável pelas suas informações.";

    return(
        <React.Fragment>
            <Modal
                open={openModalFatMessage}
                onClose={() => setOpenModalFatMessage(false)}
                aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
                data-testid="generic-last-faturamento-message"
            >
                <Paper style={{...verySmallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME}}>
                    <Grid container>
                        <Grid item xs={12} className="start">
                            <h3>O faturamento bruto foi salvo e suas informações estão atualizadas</h3>
                        </Grid>
                        <Grid item xs={12} sx={{color: temaEscuro ? "#FFFFFF" : "#2D3748"}}>
                            <Grid container>
                                <Grid item xs={12} sx={{display: "flex", flexDirection: "column", gap: 1}}>
                                    <span>
                                        <p className="textAlign" data-testid="generic-text-message">{textMessage}</p>
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default React.memo(MessageLastFaturamento);