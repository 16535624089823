import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
	DASH_KPI_DATA_COLOR,
	DASH_LABEL_COLOR,
	formatterPercentage,
	paperDash, BLUE_THEME,
	WHITE_ESCRITA_THEME,
	WHITE_THEME_BLACK
} from "../../shared/utils";
import { useSelector } from "react-redux";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";

const MultipleKPI = (props) => {
	const {firstData, secData, firstFooter, secFooter, prefix, suffix, testId} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const stylePrefix = {...styles.subTitle, ...(temaEscuro && {color: WHITE_ESCRITA_THEME})};
	const styleFooter = {...styles.footer,  ...(temaEscuro && {color: WHITE_THEME_BLACK})};

	return(
		<Paper data-testid={testId} sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}} className={props.className}>
			<Grid sx={{display: "flex", alignItems: "center", justifyContent: "end"}}>
				<ButtonOpenTour onClick={props.onClick}/>
			</Grid>
			<Grid container sx={styles.grid}>
				<Grid item xs={12} sx={styles.gridItem}>
					<span style={stylePrefix}>{prefix} {formatterPercentage(firstData, 1)}{suffix}</span>
					<span style={styleFooter}>{firstFooter}</span>
				</Grid>
				<Grid item xs={12} sx={styles.gridItem}>
					<span style={stylePrefix}>{prefix} {formatterPercentage(secData, 1)}{suffix}</span>
					<span style={styleFooter}>{secFooter}</span>
				</Grid>
			</Grid>
		</Paper>
	);
};

// Create styles
const styles = {
	grid: {
		flexGrow: 1,
		textAlign: "center"
	},
	gridItem: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column"
	},
	subTitle: {
		fontSize: "35px",
		fontWeight: 600,
		color: DASH_KPI_DATA_COLOR
	},
	footer: {
		fontSize: "20px",
		fontWeight: 400,
		color: DASH_LABEL_COLOR,
		alignItems: "center"
	},
};

export default React.memo(MultipleKPI);