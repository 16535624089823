import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import api from "../../axios";
import FilterYear from "../../components/Filter/FilterYear";
import moment from "moment";
import Header from "../../components/Titles/Header";
import {
	GRAY_LABEL_UX,
	WHITE_THEME_BLACK,
    formatterCurrency,
    paperStyleFull,
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    GRAY_STRONG_UX,
    DASH_KPI_COLOR,
    PAPER_PADDING_THEME,
    LINE_TABLE,
    DASH_KPI_POSITIVE_COLOR,
    DASH_BAR_COLOR,
    DASH_KPI_NEGATIVE_COLOR,} from "../../shared/utils";
import IconButton from "@mui/material/IconButton";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import DataGrid from "../../components/DataGrid/DataGrid";
import Paper from "@mui/material/Paper";
import FaturamentoIfood from "./ResumoIfoodComponents/FaturamentoIfood";
import FaturamentoKPI from "./ResumoIfoodComponents/FaturamentoKPI";
import Tour from "reactour";
import Skeleton from "@mui/material/Skeleton";
import {showSnackMessage} from "../../actions/SnackActions";
import { steps } from "./TourResumoIfood";
import FilterWithTag from "../../components/Filter/FilterWithTag";


const ResumoIfood = () => {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const storeId = useSelector(state => state.StoreReducer.storeId);
    const [repassesIfood, setRepassesIfood] = useState([]);
    const [repassesIfoodColumns, setRepassesIfoodColumns] = useState([]);
    const [selectedMonth, setSelectecMonth] = useState("");
    const [valorBruto, setValorBruto] = useState(0);
    const [totalRepasses, setTotalRepasses] = useState(0);
    const [valorRecebido, setValorRecebido] = useState(0);
    const [valorRetido, setValorRetido] = useState(0);
    const [valorAReceber, setValorAReceber] = useState(0);
    const [recebidosLoja, setRecebidosLoja] = useState(0);
    const [descontosAcrescimos, setDescontosAcrescimos] = useState(0);
    const [valorLiquidoTotal, setValorLiquidoTotal] = useState(0);
    const [promocoesCusteadasPelaLoja, setPromocoesCusteadasPelaLoja] = useState(0);
    const [promocoesCusteadasPeloIFood, setPromocoesCusteadasPeloIFood] = useState(0);
    const [promocoesCusteadasPeloIFoodCount, setPromocoesCusteadasPeloIFoodCount] = useState(0);
    const [taxaComissoesTotal, setTaxaComissoesTotal] = useState(0);
    const [taxaEntregaParceira, setTaxaEntregaParceira] = useState(0);
    const [taxaPedidoMinimo, setTaxaPedidoMinimo] = useState(0);
    const [taxaIfoodRefeicao, setTaxaIfoodRefeicao] = useState(0);
    const [entregasSolicitadas, setEntregasSolicitadas] = useState(0);
    const [pedidosCancelados, setPedidosCancelados] = useState(0);
    const [debitoCanceladoParcialmente, setDebitoCanceladoParcialmente] = useState(0);
    const [comissaoIfood, setComissaoIfood] = useState(0);
    const [comissaoIfoodCancelado, setComissaoIfoodCancelado] = useState(0);
    const [comissaoTransacao, setComissaoTransacao] = useState(0);
    const [comissaoTransacaoCancelado, setComissaoTransacaoCancelado] = useState(0);
    const [outrosValores, setOutrosValores] = useState(0);
    const [valoresPagosClienteDevidoIFood, setValoresPagosClienteDevidoIFood] = useState(0);
    const [valorPedidosIFood, setValorPedidosIFood] = useState(0);
    const [valorPedidosViaLojaCount, setValorViaLojaCount] = useState(0);
    const [recebidosLojaPerc, setRecebidosLojaPerc] = useState(0);
    const [promocoesCusteadasPelaLojaPerc, setPromocoesCusteadasPelaLojaPerc] = useState(0);
    const [promocoesCusteadasPeloIFoodPerc, setPromocoesCusteadasPeloIFoodPerc] = useState(0);
    const [taxaComissoesTotalPerc, setTaxaComissoesTotalPerc] = useState(0);
    const [taxaEntregaParceiraPerc, setTaxaEntregaParceiraPerc] = useState(0);
    const [taxaPedidoMinimoPerc, setTaxaPedidoMinimoPerc] = useState(0);
    const [taxaIfoodRefeicaoPerc, setTaxaIfoodRefeicaoPerc] = useState(0);
    const [entregasSolicitadasPerc, setEntregasSolicitadasPerc] = useState(0);
    const [pedidosCanceladosPerc, setPedidosCanceladosPerc] = useState(0);
    const [debitoCanceladoParcialmentePerc, setDebitoCanceladoParcialmentePerc] = useState(0);
    const [comissaoIfoodPerc, setComissaoIfoodPerc] = useState(0);
    const [comissaoIfoodCanceladoPerc, setComissaoIfoodCanceladoPerc] = useState(0);
    const [comissaoTransacaoPerc, setComissaoTransacaoPerc] = useState(0);
    const [comissaoTransacaoCanceladoPerc, setComissaoTransacaoCanceladoPerc] = useState(0);
    const [outrosValoresPerc, setOutrosValoresPerc] = useState(0);
    const [valoresPagosClienteDevidoIFoodPerc, setValoresPagosClienteDevidoIFoodPerc] = useState(0);
    const [openFaturamentoIFood, setOpenFaturamentoIFood] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [startAt, setStartAt] = useState(0);
    const [estabelecimentos, setEstabelecimentos] = useState([]);
    const [estabelecimentosSelected , setEstabelecimentosSelected] = useState([]);
    const [valueEstabelecimentos, setValueEstabelecimentos] = useState([]);

    const dispatch = useDispatch();

    const previousMonth = moment().subtract(1, "month").format("MM");
    const previousYear = moment().subtract(1, "month").format("YYYY"); 

    useEffect(() => {
        clearResumoIfoodValues();
        getResumoIfood(previousYear, previousMonth, true);
    }, [storeId]);

    const goToFaturamentoDetalhado = (row) => {
        if (row.length > 0) {
            let periodoDesejado = repassesIfood.find((item) => item.id == row);
            const url = "faturamento_ifood_detalhado";
            const params = { repasseData: periodoDesejado.previsao_pagamento, loja: storeId, periodoApuracao: periodoDesejado.periodo_apuracao, situacao: periodoDesejado.situacao, estabelecimentos: JSON.stringify(estabelecimentosSelected)};
            const queryString = new URLSearchParams(params).toString();
            const fullUrl = `${url}?${queryString}`;
            window.open(fullUrl, "_blank");
        }
    };

    const getResumoIfood = (ano, mes, isChangeStore = false) => {
        setLoading(true);
        setSelectecMonth(`${mes}/${ano}`);

        const dataRequest = {
            "loja_id": storeId,
            "periodo": `${mes}/${ano}`,
            "estabelecimentos": isChangeStore ? [] : estabelecimentosSelected,
        };

        api.GetResumoIfood(dataRequest).then((response) => {
            let responseData = response.data;
            setRepassesIfood(responseData.repasses_ifood);
            let columnsFiltered = responseData.repasses_ifood_columns;
            columnsFiltered[3] = {
                field: "valor",
                headerName: "Valor",
                flex: 1,
                type: "number",
                align: "center",
                headerAlign: "center",
                valueFormatter: (params) => formatterCurrency(params, 2),
            };
            setRepassesIfoodColumns(columnsFiltered);
            setTotalRepasses(responseData.resumo_repasses.total_repasses);
            setValorRecebido(responseData.resumo_repasses.valor_recebido);
            setValorRetido(responseData.resumo_repasses.valor_retido);
            setValorAReceber(responseData.resumo_repasses.valor_a_receber);
            setValorBruto(responseData.resumo_faturamento.valor_bruto_vendas_total);
            setRecebidosLoja(responseData.resumo_faturamento.recebido_via_loja_total);
            setValorLiquidoTotal(responseData.resumo_faturamento.valor_liquido_total);
            setDescontosAcrescimos(responseData.resumo_faturamento.descontos_acrescimos);
            setPromocoesCusteadasPelaLoja(responseData.resumo_faturamento.promocoes.promocoes_custeadas_loja);
            setTaxaComissoesTotal(responseData.resumo_faturamento.taxa_comissoes_total);
            setTaxaEntregaParceira(responseData.resumo_faturamento.valores_pagos_cliente_devido_ifood.taxa_entrega_parceira);
            setTaxaPedidoMinimo(responseData.resumo_faturamento.valores_pagos_cliente_devido_ifood.taxa_pedido_minimo);
            setTaxaIfoodRefeicao(responseData.resumo_faturamento.taxas_comissoes.taxa_ifood_refeicao);
            setEntregasSolicitadas(responseData.resumo_faturamento.servicos_logisticos.entregas_solicitadas);
            setPedidosCancelados(responseData.resumo_faturamento.cancelamentos_reembolsos.pedidos_cancelados);
            setDebitoCanceladoParcialmente(responseData.resumo_faturamento.cancelamentos_reembolsos.debito_cancelados_parcialmente);
            setComissaoIfood(responseData.resumo_faturamento.taxas_comissoes.comissao_ifood);
            setComissaoIfoodCancelado(responseData.resumo_faturamento.cancelamentos_reembolsos.comissao_ifood_cancelados);
            setComissaoTransacao(responseData.resumo_faturamento.taxas_comissoes.comissao_transacao);
            setComissaoTransacaoCancelado(responseData.resumo_faturamento.cancelamentos_reembolsos.comissao_transacao_cancelados);
            setValoresPagosClienteDevidoIFood(responseData.resumo_faturamento.valores_pagos_cliente_devido_ifood.valores_pagos_cliente_devido_ifood_total);
            setOutrosValores(responseData.resumo_faturamento.outros_valores);
            setValorPedidosIFood(responseData.resumo_faturamento.valor_pedidos_ifood);
            setValorViaLojaCount(responseData.resumo_faturamento.total_pedidos_via_loja);
            setPromocoesCusteadasPeloIFood(responseData.resumo_faturamento.promocoes.promocoes_custeadas_ifood);
            setPromocoesCusteadasPeloIFoodCount(responseData.resumo_faturamento.promocoes.qnt_promocoes_custeadas_ifood);
            setRecebidosLojaPerc(responseData.resumo_faturamento.porcentagens.perc_recebido_via_loja_total);
            setPromocoesCusteadasPelaLojaPerc(responseData.resumo_faturamento.porcentagens.perc_promocoes_custeadas_loja);
            setPromocoesCusteadasPeloIFoodPerc(responseData.resumo_faturamento.porcentagens.perc_promocoes_custeadas_ifood);
            setTaxaComissoesTotalPerc(responseData.resumo_faturamento.porcentagens.perc_taxa_comissoes_total);
            setTaxaEntregaParceiraPerc(responseData.resumo_faturamento.porcentagens.perc_taxa_entrega_parceira);
            setTaxaPedidoMinimoPerc(responseData.resumo_faturamento.porcentagens.perc_taxa_pedido_minimo);
            setTaxaIfoodRefeicaoPerc(responseData.resumo_faturamento.porcentagens.perc_taxa_ifood_refeicao);
            setEntregasSolicitadasPerc(responseData.resumo_faturamento.porcentagens.perc_entregas_solicitadas);
            setPedidosCanceladosPerc(responseData.resumo_faturamento.porcentagens.perc_pedidos_cancelados);
            setDebitoCanceladoParcialmentePerc(responseData.resumo_faturamento.porcentagens.perc_debito_cancelados_parcialmente);
            setComissaoIfoodPerc(responseData.resumo_faturamento.porcentagens.perc_comissao_ifood);
            setComissaoIfoodCanceladoPerc(responseData.resumo_faturamento.porcentagens.perc_comissao_ifood_cancelados);
            setComissaoTransacaoPerc(responseData.resumo_faturamento.porcentagens.perc_comissao_transacao);
            setComissaoTransacaoCanceladoPerc(responseData.resumo_faturamento.porcentagens.perc_comissao_transacao_cancelados);
            setOutrosValoresPerc(responseData.resumo_faturamento.porcentagens.perc_outros_valores);
            setValoresPagosClienteDevidoIFoodPerc(responseData.resumo_faturamento.porcentagens.perc_valores_pagos_cliente_devido_ifood_total);
            setValueEstabelecimentos(responseData.estabelecimentos_selected);
            setEstabelecimentos(responseData.estabelecimentos);
            dispatch(showSnackMessage({message: "Resumo iFood obtido com sucesso!", severity: "success"}));
            setLoading(false);
        }).catch(() => {
            clearResumoIfoodValues();
            dispatch(showSnackMessage({message: "Algo deu errado, tente novamente mais tarde!", severity: "error"}));
            setLoading(false);
        });
    };

    const explodeFaturamentoIfood = () => {
        setOpenFaturamentoIFood(true);
    };

    const clearResumoIfoodValues = () => {
        setOpenFaturamentoIFood(false);
        setRepassesIfood([]);
        setRepassesIfoodColumns([]);
        setTotalRepasses(0);
        setValorRecebido(0);
        setValorRetido(0);
        setValorAReceber(0);
        setValorBruto(0);
        setRecebidosLoja(0);
        setValorLiquidoTotal(0);
        setDescontosAcrescimos(0);
        setEstabelecimentosSelected([]);
        setValueEstabelecimentos([]);
        setEstabelecimentos([]);
    };

    const changeOpen = () => {
		setStartAt(0);
		setOpen(!open);
	};

    return (
        openFaturamentoIFood ? (
            <FaturamentoIfood
                selectedMonth={selectedMonth}
                comissaoIfood={comissaoIfood}
                comissaoIfoodCancelado={comissaoIfoodCancelado}
                comissaoTransacao={comissaoTransacao}
                comissaoTransacaoCancelado={comissaoTransacaoCancelado}
                debitoCanceladoParcialmente={debitoCanceladoParcialmente}
                descontosAcrescimos={descontosAcrescimos}
                entregasSolicitadas={entregasSolicitadas}
                outrosValores={outrosValores}
                pedidosCancelados={pedidosCancelados}
                promocoesCusteadasPelaLoja={promocoesCusteadasPelaLoja}
                recebidosLoja={recebidosLoja}
                taxaComissoesTotal={taxaComissoesTotal}
                taxaEntregaParceira={taxaEntregaParceira}
                taxaIfoodRefeicao={taxaIfoodRefeicao}
                valorBruto={valorBruto}
                valorLiquidoTotal={valorLiquidoTotal}
                setOpenFaturamentoIFood={setOpenFaturamentoIFood}
                valoresPagosClienteDevidoIFood={valoresPagosClienteDevidoIFood}
                valorPedidosIFood={valorPedidosIFood}
                valorPedidosViaLojaCount={valorPedidosViaLojaCount}
                promocoesCusteadasPeloIFood={promocoesCusteadasPeloIFood}
                promocoesCusteadasPeloIFoodCount={promocoesCusteadasPeloIFoodCount}
                taxaPedidoMinimo={taxaPedidoMinimo}
                recebidosLojaPerc={recebidosLojaPerc}
                promocoesCusteadasPelaLojaPerc={promocoesCusteadasPelaLojaPerc}
                promocoesCusteadasPeloIFoodPerc={promocoesCusteadasPeloIFoodPerc}
                taxaComissoesTotalPerc={taxaComissoesTotalPerc}
                taxaEntregaParceiraPerc={taxaEntregaParceiraPerc}
                taxaPedidoMinimoPerc={taxaPedidoMinimoPerc}
                taxaIfoodRefeicaoPerc={taxaIfoodRefeicaoPerc}
                entregasSolicitadasPerc={entregasSolicitadasPerc}
                pedidosCanceladosPerc={pedidosCanceladosPerc}
                debitoCanceladoParcialmentePerc={debitoCanceladoParcialmentePerc}
                comissaoIfoodPerc={comissaoIfoodPerc}
                comissaoIfoodCanceladoPerc={comissaoIfoodCanceladoPerc}
                comissaoTransacaoPerc={comissaoTransacaoPerc}
                comissaoTransacaoCanceladoPerc={comissaoTransacaoCanceladoPerc}
                outrosValoresPerc={outrosValoresPerc}
                valoresPagosClienteDevidoIFoodPerc={valoresPagosClienteDevidoIFoodPerc}
                estabelecimentos={estabelecimentosSelected}
            />
        ) : (
            <div data-testid="resumo-container" className="main">
                {loading ? (
                    <Skeleton variant="rectangular" width={"100%"} height={"95vh"}/>
                ): (
                    <React.Fragment>
                        <Header title={<span style={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX}}>{"Resumo iFood"}</span>} />
                        <Paper
                            sx={{...paperStyleFull, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none"}}
                            data-testid="perfil-screen"
                        >
                            <Grid container xs={12}>
                                <Grid item xs={12} sx={{display: "flex", alignItems: "center", marginBottom: "1.5rem"}}>
                                    <Grid spacing={2} container xs={12} sx={{display: "flex", alignItems: "center"}}>
                                        <Grid item>
                                            <FilterWithTag
                                                options={estabelecimentos}
                                                setOptions={setEstabelecimentosSelected}
                                                testIdAll={""}
                                                previousValue={valueEstabelecimentos}
                                                optionFilter
                                                handleSubmit={null}
                                                monthsFiltered={null}
                                                limitSelection={null}
                                                widthSetting={"390px"}
                                                testId={"filter-estabelecimentos"}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FilterYear
                                                optionFilter
                                                handleSubmit={getResumoIfood}
                                                marginBottom={0}
                                                prevMonth={selectedMonth != "" ? selectedMonth.substring(0, 2) : previousMonth}
                                                prevYear={selectedMonth != "" ? selectedMonth.substring(3, 7) : previousYear}
                                                testId="filter-year"
                                            />
                                        </Grid>
                                        <Grid item xs sx={{display: "flex", justifyContent: "end"}}>
                                            <IconButton
                                                style={{background: "#ECC94B", borderRadius: "4px"}}
                                                onClick={() => changeOpen()}
                                                data-testid="button-guia"
                                            >
                                                <LightbulbIcon style={{color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px"}}/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Tour
                                        steps={steps}
                                        isOpen={open}
                                        onRequestClose={changeOpen}
                                        accentColor={DASH_BAR_COLOR}
                                        startAt={startAt}
                                        rounded={5}
                                        showNavigation={false}
                                        inViewThreshold={60}
                                        className={temaEscuro && "tour"}
                                        badgeContent={(curr, tot) => `${curr}/${tot}`}
                                    />
                                </Grid>
                                <Grid container xs={12} >
                                    <Grid xs={12} item gap={2} sx={{display: "flex", marginBottom: "1.5rem"}} className="faturamento-kpis" data-testid="faturamento-kpis">
                                        <FaturamentoKPI
                                            value={valorBruto}
                                            title="Valor Bruto"
                                            explodeFaturamentoIfood={explodeFaturamentoIfood}
                                            temaEscuro={temaEscuro}
                                            styles={styles}
                                        />
                                        <FaturamentoKPI
                                            value={recebidosLoja}
                                            title="Recebido via loja"
                                            explodeFaturamentoIfood={explodeFaturamentoIfood}
                                            temaEscuro={temaEscuro}
                                            styles={styles}
                                        />
                                        <FaturamentoKPI
                                            value={descontosAcrescimos}
                                            title="Descontos ou acréscimos"
                                            explodeFaturamentoIfood={explodeFaturamentoIfood}
                                            temaEscuro={temaEscuro}
                                            styles={styles}
                                        />
                                        <FaturamentoKPI
                                            value={valorLiquidoTotal}
                                            title="Valor líquido total"
                                            explodeFaturamentoIfood={explodeFaturamentoIfood}
                                            temaEscuro={temaEscuro}
                                            styles={styles}
                                            isValorLiquido
                                        />
                                    </Grid>
                                    <Grid item xs={12} height={"calc(75vh - 196px - 72px)"} className="tabela-repasses" data-testid="tabela-repasses">
                                        <DataGrid
                                            rows={(row) => row.id}
                                            columns={repassesIfoodColumns}
                                            data={repassesIfood}
                                            customRowSelectionModelChange={goToFaturamentoDetalhado}
                                        />
                                    </Grid>
                                    <Grid item xs={12} data-testid="resumo-repasses" sx={{mt: 2}}>
                                        <Paper sx={{ display: "flex", p: 2, alignItems: "center", textAlign: "center", backgroundColor: temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                                            <Grid container sx={{display: "flex", alignItems: "center"}}>
                                                <Grid item xs={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                                            <span style={{ ...styles.repasseTitle, ...(temaEscuro && { color: WHITE_ESCRITA_THEME }) }}>Total em repasses</span>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                                            <span style={{ ...styles.repasseTitle, color: totalRepasses > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR }}>{formatterCurrency(totalRepasses, 2)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                                            <span style={{ ...styles.repasseSubtitle, ...(temaEscuro && { color: WHITE_THEME_BLACK }) }}>Valor já recebido</span>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                                            <span style={{ ...styles.repasseTitle, ...(temaEscuro && { color: WHITE_ESCRITA_THEME }) }}>{formatterCurrency(valorRecebido, 2)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                                            <span style={{ ...styles.repasseSubtitle, ...(temaEscuro && { color: WHITE_THEME_BLACK }) }}>Valor retido</span>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                                            <span style={{ ...styles.repasseTitle, ...(temaEscuro && { color: WHITE_ESCRITA_THEME }) }}>{formatterCurrency(valorRetido, 2)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                                            <span style={{ ...styles.repasseSubtitle, ...(temaEscuro && { color: WHITE_THEME_BLACK }) }}>Valor a receber</span>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                                            <span style={{ ...styles.repasseTitle, ...(temaEscuro && { color: WHITE_ESCRITA_THEME }) }}>{formatterCurrency(valorAReceber, 2)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                            </Grid>
                            </Grid>
                        </Paper>
                    </React.Fragment>
                )}
            </div>
        )
    );
};

const styles = {
    title: {
        fontSize: "16px",
        color: DASH_KPI_COLOR,
        marginBottom: ".5rem",
        fontWeight: 500
    },
    repasseTitle: {
        fontSize: "16px",
		fontWeight: 700,
		color: DASH_KPI_COLOR
    },
    repasseSubtitle: {
        fontSize: "16px",
		fontWeight: 500,
		color: DASH_KPI_COLOR
    },
    value: {
        fontWeight: "bold",
        fontSize: "20px"
    },
};

export default ResumoIfood;