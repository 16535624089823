import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { smallPaperStyle, BLUE_THEME, styleTextStrong, styleText, GRAY_DATE_UX, WHITE_THEME_BLACK, GRAY_LABEL_UX } from "../../shared/utils";
import {useSelector} from "react-redux";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";
import IconButton from "@mui/material/IconButton";
import LightbulbIcon from "@mui/icons-material/Lightbulb";


const FirstLoginModal = (props) => {
    const {openModal, setOpenModal, isFirstMonth, handleTutorial, handleRedirect} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const name = useSelector(state => state.AuthReducer.name);

    const handleTutorialButton = () => {
        setOpenModal(false);
        handleTutorial();
    };

    return (
        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testid="first-login-modal"
        >
            <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h1 style={{...styleTextStrong, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontSize: "28px"}}>
                            Olá, {name}. Seja muito bem-vindo!
                        </h1>
                        {isFirstMonth ? (
                            <h2 data-testid="first-option-text" style={{...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontSize: "20px", textAlign: "justify"}}>
                                Esse é seu primeiro acesso ao sistema de informações gerenciais da Voxel, ainda não temos as informações de um mês completo do seu restaurante para análise, 
                                mas já podemos agregar valor à sua gestão com informações de mercado. 
                                Selecione abaixo a opção <b>&quot;Ir para Insights&quot;</b> que iremos te direcionar para a nossa página de informações do seu segmento e região.
                                Caso queira aprender a utilizar o nosso sistema, clique no botão &quot;Tutorial&quot; abaixo ou, a qualquer momento, clique no ícone de lâmpada {
                                    <IconButton  style={{background: "#ECC94B", color: temaEscuro && GRAY_DATE_UX}}>
                                        <LightbulbIcon style={{ fontSize: "16px"}}/>
                                    </IconButton>
                                } para receber as instruções.
                            </h2>
                        ) : (
                            <h2 data-testid="second-option-text" style={{...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontSize: "20px", textAlign: "justify"}}>
                                Esse é seu primeiro acesso ao sistema de informações gerenciais da Voxel. 
                                Para aprender a utilizar o nosso sistema, clique no botão <b>&quot;Tutorial&quot;</b> abaixo ou, a qualquer momento, clique no ícone de lâmpada {
                                    <IconButton  style={{background: "#ECC94B", color: temaEscuro && GRAY_DATE_UX}}>
                                        <LightbulbIcon style={{ fontSize: "16px"}}/>
                                    </IconButton>
                                } para receber as instruções. Mas caso queira obter informações do seu segmento e região, clique na opção abaixo &quot;Ir para Insights&quot;.
                            </h2>
                        )}
                    </Grid>
                    <Grid item xs={12} >
                        <Grid container spacing={1} sx={{display: "flex", justifyContent: "end"}}>
                            <Grid item>
                                <GrayButton
                                    title="Fechar"
                                    onClick={() => setOpenModal(false)}
                                    testId="close-button"
                                />
                            </Grid>
                            <Grid item>
                                <YellowButton
                                    title="Tutorial"
                                    onClick={() => handleTutorialButton()}
                                    testId="tutorial-button"
                                />
                            </Grid>
                            <Grid item>
                                <YellowButton
                                    title="Ir para Insights"
                                    onClick={() => handleRedirect()}
                                    testId="submit-button"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>

    );
};

export default React.memo(FirstLoginModal);