import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./PlanoModal.css";

const PlanoModal = (props) => {
	const {
		openModal,
		setOpenModal,
		fieldId,
		handleDelete,
		selectedTab,
		handleDeleteCompras
	} = props;

	const handleChangeDelete = () =>{
		if (selectedTab === 0){
			deletePlano();
		} else {
			deletePlanoCompras();
		}
	};

	const deletePlano = () =>{
		handleDelete(fieldId);
	};

	const deletePlanoCompras = () =>{
		handleDeleteCompras(fieldId);
	};

	return(
		<React.Fragment>
			<Dialog
				open={openModal}
				onClose={() => setOpenModal(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Deletar Plano de Conta"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Tem certeza que deseja deletar esse plano de conta? Essa ação é irreversível.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenModal(false)}>Cancelar</Button>
					<Button onClick={() => handleChangeDelete()} autoFocus data-testid="button-confirm-delete">
						Deletar
					</Button>
				</DialogActions>
			</Dialog>
			
		</React.Fragment>
	);
};

export default PlanoModal;