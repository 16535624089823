import React, { useState }  from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import "../../Table/Table.css";
import {useDispatch, useSelector} from "react-redux";
import {showSnackMessage} from "../../../actions/SnackActions";
import {
	GRAY_LABEL_UX_THEME,
	GRAY_LABEL_UX,
	translateAutocomplete,
	BLUE_THEME,
	GRAY_BG_UX,
	GRAY_BORDER_UX,
	WHITE_THEME_BLACK,
} from "../../../shared/utils";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import YellowButton from "../../Buttons/YellowButton";
import api from "../../../axios";
import { useNavigate } from "react-router-dom";

const NotClassified = (props) => {
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {rows, selectedTab, categorias, storeId, setShowNotClassified, getPlanoContasCompras, getPlanoContas} = props;

	const [categoria, setCategoria] = useState({});

	const handleCopy = (categoria) =>{
		navigator.clipboard.writeText(categoria).then(() => {
			dispatch(showSnackMessage({message: "Categoria copiada com sucesso!"}));
		})
		.catch(() => {
			alert("algo deu errado.");
		});
	};

	const handleChangeCategoria = (categoriaSelecionada, item) => {
		setCategoria((prevCategoria) => ({
			...prevCategoria,
			[item]: categoriaSelecionada
		}));
	};

	const handleAddPlanoContas = () => {
		const categoriasData = [];
		Object.entries(categoria).forEach(([key, value]) => {
			let dataRequest = {
				categoria_ca: key,
				...(selectedTab === 0 ? { categoria_dre: value } : { categorizacao_1: value})
			};
			categoriasData.push(dataRequest);
		});
		if (selectedTab === 0) {
			handleAdd(categoriasData);
		} else {
			handleAddCompras(categoriasData);
		}
	};

	const handleAdd = (dataRequest) => {
		dataRequest["loja_id"] = storeId;
		api.AddPlanoContas(storeId, dataRequest).then(() => {
			dispatch(showSnackMessage({message: "Plano de contas adicionado com sucesso!"}));
			setShowNotClassified(false);
			getPlanoContas(selectedTab);
		}).catch(() => {
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	};

	const handleAddCompras = (dataRequest) => {
		dataRequest["loja_id"] = storeId;
		api.AddPlanoContasCompras(storeId, dataRequest).then(() => {
			dispatch(showSnackMessage({message: "Plano de contas adicionado com sucesso!"}));
			setShowNotClassified(false);
			getPlanoContasCompras(selectedTab);
		}).catch((error) => {
			const response = error.response;
			if (response.status === 400)
				dispatch(showSnackMessage({message: "Essa categoria já existe para essa loja", severity: "error"}));
			else
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			navigate("/plano_contas");
		});
	};

	return(
		<React.Fragment>
			<nav aria-label="secondary mailbox folders" data-testid="notclassified-list">
				<h2 className="info-class-text" style={{color: temaEscuro ? GRAY_LABEL_UX_THEME : GRAY_LABEL_UX}}>Categorias não classificadas para essa loja:</h2>
				<List sx={{display: "flex", flexDirection: "column", gap: 1.7, padding: 0, marginTop: 3}}>
					{rows.map((item, index) => {
						return(
							<ListItem disablePadding key={index}>
								<ListItemButton selected={true} onClick={() => handleCopy(selectedTab == 0 ? (item?.categoria) : (item?.categoria_ca))} sx={{
									"&.Mui-selected": {
										backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BORDER_UX,
										height: "56px",
										borderRadius: 1.5
									},
									marginRight: 1
								}}>
									<ListItemText
										disableTypography
										primary={selectedTab == 0 ? (item?.categoria) : (item?.categoria_ca)} 
										sx={{"&.MuiListItemText-root": {
											color: temaEscuro ? WHITE_THEME_BLACK :  GRAY_LABEL_UX,
											fontWeight: "500"
										}}}
									/>
								</ListItemButton>
								<Autocomplete
									size="small"
									disableClearable
									noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
									style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, width: "30%"}}
									onChange={(event, newValue) => {
										handleChangeCategoria(newValue, selectedTab == 0 ? item?.categoria : item?.categoria_ca);
									}}
									isOptionEqualToValue={(option, value) => option.value === value.value}
									data-testid="select-store"
									options={categorias}
									renderInput={(params) => <TextField {...params} label={selectedTab == 0 ? "Categorização DRE" : "Categorização Produto"} />}
									PaperComponent={({ children }) => (
										<Paper style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none"}}>{children}</Paper>
									)}
								/>
							</ListItem>
						);
					})}

				</List>
				{
					Object.keys(categoria).length > 0 && (
						<Grid container xs={12} sx={{display: "flex", justifyContent: "end", marginTop: 4}}>
							<Grid item >
								<YellowButton 
									title="Adicionar plano de contas" 
									onClick={handleAddPlanoContas}
									testId={"buttonAddAta"}
								/>
							</Grid>
						</Grid>
					)
				}
			</nav>
		</React.Fragment>
	);
};

export default NotClassified;