import React, { useState, useEffect }  from "react";
import Button from "@mui/material/Button";
import {
	GRAY_LABEL_UX,
	styleButton,
	styleModal, 
	styleYellowButton, 
	WALPAPPER_THEME, 
	BLUE_THEME, 
	GRAY_BG_UX, 
	textCompleteStyleBlack, 
	textCompleteStyle, 
	BORDER_BUTTON, 
	WHITE_THEME_BLACK,
	translateAutocomplete
} from "../../../shared/utils";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FilterWithTag from "../../Filter/FilterWithTag";
import {useSelector} from "react-redux";
import "./PlanoModal.css";


const PlanoRename = (props) => {
	const {open, setOpen, loadingModal, handleRename, categorias, storeId, testId}= props;
	const [categoriaCA, setCategoriaCA] = useState("");
	const [categorizacaoDRE, setCategorizacaoDRE] = useState("");
    const [newCategorizacaoDRE, setNewCategorizacaoDRE] = useState("");
    const [selectedStore, setSelectedStores] = useState([]);
    const [checked, setChecked] = useState(false);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const stores = useSelector(state => state.StoreReducer.lojas);

    useEffect(() => {
        setChecked(false);
    }, []);

	const editPlano = () => {
        let dataRequest = {
            lojas: (checked && selectedStore.length) ? selectedStore : [storeId],
            categoria_ca: categoriaCA,
            old_name: categorizacaoDRE,
            new_name: newCategorizacaoDRE,
        };
		handleRename(dataRequest);
	};

    const handleCheckBox = (event) => {
        setChecked(event.target.checked);
    };

	return(
		<React.Fragment>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Paper className="modal" sx={{...styleModal, height: "75%", backgroundColor: temaEscuro && WALPAPPER_THEME, backgroundImage: temaEscuro && "none"}} data-testid={testId}>
					<Grid container sx={{height: "100%", display: "flex"}}>
						<Grid item xs={12}>
							<h3 style={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>Preencha as informações abaixo para alterar a categoria:</h3>
							<Grid container spacing={1} className="relative" sx={{display: "flex"}}>
								<Grid container sx={{padding: "10px"}}>
									<Grid item xs={12} sx={{marginBottom: "8px"}}>
                                        <FormControl fullWidth>
                                            <Autocomplete style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
												size="small"
												noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
                                                disablePortal
												sx={{borderColor: temaEscuro && BORDER_BUTTON}}
                                                disableClearable
												options={(categorias?.categoria_ca) ? categorias.categoria_ca : []}
                                                onChange={(event, newValue) => {
                                                    setCategoriaCA(newValue);
                                                }}
												PaperComponent={({ children }) => (
													<Paper style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none"}}>{children}</Paper>
												)}
                                                renderInput={(params) => 
												<TextField 
												{...params} 
												sx={temaEscuro ? textCompleteStyleBlack: textCompleteStyle} 
												label={
													<span
														style={{
															color: temaEscuro && BORDER_BUTTON,
														}}
													>
														Categoria
													</span>
												}
												data-testid={"autocomplete-categorias"}/>}
                                            />
                                        </FormControl>
									</Grid>
                                    <Grid item xs={12} sx={{marginBottom: "8px"}}>
                                        <FormControl fullWidth>
                                            <Autocomplete style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
												size="small"
												noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
                                                disablePortal
												sx={{borderColor: temaEscuro && BORDER_BUTTON}}
                                                disableClearable
												options={(categorias?.categoria_dre) ? categorias.categoria_dre : []}
                                                onChange={(event, newValue) => {
                                                    setCategorizacaoDRE(newValue);
                                                }}
												PaperComponent={({ children }) => (
													<Paper style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none"}}>{children}</Paper>
												)}
                                                renderInput={(params) => 
												<TextField 
												{...params} 
												sx={temaEscuro ? textCompleteStyleBlack: textCompleteStyle} 
												label={
													<span
														style={{
															color: temaEscuro && BORDER_BUTTON,
														}}
													>
														Atual Categorização
													</span>
												}
												data-testid={"autocomplete-atualCategorizacao"}/>}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{marginBottom: "8px"}}>
                                        <FormControl fullWidth>
                                            <Autocomplete style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
												size="small"
												noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
                                                disablePortal
												sx={{borderColor: temaEscuro && BORDER_BUTTON}}
                                                disableClearable
												options={(categorias?.categoria_dre) ? categorias.categoria_dre : []}
                                                onChange={(event, newValue) => {
                                                    setNewCategorizacaoDRE(newValue);
                                                }}
												PaperComponent={({ children }) => (
													<Paper style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none"}}>{children}</Paper>
												)}
                                                renderInput={(params) => 
												<TextField 
												{...params} 
												sx={temaEscuro ? textCompleteStyleBlack: textCompleteStyle} 
												label={
													<span
														style={{
															color: temaEscuro && BORDER_BUTTON,
														}}
													>
														Nova Categorização
													</span>
												}
												data-testid={"autocomplete-novaCategorizacao"}/>}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel 
                                            control={<Checkbox checked={checked} onChange={handleCheckBox}/>} 
                                            label="Deseja realizar esta alteração para outras lojas?"
											data-testid={"checkbox"} />
                                    </Grid>
                                    {checked && (
                                        <Grid item xs={12} sx={{marginBottom: "8px"}}>
                                            <FilterWithTag optionFilter options={stores} setOptions={setSelectedStores} testIdAll={"filterWithTag"}/>
                                        </Grid>
                                    )}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sx={{display: "flex", alignItems: "end", justifyContent: "center"}}>
							<Grid container spacing={2} sx={{justifyContent: "center", marginBottom: "20px"}}>
								<Grid item>
									{
										loadingModal ? (
											<LoadingButton variant="outlined" loading >
												Cancelar
											</LoadingButton>
										) : (
											<Button
												variant="contained"
												onClick={()=> setOpen(false)}
												style={{...styleButton, borderColor: temaEscuro && BORDER_BUTTON, color: temaEscuro && WHITE_THEME_BLACK}}
											>
												Cancelar
											</Button>
										)
									}
								</Grid>
								<Grid item>
									{
										loadingModal ? (
											<LoadingButton variant="outlined" loading >
												Salvar Alterações
											</LoadingButton>
										) : (
											<Button
												variant="contained"
												onClick={()=> editPlano()}
												style={styleYellowButton}
												data-testid="save-new-category-btn"
											>
												Salvar Alterações
											</Button>
										)
									}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Modal>
		</React.Fragment>
	);
};

export default PlanoRename;