import React, { useEffect, useState } from "react";
import Header from "../../components/Titles/Header";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import {showSnackMessage} from "../../actions/SnackActions";
import { 
    paperStyle,
    PAPER_PADDING_THEME,
} from "../../shared/utils";
import YellowButton from "../../components/Buttons/YellowButton";
import ImageButtonCard from "../../components/Cards/ImageButtonCard";
import Stone from "./LogoPlatforms/Stone.png";
import Rede from "./LogoPlatforms/Rede.png";
import Nimbus from "./LogoPlatforms/Nimbus.png";
import Ifood from "./LogoPlatforms/Ifood.png";
import Colibri from "./LogoPlatforms/Colibri.png";
import Saipos from "./LogoPlatforms/Saipos.png";
import ContaAzul from "./LogoPlatforms/ContaAzul.png";
import SelectStore from "../../components/SelectStore/SelectStore";
import api from "../../axios";


export default function API() {
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const storeId = useSelector(state => state.StoreReducer.storeId);
    const [estabelecimentos, setEstabelecimentos] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (storeId !== 0) {
            getEstabelecimentos();
        }
	}, [storeId]);

    const getEstabelecimentos = () => {
		const dataRequest = {
			loja_id: storeId,
		};
		api.GetEstabelecimentos(dataRequest).then(response => {
			let data = response.data;
			setEstabelecimentos(data);
		}).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado, tente novamente mais tarde!", severity: "error"}));
		});
	};

    const redirectToDocumentacao = () => {
        window.open("https://voxelgestaocom-my.sharepoint.com/:w:/g/personal/iure_brandao_voxelgestao_com/ERq_jlbTRKxPqw0R1fYzO04BXzqjaD2Vi9zKF4lm27bO6g?e=phyzKE", "_blank");
    };

    return (
        <div className="main">
            <Header title="Registro APIS" />
            <Paper data-testid="api" sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}}>
                {
                    storeId === 0 ?
                    (
                        <SelectStore />
                    ) : (
                        <Grid container rowSpacing={3}>
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                <YellowButton onClick={() => redirectToDocumentacao()} title="Documentação APIs" />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container sx={{display: "flex", justifyContent: "center", flexWrap: "wrap", gap: 5.3}}>
                                    <Grid item sx={{display: "flex", justifyContent: "center"}}>
                                        <ImageButtonCard img={Stone} estabelecimentos={estabelecimentos} plataforma="Stone" />
                                    </Grid>
                                    <Grid item sx={{display: "flex", justifyContent: "center"}}>
                                        <ImageButtonCard img={Rede} estabelecimentos={estabelecimentos} plataforma="Rede" />
                                    </Grid>
                                    <Grid item sx={{display: "flex", justifyContent: "center"}}>
                                        <ImageButtonCard img={Nimbus} estabelecimentos={estabelecimentos} plataforma="Nimbus" />
                                    </Grid>
                                    <Grid item sx={{display: "flex", justifyContent: "center"}}>
                                        <ImageButtonCard img={Ifood} estabelecimentos={estabelecimentos} plataforma="Ifood" />
                                    </Grid>
                                    <Grid item sx={{display: "flex", justifyContent: "center"}}>
                                        <ImageButtonCard img={Colibri} estabelecimentos={estabelecimentos} plataforma="Colibri" />
                                    </Grid>
                                    <Grid item sx={{display: "flex", justifyContent: "center"}}>
                                        <ImageButtonCard img={Saipos} estabelecimentos={estabelecimentos} plataforma="Saipos" />
                                    </Grid>
                                    <Grid item sx={{display: "flex", justifyContent: "center"}}>
                                        <ImageButtonCard img={ContaAzul} estabelecimentos={estabelecimentos} plataforma="ContaAzul" />
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            </Paper>
        </div>
    );
}
