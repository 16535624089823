import React, { useState, useEffect } from "react";
import LastModified from "../../components/LastModified/LastModified";
import {showSnackMessage} from "../../actions/SnackActions";
import { Grid } from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import api from "../../axios";
import Paper from "@mui/material/Paper";
import {
    LINE_TABLE,
    PAPER_PADDING_THEME,
    formatterDayMonthYear,
    formatterCurrency,
    paperStyle,
    GRAY_LABEL_UX,
    DASH_BAR_COLOR,
    WHITE_TABLE, BLUE_THEME, GRAY_BG_UX,
    BORDER_BUTTON
} from "../../shared/utils";
import SelectStore from "../../components/SelectStore/SelectStore";
import YellowButton from "../../components/Buttons/YellowButton";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import EXMKPI from "../../components/Dash/EXMKPI";
import DataGrid from "../../components/DataGrid/DataGrid";
import EXMFooter from "../../components/Dash/EXMFooter";
import FilterEXM from "../../components/Filter/FilterEXM";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import Skeleton from "@mui/material/Skeleton";
import { steps } from "./TourEXM";
import Tour from "reactour";
import { useLocation } from "react-router-dom";
import moment from "moment";
import InputTextEXM from "../../components/Input/InputTextEXM";



export default function ExtratoMovimentacoes() {

    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [openTour, setOpenTour] = useState(false);
    const [startAt, setStartAt] = useState(0);

    const [periodo, setPeriodo] = useState(location.state !== null ? "custom_periodo" : "este_mes");
    const [filterPlaceholders, setFilterPlaceholders] = useState({});
    const [specificDate, setSpecificDate] = useState("");
    const [arrowCounter, setArrowCounter] = useState(0);
    const [lastSearchedValue, setLastSearchedValue] = useState("");
    const [selectedKPI, setSelectedKPI] = useState("totalPeriodo");
    const [filterExm, setFilterExm] = useState(location.state !== null);
    const [searchValue, setSearchValue] = useState(lastSearchedValue || null);

    const [customPeriodo, setCustomPeriodo] = useState(() => {
        if (location.state !== null && location.state.dataRecebimento) {
            const dates = location.state.dataRecebimento;
            if (dates.length !== 2) {
                const dateFormatted = moment(dates, "DD/MM/YYYY");
                return [dateFormatted, dateFormatted];
            } else {
                const [date1, date2] = dates;
                const startDate = moment(date1, "DD/MM/YYYY");
                const endDate = moment(date2, "DD/MM/YYYY");
                return [startDate, endDate];
            }
        } else {
            return [null, null];
        }
    });

    const [extratosKPIs, setExtratosKPIs] = useState({});
    const [receitaEmAbertoKPI, setReceitaEmAbertoKPI] = useState(0);
    const [receitaRealizadaKPI, setReceitaRealizadaKPI] = useState(0);
    const [despesaEmAbertoKPI, setDespesaEmAbertoKPI] = useState(0);
    const [despesaRealizadaKPI, setDespesaRealizadaKPI] = useState(0);
    const [totalPeriodoKPI, setTotalPeriodoKPI] = useState(0);

    const [tableData, setTableData] = useState([]);
    const [footerPeriodo, setFooterPeriodo] = useState("");

    const storeId = useSelector(state => state.StoreReducer.storeId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        if (location.state !== null) {
            setPeriodo("custom_periodo");
        }
    }, [location.state]);

    useEffect(() => {
        const isCustomPeriodoValid = customPeriodo[0] !== null && customPeriodo[1] !== null;
        const shouldFetchMovimentacoes =
            storeId !== 0 &&
            (
                (location.state !== null && isCustomPeriodoValid) ||
                (periodo === "custom_periodo" && isCustomPeriodoValid) ||
                (periodo !== "custom_periodo")
            );
    
        if (shouldFetchMovimentacoes) {
            getExtratoMovimentacoes();
        }
    }, [storeId, arrowCounter, periodo, customPeriodo]);

    useEffect(() => {
        setFilterExm(false);
    }, [periodo, arrowCounter]);

    const getExtratoMovimentacoes = () => {
        setLoading(true);
        const dataRequest = {
            loja_id: storeId,
            periodo: periodo,
            filter_conciliacao: filterExm,
            platform: location.state !== null ? location.state.platform : "",
            plus_minus: arrowCounter,
            search: searchValue,
        };
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        api.GetExtratoMovimentacoes(dataRequest).then(response => {
            setLoading(false);
            let data = response.data;
            let kpiData = data.kpis;

            setFilterPlaceholders(data.filter_placeholders);
            setSpecificDate(data?.specific_date || "");
            setLastSearchedValue(data?.searched_value || "");
            let extratoData = data.extrato;
            if (extratoData.extrato.length === 0) {
                dispatch(showSnackMessage({message: "Nenhum registro encontrado", severity: "info"}));
                return;
            }

            setReceitaEmAbertoKPI(kpiData.total_receita_em_aberto);
            setReceitaRealizadaKPI(kpiData.total_receita_realizada);
            setDespesaEmAbertoKPI(kpiData.total_despesa_em_aberto);
            setDespesaRealizadaKPI(kpiData.total_despesa_realizada);
            setTotalPeriodoKPI(kpiData.total);

            setExtratosKPIs(extratoData);
            setTableData(data.extrato.extrato);

            let _footerPeriodo = `${extratoData.extrato[0].data_pagamento} - ${extratoData.extrato[extratoData.extrato.length - 1].data_pagamento}`;
            setFooterPeriodo(_footerPeriodo);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const changeOpen = () => {
		setStartAt(0);
		setOpenTour(!openTour);
	};

    const handleClickKPIs = (kpi) => {
        if (kpi === "receitaEmAberto") {
            setTableData(extratosKPIs.receitas_em_aberto);
            setSelectedKPI("receitaEmAberto");
        } else if (kpi === "receitaRealizada") {
            setTableData(extratosKPIs.receitas_realizadas);
            setSelectedKPI("receitaRealizada");
        } else if (kpi === "despesaEmAberto") {
            setTableData(extratosKPIs.despesa_em_aberto);
            setSelectedKPI("despesaEmAberto");
        } else if (kpi === "despesaRealizada") {
            setTableData(extratosKPIs.despesa_realizada);
            setSelectedKPI("despesaRealizada");
        } else if (kpi === "totalPeriodo") {
            setTableData(extratosKPIs.extrato);
            setSelectedKPI("totalPeriodo");
        }
    };

    const handleDateChange = (newValue) => {
        setCustomPeriodo(newValue);
    };

    const columns = [
        {
            field: "data_pagamento",
            headerName: "Data",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: params => formatterDayMonthYear(params)
        },
        { 
            field: "categoria",
            headerName: "Categoria",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{backgroundColor: GRAY_LABEL_UX, padding: 8, borderRadius: "3px", color: temaEscuro ? LINE_TABLE : WHITE_TABLE }}>
                        {params.value}
                    </span>
                );
            }
        },
        { field: "descricao", headerName: "Descrição", flex: 1, align: "center", headerAlign: "center" },
        { field: "situacao", headerName: "Situação", flex: 1, align: "center", headerAlign: "center" },
        { 
            field: "valor",
            headerName: "Valor",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
    ];

    return (
        <div className="main">
            <LastModified title="EXM - Extrato de Movimentações" />
            <Tour
				steps={steps}
				isOpen={openTour}
				onRequestClose={changeOpen}
				startAt={startAt}
				rounded={5}
				accentColor={DASH_BAR_COLOR}
				{...( temaEscuro ? {className: "tour"} : {})}
				badgeContent={(curr, tot) => `${curr}/${tot}`}
			/>
            <Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, color: temaEscuro && LINE_TABLE, backgroundImage: temaEscuro && "none"}}>
                {loading ? (
                    [...Array(15).keys()].map((i,d) =>{
						return(
							<Skeleton key={d} height={50}/>
						);
					})
                ) : (
                    storeId === 0 ? (
                        <SelectStore/>
                    ) : (
                        <Grid container data-testid="extratoMovimentacoes" spacing={2} sx={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={12}>
                                <Grid container sx={{display: "flex", flexDirection: "row", gap: 2}}>
                                    <Grid item>
                                        <FilterEXM
                                            placeholders={filterPlaceholders}
                                            periodo={periodo}
                                            setPeriodo={setPeriodo}
                                            specificDate={specificDate}
                                            setSpecificDate={setSpecificDate}
                                            arrowCounter={arrowCounter}
                                            setArrowCounter={setArrowCounter}
                                            getExtratoMovimentacoes={getExtratoMovimentacoes}
                                        />
                                        {
                                            periodo === "custom_periodo" && (
                                                <DateRangePicker
                                                    value={customPeriodo}
                                                    onChange={handleDateChange}
                                                    localeText={{ start: "Início", end: "Fim" }}
                                                    sx={{width: "330px", marginTop: 2, "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON}}}
                                                    slotProps={{
                                                        textField: {
                                                            size: "small",
                                                            sx: {backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}
                                                        }
                                                    }}
                                                />  
                                            )
                                        }
                                    </Grid>
                                    <Grid item>
                                        <InputTextEXM
                                            periodo={periodo}
                                            setSearchValue={setSearchValue}
                                            searchValue={searchValue}
                                            getExtratoMovimentacoes={getExtratoMovimentacoes}
                                            className="input-text"
                                        />
                                    </Grid>
                                    <Grid item sx={{flex: 1, textAlign: "end"}}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <YellowButton
                                                    onClick={changeOpen}
                                                    title="guia"
                                                    testId="button-guia"
                                                    startIcon={<LightbulbIcon/>}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} rowSpacing={4}>
                                    <Grid item xs={2.4}>
                                        <EXMKPI
                                            className="card_1"
                                            title="Receitas em aberto (R$)"
                                            testId="receita-aberto-kpi"
                                            data={receitaEmAbertoKPI}
                                            handleClick={() => handleClickKPIs("receitaEmAberto")}
                                            selectedKPI={selectedKPI === "receitaEmAberto"}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <EXMKPI
                                            className="card_2"
                                            title="Receitas realizadas (R$)"
                                            testId="receita-realizada-kpi"
                                            data={receitaRealizadaKPI}
                                            handleClick={() => handleClickKPIs("receitaRealizada")}
                                            selectedKPI={selectedKPI === "receitaRealizada"}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <EXMKPI
                                            className="card_3"
                                            title="Despesas em aberto (R$)"
                                            testId="despesa-aberto-kpi"
                                            data={despesaEmAbertoKPI}
                                            handleClick={() => handleClickKPIs("despesaEmAberto")}
                                            selectedKPI={selectedKPI === "despesaEmAberto"}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <EXMKPI
                                            className="card_4"
                                            title="Despesas realizadas (R$)"
                                            testId="despesa-realizada-kpi"
                                            data={despesaRealizadaKPI}
                                            handleClick={() => handleClickKPIs("despesaRealizada")}
                                            selectedKPI={selectedKPI === "despesaRealizada"}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <EXMKPI
                                            isTotal
                                            className="card_5"
                                            title="Total do período (R$)"
                                            testId="total-periodo-kpi"
                                            data={totalPeriodoKPI}
                                            handleClick={() => handleClickKPIs("totalPeriodo")}
                                            selectedKPI={selectedKPI === "totalPeriodo"}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ height: "calc(100vh - 160px - 95px - 177px)", width: "100%"}}>
                                        <DataGrid
                                            columns={columns}
                                            data={tableData}
                                            row={(row) => (`${row.id}-${row.data_pagamento}-${row.categoria}-${row.descricao}-${row.situacao}-${row.valor}`)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <EXMFooter
                                    valorTotal={totalPeriodoKPI}
                                    periodo={footerPeriodo}
                                    testId="footer-kpi"
                                />
                            </Grid>
                        </Grid>
                    )
                )}
            </Paper>
        </div>
    );
}
