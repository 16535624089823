import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import api from "../../axios";
import {
    paperStyleCompras,
    GRAY_LABEL_UX,
    PAPER_PADDING_THEME,
    formatterCurrency,
    GRAY_LABEL_UX_THEME,
    YELLOW_BG_UX,
    formatterDayMonthYear,
} from "../../shared/utils";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import SelectStore from "../../components/SelectStore/SelectStore";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import Tour from "reactour";
import {
    DASH_BAR_COLOR,
    GRID_BUTTONS_SPACING,
} from "../../shared/utils";
import { steps } from "./TourCompras";
import FilterWithTag from "../../components/Filter/FilterWithTag";
import GrayButton from "../../components/Buttons/GrayButton";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import LastModified from "../../components/LastModified/LastModified";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useDispatch} from "react-redux";
import {showSnackMessage} from "../../actions/SnackActions";
import ComprasDashboard from "./tabs/ComprasDashboard";
import ComprasTable from "./tabs/ComprasTable";


export default function Compras() {
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] =  useState(false);
    const [startAt, setStartAt] = useState(0);
    const [months, setMonths] = useState([]);
    const [monthsAll, setMonthsAll] = useState([]);
    const [previousMonths, setPreviousMonths] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [dataDash, setDataDash] = useState([]);
    const [lineCompras, setLineCompras] = useState({});
    const [seriesForn, setSeriesForn] = useState([]);
    const [treeMap, setTreeMap] = useState([]);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const storeId = useSelector(state => state.StoreReducer.storeId);

    const dispatch = useDispatch();

    useEffect(() => {
        if(storeId !== 0){
            getCompras();
            getDashCompras();
            getMesesCompetencia();
        }
    }, [storeId]);

    const getMesesCompetencia = () => {
        setLoading(true);

        let dataRequest = {
            lojas: [storeId]
        };

        api.GetMesesCompetencia(dataRequest).then(response => {
            setLoading(false);
            let dataResponse = response.data;
            setMonthsAll(dataResponse);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const getCompras = () => {
        setLoading(true);
        let dataRequest = {
            loja_id: storeId,
            meses: months,
        };
        api.GetCompras(dataRequest).then(response => {
            setLoading(false);
            let dataResponse = response.data;
            setTableData(dataResponse.data);
            let prevMonths = dataResponse.months;
            setPreviousMonths(() => prevMonths);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const changeOpen = () => {
        setStartAt(0);
        setOpen(!open);
    };

    const handleChange = (event, newTabIdx) => {
        setSelectedTab(newTabIdx);
    };

    const getDashCompras = () => {
        let dataRequest = {
            loja_id: storeId,
            months,
        };

        api.GetDashCompras(dataRequest).then(response => {
                let dataResponse = response.data;
                setLineCompras(dataResponse.line_compras);
                setSeriesForn(dataResponse.series_forn);
                setTreeMap(dataResponse.series_tree_map);
                setDataDash(dataResponse);
            }
        ).catch((error) => {
            let msgState = error?.response?.data?.msg;
            setLoading(false);
            dispatch(showSnackMessage({message: msgState || "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const columns = [
        { field: "nome_fornecedor", headerName: "Cliente Fornecedor", flex: 1, align: "center", headerAlign: "center" },
        {
            field: "data_competencia",
            headerName: "Data de Emissão",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: params => formatterDayMonthYear(params)
        },
        {
            field: "data_vencimento",
            headerName: "Data de Vencimento",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: params => formatterDayMonthYear(params)
        },
        { field: "produto", headerName: "Nome", flex: 1, align:"center", headerAlign: "center" },
        { field: "tipo", headerName: "Tipo", flex: 1, align:"center", headerAlign: "center" },
        { field: "qtd_produtos", headerName: "Quantidade", flex: 1, type: "number", align:"center", headerAlign: "center" },
        {
            field: "valor_unitario",
            headerName: "R$ Unitário",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        {
            field: "total_produtos",
            headerName: "R$ Total Produtos",
            flex: 1,
            align:"center",
            type: "number",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        {
            field: "total_nota",
            headerName: "R$ Total Nota",
            flex: 1,
            align:"center",
            type: "number",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { field: "numero_nota", headerName: "Número da Nota", flex: 1, align:"center", headerAlign: "center" },
        { field: "categorizacao_1", headerName: "Categoria do produto", flex: 1, align:"center", headerAlign: "center" },
        { field: "categoria_despesa", headerName: "Categoria Despesa", flex: 1, align:"center", headerAlign: "center"},
        { field: "categoria_dre", headerName: "Categoria DRE", flex: 1, align:"center", headerAlign: "center"},
        { field: "categoria_ca", headerName: "Resumo do produto", flex: 1, align:"center", headerAlign: "center" },
    ];

    const initialColumns = {
        columns: {
            columnVisibilityModel: {
                tipo: false,
                total_nota: false,
                categorizacao_1: false,
                categoria_despesa: false,
                categoria_ca: false,
            },
        },
    };

    const handleClick = () => {
        getCompras();
        getDashCompras();
    };

    return (

        <div className="main" data-testid="compras">
            <LastModified title="Relatório de Compras"/>
            {loading ? (
                <Skeleton variant="rectangular" width={"100%"} height={"80vh"} />
            ) : (
                storeId === 0 ?
                    (
                        <SelectStore/>
                    ) : (
                        <Paper sx={{...paperStyleCompras, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}}>
                            <React.Fragment>
                                <Grid sx={{marginBottom:2}}>
                                    <Tabs
                                        value={selectedTab}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                        TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                    >
                                        <Tab
                                            data-testid={"tabDash"}
                                            label="Dashboard"
                                            key={0}
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : GRAY_LABEL_UX,
                                                backgroundColor: selectedTab === 0 ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                    color: GRAY_LABEL_UX,
                                                },
                                            }}
                                        />
                                        <Tab
                                            data-testid={"tabTable"}
                                            label="Tabela"
                                            key={1}
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : GRAY_LABEL_UX,
                                                backgroundColor: selectedTab === 1 ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                    color: GRAY_LABEL_UX,
                                                },
                                            }}
                                        />
                                    </Tabs>
                                </Grid>
                            </React.Fragment>
                            <Grid container sx={{display: "flex", alignItems: "center", marginBottom: 2}}>
                                <Grid item sx={{display: "flex", alignItems: "center"}}>
                                    <Grid container spacing={GRID_BUTTONS_SPACING} sx={{alignItems: "center"}}>
                                        <Grid item>
                                            <FilterWithTag
                                                optionFilter
                                                options={monthsAll}
                                                setOptions={setMonths}
                                                previousValue={previousMonths}
                                            />
                                        </Grid>
                                        <Grid item sx={{marginRight: 2}}>
                                            <GrayButton
                                                onClick={() => handleClick()}
                                                title="filtrar"
                                                startIcon={<FilterListOutlinedIcon/>}
                                                testId="filtrar"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs sm sx={{display: "flex", alignItems: "center"}}>
                                    <Grid container spacing={GRID_BUTTONS_SPACING} sx={{justifyContent: "end"}}>
                                        <Grid item>
                                            <IconButton style={{ background: "#ECC94B", borderRadius: "4px" }} onClick={() => changeOpen()}>
                                                <LightbulbIcon style={{ color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px" }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Tour
                                    steps={steps}
                                    isOpen={open}
                                    onRequestClose={changeOpen}
                                    accentColor={DASH_BAR_COLOR}
                                    startAt={startAt}
                                    rounded={5}
                                    showNavigation={false}
                                    inViewThreshold={60}
                                    className={temaEscuro && "tour"}
                                    badgeContent={(curr, tot) => `${curr}/${tot}`}
                                />
                            </Grid>
                            {selectedTab == 0 && (
                                <ComprasDashboard
                                    lineCompras={lineCompras}
                                    treeMap={treeMap}
                                    seriesForn={seriesForn}
                                    dataDash={dataDash}
                                />
                            )}
                            {selectedTab == 1 && (
                                <ComprasTable
                                    columns={columns}
                                    tableData={tableData}
                                    initialColumns={initialColumns}
                                />
                            )}
                        </Paper>
                    )
            )
            }
        </div>
    );
}