import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GrayButton from "./GrayButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
    BLACK_TABLE_THEME,
} from "../../shared/utils";

export default function ExportMenu(props) {
    const {print, excel}= props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const fileIcon = useMemo(() => (
		<FileDownloadIcon/>
	), []);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return (
        <div>
            <GrayButton
                title="Exportar"
                startIcon={fileIcon}
                onClick={handleClick}
                testId="export-menu"
                className={props.className}
            />
            <Menu
            sx={{
                "& .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : {
                    background: temaEscuro && BLACK_TABLE_THEME
                }
            }}
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuItem
                    onClick={print}
                    data-testid="export-menu-pdf"
                >
                    PDF
                </MenuItem>
                <MenuItem
                    onClick={excel}
                    data-testid="export-menu-excel"
                >
                    Excel
                </MenuItem>
            </Menu>
        </div>
    );
}