import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GrayButton from "./GrayButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Refresh from "../Refresh/Refresh";
import { useNavigate } from "react-router-dom";
import {
    BLACK_TABLE_THEME,
} from "../../shared/utils";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";


export default function OptionsMenuComponent(props) {
    const {
        isAdmin,
        handleRefresh,
        monthsNotRevisados,
        setMonthsFiltered,
        monthsFiltered,
        disabled,
        changeStatePercent,
        openEmptyRows,
        setOpenEmptyRows,
        component,
    } = props;

    const nav = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [openDRE, setOpenDRE] = useState(false);

    const handleOpen = () => setOpenDRE(true);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const moreVertIcon = useMemo(() => (
		<MoreVertIcon/>
	), []);


    const handleClickHistoric = () => {
        nav("/historico_modificacoes");
    };

    const openRefresh = () => {
        setOpenDRE(true);
    };

    const handlePercentage = () => {
        changeStatePercent();
        setAnchorEl(null);
    };

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    return (
        <div>
            <GrayButton
                title="OPÇÕES"
                startIcon={moreVertIcon}
                onClick={handleClick}
                testId="options-menu"
                className={props.className}
            />
            <ClickAwayListener onClickAway={() => setOpenDRE(false)}>
                <Menu
                    onClick={() => {}}
                    sx={{
                        "& .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : {
                            background: temaEscuro && BLACK_TABLE_THEME
                        }
                    }}
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    {isAdmin && (
                        <MenuItem 
                            onClick={component !== "Consolidada" ? openRefresh : handleRefresh}
                            className="button_reload"
                            data-testid="refresh"
                        >
                            Recalcular
                        </MenuItem>
                    )}
                    {
                        component !== "Consolidada" && (
                            <Refresh
                                handleRefresh={handleRefresh}
                                monthOptions={monthsNotRevisados}
                                setMonthOptions={setMonthsFiltered}
                                monthsFiltered={monthsFiltered}
                                optionFilter
                                disabled={disabled}
                                handleOpen={handleOpen}
                                open={openDRE}
                                setOpen={setOpenDRE}
                            />
                        )
                    }
                    {
                        component === "DRE" && (
                            <MenuItem
                                onClick={handleClickHistoric}
                                data-testid="historic-dre"
                                className="button_historico"
                            >
                                Histórico
                            </MenuItem>
                        )
                    }
                    {
                        component === "Painel" && (
                            <MenuItem
                                onClick={() => setOpenEmptyRows(!openEmptyRows)}
                                data-testid="show-zeros-painel"
                                className="button_reveal"
                            >
                                Mostrar zeros
                            </MenuItem>
                        )
                    }
                    <MenuItem
                        onClick={handlePercentage}
                        data-testid="percentage-dre"
                        className="button_percentage"
                    >
                        Porcentagem
                    </MenuItem>
                </Menu>
            </ClickAwayListener>
        </div>
    );
}