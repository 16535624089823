import React  from "react";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {BLACK_TABLE, WHITE_TABLE, GRAY_STRONG_UX, styleDefaultText, BLUE_INFO_UX} from "../../shared/utils";
import Grid from "@mui/material/Grid";
import CircleIcon from "@mui/icons-material/Circle";


const TooltipThirteenthSalary = (props) => {
    let {row, column} = props;

	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(() => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: WHITE_TABLE,
			color: BLACK_TABLE,
			maxWidth: 420,
			border: "1px solid #dadde9",
		},
	}));

	const getHtmlTitle = () =>{
		return(
			<React.Fragment>
				<Grid container data-testid="tooltip-13">
					<Grid item sx={{display: "flex", alignItems: "center"}}>
						<CircleIcon className="circles" style={{ color: BLUE_INFO_UX, }}/>
					</Grid>
					<Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center"}}>
						<a style={{fontSize: "16px", color: GRAY_STRONG_UX, fontWeight: "700"}}>Provisão</a>
					</Grid>
					<Grid item xs={12}>
						<a>Provisão: 1/12</a>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return(
		<React.Fragment>
			{(row?.value.includes("(-) 13º") && column !== "periodo" && column !== "total" && !window.location.href.includes("dfc")) ? (
				<React.Fragment>
					<HtmlTooltip
						title={getHtmlTitle()}
						placement="left-start">
						{props.children}
					</HtmlTooltip>
				</React.Fragment>
			) : (
				props.children
			)}
		</React.Fragment>
	);
};

export default React.memo(TooltipThirteenthSalary);