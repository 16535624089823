import React from "react";
import Grid from "@mui/material/Grid";
import {GRAY_LABEL_UX, WHITE_ESCRITA_THEME} from "../../shared/utils";
import {useSelector} from "react-redux";
import searchStore from "../../assets/images/search_store.png";
import searchStoreDark from "../../assets/images/search_store_dark.png";


const SelectConsolidado = () => {
const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	return(
		<div style={styles.center}>
			<Grid container rowSpacing={5} sx={{alignItems: "center"}} data-testid="select-consolidada">
				<Grid item xs={12} sx={{paddingTop: "0 !important"}}>
				<img src={temaEscuro ? searchStoreDark : searchStore} />
				</Grid>
				<Grid item xs={12} >
					<span style={{...styles.text, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Selecione os filtros para visualizar os dados</span>
				</Grid>
			</Grid>
		</div>
	);
};

// Create styles
const styles = {
	center: {
		textAlign: "center",
		margin: "auto",
		width: "70%",
		// margin is 30px
		height: "calc(100vh - 60px)",
		display: "flex",
		alignItems: "center"
	},
	text: {
		color: GRAY_LABEL_UX,
		fontWeight: 600,
		fontSize: "24px",
		lineHeight: "32px"
	}
};

export default SelectConsolidado;