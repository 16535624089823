import React, {useState}  from "react";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import FormControl from "@mui/material/FormControl";
import {useSelector} from "react-redux";
import {
	GRAY_BG_UX, BLUE_THEME,
	GRAY_LABEL_UX,
	BORDER_BUTTON,
	COLOR_THEME,
	WHITE_ESCRITA_THEME,
	GRAY_HEADER_UX
} from "../../shared/utils";

const InputPassword = (props) => {
	const [showPassword, setShowPassword] = useState(false);

	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	return(
		<FormControl error={props.error} fullWidth variant="outlined">
			<InputLabel 
				htmlFor="outlined-adornment-password" 
				style={{color: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX, fontSize: props.matches ? "1rem" : "0.9rem"}}
			>
				{props.label}
			</InputLabel>
			<OutlinedInput
				data-testid="password-input"
				id="outlined-adornment-password"
				required
				type={showPassword ? "text" : "password"}
				value={props.password}
				onChange={(e) => props.handleChange(e)}
				style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_HEADER_UX}}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => setShowPassword(!showPassword)}
							edge="end"
							style={{color: temaEscuro ? BORDER_BUTTON : COLOR_THEME}}
						>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				}
				label="Password"
			/>
		</FormControl>
	);
};

export default InputPassword;