import React, {useState, useEffect} from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import api from "../../axios";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import EstabelecimentoModal from "../../components/Modal/EstabelecimentoModal";
import {
    GRAY_BORDER_UX,
    styleGrid,
    styleTitle,
    styleField,
    GRAY_STRONG_UX,
    GRAY_BORDER_IMPORT,
    PAPER_PADDING_THEME,
    GRAY_BG_UX,
    GRAY_LABEL_UX,
    textCompleteStyleBlack,
    textCompleteStyle,
    BLUE_THEME,
    BORDER_BUTTON,
    WHITE_TABLE,
    WHITE_THEME_BLACK,
    getLastMonthYear,
    getLastMonth, translateAutocomplete,
} from "../../shared/utils";
import GrayButton from "../../components/Buttons/GrayButton";
import YellowButton from "../../components/Buttons/YellowButton";
import FilterYear from "../../components/Filter/FilterYear";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import PathName from "../../components/Titles/PathName";
import {useDispatch, useSelector} from "react-redux";
import {showSnackMessage} from "../../actions/SnackActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";


export default function UploadFile(){
    const dispatch = useDispatch();

    const [inputKey, setInputKey] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSelected, setIsSelected] = useState(false);
    const hiddenFileInput = React.useRef(null);
    const [loja, setLoja] = useState(null);
    const [plataformas, setPlataformas] = useState([]);
    const [plataforma, setPlataforma] = useState();
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const [estabelecimento, setEstabelecimento] = useState();
    const [ano, setAno] = useState(getLastMonthYear());
    const [mes, setMes] = useState(getLastMonth());
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const lojas = useSelector(state => state.StoreReducer.lojas);

    const getPlataformas = () => {
        api.GetPlataformas().then(response => {
            let data = response.data;
            setPlataformas(data);
        }).catch(() => {
        });
    };

    useEffect(() => {
        getPlataformas();
    }, []);

    const stylePaper = {
        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
        color: temaEscuro ? "#FFF" : "#4A5568",
        fontWeight: "500"
    };

    const changeHandler = (event) => {
        let file = event.target.files[0];
        if(file?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file?.type === "text/csv"){
            setSelectedFile(file);
            setIsSelected(true);
        }
        else{
            dispatch(showSnackMessage({message: "Tipo de arquivo inválido", severity: "error"}));
        }
    };

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleSubmission = (novoNumero) => {
        const formData = new FormData();
        if(selectedFile == null){
            dispatch(showSnackMessage({ message: "Anexe o arquivo!", severity: "error" }));
            return;
        }
        setLoading(true);
        formData.append("loja_id", loja.value);
        formData.append("plataforma_id", plataforma.value);
        formData.append("mes", mes);
        formData.append("ano", ano);
        formData.append("numero", novoNumero != null ? novoNumero : 1);
        formData.append("file_name", selectedFile.name);
        formData.append("selected_file", selectedFile);
        formData.append("estabelecimento", estabelecimento);
        api.SendUploadFile(loja.value, formData).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Arquivo importado com sucesso" }));
            setEstabelecimento(null);
            setSelectedFile(null);
            setInputKey(Math.random().toString(36));
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const handleMonthYear = (ano, mes) => {
        setAno(ano);
        setMes(mes);
    };

    const openEstabelecimento = () => {
        setOpenModal(true);
    };


    return(
        <div className="main">
            <React.Fragment>
                <EstabelecimentoModal loja={loja} estabelecimento={estabelecimento} setEstabelecimento={setEstabelecimento} ano={ano} mes={mes} plataforma={plataforma} open={openModal} setOpen={setOpenModal} handleSubmission={handleSubmission}/>
                <Grid container>
                    <Paper sx={{...styleGrid, width: "100%", backgroundColor: temaEscuro ? PAPER_PADDING_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none"}}>
                        <Grid sx={styleField}>
                            <span style={{...styleTitle, color: temaEscuro ? GRAY_BG_UX : GRAY_LABEL_UX}}>Importar arquivo</span>
                        </Grid>
                        <Grid container>
                            <Grid item sx={{...styleField, marginTop: -2}}>
                                <PathName firstPath="Resolução" secondPath="Importar Arquivo"/>
                            </Grid>
                        </Grid>
                        <Grid item sx={{...styleField, backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE}}>
                            <FormControl fullWidth sx={{ ...styles.formControl, "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: temaEscuro ? BORDER_BUTTON : GRAY_BORDER_UX }}}}>
                                <Autocomplete
                                    size="small"
                                    data-testid="select-store"
                                    options={lojas}
                                    noOptionsText={translateAutocomplete}
                                    slotProps={{
                                        paper: {style: stylePaper}
                                    }}
                                    style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                    value={loja || null}
                                    onChange={(event, newValue) => setLoja(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Loja"
                                            sx={temaEscuro ? textCompleteStyleBlack : textCompleteStyle}
                                            InputLabelProps={{
                                                style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON},
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sx={{...styleField, backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE}}>
                            <FormControl fullWidth sx={{ ...styles.formControl, "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: temaEscuro ? BORDER_BUTTON : GRAY_BORDER_UX }}}}>
                                <Autocomplete
                                    size="small"
                                    data-testid="select-platform"
                                    options={plataformas}
                                    slotProps={{
                                        paper: {style: stylePaper}
                                    }}
                                    style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                    value={plataforma}
                                    onChange={(event, newValue) => setPlataforma(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Plataforma"
                                            sx={temaEscuro ? textCompleteStyleBlack : textCompleteStyle}
                                            InputLabelProps={{
                                                style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON},
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sx={{...styleField, width: "1870px"}}>
                            <FilterYear
                                style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                handleSubmit={handleMonthYear}
                                prevYear={ano}
                                prevMonth={mes}
                            />
                        </Grid>
                        <Grid item sx={styleField}>
                            <GrayButton
                                title="Anexar arquivo"
                                onClick={handleClick}
                                startIcon={<AttachFileOutlinedIcon/>}
                            />
                        </Grid>
                        <input
                            type="file"
                            data-testid="attach-file"
                            accept=".xlsx, .csv"
                            key={inputKey}
                            ref={hiddenFileInput}
                            onChange={changeHandler}
                            style={{display: "none"}}
                        />
                        {isSelected &&
                            (
                                <Grid data-testid="file-name" style={{...styles.styleFieldImport, color: temaEscuro && WHITE_THEME_BLACK}}>
                                    <span>Nome do arquivo: {selectedFile?.name}</span>
                                </Grid>
                            )
                        }
                        <Grid container sx={{display: "flex", justifyContent: "end"}}>
                            <Grid item sx={styleGrid}>
                                <GrayButton
                                    isRedirect
                                    testId="cancel-upload-file"
                                    path="/resolucao"
                                    title="Cancelar"/>
                            </Grid>
                            <Grid item sx={styleGrid}>
                                {
                                    loading ? (
                                        <LoadingButton variant="outlined" loading >
                                            Enviar Arquivo
                                        </LoadingButton>
                                    ) : (
                                        <YellowButton
                                            onClick={openEstabelecimento}
                                            testId="button-enviar-arquivo"
                                            title="Enviar Arquivo"/>
                                    )
                                }

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </React.Fragment>
        </div>
    );
}
const styles = {
    main: {
        marginBottom: "0px"
    },
    modal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        height: "auto",
        bgcolor: "background.paper",
        padding: "20px",
    },

    styleButtonImport : {
        color: GRAY_STRONG_UX,
        backgroundColor: "transparent",
        width: "300px",
        border: `1px solid ${GRAY_BORDER_IMPORT}`,
        borderRadius: "5px",
    },

    styleCheckbox: {
        color: GRAY_BORDER_IMPORT,
        marginLeft: -1
    },

    styleFieldImport: {
        color : GRAY_STRONG_UX,
        marginLeft: 15
    },

    formControl: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: GRAY_BORDER_UX
            },
        }
    }
};