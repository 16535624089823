import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./GenericModal.css";
import {
    BLUE_THEME, smallPaperStyle,
} from "../../shared/utils";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";
import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import Skeleton from "@mui/material/Skeleton";


const AvaliacaoModal = (props) => {
	const {
        openModal,
        setOpenModal,
        sendDRERate,
        avaliacoesPendentes,
        storeName,
        loadingAvaliacaoModal
    } = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const name = useSelector(state => state.AuthReducer.name);
    const storeId = useSelector(state => state.StoreReducer.storeId);
    const [avaliacao, setAvaliacao] = useState(0);
    const [comentario, setComentario] = useState("");

    const sendDRERateData = (avaliacaoPendente) => {
        const dataRequest = {
            "loja_id": storeId,
            "comentario": comentario,
            "competencia": avaliacaoPendente,
            "avaliacao": avaliacao
        };

        sendDRERate(dataRequest);
    };

	return(
		<React.Fragment>
            {avaliacoesPendentes.map((avaliacaoPendente) => {
                return (
                    <Modal
                        key={avaliacaoPendente}
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        data-testid="avaliacao-modal"
                    >
                        <Paper style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, width: "fit-content", padding: loadingAvaliacaoModal ? 0 : "2rem"}}>
                            {loadingAvaliacaoModal ? (
                                <Skeleton variant="rounded" height={405} width={834}/>
                            ) : (
                                <React.Fragment>
                                    <Box sx={{textAlign: "center", fontSize: "24px", fontWeight: "bold"}}>
                                        Avalie sua DRE
                                    </Box>
                                    <Grid container sx={{display: "flex", flexDirection: "column", gap: 2, marginTop: "1rem"}}>
                                        <Box>
                                            Olá, {name}!
                                        </Box>
                                        <Box>
                                            Você já verificou a DRE da loja {<strong>{storeName}</strong>} referente à competência {<strong>{avaliacaoPendente}</strong>}?
                                        </Box>
                                        <Box>
                                            Sua opinião é muito importante para nós. Por favor, avalie sua satisfação utilizando o sistema de 1 a 5 estrelas, onde 1 estrela indica total insatisfação e 5 estrelas indica total satisfação. Se possível, justifique sua resposta para nos ajudar a melhorar.
                                        </Box>
                                        <Rating
                                            size="large"
                                            value={avaliacao}
                                            onChange={(event, newValue) => {
                                                if (newValue == null) {
                                                    setAvaliacao(1);
                                                }
                                                else {
                                                    setAvaliacao(newValue);
                                                }
                                            }}
                                        />
                                        <TextField
                                            label = "Insira um comentário sobre a DRE recebida, caso queira."
                                            onChange={(event) => {
                                                setComentario(event.target.value);
                                            }}
                                            multiline
                                            rows={4}
                                        />
                                        <Grid sx={{gap: 2, display: "flex", justifyContent: "end", marginTop: "1rem"}}>
                                            <GrayButton
                                                title="Agora não"
                                                onClick={() => setOpenModal(false)}
                                            />
                                            <YellowButton
                                                title="Enviar avaliação"
                                                onClick={() => sendDRERateData(avaliacaoPendente)}
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Paper>
                    </Modal>
                );
            })}
		</React.Fragment>
	);
};

export default AvaliacaoModal;