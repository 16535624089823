import React, {useState, useEffect} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import {Button} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import api from "../../axios";
import Skeleton from "@mui/material/Skeleton";
import SelectStore from "../../components/SelectStore/SelectStore";
import {GRAY_BORDER_UX,
	paperStyle,
	GRAY_LABEL_UX,
	WHITE_THEME_BLACK,
	PAPER_PADDING_THEME,
	BORDER_TABLE,
	DASH_BAR_COLOR,
	BLUE_THEME,
	styleButton,
	BORDER_BUTTON} from "../../shared/utils";
import FilterWithTag from "../../components/Filter/FilterWithTag";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import GrayButton from "../../components/Buttons/GrayButton";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import Tour from "reactour";
import { steps } from "./TourBaseDados";
import "moment/locale/pt-br";
import LastModified from "../../components/LastModified/LastModified";
import {showSnackMessage} from "../../actions/SnackActions";

export default function BaseDados() {
	const [loading, setLoading] = useState(false);
	const [plataformas, setPlataformas] = useState([]);
	const [months, setMonths] = useState([]);
	const [monthsSelected, setMonthsSelected] = useState([]);
	const [open, setOpen] =  useState(false);
	const [startAt, setStartAt] = useState(0);
	const dispatch = useDispatch();

	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const storeId = useSelector(state => state.StoreReducer.storeId);

	const monthsFormated = monthsSelected.map((month) => month = {value:month, label:month});

	useEffect(() => {
		if (storeId !== 0) {
			getMonthsList();
		}
	}, [storeId]);

	const getMonthsList = () => {
		setLoading(true);
		let dataRequest = {
			lojas: [storeId]
		};

		api.GetMesesCompetencia(dataRequest).then((request) => {
			let dataResponse = request.data;
			setMonths(dataResponse);
			setLoading(false);
		}).catch(() => { 
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	};

	const getPlataforma = () => {
		setLoading(true);

		const dataRequest = {
			loja_id: storeId,
			dates: monthsSelected,
		};

		api.GetPlataformasDownloads(dataRequest).then(response => {
			setLoading(false);
			let dataResponse = response.data;
			setPlataformas(dataResponse);
		}).catch(() => {
			setLoading(false);
		});
	};

	const getDownloadLinks = (item = null) => {
		setLoading(true);
		const dataRequest = {
			loja_id: storeId,
			dates: item ? [item.data] : monthsSelected,
			plataforma: item ? item.plataforma : undefined,
		};
		api.GetDownloadLinks(dataRequest).then(response => {
			setLoading(false);
			if (item === null) {
				dispatch(showSnackMessage({message: "Seu arquivo está sendo gerado e será enviado para o seu email. Por favor, verifique sua caixa de entrada em alguns minutos.", severity: "success"}));
			} else {
				let url = response.data.url;
				window.open(url, "_blank");
			}
		}).catch(() => {
			setLoading(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	};

	const changeOpen = () => {
		setStartAt(0);
		setOpen(!open);
	};

	return (
		<div className="main" data-testid="base-dados">
			<LastModified title="Base de Dados"/>
			<Paper sx={{...paperStyle,backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}}>

				{loading ? (
					<div style={{display: "flex", flexDirection: "column"}}>
						<Grid container>
							<Grid item xs={5}>
								<Grid container spacing={2}>
									{
										[...Array(2).keys()].map((i,d) =>{
											return(
												<Grid item key={d}>
													<Skeleton height={75} width={110} />
												</Grid>
											);
										})
									}
								</Grid>
							</Grid>
							<Grid item xs={7} sx={{display: "flex", alignItems: "center"}}>
								<Grid container spacing={2} sx={{display: "flex", justifyContent: "end"}}>
									{
										[...Array(3).keys()].map((i,d) =>{
											return(
												<Grid item key={d}>
													<Skeleton height={75} width={110} />
												</Grid>
											);
										})
									}

								</Grid>
							</Grid>
						</Grid>
						{
							[...Array(15).keys()].map((i,d) =>{
								return(
									<Skeleton height={50} key={d}/>
								);
							})
						}
					</div>
				) : (
					storeId === 0 ?
						(
							<SelectStore/>
						) : (
							<React.Fragment>
								
								<Grid container sx={{display: "flex", justifyContent: "space-between"}}>
									<Grid item sx={{display: "flex", alignItems: "center", marginBottom: 2, width: "350px"}}>
										<FilterWithTag
											testId="filter-base-dados"
											setOptions={setMonthsSelected}
											optionFilter
											limitSelection={3}
											placeholderProp="Selecione os Meses"
											options={months}
											previousValue={monthsFormated}
										/>
										{monthsSelected.length !== 0 && (
											<Grid item xs={2} sx={{marginLeft: 2}}>
												<Button data-testid="btn-filtrar" className="filtro" variant="contained" onClick={getPlataforma}
														style={{...styleButton, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, borderColor: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX}}>
													<FilterListOutlinedIcon/>
													Filtrar
												</Button>
											</Grid>
										)}
									</Grid>
									<Grid item sx={{display: "flex", justifyContent: "end", alignItems: "start", gap: 2}} >
										<GrayButton
											onClick={() => getDownloadLinks()}
											title="exportar"
											testId="exportar"
										/>
										<IconButton
											style={{background: "#ECC94B", borderRadius: "4px"}}
											onClick={() => changeOpen()}
											data-testid="tutorial-basedados"
										>
											<LightbulbIcon style={{color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px"}}/>
										</IconButton>
									</Grid>
								</Grid>
								<Tour
									steps={steps}
									isOpen={open}
									onRequestClose={changeOpen}
									accentColor={DASH_BAR_COLOR}
									startAt={startAt}
									rounded={5}
									showNavigation={false}
									inViewThreshold={60}
									className={temaEscuro && "tour"}
									badgeContent={(curr, tot) => `${curr}/${tot}`}
								/>
								<Grid container spacing={3}>
									<Grid item xs={12} md={12} lg={12}>
										<Grid item sx={{borderRadius: 2, border: 0.5, borderColor: temaEscuro ? BORDER_TABLE : GRAY_BORDER_UX, backgroundColor:temaEscuro && BLUE_THEME}}>
											<Grid item sx={{marginTop: 2, marginLeft: 2, marginRight: 2}}>
												<TableContainer>
													<Table>
														<TableHead>
															<TableRow>
																<TableCell sx={{borderRight: "1px solid", borderRightColor: "divider", fontWeight: "bold", width: 80, height: 20}}>
																	Período
																</TableCell>
																<TableCell sx={{ fontWeight: "bold", paddingLeft: "36px" }}>
																	Conta
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{
																plataformas.map((item, index) =>{
																	return (
																		<TableRow data-testid={`tablerow-${index}`} key={index} sx={{ borderTop: "1px solid", borderColor: "divider"}}>
																			<TableCell sx={{borderRight: "1px solid", borderRightColor: "divider"}}>
																				{item.data.split("/", 1) <= 9 ? ("0"+item.data) : (item.data)}
																			</TableCell>
																			<TableCell>
																				<ListItemButton onClick={() => getDownloadLinks(item)}>
																					<ListItemIcon>
																						<FileUploadOutlinedIcon sx={{color:temaEscuro && WHITE_THEME_BLACK}}/>
																					</ListItemIcon>
																					<ListItemText primary={item.plataforma} disableTypography sx={{color: temaEscuro && WHITE_THEME_BLACK}}/>
																				</ListItemButton>
																			</TableCell>
																		</TableRow>
																	);
																})
															}
															<TableRow>

															</TableRow>
														</TableBody>
													</Table>
												</TableContainer>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</React.Fragment>
						)
				)}
			</Paper>
		</div>
	);
}
