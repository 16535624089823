export const steps = [
    {
        selector: ".filter-estabelecimentos",
        content: "Filtra o(s) estabelecimento(s) apresentado(s) na conciliação."
    },
    {
        selector: ".filter-date",
        content: "Filtra os meses que serão apresentados na conciliação."
    },
    {
        selector: ".enviar-conciliacao-btn",
        content: "Para enviar uma conciliação via whatsapp."
    },
    {
        selector: ".plataformas-tabs",
        content: "Mostra as plataformas disponíveis para a conciliação."
    },
    {
        selector: ".all-conciliacao-accordions",
        content: "Mostra as conciliações separadas por mês. Para inserir comentários ou fixar valores, clique 2 vezes na célula."
    },
];