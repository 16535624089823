import React from "react";
import {
    PURPLE_INFO_UX,
    styleDefaultText,
    avatarStyle,
    smallPaperStyle,
    GRAY_STRONG_UX,
    BLUE_THEME, WHITE_THEME_BLACK, imagePerfilSmall
} from "../../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CircleIcon from "@mui/icons-material/Circle";
import Avatar from "@mui/material/Avatar";
import YellowButton from "../../Buttons/YellowButton";
import DottedMenu from "./DottedMenu";
import {useSelector} from "react-redux";


const VisualizeComment = (props) => {
    const {openViewCommentModal, setOpenViewCommentModal, commentsList, setOpenInsertCommentModal, setComment, setIsEdit, setCommentId, delMetadata, component}= props;
    const name = useSelector(state => state.AuthReducer.name);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const initals = (name) => {
        let nameSplit = name.split(" ").map((word) => word[0]).join("");
        return nameSplit;
    };

    const initalName = (name) => {
        let nameSplit = name.split(" ");
        return nameSplit[0];
    };

    return(
        <React.Fragment>
            <Modal
                open={openViewCommentModal}
                onClose={() => setOpenViewCommentModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="modal-visualize-comment"
            >
                <Paper className="modal" style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME}}>
                    <Grid container spacing={2} sx={{padding: 1}}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item sx={{display: "flex", alignItems: "center"}}>
                                    <CircleIcon className="circles" style={{ color: PURPLE_INFO_UX, }}/>
                                </Grid>
                                <Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center"}}>
                                    <p style={{...styles.header, color: temaEscuro && WHITE_THEME_BLACK}}>Comentário</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                commentsList.map((item, index) => {
                                    return(
                                        <React.Fragment key={index}>
                                            <Grid container sx={styles.comment} data-testid="visualize-comment-grid">
                                                <Grid item xs={5}>
                                                    <Grid container spacing={1}>
                                                        <Grid item sx={{display: "flex", alignItems: "center"}}>
                                                            <Avatar sx={{...avatarStyle, height: 24, width: 24, fontSize: 10}}>
                                                                {
                                                                    item.image_perfil ? (
                                                                        <img src={item.image_perfil} alt="image" style={{...imagePerfilSmall}}/>
                                                                    ) : (
                                                                        <React.Fragment>
                                                                            {initals(item.user_comment)}
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            </Avatar>
                                                        </Grid>
                                                        <Grid item>
                                                            <h3 color="inherit">
                                                                {initalName(item.user_comment)}:
                                                            </h3>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={7} sx={{display: "flex", justifyContent: "end"}}>
                                                    {
                                                        item.user_comment === name && (
                                                            <DottedMenu
                                                                comment={item.comment}
                                                                commentId={item.id}
                                                                setOpenViewCommentModal={setOpenViewCommentModal}
                                                                setOpenInsertCommentModal={setOpenInsertCommentModal}
                                                                setComment={setComment}
                                                                setIsEdit={setIsEdit}
                                                                setCommentId={setCommentId}
                                                                delMetadata={delMetadata}
                                                                component={component}
                                                            />
                                                        )
                                                    }
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <a dangerouslySetInnerHTML={{__html: item.comment != null ? item.comment.replaceAll("\n","<br>") : ""}}></a>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })
                            }
                            <Grid item>
                                <Grid container sx={{display: "flex", justifyContent: "flex-end"}}>
                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end"}}>
                                        <YellowButton
                                            title="Responder"
                                            testId="visualize-comment-reply"
                                            onClick={() => {setOpenInsertCommentModal(true); setOpenViewCommentModal(false);}}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default React.memo(VisualizeComment);

const styles = {
    comment: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
    },
    header: {
        color: GRAY_STRONG_UX,
        fontSize: "24px",
        fontWeight: "bold",
        lineHeight: 1,
        marginBlock: 0,
    }
};