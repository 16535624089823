import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Cookies from "js-cookie";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { RouterProvider } from "react-router-dom";
import SnackBar from "./components/SnackBar/SnackBar";
import { LicenseInfo } from "@mui/x-license-pro";
import {getRoutes} from "./Routes";
import ReactGA from "react-ga4";
import Notification from "./components/Notification/Notification";
import {
	changeIsDesktop
} from "./actions/AuthActions";

// eslint-disable-next-line no-undef
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

try{
	// eslint-disable-next-line no-undef
	ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}catch (e){
	// eslint-disable-next-line no-console
	console.error(`not able to initialize ReactGA ${e}`);
}

export default function App(){
	const dispatch = useDispatch();
	const token = Cookies.get("tk");
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
	const resetarSenha = useSelector(state => state.AuthReducer.resetarSenha);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const isCommonUser = useSelector(state => state.AuthReducer.isCommonUser);
	const isOperator = useSelector(state => state.AuthReducer.isOperator);
	const isManager = useSelector(state => state.AuthReducer.isManager);

	const theme = createTheme({
		palette:{
			mode: temaEscuro ? "dark" : "light"
		},
		typography: {
			allVariants: {
				fontFamily: "Inter, sans-serif",
				color: temaEscuro ? "#FFF" : "#4A5568",
				fontWeight: "500"
			},
		},
	});

	const handleResize = () => {
		dispatch(changeIsDesktop(window.innerWidth > 900));
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<ThemeProvider theme={theme}>
				<Notification />
				<SnackBar>
					<RouterProvider router={getRoutes(dispatch, token, isAdmin, resetarSenha, isCommonUser, isOperator, isManager)}/>
				</SnackBar>
			</ThemeProvider>
		</LocalizationProvider>
	);
}
