import React from "react";
import {
	BLUE_THEME,
	GRAY_STRONG_UX,
	PAPER_PADDING_THEME, textCompleteStyle, textCompleteStyleBlack,
	WHITE_ESCRITA_THEME,
	WHITE_THEME_BLACK, BORDER_BUTTON, translateAutocomplete, GRAY_BG_UX
} from "../../../shared/utils";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import GrayButton from "../../Buttons/GrayButton";
import YellowButton from "../../Buttons/YellowButton";
import {useSelector} from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const CopyPlano = (props) => {
	const {loadingModal, handleCopy, setOpenModalCopy, openModalCopy, testId} = props;
	const [loja, setLoja] = React.useState("");
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const lojas = useSelector(state => state.StoreReducer.lojas);

	return(
		<React.Fragment>
			<Modal
				open={openModalCopy}
				onClose={() => setOpenModalCopy(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Paper className="modal" style={{width: "30%", height: "30%", marginTop: "7%", backgroundColor: temaEscuro && PAPER_PADDING_THEME}} data-testid={testId}>
					<Grid container spacing={3} sx={{overflowY: "hidden", alignItems: "left", display: "flex", flexDirection: "column"}}>
						<Grid item xs={12} sx={{display: "flex", flexDirection: "column", marginLeft: "30px"}}>
							<h3
								className="info-text"
								style={{color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontWeight: 700, fontSize: "18px", display: "flex", alignItems: "center"}}
							>
								Copiar plano de contas de outra loja:
							</h3>
							<h3
								style={{color: temaEscuro ? WHITE_THEME_BLACK: GRAY_STRONG_UX, fontWeight: 400, fontSize: "16px", display: "flex", alignItems: "center"}}
							>
								Selecione a loja que deseja copiar
							</h3>
							<FormControl fullWidth>
								<Autocomplete
									size="small"
									data-testid="select-store"
									options={lojas}
									noOptionsText={translateAutocomplete}
									slotProps={{
										paper: {
											style: {
												backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
												color: temaEscuro ? "#FFF" : "#4A5568",
												fontWeight: "500"
											}
										}
									}}
									style={{backgroundColor: temaEscuro && BLUE_THEME}}
									value={loja || null}
									onChange={(event, newValue) => setLoja(newValue)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Loja"
											sx={temaEscuro ? textCompleteStyleBlack : textCompleteStyle}
											InputLabelProps={{
												style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON},
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} sx={{justifyContent: "end", display: "flex", marginLeft: "30px", marginRight: "26px"}}>
							<Grid container spacing={2} sx={{justifyContent: "end", display: "flex"}}>
								<Grid item>
									{
										loadingModal ? (
											<LoadingButton variant="outlined" loading >
												Cancelar
											</LoadingButton>
										) : (
											<GrayButton title="Cancelar" onClick={()=> setOpenModalCopy(false)} testId={"button-cancel"}/>
										)
									}
								</Grid>
								<Grid item>
									{
										loadingModal ? (
											<LoadingButton variant="outlined" loading >
												Copiar
											</LoadingButton>
										) : (
											<YellowButton title="Copiar" onClick={()=> handleCopy(loja.value)} testId={"button-copy"}/>
										)
									}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Modal>
		</React.Fragment>

	);
};

export default CopyPlano;