import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Header from "../../components/Titles/Header";
import { useDispatch, useSelector } from "react-redux";
import api from "../../axios";
import KPI from "../../components/Dash/KPI";
import {
    paperStyle,
    PAPER_PADDING_THEME,
    LINE_TABLE,
    styleField,
    styleTextStrong,
    WHITE_ESCRITA_THEME,
    getLastMonth,
    getLastMonthYear,
} from "../../shared/utils";
import Paper from "@mui/material/Paper";
import PathName from "../../components/Titles/PathName";
import {showSnackMessage} from "../../actions/SnackActions";
import SelectStore from "../../components/SelectStore/SelectStore";
import Skeleton from "@mui/material/Skeleton";
import FilterYear from "../../components/Filter/FilterYear";


export default function Insights() {
    const dispatch = useDispatch();

	const storeId = useSelector(state => state.StoreReducer.storeId);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const [loading, setLoading] = useState(false);
    const [ano, setAno] = useState(getLastMonthYear());
	const [mes, setMes] = useState(getLastMonth());

    const [fatBrutoTotalSegmento, setFatBrutoTotalSegmento] = useState(null);
    const [fatBrutoTotalGeral, setFatBrutoTotalGeral] = useState(null);
    const [cmvSegmento, setCmvSegmento] = useState(null);
    const [cmoSegmento, setCmoSegmento] = useState(null);
    const [pontoEquilibrioSegmento, setPontoEquilibrioSegmento] = useState(null);

    useEffect(() => {
        if (storeId !== 0) {
            getInsightsMercado();
        }
    }, [storeId]);

    const handleMonthYear = useCallback((ano, mes) => {
		getInsightsMercado(ano, mes);
		setAno(ano);
		setMes(mes);
	}, [storeId, ano, mes]);

    const getInsightsMercado = (ano, mes) => {
        setLoading(true);
        const dataRequest = {
            loja_id: storeId,
            year: ano,
            month: mes
        };
        api.GetInsightsMercado(dataRequest).then(response => {
            let data = response.data;
            let insightsData = data.insights;

            setFatBrutoTotalSegmento(insightsData.fat_bruto_total_segmento_kpi);
            setFatBrutoTotalGeral(insightsData.fat_bruto_total_all_kpi);
            setCmvSegmento(insightsData.cmv_segmento_kpi);
            setCmoSegmento(insightsData.cmo_segmento_kpi);
            setPontoEquilibrioSegmento(insightsData.ponto_equilibrio_segmento_kpi);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    return(
        <div className="main">
            <Header title="Insights" />
            {loading ? (
				<Grid container spacing={2} rowSpacing={4}>
					{[...Array(1).keys()].map((i, d) => {
						return (
							<Grid item xs={4} key={d}>
								<Skeleton variant="rectangular" height={50} />
							</Grid>
						);
					})}
					<Grid item xs={8} />
					{[...Array(3).keys()].map((i, d) => {
						return (
							<Grid item xs={4} key={d}>
								<Skeleton variant="rectangular" height={150} />
							</Grid>
						);
					})}
					{[...Array(3).keys()].map((i, d) => {
						return (
							<Grid item xs={4} key={d}>
								<Skeleton variant="rectangular" height={150} />
							</Grid>
						);
					})}
                    {[...Array(2).keys()].map((i, d) => {
						return (
							<Grid item xs={4} key={d}>
								<Skeleton variant="rectangular" height={150} />
							</Grid>
						);
					})}
				</Grid>
			) : (
                storeId === 0 ?
                (
                    <SelectStore/>
                ) : (
                    <Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, color: temaEscuro && LINE_TABLE, backgroundImage: temaEscuro && "none"}}>
                        <Grid container spacing={1} data-testid="insights">
                            <Grid item xs={12} sx={{...styleField, marginLeft: 0}}>
                                <PathName firstPath="Resumo" secondPath="Insights"/>
                            </Grid>
                            <Grid item xs={12}>
                                <h3 style={{...styleTextStrong, marginTop: 0, fontSize: "22px", color: temaEscuro && WHITE_ESCRITA_THEME}}>Informações de mercado referente ao mês:</h3>
                            </Grid>
                            <Grid item xs={5}>
                                <FilterYear
                                    handleSubmit={handleMonthYear}
                                    prevYear={ano}
                                    prevMonth={mes}
                                    optionFilter={true}
                                    blockCurrentMonth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <KPI title="Variação média do faturamento global" testId="faturamento-global-kpi" data={fatBrutoTotalGeral?.data} footerPercData={fatBrutoTotalGeral?.percData}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <KPI title="Variação média do faturamento do segmento" testId="faturamento-segmento-kpi" data={fatBrutoTotalSegmento?.data} footerPercData={fatBrutoTotalSegmento?.percData} />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <KPI title="Ponto de equilíbrio do segmento" testId="ponto-equilibrio-segmento-kpi" data={pontoEquilibrioSegmento?.data}/>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <KPI title="CMV médio do segmento" testId="cmv-segmento-kpi" data={cmvSegmento?.data} suffix="%"/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <KPI title="CMO médio do segmento " testId="cmo-segmento-kpi" data={cmoSegmento?.data} suffix="%"/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                )
            )
        }
        </div>
    );
}