import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import api from "../../axios";
import FormControl from "@mui/material/FormControl";
import YellowButton from "../../components/Buttons/YellowButton";
import InputLabel from "@mui/material/InputLabel";
import {showSnackMessage} from "../../actions/SnackActions";
import GrayButton from "../../components/Buttons/GrayButton";
import {
	PAPER_PADDING_THEME,
	styleGrid,
	styleInput,
	styleField,
	GRAY_LABEL_UX,
	BLUE_THEME,
	GRAY_BG_UX,
	textCompleteStyleBlack,
	textCompleteStyle,
	WHITE_TABLE,
	LINE_TABLE,
	BORDER_BUTTON
} from "../../shared/utils";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";


function EstabelecimentoModal(props){
	const {plataforma, testId, open, estabelecimento, setEstabelecimento, setOpen, loja, handleSubmission, mes, ano} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const [loading, setLoading] = useState(false);
	const [estabelecimentos, setEstabelecimentos] = useState([]);
	const [novoNumero, setNovoNumero] = useState();
	const [existRelatorio, setExistRelatorio] = useState(false);
	const dispatch = useDispatch();


	const getEstabelecimentos = () => {
		const dataRequest = {
			loja_id: loja.value,
		};
		api.GetEstabelecimentos(dataRequest).then(response => {
			let data = response.data;
			setEstabelecimentos(data);
		}).catch(() => {
		});
	};
	useEffect(() => {
		if (open){
			getEstabelecimentos();
		}
	},[open]);


	const handleClose = () => setOpen(false);
	const onChange = (event) => setEstabelecimento(event.target.value);

	const handleAdicionarNovo = () => {
		handleSubmission(novoNumero);
		setExistRelatorio(false);
		handleClose();
	};

	const handleReplace = () => {
		handleSubmission();
		setExistRelatorio(false);
		handleClose();
	};

	const handleData = () => {
		setLoading(true);
		const dataRequest = {
			loja_id: loja.value,
			plataforma: plataforma.value,
			mes,
			ano,
			estabelecimento,
		};
		let data = {
			...dataRequest,
			"estabelecimento": estabelecimento,
		};
		api.CheckUploadFile(loja.value, data).then(() => {
			handleSubmission();
			setExistRelatorio(false);
			setLoading(false);
			handleClose();
		}).catch((error) => {
			dispatch(showSnackMessage({ message: "Esse relatório já existe", severity: "error" }));
			setLoading(false);
			setExistRelatorio(true);
			setNovoNumero(error.response.data.numero);
		});
	};


	return (
		<React.Fragment>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Paper style={{...styles.modal, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none", width: existRelatorio ? "fit-content" : "25vw"}} data-testid={testId}>
					<h3 className ="info-text" style={{color:temaEscuro ? LINE_TABLE : GRAY_LABEL_UX}} >Selecionar Estabelecimento</h3>
					<Grid item xs={4} sx={{...styleField, backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}>
					</Grid>
					{!existRelatorio && (
						<Grid item xs={4} sx={{...styleField, backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, width: 1, marginLeft: 0}}>
							<FormControl fullWidth sx={{...styles.formControl}}>
								<InputLabel id="demo-simple-select-label" style={{...styleInput, color: temaEscuro && BORDER_BUTTON}}>Estabelecimentos</InputLabel>

								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									fullWidth
									value={estabelecimento}
									onChange={onChange}
									data-testid="select-estabelecimento"
									sx={{color: temaEscuro ? textCompleteStyleBlack : textCompleteStyle}}
									inputProps={{MenuProps: {
											MenuListProps: {
												sx:{backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE, color: temaEscuro ? textCompleteStyleBlack : textCompleteStyle}
											}
										}}}
								>
									{estabelecimentos.map((item) => (
										<MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					)}
					<Grid item xs={4} sx={{ ...styleField}}>
					</Grid>

					{existRelatorio && (
						<Grid item data-testid="relatorio-exists">
							<p>Este relatório já existe para o estabelecimento {estabelecimento}. <br/>Gostaria de: </p>
						</Grid>
					)}

					<Grid container sx={{ display: "flex", justifyContent: "flex-end" }} data-testid="div-select-estabelecimento" >
						{existRelatorio && (
							<React.Fragment>
								<Grid item sx={styleGrid}>
									{
										loading ? (
											<LoadingButton variant="outlined" loading >
												Substituir
											</LoadingButton>
										) : (
											<GrayButton
												onClick={handleReplace}
												style={{ backgroundColor: "#00ff00" }}
												title="Substituir"
												testId="button-substituir-dados"
											/>
										)
									}
								</Grid>
								<Grid item sx={styleGrid}>
									{
										loading ? (
											<LoadingButton variant="outlined" loading >
												Adicionar novo
											</LoadingButton>
										) : (
											<GrayButton
												onClick={handleAdicionarNovo}
												title="Adicionar novo"
												testId="button-adicionar-novo"
											/>
										)
									}
								</Grid>
							</React.Fragment>
						)}
						<Grid item sx={styleGrid}>
							<GrayButton
								onClick={handleClose}
								title="Cancelar"
								testId="button-cancelar-dados"
							/>
						</Grid>
						{
							!existRelatorio && (
								<Grid item sx={styleGrid}>
									<YellowButton
										onClick={() => {
											handleData();
										}}
										title="Enviar Dados"
										testId="button-enviar-dados"
									/>
								</Grid>
							)
						}
					</Grid>
				</Paper>
			</Modal>
		</React.Fragment>

	);
}
const styles = {
	main: {
		marginBottom: "0px"
	},
	modal: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		height: "auto",
		bgcolor: "background.paper",
		padding: "20px",
	},
	formControl: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {

			},
		}
	}
};
export default EstabelecimentoModal;