import React from "react";
import {useSelector} from "react-redux";
import {GRAY_STRONG_UX, TITLE_SIZE, TITLE_MARGIN, WHITE_ESCRITA_THEME} from "../../shared/utils";

const Header = (props) => {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const isDesktop = useSelector(state => state.AuthReducer.isDesktop);
    return (
        <h1
            style={{
                color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX,
                fontSize: TITLE_SIZE,
                fontWeight: "bold",
                lineHeight: 1,
                marginBlock: 0,
                textAlign: isDesktop ? "left" : "center",
                marginBottom: `${TITLE_MARGIN}em`
            }}
            data-testid="header-title"
            className={props.className}
        >
            {props.title}
        </h1>
    );
};

export default React.memo(Header);