import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import api from "../../axios";
import {
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    styleGrid,
    styleText, 
    smallPaperStyle, 
    GRAY_LABEL_UX
} from "../../shared/utils";
import {showSnackMessage} from "../../actions/SnackActions";


const DownloadsModal = (props) => {
    const {openModal, setOpenModal, selectedMsg, reloadTable} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const dispatch = useDispatch();
    const [formattedJson, setFormattedJson] = React.useState("");

    const linkStyle = {
        whiteSpace: "pre", // Preserve tab characters
    };
    
    useEffect(() => {
        if (openModal) {
            let parsedJson = JSON.parse(selectedMsg);
            let stringfiedJson = JSON.stringify(parsedJson, null, "\t");
            setFormattedJson(stringfiedJson);
        }
	}, [openModal, selectedMsg]);
    
    const postRelatorio = () => {
        api.PostRelatorio(selectedMsg).then(() => {
            setOpenModal(false);
            reloadTable();
        });
    };

    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value).then(() => {
            dispatch(showSnackMessage({message: "Copiado com sucesso!"}));
        })
        .catch(() => {
            alert("algo deu errado.");
        });
    };

    return(
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME}} data-testid="download-modal">
                    <Grid item xs={10} className="start">
                        <span style={{...styleText, fontWeight: "700", display: "flex", justifyContent: "center", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>
                            Deseja Copiar ou Deixar o Relatório Vazio?
                        </span>
                    </Grid>
                    <Card sx={{marginTop: 2}}>
                        <CardContent>
                            <a style={linkStyle}>
                                {formattedJson}
                            </a>
                        </CardContent>
                    </Card>
                    <Grid container sx={{display: "flex", justifyContent: "center"}}>
                        <Grid item sx={styleGrid}>
                            <GrayButton
                                onClick={() => copyToClipboard(selectedMsg)}
                                title="Copiar"
                            />
                        </Grid>
                        <Grid item sx={styleGrid}>
                            <YellowButton
                                onClick={() => postRelatorio()}
                                testId="generic-continue-btn"
                                title="Relatório Vazio"
                            />
                        </Grid>
                    </Grid>
                </Paper>
                
            </Modal>
        </React.Fragment>
    );
};

export default DownloadsModal;