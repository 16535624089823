import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useSelector } from "react-redux";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
	DASH_KPI_COLOR,
	DASH_KPI_DATA_COLOR,
	DASH_KPI_NEGATIVE_COLOR,
	DASH_KPI_POSITIVE_COLOR,
	DASH_LABEL_COLOR,
	dashLeftTitle,
	formatterCurrency,
	formatterPercentage,
	paperDash,
	BLUE_THEME,
	WHITE_ESCRITA_THEME,
	WHITE_THEME_BLACK,
} from "../../shared/utils";

const KPI = (props) => {
	const {data, title, suffix, footerPercData, footerData, testId, isTour} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	const titlePositive = ["CMV", "CMV + CMO", "CMO", "Juros"].includes(title);

	return(
		<Paper sx={{...paperDash, ...dashLeftTitle, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}} className={props.className}>
			<Grid container rowSpacing={2} data-testid={testId}>
				<Grid item xs={12} sx={{display: "flex", alignItems: "center"}}>
					<span style={{...styles.title, ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>{title}</span>
					{
						isTour && <ButtonOpenTour onClick={props.onClick}/>
					}
				</Grid>
				<Grid item xs={12}>
					<span style={{...styles.subTitle, ...(temaEscuro && {color: WHITE_THEME_BLACK})}}>
						{suffix != null ? formatterPercentage(data, 1) : formatterCurrency(data)}{suffix}
					</span>
				</Grid>
				<Grid item xs={12}>
					<Grid container rowSpacing={1}>
						{
							footerData && (
								<Grid item xs={12}>
									<Grid container sx={styles.footer} spacing={0.3}>
										<Grid item>
											{footerData?.map((item, index) => {
												return (
													<React.Fragment key={index}>
													<span style={item?.value < 0 ? styles.negative : styles.positive}>
														{item?.suffix != null ? formatterPercentage(item?.value, 1) : formatterCurrency(item?.value)}{item?.suffix}
													</span>
														{
															index < footerData.length -1 && (
																<span> e </span>
															)
														}

													</React.Fragment>
												);
											})}

										</Grid>
										<Grid item style={{color:temaEscuro && WHITE_THEME_BLACK}}>
											<span>Média/Mês</span>
										</Grid>
									</Grid>
								</Grid>
							)
						}
						{
							!!footerPercData && (
								<Grid item xs={12}>

									<Grid container sx={styles.footer} spacing={0.3}>
										<Grid item>
											{ 
												footerPercData < 0 ? (
													<div style={{ ...(titlePositive ? styles.positive : styles.negative), ...styles.arrowDiv }}>
														<ArrowDownwardIcon sx={styles.icon} /> {formatterPercentage(footerPercData, 1)}%
													</div>
												) : (
													<div style={{ ...(titlePositive ? styles.negative : styles.positive), ...styles.arrowDiv}}>
														<ArrowUpwardIcon sx={styles.icon}/> +{formatterPercentage(footerPercData, 1)}%
													</div>
												)
											}
										</Grid>
										<Grid item>
											<span style={{color:temaEscuro && WHITE_THEME_BLACK}}>Último Mês</span>
										</Grid>
									</Grid>
								</Grid>
							)
						}
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

// Create styles
const styles = {
	title: {
		fontSize: "16px",
		fontWeight: 500,
		color: DASH_KPI_COLOR
	},
	subTitle: {
		fontSize: "20px",
		fontWeight: 600,
		color: DASH_KPI_DATA_COLOR
	},
	footer: {
		fontSize: "14px",
		fontWeight: 400,
		color: DASH_LABEL_COLOR,
		alignItems: "center"
	},
	icon: {
		fontSize: 16
	},
	arrowDiv: {
		display: "flex",
		alignItems: "center"
	},
	positive: {
		color: DASH_KPI_POSITIVE_COLOR
	},
	negative: {
		color: DASH_KPI_NEGATIVE_COLOR
	}
};

export default React.memo(KPI);