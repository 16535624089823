import React, {useState, useEffect, useMemo} from "react";
import Paper from "@mui/material/Paper";
import ReactECharts from "echarts-for-react";
import Box from "@mui/material/Box";
import {
	DASH_AXIS_COLOR, DASH_BAR_COLOR,
	DASH_LABEL_COLOR,
	DASH_LINESTYLE_COLOR,
	formatterPercentage, formatterThousand,
	paperDash, spanDash,
	fontFamily, grid, BLUE_THEME,
	WHITE_ESCRITA_THEME, TEXT_THEME,
	WHITE_THEME_BLACK, GRAY_TABLE, LINE_TABLE, seriesColor
} from "../../shared/utils";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeOpenComponent } from "../../actions/StoreActions";


const Bar = (props) => {
	const {data, percentage, testId, multiColors} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const title = percentage ? "%": "R$";
	const formatter = percentage ? formatterPercentage : formatterThousand;

	const barFormatter = (params) => {
		try{
			return formatter(params.data.toFixed(2));
		} catch {
			return formatter(params.data.value.toFixed(2));
		}
		
	};

	const axisFormatter = (value) =>{
		return formatter(value);
	};

	const YAxis = {
		type: "value",
		splitLine: {
			show: true,
			lineStyle: {
				color: temaEscuro ? TEXT_THEME: DASH_LINESTYLE_COLOR,
				type: "dashed"
			}
		},
		axisLabel: {
			fontFamily,
			color: temaEscuro ? WHITE_THEME_BLACK : DASH_AXIS_COLOR,
			...(formatter != null && {formatter: axisFormatter})
		}
	};

	const [options, setOptions] = useState({
		grid,
		xAxis: {
			type: "category",
			color: temaEscuro ? GRAY_TABLE  : DASH_LABEL_COLOR,
			data: [],
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			axisLabel: {
				hideOverlap: true,
				interval: 0,
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK  : DASH_AXIS_COLOR
			}
		},
		yAxis: YAxis,
		series: [
			{
				name: props.title,
				color: DASH_BAR_COLOR,
				data: [],
				type: "bar",
				label: {
					show: true,
					position: "top",
					color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_LABEL_COLOR,
					fontFamily,
					...(formatter != null && {formatter: barFormatter})
				},
			},
		],
		title: {
			text: title,
			left: "left",
			textStyle: {
				color:  temaEscuro ? WHITE_ESCRITA_THEME  : DASH_LABEL_COLOR,
				fontFamily,
				fontSize: 12,
				fontWeight: 400
			}
		},
		tooltip: {
			trigger: "item",
			fontFamily,
		},
	});
	const [key, setKey] = useState();

	useEffect(() => {
		let newOptions = {...options};
		let newSeries = data.series;
		if (multiColors) {
			newSeries = [];
			data.series.map((item, idx) => {
				let seriesDict = {
					value: item,
				};
				if (idx < seriesColor.length) {
					seriesDict.itemStyle = { color: seriesColor[idx] };
				}
				newSeries.push(seriesDict);
			});
		}
		newOptions.xAxis.data = data.data;
		newOptions.series[0].data = newSeries;
		setOptions(newOptions);
		setKey(Math.random());
	}, [data]);

	const handleClick = (params) => {
		const innerText = params.event.event.currentTarget.offsetParent.innerText;

		// columnName = X axis
		const columnName = innerText.split("\n")[1];
		
		dispatch(changeOpenComponent(true));
		navigate("/dre", { state: { monthRedirectDashboard: columnName, lineRedirectDashboard: props.title }});
	};

	const getChart = useMemo(() => {
		return(
			<ReactECharts
				key={key}
				option={options}
				notMerge={true}
				onEvents={{
					click: props?.notRedirect ? null : handleClick, // Register the click event handler
				}}
			/>
		);
	}, [options]);

	return(
		<Paper data-testid={testId} sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}} className={props.className}>
			<Box sx={{mb: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
				<span style={{...spanDash, ...(temaEscuro && {color:  LINE_TABLE})}}>{props.title}</span>
				<ButtonOpenTour onClick={props.onClick}/>
			</Box>
			{getChart}
		</Paper>
	);
};

export default React.memo(Bar);