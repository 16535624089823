import {
	CHANGE_EMAIL,
	CHANGE_IS_ADMIN,
	CHANGE_TOKEN,
	CHANGE_DEVICE_TOKEN,
	CHANGE_NAME,
	CHANGE_RESETAR_SENHA,
	CHANGE_TEMA,
	CHANGE_IMAGE_PERFIL,
	CHANGE_IS_MANAGER,
	CHANGE_IS_OPERATOR,
	CHANGE_IS_COMMON_USER,
	CHANGE_IS_DESKTOP
} from "../actions/types";

const INITIAL_STATE = {
	token: null,
	isAdmin: false,
	resetarSenha: false,
	email: "",
	name: null,
	temaEscuro:false,
	imagePerfil: "",
	deviceToken: null,
	isCommonUser: false,
	isManager: false,
	isOperator: false,
	isDesktop: true
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
	case CHANGE_TOKEN:
		return { ...state, token: action.payload };
	case CHANGE_DEVICE_TOKEN:
		return { ...state, deviceToken: action.payload };
	case CHANGE_IS_ADMIN:
		return { ...state, isAdmin: action.payload };
	case CHANGE_EMAIL:
		return { ...state, email: action.payload };
	case CHANGE_NAME:
		return { ...state, name: action.payload };
	case CHANGE_RESETAR_SENHA:
		return { ...state, resetarSenha: action.payload };
	case CHANGE_TEMA:
		return {...state, temaEscuro: action.payload};
	case CHANGE_IMAGE_PERFIL:
		return {...state, imagePerfil: action.payload};
	case CHANGE_IS_COMMON_USER:
		return {...state, isCommonUser: action.payload};
	case CHANGE_IS_OPERATOR:
		return {...state, isOperator: action.payload};
	case CHANGE_IS_MANAGER:
		return {...state, isManager: action.payload};
	case CHANGE_IS_DESKTOP:
		return {...state, isDesktop: action.payload};
	default:
		return state;
	}
};
