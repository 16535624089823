import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export default function DottedMenu(props) {
    const {comment, commentId, setOpenViewCommentModal, setOpenInsertCommentModal, setComment, setIsEdit, setCommentId, delMetadata, component}= props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteComment = () => {
        if (component === "conciliacao"){
            delMetadata("comentario", commentId);
        } else {
            delMetadata("comment", commentId);
        }
    };

    const editComment = () => {
        setComment(comment);
        setCommentId(commentId);
        setOpenViewCommentModal(false);
        setOpenInsertCommentModal(true);
        setIsEdit(true);
    };

    return (
        <div>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                data-testid="dotted-menu-btn"
                onClick={handleClick}
                startIcon={<MoreHorizIcon />}
            />
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuItem
                    onClick={editComment}
                    data-testid="dotted-menu-edit"
                >
                    Editar
                </MenuItem>
                <MenuItem
                    onClick={handleDeleteComment}
                    data-testid="dotted-menu-delete"
                >
                    Excluir
                </MenuItem>
            </Menu>
        </div>
    );
}