import React, {useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {GRAY_WEAK_UX,
	paperStyleFull,
	BLUE_THEME,
	BORDER_BUTTON,
	textCompleteStyleBlack,
	textCompleteStyle,
	GRAY_BG_UX,
	PAPER_PADDING_THEME
} from "../../shared/utils";
import api from "../../axios";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import GrayButton from "../../components/Buttons/GrayButton";
import YellowButton from "../../components/Buttons/YellowButton";
import Header from "../../components/Titles/Header";
import PathName from "../../components/Titles/PathName";
import {showSnackMessage} from "../../actions/SnackActions";
import {useDispatch, useSelector} from "react-redux";


export default function AddPlano(){
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [categoria, setCategoria] = useState("");
	const [categorizacaoDRE, setCategorizacaoDRE] = useState("");
	const [categorizacao1, setCategorizacao1] = useState("");
	const categorias = (location.state.categorias);
	const storeId = (location.state.storeId);
	const selectedTab = (location.state.selectedTab);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	const handleAddPlano = () => {
		if (selectedTab === 0){
			let dataRequest = [{
				categoria_ca: categoria,
				categoria_dre: categorizacaoDRE,
			}];
			handleAdd(dataRequest);
		} else {
			let dataRequest = [{
				categoria_ca: categoria,
				categorizacao_1: categorizacao1,
			}];
			handleAddCompras(dataRequest);
		}
	};

	const handleAdd = (dataRequest) => {
		dataRequest["loja_id"] = storeId;
		api.AddPlanoContas(storeId, dataRequest).then(() => {
			dispatch(showSnackMessage({message: "Plano de contas adicionado com sucesso!"}));
			navigate("/plano_contas");
		}).catch(() => {
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			navigate("/plano_contas");
		});
	};

	const handleAddCompras = (dataRequest) => {
		dataRequest["loja_id"] = storeId;
		api.AddPlanoContasCompras(storeId, dataRequest).then(() => {
			dispatch(showSnackMessage({message: "Plano de contas adicionado com sucesso!"}));
			navigate("/plano_contas");
		}).catch((error) => {
			const response = error.response;
			if (response.status === 400)
				dispatch(showSnackMessage({message: "Essa categoria já existe para essa loja", severity: "error"}));
			else
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			navigate("/plano_contas");
		});
	};

	return(
		<React.Fragment>
			<div className="main">
				<Paper sx={paperStyleFull} style={{backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}}data-testid={"AddPlano"}>
					<Grid container>
						<Grid item xs={12}>
							<Header title="Adicionar Plano de Contas"/>
							<PathName firstPath="Plano de contas" secondPath="Adicionar Plano de Contas"/>
							<Grid container spacing={2} sx={{marginTop: "16px"}}>
								<Grid item xs={12}>
									{
										selectedTab === 0 ? (
											<Grid container rowSpacing={2} data-testid={"inputs"}>
												<Grid item xs={12} >
													<FormControl sx={{width: "30%"}}>
														<TextField
															style={{borderColor: temaEscuro ? BORDER_BUTTON : GRAY_WEAK_UX, backgroundColor: temaEscuro && BLUE_THEME}}
															type="text"
															label={
																<span
																	style={{
																		color: temaEscuro && BORDER_BUTTON,
																	}}
																>
																	Categoria
																</span>
															}
															onChange={(e) => setCategoria(e.target.value)}
															value={categoria}
															data-testid="input-categoria"
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12}>
													<FormControl sx={{width: "30%"}}>
														<Autocomplete
															size="small"
															style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
															sx={{borderColor: temaEscuro && BORDER_BUTTON}}
															disableClearable
															onChange={(event, newValue) => {
																setCategorizacaoDRE(newValue);
															}}
															options={(categorias?.categoria_dre) ? categorias.categoria_dre : []}
															PaperComponent={({ children }) => (
																<Paper style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none"}}>{children}</Paper>
															)}
															renderInput={(params) =>
																<TextField
																	{...params}
																	sx={temaEscuro ? textCompleteStyleBlack: textCompleteStyle}
																	label={
																		<span
																			style={{
																				color: temaEscuro && BORDER_BUTTON,
																			}}
																		>Categorização DRE</span>
																	}
																	data-testid="input-categorizacao-DRE"
																/>}
															className="Categorização 3"
														/>
													</FormControl>
												</Grid>
											</Grid>
										) : (
											<Grid container rowSpacing={2}>
												<Grid item xs={12}>
													<FormControl sx={{width: "30%"}}>
														<TextField
															style={{fontSize:"10px", borderColor: temaEscuro ? BORDER_BUTTON : GRAY_WEAK_UX, backgroundColor: temaEscuro && BLUE_THEME}}
															type="text"
															label="Categoria"
															data-testid="input-categoria"
															onChange={(e) => setCategoria(e.target.value)}
															value={categoria}
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12}>
													<FormControl sx={{width: "30%"}}>
														<Autocomplete
															size="small"
															style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
															sx={{borderColor: temaEscuro && BORDER_BUTTON}}
															disableClearable
															onChange={(event, newValue) => {
																setCategorizacao1(newValue);
															}}
															options={(categorias?.categorizacao_1) ? categorias.categorizacao_1 : []}
															PaperComponent={({ children }) => (
																<Paper style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none"}}>{children}</Paper>
															)}
															renderInput={(params) =>
																<TextField
																	{...params}
																	sx={temaEscuro ? textCompleteStyleBlack: textCompleteStyle}
																	label="Categorização DRE"
																	data-testid={"input-categorizacao-1"}
																/>
															}
															className="Categorização 1"
														/>
													</FormControl>
												</Grid>
											</Grid>
										)}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={2} style={{display: "flex", justifyContent: "end"}}>
								<Grid item>
									<GrayButton
										isRedirect
										title={"Cancelar"}
										path={"/plano_contas"}
										testId={"button-cancel"}
									/>
								</Grid>
								<Grid item>
									<YellowButton title={"Adicionar plano de conta"} onClick={handleAddPlano} testId={"button-add"}/>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</div>
		</React.Fragment>

	);
}
