import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Header from "../Titles/Header";
import { textModificacao, WHITE_THEME_BLACK } from "../../shared/utils";
import api from "../../axios";
import moment from "moment";
import "moment/locale/pt-br";
import { useSelector, useDispatch } from "react-redux";
import { changeModified } from "../../actions/StoreActions";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import LastTenModificationModal from "../Modal/LastTenModificationModal";

const LastModified = ({ title, shouldUpdate }) => {
    const storeId = useSelector(state => state.StoreReducer.storeId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const dataModified = useSelector(state => state.StoreReducer.dataModified);
    const dispatch = useDispatch();
    const [showUpdateModification, setShowUpdateModification] = useState(false);
    const [modifications, setModifications] = useState([]);

    useEffect(() => {
        if (storeId !== 0 && dataModified === "") {
            getLastModification();
        }
    }, [storeId, shouldUpdate]);

    useEffect(() => {
        if (shouldUpdate) {
            getLastModification();
        }
    }, [shouldUpdate]);

    const getLastModification = () => {
        api.GetLastModification(storeId).then(response => {
            let lastModification = response.data;
            dispatch(changeModified(lastModification.data_modificacao));
        }).catch(() => {
            dispatch(changeModified(""));
        });
    };

    const postTenModification = () => {
        api.PostTenModification(storeId).then(response => {
            setModifications(response.data);
        }).catch(() => {
            setModifications([]);
        });
    };

    const openUpdateModification = (show) => {
        if (show) {
            postTenModification();
        }
        setShowUpdateModification(show);
    };

    return (
        <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} data-testid="lastModifier">
            <Header title={title} />
            <Grid item sx={{ display: "flex", gap: 1 }} style={{ ...textModificacao }}>
                <Grid item sx={{color: temaEscuro && WHITE_THEME_BLACK}}>
                    {typeof dataModified === "string" && moment(dataModified, "DD/MM/YYYY HH:mm:ss").isValid() && (
                        <span>
                            <IconButton onClick={() => openUpdateModification(true)} data-testid="info-button">
                                <InfoIcon style={{ color: "#ECC94B", fontSize: "20px", padding: "1px" }} />
                            </IconButton>
                            <LastTenModificationModal
                                open={showUpdateModification}
                                modifications={modifications}
                                openUpdateModification={openUpdateModification}
                            />
                            <a>Última Modificação: </a>
                            <span>{dataModified}</span>
                        </span>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(LastModified);
