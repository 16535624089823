import React from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AtaNotFoundImage from "../../assets/images/AtaNotFound.png";
import AtaNotFoundImageDark from "../../assets/images/AtaNotFound_dark.png";
import YellowButton from "../Buttons/YellowButton";
import {GRAY_LABEL_UX, styleDefaultText, WHITE_THEME_BLACK} from "../../shared/utils";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Box from "@mui/material/Box";

const AtaNotFound = (props) => {
    const navigate = useNavigate();
    const storeId = useSelector(state => state.StoreReducer.storeId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const {testId} = props;

    const handleAddAta = () => {
        navigate("/add_ata", { state: {storeId: storeId}});
    };

    return (
        <Box sx={{p: 3}} data-testid={testId}>
            <Grid container rowSpacing={3} sx={{display: "flex", alignItems: "center", justifyContent: "center", flexGrow: 1}}>
                <Grid item>
                    <img src={temaEscuro ? AtaNotFoundImageDark : AtaNotFoundImage} />
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Typography variant="h6" sx={{color: temaEscuro ? "#FFF" : GRAY_LABEL_UX, fontWeight: "600", fontSize: "24px"}}>Ainda não foi criada uma ata para este mês</Typography>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Typography variant="h6" sx={{styleDefaultText, color: temaEscuro && WHITE_THEME_BLACK}}>Adicione uma ata clicando no botão abaixo</Typography>
                </Grid>
                <Grid item>
                    <YellowButton title="Adicionar ata de reunião" onClick={handleAddAta} startIcon={<AddBoxOutlinedIcon/>} testId={"buttonAddAta"}/>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AtaNotFound;