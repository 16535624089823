import React, {useState, useEffect}  from "react";
import {
    styleText,
    smallPaperStyle,
    WHITE_ESCRITA_THEME,
    PAPER_PADDING_THEME,
    GRAY_BG_UX, 
    GRAY_LABEL_UX
} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import GrayButton from "../Buttons/GrayButton";
import {useSelector} from "react-redux";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FilterWithTag from "./FilterWithTag";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const ModalFilter = (props) => {
    const {openModalFilter, setOpenModalFilter, handleFilterLojas, isComparativo, handleClickAway, monthsDisplay, filteredMonths, setCanCallGetMeses} = props;
    const segmentos = props?.segmentos || [];
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const [lojasSelected, setLojasSelected] = useState([]);
    const [monthsSelected, setMonthsSelected] = useState([]);
    const [segmentosSelected, setSegmentosSelected] = useState([]);
    const lojas = useSelector(state => state.StoreReducer.lojas);


    useEffect(() => {
        setCanCallGetMeses(true);
    }, [lojasSelected]);
    

    const handleFilter = () => {
        handleFilterLojas(monthsSelected, lojasSelected, segmentosSelected);
        setSegmentosSelected([]);
        setMonthsSelected([]);
        setLojasSelected([]);
    };

	return(
		<React.Fragment>
            <Modal
                open={openModalFilter}
                onClose={() => setOpenModalFilter(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="modal-filter-lojas"
            >
                <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro ? PAPER_PADDING_THEME  : GRAY_BG_UX}}>
                    <Grid item xs={10} className="start">
                        <span style={{...styleText, fontWeight: "700", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Selecione os filtros desejados</span>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <ClickAwayListener onClickAway={() => handleClickAway(lojasSelected)}>
                                <Grid item xs={6} sx={{marginTop: 2}}>
                                    <FilterWithTag
                                        options={lojas}
                                        setOptions={setLojasSelected}
                                        optionFilter
                                        handleSubmit={null}
                                        monthsFiltered={null}
                                        limitSelection={null}
                                        placeholderProp="Selecione as Lojas"
                                        testId={"filter-lojas"}
                                    />
                                </Grid>
                            </ClickAwayListener>
                            
                            {
                                monthsDisplay ? (
                                    <Grid item xs={6} sx={{marginTop: 2}}>
                                        <FilterWithTag 
                                            options={filteredMonths}
                                            setOptions={setMonthsSelected}
                                            optionFilter
                                            handleSubmit={null}
                                            monthsFiltered={null}
                                            limitSelection={null}
                                            placeholderProp="Selecione os Meses"
                                            testId={"filter-lojas-months"}
                                        />
                                    </Grid>
                                ) :
                                null
                            }
                            
                            {
                                isComparativo && 
                                (
                                    <React.Fragment>
                                        <Grid item xs={6} sx={{marginTop: 2}}>
                                            <FilterWithTag
                                                options={segmentos}
                                                setOptions={setSegmentosSelected}
        
                                                optionFilter
                                                handleSubmit={null}
                                                monthsFiltered={null}
                                                limitSelection={null}
                                                placeholderProp="Selecione o(s) Segmento(s)"
                                                testId={"filter-lojas-segmentos"}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                        </Grid>
                        <Grid container sx={{marginTop: "20px"}} justifyContent="flex-end">
                            <Grid item>
                                <GrayButton
                                    testId="filtrar-filter-lojas"
                                    onClick={() => handleFilter()}
                                    title="Filtrar"
                                    startIcon={<FilterListOutlinedIcon/>}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
		</React.Fragment>

	);
};

export default ModalFilter;