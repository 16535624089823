import React, {useState} from "react";
import {useSelector} from "react-redux";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {GRAY_LABEL_UX, copyToClipboard, WHITE_THEME_BLACK} from "../../shared/utils";

const LoginInfo = (props) => {
	const {item} = props;
	const [hiddenPassword, setHiddenPassword] = useState(true);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	return(
		<div>
			<span>{hiddenPassword ? ("*".repeat(item.senha.length)) : (item.senha)}</span>
			<Button data-testid="copy-login" id="copy" size="small" onClick={() => copyToClipboard(item.senha)}>
				<ContentCopyIcon style={{color : temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}/>
			</Button>
			<Button data-testid="show-password" id="show" size="small" onClick={() => setHiddenPassword(!hiddenPassword)} >
				{hiddenPassword ? (
				<VisibilityOffOutlinedIcon style={{color : temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}/>
				) : (
				<VisibilityOutlinedIcon style={{color: GRAY_LABEL_UX}}/>)}
			</Button>
		</div>
	);
};

export default LoginInfo;