import React, { useState, useEffect, useMemo }  from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
	format,
	RED_NEGATIVE_UX,
	negativeEl,
	getClassName,
	PAPER_PADDING_THEME,
	stylesRow,
	WHITE_THEME_BLACK,
	GRAY_STRONG_UX,
	ITENS_PAINEL,
	styleNegativeValue,
	BLUE_LIGHTER_UX
} from "../../shared/utils";
import "./Table.css";
import TooltipTable from "./TooltipTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";

const Row = (props) => {
	let {rows, columns, title, subTitle, fontStyle, fontWeight, percentage,
		backgroundColor, color, colorTitle, handleOpenModal, isCollapse, openTour, isTour, detalharDRE, percentActive, rowsPercent} = props;

	const [open, setOpen] = useState(false);
	const openComponent = useSelector(state => state.StoreReducer.openComponent);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	useEffect(() => {
		if (title == "Receita Bruta" && detalharDRE ) {
			setOpen(detalharDRE);
		} else {
			setOpen(openComponent);
		}
	}, [openComponent]);

	const tableRows = useMemo(() => {
		return rows.map((row, idx) => {
				return (
					<React.Fragment key={idx}>
						{columns.map((column, index) => {
							if (column.id !== "periodo") {
								const data = row[column.id];
								const style = {
									// BackgroundColor padrão é igual ao Fat. Bruto Itens 
									backgroundColor: backgroundColor != null ? backgroundColor : temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX,
									fontStyle: fontStyle != null ? fontStyle : "normal",
									fontWeight: fontWeight != null ? fontWeight : "normal",
									color: color != null ? color : (data?.value < 0 ? RED_NEGATIVE_UX : "inherit"),
									zIndex: 1,
								};
								const negativeStyle = data?.value < 0 ? {color: RED_NEGATIVE_UX, fontWeight: "bold"} : {};
								const cursorStyle = column.id !== "total" ? {cursor: "pointer"} : {};
								let styleBold = {};
								if (["total"].includes(column.id)) {
									styleBold = {fontWeight: "bold"};
								}
								return (
									<TooltipTable key={index} comments={data?.comments || []}>
										<TableCell
											style={{...style, ...styleBold, ...cursorStyle, ...negativeStyle}}
											align="center"
											key={index}
											className={getClassName(data)}
										>		
											<div
												data-testid="row"
												onClick={() => column.id !== "total" && handleOpenModal(data, index - 1, title, data?.comments || [])}
											>
												{
													percentActive && rowsPercent?.[idx]?.[column.id]?.value ? (
														<React.Fragment>
															<div style={{...typeof data.value === "number" && styleNegativeValue}}>
																{
																	negativeEl(data)
																}
																{format(data?.value)}
															</div>
															<a style={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_STRONG_UX, fontSize: 13.5, fontWeight: "normal"}}>
																{rowsPercent[idx][column.id].value}%
															</a>
														</React.Fragment>
													) : (
														<div style={{...typeof data.value === "number" && styleNegativeValue}}>
															{
																negativeEl(data)
															}
															{format(data?.value)}
															{!!percentage && ("%")}
														</div>
													)
												}
											</div>
										</TableCell>
									</TooltipTable>
								);
							}
							return null;
						})}
					</React.Fragment>
				);
		});
	}, [rows, percentActive]);

	return(
		<React.Fragment >
			<TableRow data-testid="table-row" className={`${props.className} table-row`} hover role="checkbox" tabIndex={-1}>
				<TableCell
					style={{
						backgroundColor: backgroundColor != null ? backgroundColor : temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX,
						fontStyle: fontStyle != null ? fontStyle : "normal",
						fontWeight: fontWeight != null ? fontWeight : "normal",
						color: colorTitle != null ? colorTitle : "inherit",
						position: "sticky",
						left: 0,
						zIndex: 2,
					}}
				>
					{
						isTour ? (
							<div style={{...styles.flexCenter}}>
								{title}
								<ButtonOpenTour onClick={props.onClick}/>
							</div>
						) : (
							<React.Fragment>
								{title}	
							</React.Fragment>
						)
					}
					<span style={{fontStyle: "italic", fontSize: "90%"}}> {subTitle}</span>
				</TableCell>
				{tableRows}
				{ isCollapse &&
					(
						<TableCell sx={{zIndex: 1, backgroundColor: temaEscuro && PAPER_PADDING_THEME, ...stylesRow}} data-testid="open-row" onClick={() => setOpen(!open)}>
							{(open || openTour===true) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</TableCell>
					)
				}
			</TableRow>
			{ (open || openTour===true) && props.children}
		</React.Fragment>
	);
};

export default React.memo(Row);

const styles = {
	flexCenter: {
		display: "flex",
		alignItems: "center",
	}
};
