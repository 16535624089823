import React, {useState, useEffect, useMemo} from "react";
import Paper from "@mui/material/Paper";
import ReactECharts from "echarts-for-react";
import Box from "@mui/material/Box";
import {
	DASH_AXIS_COLOR, DASH_BAR_COLOR,
	DASH_LABEL_COLOR,
	DASH_LINESTYLE_COLOR,
	formatterThousand,
	formatterPercentage,
	paperDash, spanDash, fontFamily,
	YELLOW_BG_UX, grid, legend, formatterCurrency, changePosition,
	BLUE_THEME, WHITE_ESCRITA_THEME, WHITE_THEME_BLACK, TEXT_THEME,
	BORDER_BUTTON, GRAY_LABEL_UX, LINE_TABLE, DASH_STACKED_COLOR,
	WEAK_ORANGE_COMPARATIVO, PURPLE_INFO_UX,
	VERY_WEAK_GRAY_COMPARATIVO, BLUEISH_PURPLE, VERY_WEAK_GREEN_COMPARATIVO,
	VERY_WEAK_YELLOW_COMPARATIVO, VERY_WEAK_BROWN_COMPARATIVO
} from "../../shared/utils";
import {graphic} from "echarts";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeOpenComponent } from "../../actions/StoreActions";


const Line = (props) => {
	const {data, percentage, testId} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const title = percentage ? "%": "R$";
	const formatter = percentage ? formatterPercentage : formatterThousand;

	const lineFormatter = (params) => {
		return formatter(params.data.toFixed(2));
	};

	const axisFormatter = (value) =>{
		return formatter(value);
	};

	const YAxis = {
		type: "value",
		min: function (value) {
			return value.min - (value.min * 0.05);
		},
		splitLine: {
			show: true,
			lineStyle: {
				color: temaEscuro ? TEXT_THEME : DASH_LINESTYLE_COLOR,
				type: "dashed"
			}
		},
		axisLabel: {
			fontFamily,
			color: temaEscuro ? WHITE_THEME_BLACK : DASH_AXIS_COLOR,
			...(formatter != null && {formatter: axisFormatter})
		}
	};

	const label = {
		show: true,
		position: "top",
		color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_LABEL_COLOR,
		fontFamily,
		...(formatter != null && {formatter: lineFormatter})
	};

	const series = {
		color: YELLOW_BG_UX,
		data: [],
		type: "line",
		smooth: true,
		symbol: "circle",
		label: props?.hideLabel ? null : label,
		triggerLineEvent: true,
	};

	const seriesColor = [
		{
			color: DASH_BAR_COLOR,
			areaStyle: {
				color: new graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: "rgba(56, 178, 172, 0.2)"
					},
					{
						offset: 1,
						color: "rgb(56, 178, 172, 0)"
					}])
			}
		},
		{
			color: YELLOW_BG_UX,
			areaStyle: {
				color: new graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: "rgba(248, 221, 156, 0.2)"
					},
					{
						offset: 1,
						color: "rgb(248, 221, 156, 0)"
					}])
			}
		},
		{
			color: DASH_STACKED_COLOR,
			areaStyle: {
				color: new graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: "rgba(66, 153, 225, 0.2)"
					},
					{
						offset: 1,
						color: "rgb(66, 153, 225, 0)"
					}])
			}
		},
		{
			color: VERY_WEAK_GREEN_COMPARATIVO,
			areaStyle: {
				color: new graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: "rgba(173, 255, 230, 0.2)"
					},
					{
						offset: 1,
						color: "rgb(173, 255, 230, 0)"
					}])
			}
		},
		{
			color: VERY_WEAK_YELLOW_COMPARATIVO,
			areaStyle: {
				color: new graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: "rgba(255, 249, 176, 0.2)"
					},
					{
						offset: 1,
						color: "rgb(255, 249, 176, 0)"
					}])
			}
		},
		{
			color: VERY_WEAK_BROWN_COMPARATIVO,
			areaStyle: {
				color: new graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: "rgba(158, 136, 122, 0.2)"
					},
					{
						offset: 1,
						color: "rgb(158, 136, 122, 0)"
					}])
			}
		},
		{
			color: WEAK_ORANGE_COMPARATIVO,
			areaStyle: {
				color: new graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: "rgba(250, 166, 110, 0.2)"
					},
					{
						offset: 1,
						color: "rgb(250, 166, 110, 0)"
					}])
			}
		},
		{
			color: PURPLE_INFO_UX,
			areaStyle: {
				color: new graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: "rgba(159, 122, 234, 0.2)"
					},
					{
						offset: 1,
						color: "rgb(159, 122, 234, 0)"
					}])
			}
		},
		{
			color: BLUEISH_PURPLE,
			areaStyle: {
				color: new graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: "rgba(84, 112, 198, 0.2)"
					},
					{
						offset: 1,
						color: "rgb(84, 112, 198, 0)"
					}])
			}
		},
		{
			color: VERY_WEAK_GRAY_COMPARATIVO,
			areaStyle: {
				color: new graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color: "rgba(179, 195, 208, 0.2)"
					},
					{
						offset: 1,
						color: "rgb(179, 195, 208, 0)"
					}])
			}
		},
	];

	const [options, setOptions] = useState({
		grid,
		legend:{
			...legend,
			textStyle: {
				color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
			},
			inactiveColor: temaEscuro ? BORDER_BUTTON : "#ccc"
		},
		xAxis: {
			type: "category",
			color: DASH_LABEL_COLOR,
			data: [],
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			axisLabel: {
				interval: 0,
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_LABEL_COLOR
			}
		},
		yAxis: YAxis,
		series: [],
		title: {
			text: title,
			left: "left",
			textStyle: {
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_LABEL_COLOR,
				fontFamily,
				fontSize: 12,
				fontWeight: 400
			}
		},
		tooltip: {
			appendToBody: true,
			trigger: "axis",
			axisPointer: {
				type: "shadow"
			},
			fontFamily,
		},
	});
	const [key, setKey] = useState();

	useEffect(() => {
		let newOptions = {...options};
		newOptions.series = [];
		newOptions.xAxis.data = data.data;
		let position = "top";

		data?.series?.map((item, index) => {
			let newSeries = {...series};
			if (index < seriesColor.length){
				let newSeriesColor = seriesColor[index];
				newSeries.color = newSeriesColor.color;
				newSeries.areaStyle = newSeriesColor.areaStyle;
			}
			let label = {...newSeries.label};

			label.position = position;
			newSeries.tooltip = {
				valueFormatter: percentage ? ( function (value) {
					return formatterPercentage(value, 2);
				}) : formatterCurrency
			};
			newSeries.name = item.name;
			newSeries.data = item.data;
			newSeries.label = label;
			newOptions.series.push(newSeries);
			position = changePosition(position);
		});

		setOptions(newOptions);
		setKey(Math.random());
	}, [data]);

	const handleClick = (params) => {
		const innerText = params.event.event.currentTarget.offsetParent.innerText;

		// columnName = X axis
		const columnName = innerText.split("\n")[0];

		dispatch(changeOpenComponent(true));
		navigate("/dre", { state: { monthRedirectDashboard: columnName, lineRedirectDashboard: props.title }});
	};

	const getChart = useMemo(() => {
		return (
			<ReactECharts
				key={key}
				option={options}
				notMerge={true}
				style={props.isFullHeight ? {height: "100%", width: "100%"} : null}
				onEvents={{
					click: props?.notRedirect ? null : handleClick, // Register the click event handler
				}}
			/>
		);
	}, [options]);

	return(
		<Paper data-testid={testId} sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}} className={props.className}>
			<Box sx={{mb: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
				<span style={{...spanDash, ...(temaEscuro && {color:  LINE_TABLE})}}>{props.title}</span>
				<ButtonOpenTour onClick={props.onClick}/>
			</Box>
			{getChart}
		</Paper>
	);
};

export default React.memo(Line);