import React, { useState, useEffect } from "react";
import {useLocation} from "react-router-dom";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import api from "../../axios";
import {
    paperStyle,
    styleField,
    PAPER_PADDING_THEME,
    formatterCurrency,
    formatterDayMonthYear
} from "../../shared/utils";
import Header from "../../components/Titles/Header";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import DataGrid from "../../components/DataGrid/DataGrid";

export default function DetalhaFluxo() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [columns, setColumns] = useState([]);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        let selectedDate = query.get("selectedDate");
        let cellField = query.get("cellField");
        setSelectedDate(selectedDate);
        const dataRequest = {
            loja_id: query.get("storeId"),
            selected_date: selectedDate,
            column: cellField,
        };
        GetDetalhaFluxoCaixa(dataRequest);
    }, []);

    const GetDetalhaFluxoCaixa = (dataRequest) => {
        setLoading(true);
        api.GetDetalhaFluxoCaixa(dataRequest).then((response) => {
            const newData = response.data.table_info.map((item, index) => {
                return { ...item, id: index };
            });
            getColumns(response.data.table);
            setData(newData);
            setLoading(false);
        })
            .catch(() => {
                setLoading(false);
            });
    };

    const getColumns = (table) => {
        if (table === "ContaAzul") {
            setColumns([
                {
                    field: "data_pagamento",
                    headerName: "Data de Movimentação",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    valueFormatter: params => formatterDayMonthYear(params)
                },
                {
                    field: "data_competencia",
                    headerName: "Data de Competência",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    valueFormatter: params => formatterDayMonthYear(params)
                },
                { field: "categoria", headerName: "Categoria", flex: 1, align: "center", headerAlign: "center" },
                { field: "descricao", headerName: "Descrição", flex: 1, align:"center", headerAlign: "center" },
                { field: "fornecedor", headerName: "Fornecedor", flex: 1, align:"center", headerAlign: "center" },
                { 
                    field: "valor",
                    headerName: "Valor (R$)",
                    flex: 1,
                    type: "number",
                    align:"center",
                    headerAlign: "center",
                    valueFormatter: (params) => formatterCurrency(params, 2),
                },
                { field: "conta", headerName: "Conta", flex: 1, align:"center", headerAlign: "center" },
                { field: "situacao", headerName: "Situação", flex: 1, align:"center", headerAlign: "center" },
            ]);
        } else if (table === "Platforms") {
            setColumns([
                { field: "platform", headerName: "Portal", flex: 1, align: "center", headerAlign: "center" },
                {
                    field: "data_venda",
                    headerName: "Data da Venda",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    valueFormatter: params => formatterDayMonthYear(params)
                },
                {
                    field: "data_recebimento",
                    headerName: "Data de Recebimento",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    valueFormatter: params => formatterDayMonthYear(params)
                },
                { 
                    field: "valor_venda",
                    headerName: "Valor da venda (R$)",
                    flex: 1,
                    type: "number",
                    align:"center",
                    headerAlign: "center",
                    valueFormatter: (params) => formatterCurrency(params, 2),
                },
                { field: "taxa",
                    headerName: "Valor das taxas (R$)",
                    flex: 1,
                    type: "number",
                    align:"center",
                    headerAlign: "center",
                    valueFormatter: (params) => formatterCurrency(params, 2),
                },
                { 
                    field: "valor_liquido",
                    headerName: "Valor líquido (R$)",
                    flex: 1,
                    type: "number",
                    align:"center",
                    headerAlign: "center",
                    valueFormatter: (params) => formatterCurrency(params, 2),
                },
            ]);
        }
    };

    return (
        <div className="main">
            <Header title={"Detalhamento do Fluxo de Caixa"}/>
            {loading ? (
                <Skeleton variant="rectangular" width={"100%"} height={"80vh"} />
            ) : (
                <Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}} data-testid="detalha-fluxo">
                    <Grid container>
                        <Grid item xs={12} sx={{...styleField, marginLeft: 0}}>
                            <a>Período: <strong>{selectedDate}</strong></a>
                        </Grid>
                    </Grid>
                    <Grid sx={{ height: "calc(100vh - 132px - 72px)", width: "100%"}}>
                        <DataGrid
                            columns={columns}
                            data={data}
                            row={(row) => (row.id)}
                        />
                    </Grid>
                </Paper>
            )}
        </div>
    );
}
