import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {styleLegend, tableStyle} from "../../../shared/utils";
import TableRow from "./TableRow";
import DownloadsModal from "../../Modal/DownloadsModal";

const Row = (props) => {
    const {styleColor, plataforma, colorList, hasChildren, colSpan, childrenData, msgList, reloadTable} = props;
    const [open, setOpen] = useState(false);
    const [selectedMsg, setSelectedMsg] = useState([]);
    const [openModalDownloads, setOpenModalDownloads] = useState(false);

    const handleOpen = () => {
        setOpen(open => !open);
    };

    const openModal = (color, index) => {
        if (color === "orange"){
            alert("Login inexistente");
        }
        else {
            setSelectedMsg(JSON.stringify({"body": formatItem(msgList[index])}));
            setOpenModalDownloads(true);
        }
    };

    const formatItem = (item) =>{
        let formattedItem = Object.assign({}, item);
        Object.keys(formattedItem).forEach(function(key){
            if(formattedItem[key] == null){
                formattedItem[key] = "";
            }
        });
        return formattedItem;
    };

    return (
        <React.Fragment>
            <DownloadsModal
                openModal={openModalDownloads}
                setOpenModal={setOpenModalDownloads}
                selectedMsg={selectedMsg}
                reloadTable={reloadTable}
            />
            <tr>
                <td style={{...tableStyle.tdTh, ...styleColor}}>
                    <Grid container sx={{paddingLeft: 0.5, paddingTop: 0.5, alignItems: "center", minHeight: 40 }} spacing={1}>
                        {
                            hasChildren && (
                                <Grid item sx={{"cursor": "pointer"}} onClick={handleOpen}>
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Grid>
                            )
                        }
                        <Grid item>
                            {plataforma}
                        </Grid>
                    </Grid>
                </td>
                {
                    hasChildren ? (
                        [...Array(colSpan - 1)].map((x, index) =>
                            <td
                                key={index}
                                style={{...tableStyle.tdTh, ...styleColor, ...styleLegend[""]}}
                            />
                        )
                    ) : (
                        colorList?.map((color, index) => {
                            return (
                                <td
                                    data-testid="cell-platform"
                                    key={index}
                                    onClick={() => openModal(color, index)}
                                    style={{...tableStyle.tdTh, ...tableStyle.pointer, ...styleColor, ...styleLegend[color]}}
                                />
                            );
                        })
                    )
                }
            </tr>
            {
                hasChildren && open && (
                    <tr>
                        <td colSpan={colSpan} style={{padding: 30}}>
                            <TableRow
                                columns={childrenData?.columns || []}
                                rows={childrenData?.rows || []}
                            />
                        </td>

                    </tr>
                )
            }
        </React.Fragment>
    );
};

export default React.memo(Row);