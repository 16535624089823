import React, {useState, useEffect, useMemo, useCallback} from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import {useDispatch} from "react-redux";
import {changeOpenAccordionSelected} from "../../../actions/StoreActions";
import {
    GRAY_LABEL_UX,
    PAPER_PADDING_THEME,
    RED_TABLE,
    formatarValorBrasileiro,
    GREEN_SUCCESS_UX,
    YELLOW_BG_UX,
    PURPLE_INFO_UX,
    WHITE_THEME_BLACK,
    INFO_THEME,
    GRAY_BORDER_TABLE,
    GRAY_BG_UX,
    BLACK_SUBITEM_NAVBAR,
    WHITE_SUBITEM_NAVBAR,
    BLUE_THEME,
    LINE_TABLE,
} from "../../../shared/utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import { useSelector } from "react-redux";
import TooltipTable from "../TooltipTable";
import DataGrid from "../../DataGrid/DataGrid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import GrayButton from "../../Buttons/GrayButton";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import api from "../../../axios";
import {showSnackMessage} from "../../../actions/SnackActions";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { parse, format, addDays, subDays } from "date-fns";
import { ptBR } from "@mui/x-data-grid-premium/locales";
import DetailPDVPanel from "./DetailPanels/DetailPDVPanel";

const ConciliacaoAccordion = (props) => {

    const {
        handleOpenModal,
        rows,
        plataformasRevisadas,
        tabs = [],
        selectedTab = [],
        openModal,
        setScrollPosition,
        scrollPosition,
        estabelecimentosSelected,
        monthsFiltered,
        isPDV = false,
        selectedPortal,
        selectedFormaPagamento
    } = props;

    const dispatch = useDispatch();
    const nav = useNavigate();
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const storeId = useSelector(state => state.StoreReducer.storeId);
    const openAccordionSelected = useSelector(state => state.StoreReducer.openAccordionSelected);
    const [renderCellWithIndicators, setRenderCellWithIndicators] = useState(null);

    const [nomePlataforma, setNomePlataforma] = useState(null);
    const [mesesRevisados, setMesesRevisados] = useState([]);
    const [dataDetalhar, setDataDetalhar] = useState([]);
	const [columnsDetalhar, setColumnsDetalhar] = useState([]);
	const [valorTotal, setValorTotal] = useState(0);
	const [loadingDatagrid, setLoadingDatagrid] = useState(false);
    const [rowId, setRowId] = useState(null);
    const fieldsConciliacao = ["periodo", "dia_semana"];

    const [dataPortalDetalhar, setDataPortalDetalhar] = useState([]);
    const [dataFrenteCaixaDetalhar, setDataFrenteCaixaDetalhar] = useState([]);
    
    const handleCellClick = (params, periodoData) => {
        if (!fieldsConciliacao.includes(params.field)) {
            let isRevisada = mesesRevisados.includes(periodoData.periodo) && tabs[selectedTab] === nomePlataforma;
            handleOpenModal(params.field, params.row.periodo, periodoData.periodo, isRevisada);
        }
    };

    const handleChange = (panel) => (event, isExpanded) => {
        dispatch(changeOpenAccordionSelected(isExpanded ? panel : ""));
    };

    const formatRows = useMemo(() => {
        return (data) => {
            return data.map((row, index) => {
                const formattedRow = {};
                Object.keys(row).forEach((key) => {
                    formattedRow[key] = typeof row[key].value === "number" ? formatarValorBrasileiro(row[key].value) : row[key].value;
                });
                formattedRow.id = index;
                return formattedRow;
            });
        };
    }, []);

    // UseEffect para colocar o icon de revisado
    useEffect(() => {
        if (plataformasRevisadas.length > 0 && tabs.length > 0 && selectedTab !== null) {
            const plataformaSelecionada = tabs[selectedTab];
            const objetoPlataforma = plataformasRevisadas.find(item => Object.keys(item)[0] === plataformaSelecionada);
            const nomePlataforma = objetoPlataforma ? Object.keys(objetoPlataforma)[0] : null;
            setNomePlataforma(nomePlataforma);
            const mesesRevisadosParaPlataforma = objetoPlataforma ? Object.values(objetoPlataforma).flat() : [];
            setMesesRevisados(mesesRevisadosParaPlataforma);
        }
    }, [tabs, selectedTab, plataformasRevisadas]);

    useEffect(() => {
        if (rows.length > 0) {
            const renderCell = (params) => {
                const { field, value } = params;
                const allData = rows.map(row => row.data).flat();
                const periodoCaptured = allData.find(item => item.periodo.value === params.row.periodo);
    
                let hasComments = false;
                let isFixed = false;
                let cellData = {};
    
                if (periodoCaptured) {
                    cellData = periodoCaptured[field];
                    hasComments = cellData.comments && cellData.comments.length > 0;
                    isFixed = typeof cellData.is_fixed === "number";
                }

                const isDiaSemana = params.field === "dia_semana" ? true : false;
                const isNegative = !isNaN(parseFloat(value)) && parseFloat(value) < 0;
    
                return (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: isNegative ? RED_TABLE : "inherit", fontWeight: isNegative ? "bold" : "normal", borderRight: isDiaSemana ? "1px solid white" : null}}>
                        {isFixed && <CircleIcon sx={{ color: YELLOW_BG_UX, fontSize: 15, marginRight: hasComments ? 0.5 : 1 }} />}
                        {hasComments ? (
                            <TooltipTable comments={cellData.comments}>
                                <div style={{ display: "flex", alignItems: "center"}}>
                                    <CircleIcon sx={{ color: PURPLE_INFO_UX, fontSize: 15, marginRight: 1 }} />
                                    {value}
                                </div>
                            </TooltipTable>
                        ) : (
                            value
                        )}
                    </div>
                );
            };
            renderCell.displayName = "RenderCellWithIndicators";
            setRenderCellWithIndicators(() => renderCell);
        }
    }, [rows]);

    const renderBorderRight = (params) => {
        return (
            <div style={{ borderRight: temaEscuro ? `1px solid ${INFO_THEME}` : `1px solid ${GRAY_BORDER_TABLE}`}}>
                {params.value}
            </div>
        );
    };

    const columns = !isPDV ? ([
        { field: "periodo", headerName: "Período", flex: 1, headerAlign: "center", align: "center" },
        { field: "dia_semana", headerName: "Dia da Semana", flex: 1, headerAlign: "center", align: "center", borderHeader: "1px solid red", renderCell: renderBorderRight },
        { field: "recebido_portal", headerName: "Portal", flex: 1, headerAlign: "center", align: "center", renderCell: renderCellWithIndicators },
        { field: "recebido_cc", headerName: "Conta Corrente", flex: 1, headerAlign: "center", align: "center", renderCell: renderCellWithIndicators },
        { field: "recebido_conferencia", headerName: "Conferência", flex: 1, headerAlign: "center", align: "center", renderCell: renderCellWithIndicators }
    ]) : ([
        { field: "periodo", headerName: "Período", flex: 1, headerAlign: "center", align: "center" },
        { field: "dia_semana", headerName: "Dia da Semana", flex: 1, headerAlign: "center", align: "center", borderHeader: "1px solid red", renderCell: renderBorderRight },
        { field: "recebido_portal", headerName: "Portal", flex: 1, headerAlign: "center", align: "center", renderCell: renderCellWithIndicators },
        { field: "recebido_pdv", headerName: "PDV", flex: 1, headerAlign: "center", align: "center", renderCell: renderCellWithIndicators },
        { field: "recebido_conferencia", headerName: "Conferência", flex: 1, headerAlign: "center", align: "center", renderCell: renderCellWithIndicators }
    ]);

    // Control visible columns here
    const initialColumns = useMemo(() => {
        const config = {
          ...dataDetalhar.initialState,
          columns: {
            ...dataDetalhar.initialState?.columns,
            columnVisibilityModel: {},
          },
        };
      
        if (tabs[selectedTab] === "Ifood") {
            config.columns.columnVisibilityModel = {
                codigo_pedido: false,
                taxa_entrega_ifood: false,
                incentivo_ifood: false,
                incentivo_rest: false,
                ocorrencias: false,
                valor_ocorrencia: false,
                motivo_ocorrencia: false,
                conf_receber: false,
                venda_sem_frete: false,
                formas_pagamento: false,
                taxa_servico: false,
                data_recebimento_antecipado: false,
                recebido_restaurante: false,
            };
        }
        return config;
    }, [dataDetalhar, tabs, selectedTab]);

    const getDetailPanelContent = ({ row }) => {
        return (
            isPDV ? (
                <DetailPDVPanel
                    row={row}
                    estabelecimentosSelected={estabelecimentosSelected}
                    selectedPortal={selectedPortal}
                    selectedFormaPagamento={selectedFormaPagamento}
                    setRowId={setRowId}
                    rowId={rowId}
                    getDetalharPDV={getDetalharPDV}
                    loadingDatagrid={loadingDatagrid}
                    dataPortalDetalhar={dataPortalDetalhar}
                    dataFrenteCaixaDetalhar={dataFrenteCaixaDetalhar}
                    getDateComplete={getDateComplete}
                    
                />
            ) : (
                <DetailPanelContent row={row} />
            )
        );
    };

    const getDetailPanelHeight = useCallback(() => 650, []);

    const getDateComplete = useCallback((day, monthSelected) => {
        for (const date of monthsFiltered) {
            const [month, year] = date["value"].split("/");
            if (monthSelected === month) {
                return `${day}/${month}/${year}`;
            }
        }
    }, [monthsFiltered]);

    const getDetalharConciliacao = useCallback((dataRequest) => {
        setLoadingDatagrid(true);
		api.GetDetalharConciliacao(dataRequest).then(response => {
			let dataResponse = response.data;
            const newData = dataResponse.data.map((item, index) => {
                return { ...item, id: index };
            });
			setDataDetalhar(newData);
			setColumnsDetalhar(dataResponse.columns);
			setValorTotal(dataResponse.total);
            setLoadingDatagrid(false);
		}).catch(() => {
            setLoadingDatagrid(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, []);

    const getDetalharPDV = useCallback((dataRequest) => {
        setLoadingDatagrid(true);
		api.GetDetalharPDV(dataRequest).then(response => {
			let dataResponse = response.data;
            const newDataPortal = dataResponse.portal_details.map((item, index) => {
                return { ...item, id: index };
            });
			setDataPortalDetalhar(newDataPortal);            

            const newDataFrenteCaixa = dataResponse.frente_caixa_details.map((item, index) => {
                return { ...item, id: index };
            });
			setDataFrenteCaixaDetalhar(newDataFrenteCaixa);

            setLoadingDatagrid(false);
		}).catch((e) => {
            setLoadingDatagrid(false);
            if (e.status === 403) {
                dispatch(showSnackMessage({message: "Ainda não temos como detalhar seu frente de caixa.", severity: "info"}));
            }
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, []);

    const detailCA = useCallback((periodo) => {
        const selectedTabName = tabs[selectedTab];
        const formatPeriod = (periodo) => {
            return getDateComplete(periodo.split("/")[0], periodo.split("/")[1]);
        };
    
        const navigateToExtrato = (periodo) => {
            nav("/extrato_movimentacoes", { state: { dataRecebimento: periodo, platform: selectedTabName } });
        };
    
        if (selectedTabName === "Ifood") {
            const date = formatPeriod(periodo);
            const previousDate = subDays(parse(date, "dd/MM/yyyy", new Date()), 1);
            const nextDate = addDays(parse(date, "dd/MM/yyyy", new Date()), 1);
            
            const formattedPreviousDate = format(previousDate, "dd/MM/yyyy");
            const formattedNextDate = format(nextDate, "dd/MM/yyyy");
            
            navigateToExtrato([formattedPreviousDate, formattedNextDate]);
        } else {
            const formattedPeriod = formatPeriod(periodo);
            navigateToExtrato([formattedPeriod, formattedPeriod]);
        }
    }, [rowId]);

    const DetailPanelContent = useCallback(({ row: rowProp }) => {

        setRowId(rowProp.id);
        if (!(rowId === rowProp.id)) {
            const [day, monthSelected] = rowProp.periodo.split("/");
            let dateSelected = getDateComplete(day, monthSelected);
            let dataRequest = {
                loja_id: storeId,
                estabelecimentos: estabelecimentosSelected,
                plataforma: tabs[selectedTab],
                periodo: dateSelected
            };
            getDetalharConciliacao(dataRequest);
        }
        
        return (
            <Stack
                sx={{ py: 2, height: "100%", boxSizing: "border-box", backgroundColor: temaEscuro ? PAPER_PADDING_THEME : WHITE_THEME_BLACK }}
                direction="column"
            >
                <Paper sx={{ flex: 1, mx: "auto", width: "95%", p: 2, backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE, backgroundImage: "none" }}>
                    {
                        loadingDatagrid ? (
                            <React.Fragment>
                                {
                                    [...Array(10).keys()].map((i,d) =>{
                                        return(
                                            <Skeleton height={40} key={d}/>
                                        );
                                    })
                                }
                            </React.Fragment>
                        ) : (
                            <Grid container xs={12} sx={{height: "85%"}}>
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
                                    <Typography variant="h6">{rowProp.periodo} | {rowProp.dia_semana}</Typography>
                                    <GrayButton 
                                        title="Detalhar Conta Corrente"
                                        startIcon={<AccountBalanceIcon />}
                                        onClick={() => detailCA(rowProp.periodo)}
                                        testId="button-exm"
                                        className="button-exm"
                                        disabled={rowProp.recebido_cc === "-"}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{mt: 1, mb: 2}}>
                                    <Typography variant="body1">Total Portal: R$ {valorTotal}</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{height: "100%"}}>
                                    <DataGridPremium
                                        columns={columnsDetalhar}
                                        initialState={initialColumns}
                                        rows={dataDetalhar}
                                        hideFooter
                                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                        sx={{
                                            "& .MuiDataGrid-container--top [role=row]": {
                                                backgroundColor: temaEscuro ? BLACK_SUBITEM_NAVBAR : WHITE_SUBITEM_NAVBAR,
                                            },
                                            "& .MuiDataGrid-columnHeaderTitle": {
                                                fontSize: "13px",
                                            },
                                            "& .MuiDataGrid-cell": {
                                                fontSize: "14px",
                                            },
                                            flex: 1
                                        }}
                                    /> 
                                </Grid>
                            </Grid>
                        )
                    }
                </Paper>
            </Stack>           
        );
    }, [loadingDatagrid, dataDetalhar, columnsDetalhar, valorTotal, rowId]);

    return (
        <div style={{ width: "100%" }}>
            {rows.length > 0 && (
                <React.Fragment>
                    {rows.map((periodoData) => (
                        <Accordion
                            key={periodoData.periodo}
                            expanded={openAccordionSelected === periodoData.periodo}
                            onChange={handleChange(periodoData.periodo)}
                            sx={{ marginBottom: 1, backgroundColor: "transparent" }}
                            data-testid="conciliacao-accordion"
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{color: !temaEscuro && GRAY_BG_UX }} />}
                                sx={{ backgroundColor: GRAY_LABEL_UX }}
                            >
                                <Grid container xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Grid item xs={2} sx={{display: "flex", justifyContent: "start", alignItems: "center", gap: 1}}>
                                        <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX}}>{periodoData.periodo}</Typography>
                                        {
                                            (mesesRevisados.includes(periodoData.periodo) && tabs[selectedTab] === nomePlataforma) && (
                                                <CheckCircleIcon style={{fontSize: "22px", color: GREEN_SUCCESS_UX}} data-testid="revisada" />
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={10} sx={{ paddingRight: 3 }}>
                                        <Grid container xs={12} sx={{ display: "flex", justifyContent: "end", gap: 10, flexWrap: "nowrap" }}>
                                            <Grid item>
                                                <Typography sx={{ textAlign: "center" }}>
                                                    <span
                                                        style={{
                                                            color: temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX
                                                        }}
                                                    >
                                                        {isPDV ? ("Vendas Portal: ") : ("Total Recebido: ")}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: periodoData.total_portal < 0 ? RED_TABLE : (temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX),
                                                        }}
                                                        data-testid="total_portal"
                                                    >
                                                        {formatarValorBrasileiro(periodoData.total_portal)}
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    <span
                                                        style={{
                                                            color: temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX
                                                        }}
                                                    >
                                                       {isPDV ? ("Vendas PDV: ") : ("Total Conta: ")}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: periodoData.total_cc < 0 ? RED_TABLE : (temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX),
                                                        }}
                                                        data-testid={isPDV ? "total_pdv" : "total_cc"}
                                                    >
                                                        {formatarValorBrasileiro(isPDV ? periodoData.total_pdv : periodoData.total_cc)}
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    <span 
                                                        style={{
                                                            color: temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX
                                                        }}
                                                    >
                                                        {isPDV ? ("Conferência: ") : ("Total Conferência: ")}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: periodoData.total_conferencia < 0 ? RED_TABLE : (temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX)
                                                        }}
                                                        data-testid="total_conferencia"
                                                    >
                                                        {formatarValorBrasileiro(periodoData.total_conferencia)}
                                                    </span>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    padding: "16px 0px 0px 0px",
                                    backgroundColor: temaEscuro && PAPER_PADDING_THEME,
                                    margin: "auto",
                                }}
                            >
                                <div style={{ height: "77vh", width: "100%" }}>
                                    <DataGrid
                                        columns={columns}
                                        data={formatRows(periodoData.data)}
                                        row={(row) => row.id}
                                        customHandleCellDoubleClick={(params) => handleCellClick(params, periodoData)}
                                        setScrollPosition={setScrollPosition}
                                        scrollPosition={scrollPosition}
                                        openModal={openModal}
                                        hasScrollPrevious
                                        {...({ getDetailPanelHeight })}
                                        {...({ getDetailPanelContent })}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </React.Fragment>
            )}
        </div>
    );
};

export default React.memo(ConciliacaoAccordion);