import React, {useState, useEffect, useMemo} from "react";
import Paper from "@mui/material/Paper";
import ReactECharts from "echarts-for-react";
import Box from "@mui/material/Box";
import {
	DASH_LABEL_COLOR,
	formatterThousand,
	paperDash, spanDash,
	fontFamily, BLUE_THEME,
	LINE_TABLE,
    WHITE_ESCRITA_THEME,
	WHITE_THEME_BLACK,
	DASH_LINESTYLE_COLOR,
	TEXT_THEME,
	BORDER_BUTTON,
	seriesColor,
	legend,
	DASH_BAR_COLOR,
	GRAY_LABEL_UX,
	grid,
	formatterCurrency
} from "../../shared/utils";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";
import { useSelector } from "react-redux";

const CMVGraph = (props) => {
    const {data,  testId} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const cmvFormatter = (params) => {
		return formatterThousand(params.data);
	};

	const labelOption = {
		show: true,
		position: "top",
		distance: 20,
		align: "center",
		verticalAlign: "top",
		rotate: 0,
		color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_LABEL_COLOR,
		formatter: cmvFormatter,
		fontFamily: fontFamily,
		fontSize: 12,
		fontWeight: 400,
	};

	const cmvTooltipFormatter = (params) => {
		let returnValue = [];
		params.map((item) => {
			let valor = data.series[item.seriesName].data[item.dataIndex];
			let tooltip = data.series[item.seriesName].tooltip_weeks[item.dataIndex];
			var valor_formatado = formatterCurrency(valor, 2);
			returnValue.push(`${item.marker} ${item.seriesName} (${tooltip}) | <strong>${valor_formatado}</strong><br/>`);
		});
		let returnValueFormatted = `${returnValue}`;
		return returnValueFormatted.replace(/,(?!\d)/g, "");
	};

	const YAxis = {
		type: "value",
		splitLine: {
			show: true,
			lineStyle: {
				color: temaEscuro ? TEXT_THEME : DASH_LINESTYLE_COLOR,
				type: "dashed"
			}
		},
		axisLabel: {
			interval: 0,
			fontFamily,
			color: temaEscuro ? WHITE_THEME_BLACK : DASH_LABEL_COLOR,
			formatter: formatterThousand
		},
	};

	const [options, setOptions] = useState({
		grid,
		title: {
			text: "R$",
			left: "left",
			textStyle: {
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_LABEL_COLOR,
				fontFamily,
				fontSize: 12,
				fontWeight: 400
			}
		},
		tooltip: {
			trigger: "axis",
			axisPointer: {
				type: "shadow"
			},
			fontFamily,
			formatter: (params) => cmvTooltipFormatter(params)
		}, 
		legend:{
			data: [],
			...legend,
			textStyle: {
				color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
			},
			inactiveColor: temaEscuro ? BORDER_BUTTON : "#ccc"
		},
		xAxis: {
			type: "category",
			color: DASH_LABEL_COLOR,
			data: [],
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			axisLabel: {
				interval: 0,
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_LABEL_COLOR
			}
		},
		yAxis: YAxis,
		series: [
			{
				name: "",
				color: DASH_BAR_COLOR,
				data: [],
				type: "bar",
				label: labelOption
			},
		],
	});

	const [key, setKey] = useState();

	useEffect(() => {
		let newOptions = { ...options };
		let newSeries = [];

		if (data && data.series && Object.keys(data.series).length > 0) {
			Object.keys(data.series).forEach((key, idx) => {
				let item = data.series[key];
				let seriesDict = {
					name: item.name,
					data: item.data,
					type: "bar",
					label: labelOption
				};
				if (idx < seriesColor.length) {
					seriesDict.itemStyle = { color: seriesColor[idx] };
				}
				newSeries.push(seriesDict);
			});
		} else {
			newSeries = 0;
		}

		newOptions.xAxis.data = data.list_months;
		newOptions.series = newSeries;
		newOptions.legend.data = data.list_weeks;
		newOptions.tooltip.formatter = (params) => cmvTooltipFormatter(params);
		setOptions(newOptions);
		setKey(Math.random());
	}, [data]);

    const getChart = useMemo(() => {
		return(
			<ReactECharts
				key={key}
				option={options}
				notMerge={true}
				style={{height: "40vh"}}
			/>
		);
	}, [options]);

    return(
        <Paper data-testid={testId} sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}} className={props.className}>
			<Box sx={{mb: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
				<span style={{...spanDash, ...(temaEscuro && {color:  LINE_TABLE})}}>{props.title}</span>
				<ButtonOpenTour onClick={props.onClick}/>
			</Box>
			{getChart}
		</Paper>
    );
};

export default React.memo(CMVGraph);