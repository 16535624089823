import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import { styleButton, styleDarkButton } from "../../shared/utils";



const GrayButton = (props) => {
    const navigate = useNavigate();
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const redirect = () => {
        if (props?.state) {
            navigate(props.path, { state: props.state});
        } else {
            navigate(props.path);
        }
    };

    return (
        <Button
            variant="contained"
            onClick={props.isRedirect ? redirect : props.onClick}
            style={props?.disabled ? null : (temaEscuro ? styleDarkButton : styleButton)}
            startIcon={props?.startIcon}
            data-testid={props?.testId}
            className={props?.className}
            disabled={props?.disabled}
        >
            {props.title}
        </Button>
    );
};


export default React.memo(GrayButton);