import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import {
	WHITE_THEME_BLACK,
    formatterCurrency,
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    paperDash,
    DASH_AXIS_COLOR,
    PAPER_PADDING_THEME,
    LINE_TABLE,
    PURPLE_INFO_UX,
    BLACK_TABLE,
    DASH_STACKED_COLOR,
    DASH_KPI_POSITIVE_COLOR,
    BLACK_BUTTON,
    DASH_KPI_NEGATIVE_COLOR} from "../../../shared/utils";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import FaturamentoArrow from "./FaturamentoArrow";
import api from "../../../axios";
import { useLocation } from "react-router-dom";
import {showSnackMessage} from "../../../actions/SnackActions";

const FaturamentoIfoodDetalhado = () => {

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const [dataRepasse, setDataRepasse] = useState("");
    const [periodoApuracao, setPeriodoApuracao] = useState("");
    const [situacao, setSituacao] = useState("");
    const [valorBruto, setValorBruto] = useState(0);
    const [recebidosLoja, setRecebidosLoja] = useState(0);
    const [descontosAcrescimos, setDescontosAcrescimos] = useState(0);
    const [valorLiquidoTotal, setValorLiquidoTotal] = useState(0);
    const [promocoesCusteadasPelaLoja, setPromocoesCusteadasPelaLoja] = useState(0);
    const [promocoesCusteadasPeloIFood, setPromocoesCusteadasPeloIFood] = useState(0);
    const [promocoesCusteadasPeloIFoodCount, setPromocoesCusteadasPeloIFoodCount] = useState(0);
    const [taxaComissoesTotal, setTaxaComissoesTotal] = useState(0);
    const [taxaEntregaParceira, setTaxaEntregaParceira] = useState(0);
    const [taxaPedidoMinimo, setTaxaPedidoMinimo] = useState(0);
    const [taxaIfoodRefeicao, setTaxaIfoodRefeicao] = useState(0);
    const [entregasSolicitadas, setEntregasSolicitadas] = useState(0);
    const [pedidosCancelados, setPedidosCancelados] = useState(0);
    const [debitoCanceladoParcialmente, setDebitoCanceladoParcialmente] = useState(0);
    const [comissaoIfood, setComissaoIfood] = useState(0);
    const [comissaoIfoodCancelado, setComissaoIfoodCancelado] = useState(0);
    const [comissaoTransacao, setComissaoTransacao] = useState(0);
    const [comissaoTransacaoCancelado, setComissaoTransacaoCancelado] = useState(0);
    const [outrosValores, setOutrosValores] = useState(0);
    const [valoresPagosClienteDevidoIFood, setValoresPagosClienteDevidoIFood] = useState(0);
    const [valorPedidosViaLojaCount, setValorViaLojaCount] = useState(0);
    const [recebidosLojaPerc, setRecebidosLojaPerc] = useState(0);
    const [promocoesCusteadasPelaLojaPerc, setPromocoesCusteadasPelaLojaPerc] = useState(0);
    const [promocoesCusteadasPeloIFoodPerc, setPromocoesCusteadasPeloIFoodPerc] = useState(0);
    const [taxaComissoesTotalPerc, setTaxaComissoesTotalPerc] = useState(0);
    const [taxaEntregaParceiraPerc, setTaxaEntregaParceiraPerc] = useState(0);
    const [taxaPedidoMinimoPerc, setTaxaPedidoMinimoPerc] = useState(0);
    const [taxaIfoodRefeicaoPerc, setTaxaIfoodRefeicaoPerc] = useState(0);
    const [entregasSolicitadasPerc, setEntregasSolicitadasPerc] = useState(0);
    const [pedidosCanceladosPerc, setPedidosCanceladosPerc] = useState(0);
    const [debitoCanceladoParcialmentePerc, setDebitoCanceladoParcialmentePerc] = useState(0);
    const [comissaoIfoodPerc, setComissaoIfoodPerc] = useState(0);
    const [comissaoIfoodCanceladoPerc, setComissaoIfoodCanceladoPerc] = useState(0);
    const [comissaoTransacaoPerc, setComissaoTransacaoPerc] = useState(0);
    const [comissaoTransacaoCanceladoPerc, setComissaoTransacaoCanceladoPerc] = useState(0);
    const [outrosValoresPerc, setOutrosValoresPerc] = useState(0);
    const [valoresPagosClienteDevidoIFoodPerc, setValoresPagosClienteDevidoIFoodPerc] = useState(0);
    const [estabelecimentos, setEstabelecimentos] = useState([]);

    const dispatch = useDispatch();

    const location = useLocation();

    const styles = {
        container: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            padding: ".5rem 1.5rem"
        },
        arrowValue: {
            fontWeight: "bold",
            fontSize: "16px"
        },
        arrowValueStrong: {
            fontSize: "14px",
            fontWeight: "300",
            color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_BUTTON
        },
        titleValue: {
            fontWeight: "bold",
            fontSize: "24px"
        },
        titleValueStrong: {
            fontSize: "20px",
            fontWeight: "300",
            color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_BUTTON
        },
        subtitle: {
            marginTop: 0,
            color:temaEscuro ? DASH_AXIS_COLOR : BLACK_TABLE
        },
        details: {
            marginTop: 0,
            color: temaEscuro ? DASH_AXIS_COLOR : BLACK_TABLE,
            padding: "0 1.5rem",
            marginBottom: ".5rem"
        }
    };

    useEffect(() => {
        const query = new URLSearchParams(location.search);

        setDataRepasse(query.get("repasseData"));
        setPeriodoApuracao(query.get("periodoApuracao"));
        setSituacao(query.get("situacao"));
        setEstabelecimentos(JSON.parse(query.get("estabelecimentos")));

        const dataRequest = {
            "loja_id": parseInt(query.get("loja")),
            "periodo_repasse": query.get("repasseData"),
            "periodo_apuracao": query.get("periodoApuracao"),
            "situacao": query.get("situacao"),
            "estabelecimentos": JSON.parse(query.get("estabelecimentos")),
        };

        api.GetFaturamentoIfoodDetalhado(dataRequest).then((response) => {
            let responseData = response.data;

            setValorBruto(responseData.resumo_faturamento.valor_bruto_vendas_total);
            setRecebidosLoja(responseData.resumo_faturamento.recebido_via_loja_total);
            setValorLiquidoTotal(responseData.resumo_faturamento.valor_liquido_total);
            setDescontosAcrescimos(responseData.resumo_faturamento.descontos_acrescimos);
            setPromocoesCusteadasPelaLoja(responseData.resumo_faturamento.promocoes.promocoes_custeadas_loja);
            setTaxaComissoesTotal(responseData.resumo_faturamento.taxa_comissoes_total);
            setTaxaEntregaParceira(responseData.resumo_faturamento.valores_pagos_cliente_devido_ifood.taxa_entrega_parceira);
            setTaxaPedidoMinimo(responseData.resumo_faturamento.valores_pagos_cliente_devido_ifood.taxa_pedido_minimo);
            setTaxaIfoodRefeicao(responseData.resumo_faturamento.taxas_comissoes.taxa_ifood_refeicao);
            setEntregasSolicitadas(responseData.resumo_faturamento.servicos_logisticos.entregas_solicitadas);
            setPedidosCancelados(responseData.resumo_faturamento.cancelamentos_reembolsos.pedidos_cancelados);
            setDebitoCanceladoParcialmente(responseData.resumo_faturamento.cancelamentos_reembolsos.debito_cancelados_parcialmente);
            setComissaoIfood(responseData.resumo_faturamento.taxas_comissoes.comissao_ifood);
            setComissaoIfoodCancelado(responseData.resumo_faturamento.cancelamentos_reembolsos.comissao_ifood_cancelados);
            setComissaoTransacao(responseData.resumo_faturamento.taxas_comissoes.comissao_transacao);
            setComissaoTransacaoCancelado(responseData.resumo_faturamento.cancelamentos_reembolsos.comissao_transacao_cancelados);
            setValoresPagosClienteDevidoIFood(responseData.resumo_faturamento.valores_pagos_cliente_devido_ifood.valores_pagos_cliente_devido_ifood_total);
            setOutrosValores(responseData.resumo_faturamento.outros_valores);
            setValorViaLojaCount(responseData.resumo_faturamento.total_pedidos_via_loja);
            setPromocoesCusteadasPeloIFood(responseData.resumo_faturamento.promocoes.promocoes_custeadas_ifood);
            setPromocoesCusteadasPeloIFoodCount(responseData.resumo_faturamento.promocoes.qnt_promocoes_custeadas_ifood);
            setRecebidosLojaPerc(responseData.resumo_faturamento.porcentagens.perc_recebido_via_loja_total);
            setPromocoesCusteadasPelaLojaPerc(responseData.resumo_faturamento.porcentagens.perc_promocoes_custeadas_loja);
            setPromocoesCusteadasPeloIFoodPerc(responseData.resumo_faturamento.porcentagens.perc_promocoes_custeadas_ifood);
            setTaxaComissoesTotalPerc(responseData.resumo_faturamento.porcentagens.perc_taxa_comissoes_total);
            setTaxaEntregaParceiraPerc(responseData.resumo_faturamento.porcentagens.perc_taxa_entrega_parceira);
            setTaxaPedidoMinimoPerc(responseData.resumo_faturamento.porcentagens.perc_taxa_pedido_minimo);
            setTaxaIfoodRefeicaoPerc(responseData.resumo_faturamento.porcentagens.perc_taxa_ifood_refeicao);
            setEntregasSolicitadasPerc(responseData.resumo_faturamento.porcentagens.perc_entregas_solicitadas);
            setPedidosCanceladosPerc(responseData.resumo_faturamento.porcentagens.perc_pedidos_cancelados);
            setDebitoCanceladoParcialmentePerc(responseData.resumo_faturamento.porcentagens.perc_debito_cancelados_parcialmente);
            setComissaoIfoodPerc(responseData.resumo_faturamento.porcentagens.perc_comissao_ifood);
            setComissaoIfoodCanceladoPerc(responseData.resumo_faturamento.porcentagens.perc_comissao_ifood_cancelados);
            setComissaoTransacaoPerc(responseData.resumo_faturamento.porcentagens.perc_comissao_transacao);
            setComissaoTransacaoCanceladoPerc(responseData.resumo_faturamento.porcentagens.perc_comissao_transacao_cancelados);
            setOutrosValoresPerc(responseData.resumo_faturamento.porcentagens.perc_outros_valores);
            setValoresPagosClienteDevidoIFoodPerc(responseData.resumo_faturamento.porcentagens.perc_valores_pagos_cliente_devido_ifood_total);
            dispatch(showSnackMessage({message: "Resumo iFood detalhado obtido com sucesso!", severity: "success"}));
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado, tente novamente mais tarde!", severity: "error"}));
        });
    }, []);

    const checkSituacao = (situacao_value) => {
        if (situacao_value == "Pago") {
            return (
                <strong style={{color: DASH_KPI_POSITIVE_COLOR}}>{situacao_value}</strong>
            );
        }
        else if (situacao_value == "Agendado") {
            return (
                <strong style={{color: DASH_STACKED_COLOR}}>{situacao_value}</strong>
            );
        }
    };

    const formatResumoIfoodPercentage = (value, percentage) => {
        return (
            <span>
                {formatterCurrency(value, 2)} <strong style={{...styles.titleValueStrong}}>| {percentage}%</strong>
            </span>
        );
    };

    const formatResumoIfoodPercentageArrow = (value, percentage) => {
        return (
            <span>
                {formatterCurrency(value, 2)} <strong style={{...styles.arrowValueStrong}}>| {percentage}%</strong>
            </span>
        );
    };

    return (
        <Grid container gap={2} sx={{display: "flex", flexDirection: "column", backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE, padding: "1.5rem", borderRadius: "8px", width: "90%", margin: "1rem auto"}}>
            <Grid item sx={{marginBottom: ".5rem", color: temaEscuro ? WHITE_THEME_BLACK : PAPER_PADDING_THEME}}>
                <h1>Repasse de {dataRepasse}</h1>
                <h3 style={{fontWeight: "normal"}}><span style={{color: PURPLE_INFO_UX, "& hover": {cursor: "pointer"}}}>Resumo Ifood</span> / Repasse de {dataRepasse}</h3>
                <p>Estabelecimento(s): <strong>{estabelecimentos.join(", ")}</strong></p>
                <p>Período de apuração: <strong>{periodoApuracao} | </strong>{checkSituacao(situacao)}</p>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{display: "flex", justifyContent: "space-between", alignItems: "center", height: "100%", padding: ".5rem 1.5rem"}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Valor bruto das vendas</h2>
                            <p style={{...styles.subtitle}}>Valor total de itens vendidos somados aos valores pagos pelo cliente ao iFood</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue}}>
                            {formatterCurrency(valorBruto, 2)}
                        </span>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{...styles.container}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Valores pagos pelo cliente devidos ao iFood</h2>
                            <p style={{...styles.subtitle}}>Taxa de pedido mínimo e outros valores</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: valoresPagosClienteDevidoIFood > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(valoresPagosClienteDevidoIFood, valoresPagosClienteDevidoIFoodPerc)}
                        </span>
                        </Grid>
                    </Grid>
                    <Grid sx={{...styles.container}}>
                        <FaturamentoArrow
                            title="Taxa de Entrega Parceira iFood"
                            value={taxaEntregaParceira}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(taxaEntregaParceira, taxaEntregaParceiraPerc)}
                        </span>
                    </Grid>
                    <Grid sx={{...styles.container, paddingBottom: "1.5rem"}}>
                        <FaturamentoArrow
                            title="Taxa de pedido mínimo"
                            value={taxaPedidoMinimo}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(taxaPedidoMinimo, taxaPedidoMinimoPerc)}
                        </span>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{...styles.container}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Promoções</h2>
                            <p style={{...styles.subtitle}}>Valor total de incentivos promocionais</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: promocoesCusteadasPelaLoja > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(promocoesCusteadasPelaLoja, promocoesCusteadasPelaLojaPerc)}
                        </span>
                        </Grid>
                    </Grid>
                    <Grid sx={{...styles.container}}>
                        <FaturamentoArrow
                            title="Promoções custeadas pela loja"
                            value={promocoesCusteadasPelaLoja}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(promocoesCusteadasPelaLoja, promocoesCusteadasPelaLojaPerc)}
                        </span>
                    </Grid>
                    <Divider sx={{margin: "1rem 1.5rem"}} />
                    <Grid sx={{paddingBottom: "1rem"}}>
                        <p style={{...styles.details}}>
                            {promocoesCusteadasPeloIFoodCount} pedidos tiveram promoções custeadas pelo iFood no valor de {formatterCurrency(promocoesCusteadasPeloIFood, 2)} | {promocoesCusteadasPeloIFoodPerc}%
                        </p>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{...styles.container}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Total recebido via loja</h2>
                            <p style={{...styles.subtitle}}>Valor total que sua loja já recebeu</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: -recebidosLoja > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(-recebidosLoja, recebidosLojaPerc)}
                        </span>
                        </Grid>
                    </Grid>
                    <Grid sx={{...styles.container, paddingBottom: "1.5rem"}}>
                        <FaturamentoArrow
                            title={valorPedidosViaLojaCount == 1 ? `Recebido via loja (${valorPedidosViaLojaCount} pedido)` : `Recebido via loja (${valorPedidosViaLojaCount} pedidos)`}
                            value={-recebidosLoja}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(-recebidosLoja, recebidosLojaPerc)}
                        </span>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{...styles.container}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Taxas e comissões</h2>
                            <p style={{...styles.subtitle}}>Valor bruto total das comissões e taxas do iFood</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: taxaComissoesTotal > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(taxaComissoesTotal, taxaComissoesTotalPerc)}
                        </span>
                        </Grid>
                    </Grid>
                    <Grid sx={{...styles.container}}>
                        <FaturamentoArrow
                            title="Comissão pela transação do pagamento"
                            value={comissaoTransacao}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(comissaoTransacao, comissaoTransacaoPerc)}
                        </span>
                    </Grid>
                    <Grid sx={{...styles.container}}>
                        <FaturamentoArrow
                            title="Comissão do iFood"
                            value={comissaoIfood}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(comissaoIfood, comissaoIfoodPerc)}
                        </span>
                    </Grid>
                    <Grid sx={{...styles.container, paddingBottom: "1.5rem"}}>
                        <FaturamentoArrow
                            title="Taxa de serviço iFood Refeição"
                            value={taxaIfoodRefeicao}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(taxaIfoodRefeicao, taxaIfoodRefeicaoPerc)}
                        </span>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{...styles.container}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Serviços logísticos</h2>
                            <p style={{...styles.subtitle}}>Serviços logísticos contratados pela sua loja</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: entregasSolicitadas > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(entregasSolicitadas, entregasSolicitadasPerc)}
                        </span>
                        </Grid>
                    </Grid>
                    <Grid sx={{...styles.container, paddingBottom: "1.5rem"}}>
                        <FaturamentoArrow
                            title="Total das entregas que você solicitou"
                            value={entregasSolicitadas}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(entregasSolicitadas, entregasSolicitadasPerc)}
                        </span>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{...styles.container}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Cancelamentos e reembolsos</h2>
                            <p style={{...styles.subtitle}}>Valor bruto total de pedidos cancelados e seus devidos reembolsos e descontos</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: pedidosCancelados > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(pedidosCancelados, pedidosCanceladosPerc)}
                        </span>
                        </Grid>
                    </Grid>
                    <Grid sx={{...styles.container}}>
                        <FaturamentoArrow
                            title="Reembolso de pedidos cancelados"
                            value={pedidosCancelados}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(pedidosCancelados, pedidosCanceladosPerc)}
                        </span>
                    </Grid>
                    <Divider sx={{margin: "1rem 1.5rem"}} />
                    <Grid sx={{paddingBottom: "1rem"}}>
                        <p style={{...styles.details}}>Comissão do iFood no valor de {formatterCurrency(comissaoIfoodCancelado, 2)} | {comissaoIfoodCanceladoPerc}%</p>
                        <p style={{...styles.details}}>Comissão pela transação do pagamento no valor {formatterCurrency(comissaoTransacaoCancelado, 2)} | {comissaoTransacaoCanceladoPerc}%</p>
                        <p style={{...styles.details}}>Débito de pedidos cancelados parcialmente no valor de {formatterCurrency(debitoCanceladoParcialmente, 2)} | {debitoCanceladoParcialmentePerc}%</p>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{display: "flex", justifyContent: "space-between", alignItems: "center", height: "100%", padding: ".5rem 1.5rem"}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Outros valores</h2>
                            <p style={{...styles.subtitle}}>Lançamentos gerais</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: outrosValores > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(outrosValores, outrosValoresPerc)}
                        </span>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={8} sx={{margin: "1rem auto 0 auto"}}>
                <Paper sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "start", height: "100%", padding: ".5rem", gap: 3}}>
                        <Grid>
                            <Grid item sx={{marginBottom: ".3rem"}}>
                                <span style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>Valor bruto</span>
                            </Grid>
                            <Grid item sx={{fontWeight: "bold"}}>
                                <span>
                                    {formatterCurrency(valorBruto, 2)}
                                </span>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item>
                                <span style={{fontSize: "30px", ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>-</span>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item sx={{marginBottom: ".3rem"}}>
                                <span style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>Recebido via loja</span>
                            </Grid>
                            <Grid item sx={{fontWeight: "bold"}}>
                                <span>
                                    {formatterCurrency(recebidosLoja, 2)}
                                </span>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item>
                                <span style={{fontSize: "30px", ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>+</span>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item sx={{marginBottom: ".3rem"}}>
                                <span style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>Descontos ou acréscimos</span>
                            </Grid>
                            <Grid item sx={{fontWeight: "bold"}}>
                                <span>
                                    {formatterCurrency(descontosAcrescimos, 2)}
                                </span>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item>
                                <span style={{fontSize: "30px", ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>=</span>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item sx={{marginBottom: ".3rem"}}>
                                <span style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>Valor líquido total</span>
                            </Grid>
                            <Grid item sx={{fontWeight: "bold"}}>
                                <span>
                                    {formatterCurrency(valorLiquidoTotal, 2)}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default React.memo(FaturamentoIfoodDetalhado);