import React  from "react";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {BLACK_TABLE, WHITE_TABLE, GRAY_STRONG_UX, styleDefaultText, BLUE_INFO_UX, format} from "../../shared/utils";
import Grid from "@mui/material/Grid";
import CircleIcon from "@mui/icons-material/Circle";


const TooltipProvision = (props) => {
    let {row} = props;


	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(() => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: WHITE_TABLE,
			color: BLACK_TABLE,
			maxWidth: 420,
			border: "1px solid #dadde9",
		},
	}));

	const getHtmlTitle = () =>{
		return(
			<React.Fragment>
				<Grid container data-testid="tooltip-provision">
					<Grid item sx={{display: "flex", alignItems: "center"}}>
						<CircleIcon className="circles" style={{ color: BLUE_INFO_UX, }}/>
					</Grid>
					<Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center"}}>
						<a style={{fontSize: "16px", color: GRAY_STRONG_UX, fontWeight: "700"}}>Provisão</a>
					</Grid>
                    <Grid item xs={12}>
                        <a>Provisão: {format(row.provisioned_value)} %</a>
                    </Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return(
		<React.Fragment>
			{(row?.is_provisioned) ? (
				<HtmlTooltip
					title={getHtmlTitle()}
					placement="left-start">
					{props.children}
				</HtmlTooltip>
			) : (
				props.children
			)
			}

		</React.Fragment>
	);
};

export default TooltipProvision;