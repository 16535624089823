import React from "react";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { verySmallPaperStyle, BLUE_THEME, WHITE_TABLE, GRAY_WEAK_TABLE, GRAY_STRONG_TABLE } from "../../shared/utils";

const LastTenModificationModal = ({ open, openUpdateModification, modifications }) => {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const columns = [
        { label: "Data/Horário", accessor: "data_modificacao" },
        { label: "Plataforma", accessor: "local" },
    ];

    return (
        <React.Fragment>
            <Modal open={open} onClose={() => openUpdateModification(false)} data-testid="lastTenModificationModal">
                <Paper style={{ ...verySmallPaperStyle, backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE }}>
                    <Grid>
                        <Grid >
                            <h3>Histórico de Modificações</h3>
                        </Grid>
                       <Paper sx={{ borderRadius: 2, border: "1px solid", borderColor: temaEscuro ? GRAY_STRONG_TABLE : GRAY_WEAK_TABLE  }}>
                                <TableContainer sx={{ backgroundColor: temaEscuro ? BLUE_THEME : "inherit", maxHeight: 400 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow >
                                                    <TableCell align="center" style={{ fontWeight: "bold", borderBottom: "1px solid", borderBottomColor: temaEscuro ? GRAY_STRONG_TABLE : GRAY_WEAK_TABLE }}>
                                                       Data/Horário
                                                    </TableCell>
                                                    <TableCell align="center" width="50%" style={{ fontWeight: "bold", borderBottom: "1px solid", borderBottomColor: temaEscuro ? GRAY_STRONG_TABLE : GRAY_WEAK_TABLE }}>
                                                        Plataforma
                                                    </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {modifications.length > 0 ? (
                                                modifications.map((modification, index) => (
                                                    <TableRow key={index}>
                                                        {columns.map((column, colIndex) => (
                                                            <TableCell key={colIndex} data-testid={`modification-${index}`} align="center" style={{ borderBottom: "1px solid", borderBottomColor: temaEscuro ? GRAY_STRONG_TABLE : GRAY_WEAK_TABLE }}>
                                                                {modification[column.accessor]}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={columns.length}>
                                                        Nenhuma modificação encontrada
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default LastTenModificationModal;
