import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
    styleText,
    smallPaperStyle,
    BLUE_THEME,
    styleTextStrong,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    formatterCurrency,
    formatterPercentage, BLACK_TABLE_THEME,
} from "../../../shared/utils";


const ViewProjetada = (props) => {
    const {
        openViewProjetada,
        setOpenViewProjetada,
        selectedDate,
        faturamentoLiqTotalViewProjetado,
        faturamentoProjetado,
        taxasimpostosViewProjetado,
        taxasimpostosProjetado,
        cmvPorcentViewProjetado,
        cmvPorcentagemProjetado,
        despesasEntregasViewProjetado,
        despesasEntregasProjetado,
        despesasPessoalViewProjetado,
        despesasPessoalProjetado,
        despesasOperacionalViewProjetado,
        despesasOperacionalProjetado,
        lucroPrejuizoLiqViewProjetado,
        lucroPrejuizoProjetado,
        lucroPrejuizoLiqPorcentViewProjetado,
        lucroPrejuizoPorcentagemProjetado,
        cmoViewProjetado,
        cmoProjetado,
        pontoEquilibrioViewProjetado,
        pontoEquilibrioProjetado
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const calcDiferenca = (valor1, valor2, porcentagem=false) => {
        if (porcentagem) {
            return formatterPercentage(valor1 - valor2);
        }
        return formatterCurrency(valor1 - valor2);
    };

    return (
        <React.Fragment>
            <Modal
                open={openViewProjetada}
                onClose={() => setOpenViewProjetada(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="view-projetada-modal"
            >
                <Paper sx={{ ...smallPaperStyle, width: "45%", backgroundColor: temaEscuro ? BLUE_THEME : null }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <span style={{...styleTextStrong, fontSize: "24px", fontWeight: "700", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Projeções para {selectedDate}:</span>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container sx={{backgroundColor: temaEscuro ? BLACK_TABLE_THEME : null}}>
                                <Grid item xs={12}>
                                    <Grid container sx={{borderLeft: "solid #4D4D4D", borderTop: "solid #4D4D4D", borderBottom: "solid #4D4D4D", borderRight: "solid #4D4D4D", borderWidth: "1px"}}>
                                        <Grid item xs={12}>
                                            <Grid container sx={{ borderBottom: "solid #4D4D4D", borderWidth: "1px" }} >
                                                <Grid xs={3} sx={{ borderRight: "solid #4D4D4D", borderWidth: "1px" }} />
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h2 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>Projeção</h2>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h2 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>Diferença</h2>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                                    <h2 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>Realizado</h2>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container sx={{borderBottom: "solid #4D4D4D", borderWidth: "1px"}}>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>Faturamento Líquido Total</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterCurrency(faturamentoLiqTotalViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{calcDiferenca(faturamentoProjetado, faturamentoLiqTotalViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterCurrency(faturamentoProjetado)}</h3>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container sx={{borderBottom: "solid #4D4D4D", borderWidth: "1px"}}>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>Taxas e Impostos sobre Vendas</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterCurrency(taxasimpostosViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{calcDiferenca(taxasimpostosProjetado, taxasimpostosViewProjetado, true)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterCurrency(taxasimpostosProjetado)}</h3>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container sx={{borderBottom: "solid #4D4D4D", borderWidth: "1px"}}>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>CMV (%)</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterPercentage(cmvPorcentViewProjetado)}%</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{calcDiferenca(cmvPorcentagemProjetado, cmvPorcentViewProjetado, true)}%</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterPercentage(cmvPorcentagemProjetado)}%</h3>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container sx={{borderBottom: "solid #4D4D4D", borderWidth: "1px"}}>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>CMO (%)</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterPercentage(cmoViewProjetado)}%</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{calcDiferenca(cmoProjetado, cmoViewProjetado, true)}%</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterPercentage(cmoProjetado)}%</h3>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container sx={{borderBottom: "solid #4D4D4D", borderWidth: "1px"}}>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>Despesas com Entregas</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterCurrency(despesasEntregasViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : null}}>{calcDiferenca(despesasEntregasProjetado, despesasEntregasViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                                    <h3 style={{...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null}}>{formatterCurrency(despesasEntregasProjetado)}</h3>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container sx={{borderBottom: "solid #4D4D4D", borderWidth: "1px"}}>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>Despesas com Pessoal</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterCurrency(despesasPessoalViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : null}}>{calcDiferenca(despesasPessoalProjetado, despesasPessoalViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                                    <h3 style={{...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null}}>{formatterCurrency(despesasPessoalProjetado)}</h3>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{borderBottom: "solid #4D4D4D", borderWidth: "1px"}}>
                                            <Grid container>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>Despesas Operacionais</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterCurrency(despesasOperacionalViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : null}}>{calcDiferenca(despesasOperacionalProjetado, despesasOperacionalViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                                    <h3 style={{...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null}}>{formatterCurrency(despesasOperacionalProjetado)}</h3>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container sx={{borderBottom: "solid #4D4D4D", borderWidth: "1px"}}>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>Lucro/Prejuízo Operacional</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterCurrency(lucroPrejuizoLiqViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{calcDiferenca(lucroPrejuizoProjetado, lucroPrejuizoLiqViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterCurrency(lucroPrejuizoProjetado)}</h3>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container sx={{borderBottom: "solid #4D4D4D", borderWidth: "1px"}}>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>Lucro/Prejuízo Operacional (%)</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterPercentage(lucroPrejuizoLiqPorcentViewProjetado)}%</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{calcDiferenca(lucroPrejuizoPorcentagemProjetado, lucroPrejuizoLiqPorcentViewProjetado, true)}%</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterPercentage(lucroPrejuizoPorcentagemProjetado)}%</h3>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>Ponto de Equilíbrio</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterCurrency(pontoEquilibrioViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center", borderRight: "solid #4D4D4D", borderWidth: "1px" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{calcDiferenca(pontoEquilibrioProjetado, pontoEquilibrioViewProjetado)}</h3>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                                    <h3 style={{ ...styleTextStrong, fontSize: "15px", color: temaEscuro ? WHITE_ESCRITA_THEME : null }}>{formatterCurrency(pontoEquilibrioProjetado)}</h3>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        
                                        
                                        
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default ViewProjetada;