import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import YellowButton from "../Buttons/YellowButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import api from "../../axios";
import {
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    styleGrid,
    styleText,
    styleTextStrong,
    smallPaperStyle, 
    GRAY_LABEL_UX,
    DIVIDER_COLOR,
    GRAY_LABEL_UX_TEMA,
} from "../../shared/utils";
import moment from "moment";
import "moment/locale/pt-br";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

const UpdateNewsModal = (props) => {
    const {openModal, setOpenModal} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const [dataRelease, setDataRelease] = React.useState("");
    const [descricaoRelease, setDescricaoRelease] = React.useState("");
    const [descricaoReleaseAdm, setDescricaoReleaseAdm] = React.useState("");
    const [checked, setChecked] = React.useState(true);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);

    useEffect(() => {
		getLatestRelease();
	}, []);

    const disableModal = () => {
        api.PutDisableUpdateNews().then(() => {
            setOpenModal(false);
        });
    };

    const handleCloseModal = () => {
        if (checked) {
            disableModal();
        } else {
            setOpenModal(false);
        }
    };

    const handleCheckBox = (event) => {
        setChecked(event.target.checked);
    };

    const getLatestRelease = () => {
        api.GetLatestRelease().then(response => {
            let data = response.data.data_atualizacao;
            let parsedDate = moment(data, "DD/MM/YYYY");
            let formattedDate = parsedDate.format("DD [de] MMMM [de] YYYY", { locale: "pt-br" });
            setDataRelease(formattedDate);
            setDescricaoRelease(response.data.descricao);
            setDescricaoReleaseAdm(response.data.descricao_admin);
        }).catch(() => {
            setOpenModal(false);
        });
    };

    return(
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="update-news-modal"
            >
                <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} className="start">
                            <span style={{...styleTextStrong, display: "flex", justifyContent: "center", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>
                                Fique por dentro das últimas novidades!
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <p style={{...styleText, display: "flex", justifyContent: "left", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>
                                Data da última modificação: {dataRelease}
                            </p>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{maxHeight: "50vh", overflowY: "auto"}}>
                                <CardContent sx={{padding: 0}}>
                                    <a style={styles.linkStyle}>
                                        <p dangerouslySetInnerHTML={{__html:descricaoRelease}}></p>
                                    </a>
                                    { isAdmin && ( 
                                        <React.Fragment>
                                            <Divider sx={{...styles.divider, color: temaEscuro ? GRAY_LABEL_UX_TEMA : DIVIDER_COLOR}} />
                                                <a style={styles.linkStyle}>
                                                     <p dangerouslySetInnerHTML={{ __html: descricaoReleaseAdm }}></p>
                                                </a>
                                         </React.Fragment>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container sx={{display: "flex", justifyContent: "center"}}>
                        <Grid item sx={{marginTop: 3}} xs={12}>
                            <FormControlLabel 
                                control={<Checkbox checked={checked} onChange={handleCheckBox}/>} 
                                label="Não mostrar mais"
                                data-testid={"checkbox"} />
                        </Grid>
                        <Grid item sx={{...styleGrid, marginTop: 0}}>
                            <YellowButton
                                onClick={handleCloseModal}
                                testId="generic-continue-btn"
                                title="Fechar"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default UpdateNewsModal;

const styles = {
    linkStyle: {
      whiteSpace: "pre-line", // Preserve tab characters
    },
};