export const steps = [
    {
        selector: ".button_filter", 
        content: "Filtra os meses que serão apresentados na DFC."
    },
    {
        selector: ".button_reload", 
        content: "Atualiza o relatório com as informações mais recentes."
    },
    {
        selector: ".button_export", 
        content: "Para imprimir ou salvar o relatório em PDF."
    },
    {
        selector: ".linha_1", 
        content: "Soma de todos os subitens dele (Ex.: “Receita de Vendas”, “Receita a Identificar”, ...)."
    },
    {
        selector: ".linha_2", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Receita de Vendas."
    },
    {
        selector: ".linha_3", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Receitas a Identificar."
    },
    {
        selector: ".linha_4", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Receita de Reembolso."
    },
    {
        selector: ".linha_5", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Comissões sobre Vendas."
    },
    {
        selector: ".linha_6", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Combustível e Entregas."
    },
    {
        selector: ".linha_7", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Impostos sobre Vendas."
    },
    {
        selector: ".linha_8", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Devoluções de Vendas."
    },
    {
        selector: ".linha_9", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Taxa de Marketing."
    },
    {
        selector: ".linha_10", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Royalties."
    },
    {
        selector: ".linha_11", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Insumos."
    },
    {
        selector: ".linha_12", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Embalagens."
    },
    {
        selector: ".linha_13", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Produtos para Comercialização."
    },
    {
        selector: ".linha_14", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Salários."
    },
    {
        selector: ".linha_15", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Bonificações."
    },
    {
        selector: ".linha_16", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Impostos Sobre Salários."
    },
    {
        selector: ".linha_17", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Vale Alimentação."
    },
    {
        selector: ".linha_18", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Vale Transporte."
    },
    {
        selector: ".linha_19", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Rescisões."
    },
    {
        selector: ".linha_20", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Freelancers e Terceirizados."
    },
    {
        selector: ".linha_21", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Pró-Labore e Despesas de Sócios."
    },
    {
        selector: ".linha_22", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Outros Encargos com Funcionários."
    },
    {
        selector: ".linha_23", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Férias."
    },
    {
        selector: ".linha_24", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Salários."
    },
    {
        selector: ".linha_25", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Músicos."
    },
    {
        selector: ".linha_26", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Despesas com Marketing."
    },
    {
        selector: ".linha_27", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Endomarketing."
    },
    {
        selector: ".linha_28", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Aluguel e Condomínio."
    },
    {
        selector: ".linha_29", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Água e Energia."
    },
    {
        selector: ".linha_30", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Gás de Cozinha."
    },
    {
        selector: ".linha_31", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Aluguel de Equipamentos."
    },
    {
        selector: ".linha_32", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Segurança, Telefonia e Internet."
    },
    {
        selector: ".linha_33", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Softwares e Terceirizados."
    },
    {
        selector: ".linha_34", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Limpeza e Higiene."
    },
    {
        selector: ".linha_35", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Manutenção Predial e de Equipamentos."
    },
    {
        selector: ".linha_36", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Transporte."
    },{
        selector: ".linha_37", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Materiais de Escritório."
    },
    {
        selector: ".linha_38", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Tarifas Bancárias."
    },
    {
        selector: ".linha_39", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Outras Despesas Administrativas."
    },
    {
        selector: ".linha_40", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Despesas a Identificar"
    },
    {
        selector: ".linha_41", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Manutenção de Veículos."
    },
    {
        selector: ".linha_42", 
        content: "Soma de todos os subitens dele (Ex.: “Investimento Em Equipmentos e Estrutura”, “Rendimentos de Aplicações”, ...)."
    },
    {
        selector: ".linha_43", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Investimento em Equipamentos e Estrutura."
    },
    {
        selector: ".linha_44", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Rendimento de Aplicações."
    },
    {
        selector: ".linha_45", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Impostos sobre Aplicações."
    },
    {
        selector: ".linha_46", 
        content: "Soma das “Atividades Operacionais” com as “Atividades de Investimento”."
    },
    {
        selector: ".linha_47", 
        content: "Soma de todos os subitens dele (Ex.: “Empréstimos - Entrada”, “Empréstimos - Saída”, ...)."
    },
    {
        selector: ".linha_48", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Empréstimos - Entrada."
    },
    {
        selector: ".linha_49", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Empréstimos - Saída."
    },
    {
        selector: ".linha_50", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Aportes."
    },
    {
        selector: ".linha_51", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Retiradas de Lucro."
    },
    {
        selector: ".linha_52", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Transferências de Saída."
    },
    {
        selector: ".linha_53", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Transferências de Entrada."
    },
    {
        selector: ".linha_54", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Saldo Inicial."
    },
    {
        selector: ".linha_55", 
        content: "Soma de todos os lançamentos do Conta Azul que se caracterizam como Ajuste de Caixa."
    },
    {
        selector: ".linha_56", 
        content: "Soma das “Atividades Operacionais” com as “Atividades de Investimento” e “Atividades de Financiamento”."
    },
];