import React, { useState, useCallback }  from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./GenericModal.css";
import {
    BLUE_THEME, smallPaperStyle,
    styleTextStrong, WHITE_ESCRITA_THEME
} from "../../shared/utils";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";
import ModalFixedValue from "./ModalFixedValue";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InsertComment from "./Comments/InsertComment";
import VisualizeComment from "./Comments/VisualizeComment";
import {dreProvisionedFields} from "../../containers/DRE/DREEnums";
import {useSelector} from "react-redux";

const GenericModal = (props) => {
	const {
        openModal,
        setOpenModal,
        dateFixed,
        fixedValue,
        setFixedValue,
        setOpenModalFixedValue,
        openModalFixedValue,
        component,
        setOpenViewCommentModal,
        setOpenInsertCommentModal,
        openViewCommentModal,
        openInsertCommentModal,
        commentsList,
        setComment,
        comment,
        editComment,
        loadingModal,
        delMetadata,
        sendMetadata,
        isEdit,
        setIsEdit,
        handleDetalharValor,
        selectedField,
        fieldId,
        isConsolidada,
        handleProvision,
        revisada,
        isConciliacao = false,
        estabelecimentosSelected = 0,
        isPainel = false,
    } = props;

    const [checked, setChecked] = useState("");
    const [commentId, setCommentId] = useState(0);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const handleOpenModal = useCallback(() => {
        setOpenModal(false);
        if (checked === "comment") {
            if (commentsList.length > 0) {
                setOpenViewCommentModal(true);
            } else {
                setOpenInsertCommentModal(true);
            }
        } else if (checked === "fixedValue") {
            setOpenModalFixedValue(true);
        } else if (checked === "detailValue") {
            handleDetalharValor();
        } else if (checked === "provisionMarketing") {
            handleProvision();
        }
    }, [checked, fieldId, dateFixed, commentsList]);

    const handleChangeChecked = (event) => {
        setChecked(event.target.value);
    };

	return(
		<React.Fragment>
            <VisualizeComment
                openViewCommentModal={openViewCommentModal}
                setOpenViewCommentModal={setOpenViewCommentModal}
                commentsList={commentsList}
                setOpenInsertCommentModal={setOpenInsertCommentModal}
                setComment={setComment}
                setIsEdit={setIsEdit}
                setCommentId={setCommentId}
                delMetadata={delMetadata}
                component={component}
            />
            <InsertComment
                openInsertCommentModal={openInsertCommentModal}
                setOpenInsertCommentModal={setOpenInsertCommentModal}
                loadingModal={loadingModal}
                sendMetadata={sendMetadata}
                comment={comment}
                commentId={commentId}
                editComment={editComment}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                component={component}
            />
            <ModalFixedValue
                openModalFixedValue={openModalFixedValue}
                setOpenModalFixedValue={setOpenModalFixedValue}
                dateFixed={dateFixed}
                loadingModal={loadingModal}
                fixedValue={fixedValue}
                sendMetadata={sendMetadata}
                setFixedValue={setFixedValue}
                delMetadata={delMetadata}
                setOpenModal={setOpenModal}
                selectedField={selectedField}
                component={component}
            />
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
                data-testid="generic-modal"
			>
				<Paper style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME}}>
                    <Grid item xs={10} className="start">
                        {
                            component === "conciliacao" ? (
                                <h3 style={{...styleTextStrong, color: temaEscuro && WHITE_ESCRITA_THEME, marginTop: 0}}>Linha ({dateFixed}):</h3>
                            ) : (
                                <h3 style={{...styleTextStrong, color: temaEscuro && WHITE_ESCRITA_THEME, marginTop: 0}}>{selectedField}:</h3>
                            )
                        }
					</Grid>
                    <Grid container spacing={2} className="relative">
                        <Grid item>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Selecione a opção desejada:</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={checked}
                                    onChange={handleChangeChecked}
                                >
                                    <FormControlLabel disabled={isConsolidada || estabelecimentosSelected.length > 1} value="comment" control={<Radio />} label="Inserir Comentário" />
                                    <FormControlLabel disabled={(isPainel || isConsolidada || (revisada || estabelecimentosSelected.length > 1))} value="fixedValue" control={<Radio />} label="Fixar Valor para o Campo" />
                                    <FormControlLabel disabled={isPainel || isConciliacao} value="detailValue" control={<Radio />} label="Detalhar Valor" />
                                    {
                                        dreProvisionedFields.includes(selectedField) && (
                                            <FormControlLabel disabled={isConsolidada} value="provisionMarketing" control={<Radio />} label={`Provisionar valor para "${selectedField}"`} />
                                        )
                                    }
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container sx={{display: "flex", justifyContent: "end", gap: 2, marginTop: 5}}>
                        <Grid item>
                            <GrayButton
                                onClick={() => setOpenModal(false)}
                                title="Cancelar"/>
                        </Grid>
                        <Grid item>
                            <YellowButton
                                onClick={handleOpenModal}
                                testId="generic-continue-btn"
                                title="Continuar"
                                disabled={isConciliacao && estabelecimentosSelected.length > 1}
                            />
                        </Grid>
                    </Grid>
				</Paper>
			</Modal>
		</React.Fragment>
	);
};

export default React.memo(GenericModal);