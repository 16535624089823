import {
    BLUE_THEME,
    BORDER_TABLE,
    GRAY_LABEL_UX,
    styleLegend, tableStyle,
    WHITE_THEME_BLACK,
    WHITE_TABLE
} from "../../../shared/utils";
import React from "react";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import Row from "./Row";

const TableResolucao = (props) => {
    const {columns, rows, reloadTable, colSpan, periodo} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const styleColor = {
        color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
        backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE,
        borderColor: temaEscuro && BORDER_TABLE
    };

    return (
        <Grid container sx={{margin: "20px 0"}}>
            <Grid item xs={12} style={{overflow: "auto"}}>
                <table style={{...tableStyle.style, ...styleColor}}>
                    <thead>
                    <tr>
                        <th style={{...tableStyle.firstCol, ...tableStyle.tdTh, ...styleColor}}>
                            Plataforma / Loja
                        </th>
                        {
                            columns.map((col, key) => {
                                return (
                                    <th key={key} style={{...tableStyle.tdTh, ...styleColor}}>
                                        {col}
                                    </th>
                                );
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map((item, index) => {
                            return (
                                <Row
                                    key={item.plataforma + index + periodo}
                                    styleColor={styleColor}
                                    plataforma={item.plataforma}
                                    colorList={item?.colors || []}
                                    msgList={item?.messages || []}
                                    hasChildren={item.hasChildren}
                                    childrenData={item.childrenData}
                                    colSpan={colSpan}
                                    reloadTable={reloadTable}
                                />
                            );
                        })
                    }
                    </tbody>
                </table>
            </Grid>
            <Grid>
                <div data-testid="legend-resolucao">
                    <div style={{width: "100%", color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
                        <h4>Legenda</h4>
                    </div>
                    <div style={styles.wrapper}>
                        <div style={{...styles.circle, ...styleLegend.green}}/>
                        <div style={{...styles.textLegend, color: temaEscuro && WHITE_THEME_BLACK}}>
                            Relatório Processado
                        </div>
                    </div>
                    <div style={styles.wrapper}>
                        <div style={{...styles.circle, ...styleLegend.dark_green}}/>
                        <div style={{...styles.textLegend, color: temaEscuro && WHITE_THEME_BLACK}}>
                            Relatório Baixado
                        </div>
                    </div>
                    <div style={styles.wrapper}>
                        <div style={{...styles.circle, ...styleLegend.blue}}/>
                        <div style={{...styles.textLegend, color: temaEscuro && WHITE_THEME_BLACK}}>
                            Sem Relatório p/ o Período
                        </div>
                    </div>
                    <div style={styles.wrapper}>
                        <div style={{...styles.circle, ...styleLegend.orange}}/>
                        <div style={{...styles.textLegend, color: temaEscuro && WHITE_THEME_BLACK}}>
                            Não tem Login
                        </div>
                    </div>
                    <div style={styles.wrapper}>
                        <div style={{...styles.circle, ...styleLegend.white}}/>
                        <div style={{...styles.textLegend, color: temaEscuro && WHITE_THEME_BLACK}}>
                            Ainda Não Baixou
                        </div>
                    </div>
                    <div style={styles.wrapper}>
                        <div style={{...styles.circle, ...styleLegend.purple}}/>
                        <div style={{...styles.textLegend, color: temaEscuro && WHITE_THEME_BLACK}}>
                            Login Desabilitado por API
                        </div>
                    </div>
                    <div style={styles.wrapper}>
                        <div style={{...styles.circle, ...styleLegend.red}}/>
                        <div style={{...styles.textLegend, color: temaEscuro && WHITE_THEME_BLACK}}>
                            Login e/ou Senhas Errados
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

const styles = {
    circle: {
        borderRadius: "50%",
        width: "20px",
        height: "20px",
        margin: "5px"
    },
    textLegend: {
        margin: "5px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    wrapper: {
        display: "flex",
        fontSize: "15px"
    },
};

export default React.memo(TableResolucao);