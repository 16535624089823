import React, {useEffect, useState}  from "react";
import Button from "@mui/material/Button";
import {
	styleButton,
	muiRootStyle,
	textCompleteStyleBlack,
	WHITE_THEME_BLACK,
	BLUE_THEME,
	GRAY_LABEL_UX,
	BORDER_BUTTON,
	convertMonthToStr,
	getLastMonthYear,
	getLastMonth
} from "../../shared/utils";
import Grid from "@mui/material/Grid";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {useSelector} from "react-redux";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";


const FilterYear = (props) => {
	const {handleSubmit, prevYear, prevMonth, optionFilter, marginBottom} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const [month, setMonth] = useState();
	const lastMonth = getLastMonth();
	const lastMonthYear = getLastMonthYear();
	const lastMonthDate = moment(`${lastMonthYear}-${convertMonthToStr(lastMonth)}-01`).toDate();
	
	useEffect(() => {
		if (prevYear != null && prevMonth != null) {
			setMonth(moment(`${prevYear}-${convertMonthToStr(prevMonth)}-01`).toDate());
		}
		else {
			setMonth(moment().startOf("month").toDate());
		}
	}, []);

	const getMonthYear = (newValue) => {
		let date =  month;
		if (newValue instanceof Date){
			date = newValue;
		}
		const formattedMes = moment(date).format("MM");
		const formattedAno = moment(date).format("YYYY");
		handleSubmit(formattedAno, formattedMes);
	};

	return (
		<Box sx={{marginBottom: (marginBottom != null) ? marginBottom : 2}}>
			<Grid container sx={{display: "flex", alignItems: "center"}} spacing={2}>
				<Grid item sx={{width: "200px"}}>
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
						<DatePicker
							views={["year", "month"]}
							minDate={moment("2021-12-01").toDate()}
							maxDate={props?.blockCurrentMonth ? lastMonthDate : new Date()}
							value={month || null}
							sx={border}
							onChange={(newValue) => {
								setMonth(newValue);
								if (!optionFilter){
									getMonthYear(newValue);
								}
							}}
							slots={{
								openPickerIcon: CalendarTodayOutlinedIcon,
								textField: (params) => (
									<TextField
										{...params}
										inputProps={{"data-testid": props?.testId, ...params.inputProps}}
										helperText={null}
										sx={temaEscuro ? textCompleteStyleBlack : muiRootStyle}
										style={{backgroundColor: temaEscuro && BLUE_THEME}}
									/>
								)
							}}
							slotProps={{
								inputAdornment: {position: "start"},
								textField: { size: "small" }
							}}
						/>
					</LocalizationProvider>
				</Grid>
				{
					optionFilter &&
					(
						<Grid item>
							<Button
								variant="contained"
								data-testid="button-filter-year"
								onClick={getMonthYear}
								startIcon={<FilterListOutlinedIcon/>}
								style={{...styleButton, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, borderColor:temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX}}
							>
								Filtrar
							</Button>
						</Grid>
					)
				}
			</Grid>
		</Box>
	);

};

export default React.memo(FilterYear);

const border ={
	"& .css-126wocv-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root fieldset": {
		border: "none"
	},
};