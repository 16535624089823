import React from "react";

const spaceTop = {
    marginTop: "-7px"
  };

const cardTour = {
    display: "flex",
    flexDirection: "column",
    textAlign: "left"
};

const cardCenter = {
    textAlign: "center"
};

const titleCard = {
    fontWeight: "bolder",
    textAlign: "center"
};


export const steps = [
    {
        selector: ".button_filter", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Filtrar</p>
                <p style={spaceTop}>Filtra os meses que serão apresentados no Painel.</p>
            </div>
        )
    },
    {
        selector: ".button_options",
        content: () => (
            <div>
                <p><strong>Mostrar Zeros</strong> Detalha todas as opções mostrando todas as linhas.</p>
                <p><strong>Porcentagem:</strong> Valor, em porcentagem, de cada item da DRE em relação ao faturamento. Os itens abaixo de Faturamento Líquido Total são divididos por ele, já os itens acima são divididos pelo Faturamento Bruto Total.</p>
            </div>
        )
    },
    {
        selector:  ".button_export", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Exportar</p>
                <p style={spaceTop}>Para imprimir ou para salvar o relatório em PDF.</p>
            </div>
        )
    },
    {
        selector: ".linha_1", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Faturamento Bruto</p>
                <p>Faturamento bruto das vendas provenientes do sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_2", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Frente de Caixa Cliente</p>
                <p style={spaceTop}>Soma das <strong>vendas</strong> provenientes do sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_3", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Salão (Vendas)</p>
                <p style={spaceTop}>Soma das <strong>vendas</strong> registradas no sistema frente de caixa, onde o canal de venda não se encontra na lista abaixo (Ex.: Ifood, Rappi, ...).</p>
            </div>
        )
    },
    {
        selector: ".linha_4", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Ifood</p>
                <p>Soma das vendas feitas pelo Ifood registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_5", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Rappi</p>
                <p>Soma das vendas feitas pelo Rappi registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_6", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>99 Food</p>
                <p>Soma das vendas feitas pelo 99 Food registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_7", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Pede Pronto</p>
                <p>Soma das vendas feitas pelo Pede Pronto registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_8", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>James</p>
                <p>Soma das vendas feitas pelo James registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_9", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Accon Delivery</p>
                <p>Soma das vendas feitas pelo Accon Delivery registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_10", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Delivery Direto</p>
                <p>Soma das vendas feitas pelo Delivery Direto registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_11", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>AiqFome</p>
                <p>Soma das vendas feitas pelo AiqFome registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_12", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Eatzap</p>
                <p>Soma das vendas feitas pelo Eatzap registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_13", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Americanas</p>
                <p>Soma das vendas feitas pelo Americanas registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_14", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Neemo Delivery</p>
                <p>Soma das vendas feitas pelo Neemo Delivery registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_15", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>To no Lucro</p>
                <p>Soma das vendas feitas pelo To no Lucro registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_16", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Salão</p>
                <p>Crédito +</p>
                <p style={spaceTop}>Débito +</p>
                <p style={spaceTop}>Vouchers Alimentação +</p>
                <p style={spaceTop}>Pix +</p>
                <p style={spaceTop}>Picpay</p>
            </div>
        )
    },
    {
        selector: ".linha_17", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Crédito</p>
                <p>Soma das vendas no crédito registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_18", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Débito</p>
                <p>Soma das vendas no débito registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_19", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Vouchers de Alimentação</p>
                <p>Soma das vendas feitas por Vouchers de Alimentação registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_20", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Pix</p>
                <p>Soma das vendas feitas pelo Pix registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_21", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Picpay</p>
                <p>Soma das vendas feitas pelo Picpay registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_22", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Aplicativo Próprio</p>
                <p>Soma das vendas feitas por Próprio App registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_23", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Telefone</p>
                <p>Soma das vendas feitas por Telefone registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_24", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>WhatsApp</p>
                <p>Soma das vendas feitas por WhatsApp registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_25", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Dinheiro</p>
                <p>Soma das vendas pagas em espécie registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_26", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Outas Receitas</p>
                <p>Soma das vendas registradas no sistema frente de caixa registradas como Outras Receitas.</p>
            </div>
        )
    },
    {
        selector: ".linha_27", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Devoluções e Cancelamentos</p>
                <p>Soma das Devoluções e Cancelamentos registradas no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_28", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Salão</p>
                <p>Soma das <strong>vendas canceladas</strong> no sistema frente de caixa, onde o canal de venda não se encontra na lista abaixo (Ex.: Ifood, Rappi, ...).</p>
            </div>
        )
    },
    {
        selector: ".linha_29", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Ifood</p>
                <p>Soma das vendas canceladas feitas pelo Ifood registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_30", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Rappi</p>
                <p>Soma das vendas canceladas feitas pelo Rappi registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_31", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>99 Food</p>
                <p>Soma das vendas canceladas feitas pelo 99 Food registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_32", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>James</p>
                <p>Soma das vendas canceladas feitas pelo James registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_33", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Accon Delivery</p>
                <p>Soma das vendas canceladas feitas pelo Accon Delivery registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_34", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Aiqfome</p>
                <p>Soma das vendas canceladas feitas pelo Aiqfome registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_35", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Eatzap</p>
                <p>Soma das vendas canceladas feitas pelo Eatzap registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_36", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Americanas</p>
                <p>Soma das vendas canceladas feitas pelo Americanas registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_37", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Neemo Delivery</p>
                <p>Soma das vendas canceladas feitas pelo Neemo Delivery registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_38", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>To no Lucro</p>
                <p>Soma das vendas canceladas feitas pelo To no Lucro registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_39", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Salão</p>
                <p>Soma das vendas canceladas feitas pelo Salão registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_40", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Próprio App</p>
                <p>Soma das vendas canceladas feitas pelo Próprio App registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_41", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Telefone</p>
                <p>Soma das vendas canceladas feitas pelo Telefone registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_42", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>WhatsApp</p>
                <p>Soma das vendas canceladas feitas pelo WhatsApp registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_43", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Outros</p>
                <p>Soma das vendas canceladas no sistema frente de caixa, no qual não se encontram em nenhuma categoria descrita acima.</p>
            </div>
        )
    },
    {
        selector: ".linha_44", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Descontos</p>
                <p>Soma dos descontos fornecidos sobre todas as vendas registradas no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_45", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Salão - Descontos</p>
                <p>Soma dos descontos fornecidos sobre todas as vendas no sistema frente de caixa, onde o canal de venda não se encontra na lista abaixo (Ex.: Ifood, Rappi, ...).</p>
            </div>
        )
    },
    {
        selector: ".linha_46", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Ifood</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo Ifood registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_47", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Rappi</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo Rappi registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_48", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>99 Food</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo 99 Food registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_49", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>James</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo James registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_50", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Americanas</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo Americanas registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_51", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Neemo Delivery App</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo Neemo Delivery App registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_52", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Pede Pronto</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo Pede Pronto registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_53", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Eatzap</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo Eatzap registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_54", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Accon Delivery</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo Accon Delivery registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_55", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>AiqFome</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo AiqFome registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_56", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>To no Lucro</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo To no Lucro registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_57", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Salão</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo Salão registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_58", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Próprio App</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo Próprio App registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_59", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Telefone</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo Telefone registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_60", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>WhatsApp</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo WhatsApp registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_61", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Outros</p>
                <p>Soma dos descontos fornecidos sobre as vendas feitas pelo Outros registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_62", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Crédito</p>
                <p>Soma dos descontos fornecidos sobre as vendas no crédito registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_63", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Débito</p>
                <p>Soma dos descontos fornecidos sobre as vendas no débito registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_64", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Espécie</p>
                <p>Soma dos descontos fornecidos sobre as vendas pagas em espécie registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_65", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Voucher de Alimentação</p>
                <p>Soma dos descontos fornecidos sobre as vendas por voucher de alimentação registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_66", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Pix</p>
                <p>Soma dos descontos fornecidos sobre as vendas no pix registrado no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_67", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Fretes</p>
                <p>Soma dos fretes cobrados sobre todas as vendas registradas no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_68", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Fretes Ifood</p>
                <p>Soma dos fretes cobrados pelo Ifood sobre as vendas registradas no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_69", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Fretes Rappi</p>
                <p>Soma dos fretes cobrados pelo Rappi sobre as vendas registradas no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_70", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Fretes 99 Pop</p>
                <p>Soma dos fretes cobrados pelo 99 Pop sobre as vendas registradas no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_71", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Fretes James</p>
                <p>Soma dos fretes cobrados pelo James sobre as vendas registradas no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_72", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Fretes Pede Pronto</p>
                <p>Soma dos fretes cobrados pelo Pede Pronto sobre as vendas registradas no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_73", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Fretes Accon Delivery</p>
                <p>Soma dos fretes cobrados pelo Accon Delivery sobre as vendas registradas no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_74", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Fretes Americanas</p>
                <p>Soma dos fretes cobrados pelo Americanas sobre as vendas registradas no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_75", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Fretes To no Lucro</p>
                <p>Soma dos fretes cobrados pelo To no Lucro sobre as vendas registradas no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_76", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Frete Delivery Próprio</p>
                <p>Soma dos fretes cobrados pelo Próprio App sobre as vendas registradas no sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_77", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Fretes (Outros)</p>
                <p>Soma dos fretes cobrados sobre as vendas registradas no sistema frente de caixa, onde o canal de venda não se encontra na lista acima.</p>
            </div>
        )
    },
    {
        selector: ".linha_78",
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Receita Bruta</p>
                <p>Frente de Caixa Cliente -</p>
                <p style={spaceTop}>Descontos -</p>
                <p style={spaceTop}>Devoluções e Cancelamentos -</p>
                <p style={spaceTop}>Fretes</p>
            </div>
        )
    },
    {
        selector: ".linha_79",
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Taxas de Cartão e Apps de Delivery</p>
                <p>Taxas de Aplicativos Delivery +</p>
                <p style={spaceTop}>Taxas de Adquirentes (Cartão) +</p>
                <p style={spaceTop}>Taxas de Vouchers Alimentação</p>
            </div>
        )
    },
    {
        selector: ".linha_80", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Taxas App Delivery</p>
                <p>Soma das taxas cobradas sobre todas as vendas registradas em cada canal de venda (Ex.: Ifood, Rappi, ...).</p>
            </div>
        )
    },
    {
        selector: ".linha_81", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Taxas (Rappi)</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pelo Rappi.</p>
            </div>
        )
    },
    {
        selector: ".linha_82", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Taxas (AiqFome)</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pelo AiqFome.</p>
            </div>
        )
    },
    {
        selector: ".linha_83",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Ifood</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pelo Ifood.</p>
            </div>
        )
    },
    {
        selector: ".linha_84", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Comissões Ifood</p>
                <p>Comissões provinientes do Ifood</p>
            </div>
        )
    },
    {
        selector: ".linha_85", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Incentivo Ifood</p>
                <p>Incentivo provinientes do Ifood</p>
            </div>
        )
    },
    {
        selector: ".linha_86", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Taxa de Serviços</p>
                <p>Taxa de Serviços provinientes do Ifood</p>
            </div>
        )
    },
    {
        selector: ".linha_87",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Delivery Direto</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pelo Delivery Direto.</p>
            </div>
        )
    },
    {
        selector: ".linha_88",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Taxa de serviços</p>
                <p>Taxa de Serviços provinientes do Delivery Direto.</p>
            </div>
        )
    },
    {
        selector: ".linha_89", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Taxa de Adquirentes (Cartão)</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pelas adquirentes de cartão.</p>
            </div>
        )
    },
    {
        selector: ".linha_90", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Cielo</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pela Cielo.</p>
            </div>
        )
    },
    {
        selector: ".linha_91", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Stone</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pela Stone.</p>
            </div>
        )
    },
    {
        selector: ".linha_92",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Getnet</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pela Getnet.</p>
            </div>
        )
    },
    {
        selector: ".linha_93", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Rede</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pela Rede.</p>
            </div>
        )
    },
    {
        selector: ".linha_94", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Picpay</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pela Picpay.</p>
            </div>
        )
    },
    {
        selector: ".linha_95",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>SafraPay</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pela SafraPay.</p>
            </div>
        )
    },
    {
        selector: ".linha_96", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Pagseguro</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pela Pagseguro.</p>
            </div>
        )
    },
    {
        selector: ".linha_97", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Granito</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pela Granito.</p>
            </div>
        )
    },
    {
        selector: ".linha_98",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Taxa Vouchers de Alimentação</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pelos vouchers de alimentação.</p>
            </div>
        )
    },
    {
        selector: ".linha_99",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Alelo</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pela Alelo.</p>
            </div>
        )
    },
    {
        selector: ".linha_100", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Pluxee</p>
                <p>Soma das taxas cobradas sobre todas as vendas feitas pela Pluxee.</p>
            </div>
        )
    },
    {
        selector: ".linha_101", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Receita Líquida</p>
                <p>Receita Bruta -</p>
                <p style={spaceTop}>Taxas Aplicativos Delivery -</p>
                <p style={spaceTop}>Taxas Adquirentes -</p>
                <p style={spaceTop}>Taxas Vouchers Alimentação</p>
            </div>
        )
    },
];
