import React from "react";


export const steps = [
    {
        selector: "[data-testid='btn-filtrar']",
        content: "Seleção das lojas e dos meses para o relatório."
    },
    {
        selector: ".button_options",
        content: () => (
            <div>
                <p><strong>Recalcular:</strong> Atualiza o relatório com as informações mais recentes.</p>
                <p><strong>Porcentagem:</strong> Valor, em porcentagem, de cada item da DRE Consolidada em relação ao faturamento. Os itens abaixo de Faturamento Líquido Total são divididos por ele, já os itens acima são divididos pelo Faturamento Bruto Total.</p>
            </div>
        )
    },
    {
        selector: ".exportar",
        content: "Para imprimir ou para salvar o relatório em PDF."
    }
];