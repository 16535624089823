import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import {
    formatterCurrency,
    formatterPercentage,
    GRAY_BORDER_TABLE,
    INFO_THEME,
    PAPER_PADDING_THEME,
    paperStyle,
    styleField
} from "../../shared/utils";
import Header from "../../components/Titles/Header";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import PathName from "../../components/Titles/PathName";
import DataGrid from "../../components/DataGrid/DataGrid";


export default function DREAtualizada() {
    const location = useLocation();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [periodo, setPeriodo] = useState("");
    const [loading, setLoading] = useState(true);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);


    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const dataRequest = {
            loja_id: query.get("loja_id"),
            selected_date: query.get("selected_date"),
        };
        setPeriodo(query.get("selected_date"));
        getMostrarAtualizacoesDRE(dataRequest);
    }, []);

    const getMostrarAtualizacoesDRE = (dataRequest) => {
        setLoading(true);
        api.GetAtualizacoesDRE(dataRequest).then(response => {
            const newData = response.data.map((item, index) => {
                return { ...item, id: index };
            });
            setData(newData);
            setLoading(false);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            setLoading(false);
        });
    };

    const renderBorderRight = (params) => {
        return (
            <div style={{ borderRight: temaEscuro ? `1px solid ${INFO_THEME}` : `1px solid ${GRAY_BORDER_TABLE}`}}>
                {params.value}
            </div>
        );
    };

    const columns = [
        { field: "campo", headerName: "Campo", flex: 1, align: "center", headerAlign: "center", renderCell: renderBorderRight },
        {
            field: "revisada",
            headerName: "DRE Revisada",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span>
                        {params.row?.percentage ?
                            formatterPercentage(params.value, 2) :
                            formatterCurrency(params.value, 2)
                        }
                    </span>
                );
            },
        },
        {
            field: "atualizada",
            headerName: "Atualizações",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
            renderCell: (params) => {
                const revisadaValue = params.row.revisada;
                const atualizadaValue = params.value;
                const isBold = revisadaValue !== atualizadaValue;
                return (
                    <span style={{ fontWeight: isBold ? "bold" : "normal" }}>
                        {params.row?.percentage ?
                            formatterPercentage(atualizadaValue, 2) :
                            formatterCurrency(atualizadaValue, 2)
                        }
                    </span>
                );
            },
        },
    ];

    return (
        <div className="main">
            <Header title="Mostrando atualizações da DRE Revisada"/>
            {loading ?  (
                <Skeleton variant="rectangular" width={"100%"} height={"80vh"} />
            ) : (
                <Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container>
                        <Grid item xs={12} sx={{...styleField, marginLeft: 0, display: "flex", alignItems:  "center"}}>
                            <PathName firstPath="DRE" secondPath="Atualizações"/>
                        </Grid>
                        <Grid item sx={{...styleField, marginLeft: 0}}>
                            Período: <b>{periodo}</b>
                        </Grid>
                    </Grid>
                    <Grid sx={{ height: "calc(100vh - 160px - 55px)", width: "100%"}}>
                        <DataGrid
                            columns={columns}
                            data={data}
                            row={(row) => (`${row.campo}-${row.revisada}-${row.atualizada}-${row.key}`)}
                        />
                    </Grid>
                </Paper>
            )}
        </div>
    );
}