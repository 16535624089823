import React, {useState}  from "react";
import { useSelector } from "react-redux";
import {
    smallPaperStyle,
    BLUE_THEME,
    styleInput,
    BORDER_BUTTON,
    textCompleteStyleBlack,
    textCompleteStyle,
    styleTitle,
    WHITE_THEME_BLACK,
    GRAY_BG_UX,
    WHITE_TABLE
} from "../../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import GrayButton from "../../Buttons/GrayButton";
import YellowButton from "../../Buttons/YellowButton";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch} from "react-redux";
import {showSnackMessage} from "../../../actions/SnackActions";
import api from "../../../axios";

const SendConciliacaoModal = (props) => {

    const { 
        openModalSendConciliacao,
        setOpenModalSendConciliacao,
        monthsFiltered,
        platform,
        estabelecimentosSelected
    } = props;

    const dispatch = useDispatch();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const storeId = useSelector(state => state.StoreReducer.storeId);

    const [selectedMonths, setSelectedMonths] = useState("");

    const options = monthsFiltered.map((item) => ({ label: item, value: item }));

    const sendConciliacao = () => {
        if (selectedMonths === null) {
            dispatch(showSnackMessage({message: "Selecione um mês para enviar a conciliação", severity: "warning"}));
        } else {
            let mes = parseInt(selectedMonths.split("/")[0]);
            let ano = parseInt(selectedMonths.split("/")[1]);
            let dataRequest = {
                loja_id: storeId,
                year: ano,
                month: mes,
                plataformas: [platform],
                estabelecimentos: estabelecimentosSelected,
            };
    
            api.SendConciliacao(dataRequest).then(() => {
                dispatch(showSnackMessage({message: "Conciliação enviada com sucesso"}));
            }).catch(() => {
                dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            });
        }
	};

    return(
        <React.Fragment>
            <Modal
                open={openModalSendConciliacao}
                onClose={() => setOpenModalSendConciliacao(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="totais-modal"
            >
                <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro ? BLUE_THEME : null}}>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <h3 style={{...styleTitle, width: "auto", color: temaEscuro && WHITE_THEME_BLACK, marginTop: "16px"}}>
                                Para ENVIAR uma conciliação, via whatsapp, selecione o mês desejado:
                            </h3>
                        </Grid>
                        <Grid item xs={12} sx={{paddingTop: 2}}>
                            <FormControl fullWidth sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, marginTop: 2}} data-testid="select-month">
                                <InputLabel id="demo-simple-select-label" style={{...styleInput, color: temaEscuro && BORDER_BUTTON}}>Mês</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    fullWidth
                                    value={selectedMonths}
                                    onChange={(e) => setSelectedMonths(e.target.value)}
                                    sx={{ color: temaEscuro ? textCompleteStyleBlack : textCompleteStyle, width: "100%" }}
                                    inputProps={{
                                        "data-testid": "option-select",
                                        MenuProps: {
                                            MenuListProps: {
                                                sx: {
                                                    backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE,
                                                    color: temaEscuro ? textCompleteStyleBlack : textCompleteStyle
                                                }
                                            }
                                        }
                                    }}
                                >
                                    {options.map((item) => (
                                        <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container sx={{display: "flex", justifyContent: "end", gap: 2, marginTop: 6}}>
                        <Grid item>
                            <GrayButton
                                onClick={() => setOpenModalSendConciliacao(false)}
                                title="Cancelar"
                            />
                        </Grid>
                        <Grid item>
                            <YellowButton
                                onClick={sendConciliacao}
                                testId="btn-send-email"
                                title="Enviar"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );                  
};

export default React.memo(SendConciliacaoModal);