import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { BLUE_THEME, GRAY_BG_UX, BORDER_BUTTON } from "../../shared/utils";

const InputTextEXM = (props) => {
    const { getExtratoMovimentacoes, searchValue, setSearchValue } = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return (
        <Box>
            <TextField
                label="Pesquisar"
                id="input-with-icon-textfield"
                className={props.className}
                variant="outlined"
                value={searchValue}
                onChange={(event) => {
                    setSearchValue(event.target.value);
                }}
                sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, width: "250px"}}
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment sx={{cursor: "pointer"}} position="end">
                            <SearchIcon onClick={() => getExtratoMovimentacoes()} />
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                }}
            />
        </Box>
    );
};

export default InputTextEXM;