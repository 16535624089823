import {
	CHANGE_STORE,
	CHANGE_OPEN_COMPONENT, 
	CHANGE_MODIFIED,
	CHANGE_LOJAS,
	CHANGE_SEGMENTOS,
	CHANGE_OPEN_ACCORDION_SELECTED,
	CHANGE_SCROLL_POSITION_DATA_GRID
} from "./types";

export const changeStore = (storeId) => {
	return {
		type: CHANGE_STORE,
		payload: storeId
	};
};

export const changeOpenComponent = (open) => {
	return {
		type: CHANGE_OPEN_COMPONENT,
		payload: open
	};
};

export const changeOpenAccordionSelected = (selected) => {
	return {
		type: CHANGE_OPEN_ACCORDION_SELECTED,
		payload: selected
	};
};

export const changeScrollPositionDataGrid = (scrollPosition) => {
	return {
		type: CHANGE_SCROLL_POSITION_DATA_GRID,
		payload: scrollPosition
	};
};

export const changeModified = (dataModified) => {
	return {
		type: CHANGE_MODIFIED,
		payload: dataModified
	};
};

export const changeLojas = (lojas) => {
	return {
		type: CHANGE_LOJAS,
		payload: lojas
	};
};

export const changeSegmentos = (segmentos) => {
	return {
		type: CHANGE_SEGMENTOS,
		payload: segmentos
	};
};