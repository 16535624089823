import React, {useState, useEffect} from "react";
import toast, { Toaster } from "react-hot-toast";
import {onMessageListener} from "../../firebase";
import notificationSound from "../../assets/sounds/notification.mp3";
import ToastDisplay from "./ToastDisplay";

const sound = new Audio(notificationSound);

const Notification = () => {
    const [notification, setNotification] = useState({});

    useEffect(() => {
        handleNotification();
    }, [notification]);

    const handleNotification = () => {
        if (notification?.title) {
            playNotification();
            notify();
        }
    };

    const playNotification = () => {
        const playPromise = sound.play();
        if (playPromise !== undefined) {
            playPromise
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error(`error when trying to play audio: ${error}`);
                });
        }
    };

    const notify = () =>  toast(
        (t) => <ToastDisplay t={t} notification={notification} />,
        {
            duration: 100000,
            style: {
                maxWidth: 500
            }
        }
    );

    // foreground notifications
    onMessageListener()
        .then((payload) => {
            setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
        })
        .catch(() => setNotification({}));

    // background notifications
    navigator?.serviceWorker?.addEventListener("message", function handler(event) {
        const payload = event?.data;
        if(payload?.notification){
            playNotification();
        }
    });

    return (
        <React.Fragment>
            <Toaster position="top-center" reverseOrder={false}/>
        </React.Fragment>

    );
};

export default React.memo(Notification);