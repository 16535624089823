import React, {useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {Link as LinkRouter} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import api from "../../axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {loginUser} from "../../actions/AuthActions";
import Loading from "../../components/Loading/Loading";
import InputPassword from "../../components/Input/InputPassword";
import VoxelLogo from "./VoxelLogo.png";
import RestaurantImage from "./RestaurantImage.png";
import CopyRight from "../../components/CopyRight/CopyRight";
import { GRAY_LABEL_UX, RED_ERROR_UX, GRAY_HEADER_UX, styleYellowButton } from "../../shared/utils";
import "./Login.css";
import ReCAPTCHA from "react-google-recaptcha";

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Inter, cursive",
            color: GRAY_LABEL_UX,
            fontSize: "17px",
            fontWeight: "500",
        },
        button: {
            textTransform: "none",
        }
    },
});


export default function Login() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    // eslint-disable-next-line no-undef
    const [recaptcha, setRecaptcha] = useState(process.env.NODE_ENV === "test" ? "test" : "");

    const deviceToken = useSelector(state => state.AuthReducer.deviceToken);
    const isDesktop = useSelector(state => state.AuthReducer.isDesktop);
    
    const handleRecaptchaChange = (value) => {
        setRecaptcha(value);
    };

    const handleSubmit = () => {
        const data = {
            username: email,
            password,
            recaptcha_token: recaptcha,
            device_token: deviceToken
        };
        setLoading(true);
        api.GetLogin(data).then(response => {
            let token = response.data.token;
            setLoading(false);
            dispatch(loginUser(token));
            window.location.reload();
        }).catch(error => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                setPasswordError(true);
            }
        });
    };

    const validateEmail = (email) => {
        if (email === "") {
            setEmailError(false);
            return;
        }
        if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    };

    return (
        <React.Fragment>
            <Grid container spacing={0} sx={{overflow: "hidden", height: "100vh", alignItems: "center"}}>
                <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
                    <div style={isDesktop ? styles.center : styleMobile.center}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sx={{display: "flex", justifyContent: !isDesktop ? "center" : "flex-start"}}>
                                <img src={VoxelLogo} style={isDesktop ? styles.logo : styleMobile.logo} />
                            </Grid>
                            <Grid item xs={12}>
                                <p style={isDesktop ? styles.header : styleMobile.header}>Entre em sua conta</p>
                            </Grid>
                            <Grid item xs={12}>
                                <h1 style={isDesktop ? styles.text : styleMobile.text}>Insira as credenciais fornecidas para realizar o acesso ao sistema</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <Box component="form" sx={{ mt: 1 }}>
                                    <TextField
                                        data-testid="email-input"
                                        margin="normal"
                                        fullWidth
                                        label="Email"
                                        autoComplete="email"
                                        autoFocus
                                        onChange={(e) => {setEmail(e.target.value.trim()); validateEmail(e.target.value.trim());}}
                                        value={email}
                                        variant="outlined"
                                        error={emailError || passwordError}
                                        InputProps={{style: isDesktop ? undefined : { fontSize: "1rem", height: 50 }}}
                                        InputLabelProps={{style: isDesktop ?  undefined : {fontSize: "0.9rem"}}}
                                    />
                                    {
                                        emailError && (
                                            <p data-testid="email-error" style={{color: RED_ERROR_UX, fontSize: "12px", marginTop: "-2px"}}>Insira um e-mail válido</p>
                                        )
                                    }
                                    <InputPassword label="Senha" password={password} error={passwordError} isDesktop={isDesktop} handleChange={(e) => setPassword(e.target.value)}/>
                                    {
                                        passwordError && (
                                            <p data-testid="email-pwd-error" style={{color: RED_ERROR_UX, fontSize: "12px"}}>O email ou senha informados estão incorretos!</p>
                                        )
                                    }
                                    <Grid style={{marginTop: "25px"}}>
                                        <Grid container sx={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={12} sx={{display: "flex", justifyContent: isDesktop ? "end" : "center"}}>
                                                <LinkRouter data-testid="forgot-password-button" to='/forgot_pwd' variant="body2" style={{textDecoration: isDesktop ? "none" : "underline", color: GRAY_LABEL_UX, fontWeight: "600", fontSize: isDesktop ? "16px" : "12px"}}>
                                                    Esqueci minha senha
                                                </LinkRouter>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* eslint-disable-next-line no-undef */}
                                    <ReCAPTCHA data-testid="recaptcha" style={{...(!isDesktop && styleMobile.recaptcha)}} sitekey={process.env.REACT_APP_SECRET_KEY} onChange={handleRecaptchaChange} />
                                    {
                                        loading ? (
                                            <Loading />
                                        ) : (
                                            <ThemeProvider theme={theme}>
                                                { (emailError || (recaptcha === "") || (password === "")) ? (
                                                    <Button
                                                        disabled
                                                        data-testid="unabled-submit-button"
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        sx={{ mt: 3, mb: 2 }}
                                                        style={{fontWeight: "600"}}
                                                    >
                                                        Entrar
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        data-testid="submit-button"
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        sx={{ mt: 3, mb: 2 }}
                                                        onClick={() => handleSubmit()}
                                                        style={styleYellowButton}
                                                    >
                                                        Entrar
                                                    </Button>
                                                )
                                                }
                                            </ThemeProvider>
                                        )
                                    }
                                </Box>
                            </Grid>
                        </Grid>

                        <div style={isDesktop ? styles.copyright : styleMobile.copyright}>
                            <CopyRight color="black"/>
                        </div>
                    </div>
                </Grid>
                <Box
                    component={Grid}
                    item
                    xs={0}
                    sm={0}
                    md={5}
                    lg={4}
                    xl={4}
                    display={{ xs: "none", sm: "none", md: "block", lg: "block", xl: "block" }}
                    sx={{"height": "100vh"}}
                >
                    <img src={RestaurantImage} className="img"></img>
                </Box>

            </Grid>
        </React.Fragment>
    );
}

const styles = {
    center: {
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    logo: {
        userDrag: "none",
        userSelect: "none",
        pointerEvents: "none",
        width: "191px",
        height: "36px"
    },
    header: {
        width: "398px",
        height: "15px",
        fontFamily: "Kallisto",
        fontWeight: "bold",
        fontSize: "36px",
        lineHeight: "32px",
        color: GRAY_HEADER_UX,
        marginTop: "60px"
    },
    text: {
        width: "411px",
        height: "50px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: GRAY_LABEL_UX,
        textAllign: "left center",
    },
    copyright: {
        textAlign: "center",
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "end",
        paddingBottom: "40px"
    }
};

const styleMobile = {
    center: {
        ...styles.center,
        width: "80%",
    },
    logo: {
        ...styles.logo,
        width: "180px",
        height: "auto",
    },
    header: {
        ...styles.header,
        fontSize: "24px",
        lineHeight: "32px",
        marginTop: "50px",
        textAlign: "center",
        width: "100%",
    },
    text: {
        ...styles.text,
        width: "100%",
        textAlign: "center",
    },
    recaptcha: {
        display: "flex",
        justifyContent: "center",
        marginTop: "25px",
    },
    copyright: {
        ...styles.copyright,
        paddingBottom: "70px",
    }
};

