import React, {useState, useEffect} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CachedIcon from "@mui/icons-material/Cached";
import {
	PAPER_PADDING_THEME,
	GRAY_LABEL_UX_THEME,
	GRAY_LABEL_UX,
	paperStyle,
	YELLOW_BG_UX,
	BLUE_THEME,
	GRAY_BG_UX,
	BORDER_BUTTON,
	textCompleteStyleBlack,
	textCompleteStyle,
	translateAutocomplete
} from "../../shared/utils";
import Skeleton from "@mui/material/Skeleton";
import api from "../../axios";
import {useSelector} from "react-redux";
import SelectStore from "../../components/SelectStore/SelectStore";
import CopyPlano from "../../components/Modal/PlanoContas/CopyPlano";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import NotClassified from "../../components/Modal/PlanoContas/NotClassified";
import UpdateCAModal from "../../components/Modal/PlanoContas/UpdateCAModal";
import Countdown from "react-countdown";
import GrayButton from "../../components/Buttons/GrayButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EditIcon from "@mui/icons-material/Edit";
import PlanoRename from "../../components/Modal/PlanoContas/PlanoRename";
import {showSnackMessage} from "../../actions/SnackActions";
import {useDispatch} from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import Tour from "reactour";
import { DASH_BAR_COLOR, GRID_BUTTONS_SPACING } from "../../shared/utils";
import { steps } from "./TourPlanoContas";
import LastModified from "../../components/LastModified/LastModified";
import { useLocation } from "react-router-dom";
import { useGridApiContext } from "@mui/x-data-grid";
import PlanoModal from "../../components/Modal/PlanoContas/PlanoModal";
import DataGrid from "../../components/DataGrid/DataGrid";
import PlanoContasFooter from "../../components/Footers/PlanoContasFooter";
import { GridCellModes } from "@mui/x-data-grid-premium";

export default function PlanoContas() {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openCAModal, setOpenCAModal] = useState(false);
	const [fieldId, setFieldId] = useState([]);
	const [rowPlano, setRowPlano] = useState({});
	const [showNotClassified, setShowNotClassified] = useState(false);
	const [loadingModal, setLoadingModal] = useState(false);
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);
	const [rowsNotClassified, setRowsNotClassified] = useState([]);
	const [categorias, setCategorias] = useState([]);
	const [selectedTab, setSelectedTab] = useState(0);
	const [openRenameModal, setOpenRenameModal] = useState(false);
	const [openModalCopy, setOpenModalCopy] = useState(false);
	const [open, setOpen] =  useState(false);
	const [startAt, setStartAt] = useState(0);
	const [data, setData] = useState([]);
	const [categoriasList, setCategoriasList] = useState([]);
	const [modifiedRows, setModifiedRows] = useState([]);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const storeId = useSelector(state => state.StoreReducer.storeId);
	const [cellModesModel, setCellModesModel] = useState({});

	const loc = useLocation();

	useEffect(() => {
		if(storeId !== 0){
			if (selectedTab == 0) {
				getPlanoContas();
			}

			else {
				getPlanoContasCompras();
			}

			if (loc.state != null) {
				setShowNotClassified(loc.state.resolver_categorias);
			}
		}
	}, [storeId]);

	const handleChange = (event, newTabIdx) => {
		setCategoriasList([]);
		setModifiedRows([]);
		setSelectedTab(newTabIdx);
		handleTabChange(newTabIdx);
	};

	const handleTabChange = (newTabIdx) => {
		if(newTabIdx === 0){
			getPlanoContas(newTabIdx);
		} else {
			getPlanoContasCompras(newTabIdx);
		}
	};

	const getPlanoContas = (newTabIdx) => {
		setLoading(true);
		api.GetPlanoContas(storeId).then(response => {
			setLoading(false);
			let dataResponse = response.data;
			let categoriasData = dataResponse["categorias"];
			let rowsData = dataResponse.plano_contas;
			setCategorias(response.data["categorias"]);
			setRows(dataResponse.plano_contas);
			let newData = response.data.plano_contas.map((item) => {
				return { categoria_ca: item.categoria_ca.value, categoria_dre: item.categoria_dre.value, id: item.categoria_ca.id };
			});
			setData(newData);
			if (response.data["categorias"] && response.data["categorias"].length !== 0) {
				setColumns([
					{ field: "categoria_ca", headerName: "Categoria (Conta Azul)", flex: 1, align: "center", headerAlign: "center" },
					{ field: "categoria_dre", headerName: "Categoria (DRE)", flex: 1, align: "center", headerAlign: "center", editable: true, renderEditCell: (props) => <AutocompleteEditCell {...props} categoriasData={categoriasData} rowsData={rowsData} newTabIdx={newTabIdx}/>}
				]);
			}
			setRowsNotClassified(dataResponse.nao_classificados);
		}).catch(() => {
			setLoading(false);
			setColumns([]);
		});
	};

	const getPlanoContasCompras = (newTabIdx) => {
		setLoading(true);
		api.GetPlanoContasCompras(storeId).then(response => {
			setLoading(false);
			let dataResponse = response.data;
			let categoriasData = dataResponse["categorias"];
			let rowsData = dataResponse.plano_contas_compras;
			setCategorias(dataResponse["categorias"]);
			let newData = response.data.plano_contas_compras.map((item) => {
				return { categoria_ca: item.categoria_ca.value, categorizacao_1: item.categorizacao_1.value, id: item.categoria_ca.id };
			});
			setData(newData);
			setColumns([
				{ field: "categoria_ca", headerName: "Resumo Produto", flex: 1, align: "center", headerAlign: "center" },
				{ field: "categorizacao_1", headerName: "Categoria Produto", flex: 1, align: "center", headerAlign: "center", editable: true, renderEditCell: (props) => <AutocompleteEditCell {...props} categoriasData={categoriasData} rowsData={rowsData} newTabIdx={newTabIdx}/>}
			]);
			setRows(dataResponse.plano_contas_compras);
			setRowsNotClassified(dataResponse.nao_classificados);
		}).catch(() => {
			setLoading(false);
			setColumns([]);
		});
	};

	const updateContaAzul = () => {
		setOpenCAModal(true);
		const dataRequest = {
			loja_id: storeId
		};
		api.UpdateContaAzul(dataRequest).then(() => {
		}).catch(() => {
			setLoading(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	};

	const time = React.useMemo(() => {
		return Date.now() + 3000;
	}, [openCAModal]);

	const handleChangeCopy = (lojaId) => {
		if (selectedTab === 0) {
			handleCopy(lojaId);
		} else {
			handleCopyCompras(lojaId);
		}
	};

	const handleCopyCompras = (lojaId) => {
		setLoadingModal(true);
		let dataRequest = {
			loja_id: lojaId
		};
		api.CopyPlanoContasCompras(storeId, dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModal(false);
			dispatch(showSnackMessage({message: "Plano de contas copiado com sucesso!"}));
			getPlanoContasCompras();
		}).catch(() => {
			setLoadingModal(false);
			setOpenModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));

		});
	};

	const handleCopy = (lojaId) => {
		setLoadingModal(true);
		let dataRequest = {
			loja_id: lojaId
		};
		api.CopyPlanoContas(storeId, dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModalCopy(false);
			dispatch(showSnackMessage({message: "Plano de contas copiado com sucesso!"}));
			getPlanoContas();
		}).catch(() => {
			setLoadingModal(false);
			setOpenModalCopy(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));

		});
	};

	const handleEditCompras = (dataRequest) => {
		setLoadingModal(true);
		api.EditPlanoContasCompras(storeId, dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModal(false);
			dispatch(showSnackMessage({message: "Plano de contas editado com sucesso!"}));
			getPlanoContasCompras();
			setModifiedRows([]);
			setCategoriasList([]);
		}).catch(() => {
			setLoadingModal(false);
			setOpenModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	};

	const handleEdit = (dataRequest) => {
		setLoadingModal(true);
		api.EditPlanoContas(storeId, dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModal(false);
			dispatch(showSnackMessage({message: "Plano de contas editado com sucesso!"}));
			setModifiedRows([]);
			setCategoriasList([]);
			getPlanoContas();
		}).catch(() => {
			setLoadingModal(false);
			setOpenModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));

		});
	};

	const handleDeleteCompras = (dataRequest) => {
		setLoadingModal(true);
		api.DeletePlanoContasCompras(storeId, dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModal(false);
			dispatch(showSnackMessage({message: "Plano de contas excluído com sucesso!"}));
			getPlanoContasCompras();
			setModifiedRows([]);
			setCategoriasList([]);
		}).catch(() => {
			setLoadingModal(false);
			setOpenModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	};

	const handleDelete = (dataRequest) => {
		setLoadingModal(true);
		api.DeletePlanoContas(storeId, dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModal(false);
			dispatch(showSnackMessage({message: "Plano de contas excluído com sucesso!"}));
			getPlanoContas();
			setCategoriasList([]);
		}).catch(() => {
			setLoadingModal(false);
			setOpenModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));

		});
	};

	const handleShowNotClassified = (value) =>{
		if (value === "Não categorizados"){
			setShowNotClassified(!showNotClassified);
		}
		else{
			setShowNotClassified(false);
		}
	};

	const handleOpenModal = (dataRequest) => {
		let fieldsId = [];
		dataRequest.map((item) => {
			setOpenModal(true);
			setRowPlano(item.categoria_ca);
			fieldsId.push(item.id);
		});
		setFieldId(fieldsId);
	};

	const handleOpenRenameCategoria = () => {
		setOpenRenameModal(true);
	};

	const handleRename = (dataRequest) => {
		setLoadingModal(true);
		api.RenameCategoriasPlanoContas(dataRequest).then(() => {
			setLoading(false);
			setLoadingModal(false);
			setOpenRenameModal(false);
			dispatch(showSnackMessage({message: "Categoria renomeada com sucesso!"}));
			getPlanoContas();
		}).catch(() => {
			setLoading(false);
			setLoadingModal(false);
			setOpenRenameModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));

		});
	};

	const handleOpenModalCopy = () => {
		setOpenModalCopy(true);
	};

	const changeOpen = () => {
		setStartAt(0);
		setOpen(!open);
	};

	const handleCellOneClick = React.useCallback((params, event) => {

        // Editar célular com somente um click(por padrão são dois)
        if (!params.isEditable) {
            return;
        }
        if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
            return;
        }
        setCellModesModel((prevModel) => {
        return {
            ...Object.keys(prevModel).reduce(
            (acc, id) => ({
                ...acc,
                [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                    ...acc2,
                    [field]: { mode: GridCellModes.View },
                }),
                {},
                ),
            }),
            {},
            ),
            [params.id]: {
            ...Object.keys(prevModel[params.id] || {}).reduce(
                (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
                {},
            ),
            [params.field]: { mode: GridCellModes.Edit },
            },
        };
        });
    }, []);

    const handleRowSelection = (item) => {
		const selectedIDs = new Set(item);
        const selectedRows = data.filter((row) => selectedIDs.has(row.id));
        setCategoriasList(selectedRows);
    };

    const handleCellClick = () => {
		let dataRequest = [];
		categoriasList.map((item) => {
			dataRequest.push(
				{
					id: item.id,
					categoria_ca: item.categoria_ca,
					categoria_dre: item.categoria_dre
				}
			);
		});
		handleOpenModal(dataRequest);
    };

	const AutocompleteEditCell = (props) => {
        const { id, value, field, categoriasData, rowsData, newTabIdx} = props;
        const apiRef = useGridApiContext();

        const handleChangeState = async (event) => {
			await apiRef.current.setEditCellValue({ id, field, value: event.value });
			let rowSelected = 0;
			if (newTabIdx === 0 || (newTabIdx === undefined && selectedTab === 0)) {
				rowSelected = rowsData.find(row => row.categoria_dre.id === id);
			} else {
				rowSelected = rowsData.find(row => row.categorizacao_1.id === id);
			}
			const modifiedRow = { linha: rowSelected, novo_valor: event.value };

			setModifiedRows(prevModifiedRows => {
				const updatedRows = [...prevModifiedRows, modifiedRow];
				return updatedRows; 
			});
			
        };

		const options = (newTabIdx === 0 || (newTabIdx === undefined && selectedTab === 0)) ? 
			categoriasData.categoria_dre.map(item => ({label: item, value: item })) : 
			categoriasData.categorizacao_1.map(item => ({label: item, value: item }));

        return (
            <Autocomplete
				noOptionsText={translateAutocomplete}
				disableClearable
				sx={{marginTop: 0.5, width: "50%", "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
					backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX
				}}}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        sx={temaEscuro ? textCompleteStyleBlack : textCompleteStyle}
                    />
                }
                onChange={(e, value) => {
                    handleChangeState(value);
                }}
				PaperComponent={({ children }) => (
					<Paper
						style={{
							backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
						}}
					>
						{children}
					</Paper>
				)}
                options={options}
                value={options.find(option => option.value === value)}
            />
        );
    };

	const sendListCategoriaRequest = () => {
		let dataRequest = [];
		modifiedRows.map((item) => {
			if (selectedTab === 0) {
				dataRequest.push(
					{
						categoria_ca: item.linha.categoria_ca.value,
						categoria_dre: item.novo_valor,
						id: item.linha.categoria_ca.id
					}
				);
			} else {
				dataRequest.push(
					{
						categoria_ca: item.linha.categoria_ca.value,
						categorizacao_1: item.novo_valor,
						id: item.linha.categoria_ca.id
					}
				);
			}
		});
		selectedTab === 0 ? handleEdit(dataRequest) : handleEditCompras(dataRequest);
	};

	return (
		<div className="main" data-testid="PlanoContas">
			<LastModified title="Plano de Contas"/>
			{loading ? (
				<React.Fragment>
					<div style={{display: "flex", flexDirection: "column"}}>
						<Grid container>
							<Grid item xs={5}>
								<Grid container spacing={2}>
									<Grid item>
										<Skeleton height={75} width={110} />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={7} sx={{display: "flex", alignItems: "center"}}>
								<Grid container spacing={2} sx={{display: "flex", justifyContent: "end"}}>
									{
										[...Array(3).keys()].map((i,d) =>{
											return(
												<Grid item key={d}>
													<Skeleton height={75} width={110} />
												</Grid>
											);
										})
									}

								</Grid>
							</Grid>
						</Grid>
						{
							[...Array(15).keys()].map((i,d) =>{
								return(
									<Skeleton height={50} key={d}/>
								);
							})
						}
					</div>
				</React.Fragment>
			) : (
				storeId === 0 ?
					(
						<SelectStore/>
					): (
						<Paper
							sx={{...paperStyle, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none"}}
							data-testid="plano-contas"
						>
							<PlanoModal
								openModal={openModal}
								setOpenModal={setOpenModal}
								fieldId={fieldId}
								rowPlano={rowPlano}
								loadingModal={loadingModal}
								handleEdit={handleEdit}
								handleDelete={handleDelete}
								categorias={categorias}
								selectedTab={selectedTab}
								handleEditCompras={handleEditCompras}
								handleDeleteCompras={handleDeleteCompras}
								testId={"modalViewPlano"}
								categoriasList={categoriasList}
							/>
							<PlanoRename
								open={openRenameModal}
								setOpen={setOpenRenameModal}
								handleRename={handleRename}
								categorias={categorias}
								storeId={storeId}
								testId={"modalRename"}
							/>
							<UpdateCAModal
								openModal={openCAModal}
								setOpenModal={setOpenCAModal}
								testId={"modalUpdateCA"}
							/>
							<CopyPlano
								handleCopy={handleChangeCopy}
								loadingModal={loadingModal}
								setOpenModalCopy={setOpenModalCopy}
								openModalCopy={openModalCopy}
								testId={"modalCopyPlano"}
							/>
							{ (openCAModal === true) &&
								(
									<Countdown
										date={time}
										onComplete={() => {
											setOpenCAModal(false);
											dispatch(showSnackMessage({message: "Conta Azul atualizado com sucesso!"}));
										}}
										renderer={() => null}
									/>
								)
							}
							<Grid container sx={{alignItems: "center"}}>
								<React.Fragment>
									<Grid item xs={2}>
										<div className="main-modal">
											<Autocomplete
												size="small"
												style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
												data-testid="change-unclassified"
												disablePortal
												sx={{borderColor: temaEscuro && BORDER_BUTTON}}
												disableClearable
												options={["Categorizados", "Não categorizados"]}
												onChange={(e, value) => {
													handleShowNotClassified(value);
												}}
												value={showNotClassified ? "Não categorizados" : "Categorizados"}
												PaperComponent={({ children }) => (
													<Paper style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}>{children}</Paper>
												)}
												renderInput={(params) =>
													<TextField
														{...params}
														sx={temaEscuro ? textCompleteStyleBlack : textCompleteStyle}
														label={
															<span
																style={{
																	color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON,
																}}
															>
															Categorização
														</span>
														}
														className="categoria"
													/>
												}
											/>
										</div>
									</Grid>
									<Grid item xs={10}>
										<Grid container spacing={GRID_BUTTONS_SPACING} sx={{justifyContent: "end"}}>
											{selectedTab === 0 && (
												<Grid item>
													<div className="main-modal">
														<GrayButton
															title="Alterar Categoria"
															onClick={handleOpenRenameCategoria}
															startIcon={<EditIcon/>}
															testId={"buttonChangeCategory"}
															className="buttonChangeCategory"
														/>
													</div>
												</Grid>
											)}
											<Grid item>
												<div className="main-modal">
													<GrayButton
														title="Atualizar CA"
														onClick={updateContaAzul}
														startIcon={<CachedIcon/>}
														testId={"buttonUpdateCA"}
														className="buttonUpdateCA"
													/>
												</div>
											</Grid>
											<Grid item>
												<div className="main-modal">
													<GrayButton
														isRedirect
														path="/add_plano"
														state={{categorias, storeId, selectedTab}}
														startIcon={<AddBoxOutlinedIcon/>}
														title="Adicionar"
														testId={"buttonAddPlano"}
														className="buttonAddPlano"
													/>
												</div>
											</Grid>
											<Grid item>
												<GrayButton
													title="Copiar"
													onClick={handleOpenModalCopy}
													startIcon={<ContentCopyIcon />}
													testId={"buttonCopyPlano"}
													className="buttonCopyPlano"
												/>
											</Grid>
											<Grid item>
												<IconButton
													style={{ background: "#ECC94B", borderRadius: "4px" }}
													onClick={() => changeOpen()}
													data-testid="plano-contas-tour"
												>
													<LightbulbIcon style={{ color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px" }} />
												</IconButton>
											</Grid>
											<Tour
												steps={steps}
												isOpen={open}
												onRequestClose={changeOpen}
												accentColor={DASH_BAR_COLOR}
												startAt={startAt}
												rounded={5}
												showNavigation={false}
												inViewThreshold={60}
												className={temaEscuro && "tour"}
												badgeContent={(curr, tot) => `${curr}/${tot}`}
											/>
										</Grid>
									</Grid>
								</React.Fragment>
							</Grid>
							{
								(
									showNotClassified ? (
										<NotClassified rows={rowsNotClassified} selectedTab={selectedTab} setShowNotClassified={setShowNotClassified} categorias={selectedTab == 0 ? categorias.categoria_dre : categorias.categorizacao_1} storeId={storeId} getPlanoContas={getPlanoContas} getPlanoContasCompras={getPlanoContasCompras}/>
									) : (
										<React.Fragment>
											<Tabs
												value={selectedTab}
												onChange={handleChange}
												aria-label="basic tabs example"
												TabIndicatorProps={{style: {backgroundColor: "transparent"}}}>
												<Tab
													data-testid={"tabPadrao"}
													label="Padrão"
													key={0}
													sx={{
														borderRadius: "20px",
														color: temaEscuro ? GRAY_LABEL_UX_THEME : GRAY_LABEL_UX,
														backgroundColor: selectedTab === 0 ? YELLOW_BG_UX : "transparent",
														"&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
															color: GRAY_LABEL_UX,
														},
													}}
												/>
												<Tab
													data-testid={"tabCompras"}
													label="Compras"
													key={1}
													sx={{
														borderRadius: "20px",
														color: temaEscuro ? GRAY_LABEL_UX_THEME : GRAY_LABEL_UX,
														backgroundColor: selectedTab === 1 ? YELLOW_BG_UX : "transparent",
														"&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
															color: GRAY_LABEL_UX,
														},
													}}
												/>
											</Tabs>
											<React.Fragment>
												{
													(rows.length > 0) ? (
														<Grid
															item
															xs={12}
															marginTop={1}
															sx={{ height: "calc(100vh - 196px - 72px)", width: "100%", paddingTop: 1}}
															data-testid={"DataGrid"}
														>
															<DataGrid
																data={data}
																columns={columns}
																row={(row) => row.id}
																checked
																customRowSelectionModelChange={handleRowSelection}
																customHandleCellClick={handleCellOneClick}
																customCellModesModel={cellModesModel}
																setCellModesModel={setCellModesModel}
																CustomSelectionModel
																customFooter={<PlanoContasFooter
																	categoriasList={categoriasList}
																	modifiedRows={modifiedRows}
																	handleCellClick={handleCellClick}
																	sendListCategoriaRequest={sendListCategoriaRequest}
																	dataLength={data.length}
																/>}
															/>
														</Grid>
													) : (
														<h2 style={{textAlign: "center", color: temaEscuro ? GRAY_LABEL_UX_THEME : GRAY_LABEL_UX}}>Nenhum plano de contas encontrado</h2>
													)
												}
											</React.Fragment>
										</React.Fragment>
									)
								)
							}
						</Paper>
					)
			)}
		</div>
	);
}