import React, {useState, useEffect, useCallback, useMemo} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from "react-redux";
import api from "../../../axios";
import Skeleton from "@mui/material/Skeleton";
import SelectStore from "../../../components/SelectStore/SelectStore";
import {
		YELLOW_BG_UX,
		GRAY_LABEL_UX,
		YELLOW_INFO_UX,
		PURPLE_INFO_UX,
		styleDefaultText,
		WHITE_THEME_BLACK,
		ROW_MAIN, 
		PAPER_PADDING_THEME,
		paperStyle,
	} from "../../../shared/utils";

import ConciliacaoAccordion from "../../../components/Table/Conciliacao/ConciliacaoAccordion";
import GenericModal from "../../../components/Modal/GenericModal";
import CircleIcon from "@mui/icons-material/Circle";
import GrayButton from "../../../components/Buttons/GrayButton";
import {useDispatch} from "react-redux";
import {showSnackMessage} from "../../../actions/SnackActions";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import Tour from "reactour";
import { DASH_BAR_COLOR, GRID_BUTTONS_SPACING } from "../../../shared/utils";
import { steps } from "./TourPortais";
import LastModified from "../../../components/LastModified/LastModified";
import FormControl from "@mui/material/FormControl";
import SendIcon from "@mui/icons-material/Send";
import FilterWithTag from "../../../components/Filter/FilterWithTag";
import SaveConciliacao from "../../../components/Modal/Conciliacao/SaveConciliacao";
import Box from "@mui/material/Box";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import ConciliacaoModal from "../../../components/Modal/Conciliacao/ConciliacaoModal";
import SendConciliacaoModal from "../../../components/Modal/Conciliacao/SendConciliacaoModal";
import {changeScrollPositionDataGrid} from "../../../actions/StoreActions";

export default function Conciliacao() {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [loadingModal, setLoadingModal] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [previousValue, setPreviousValue] = useState(0);
	const [dateFixed, setDateFixed] = useState("");
	const [fieldId, setFieldId] = useState(false);
	const [comment, setComment] = useState("");
	const [fixedValue, setFixedValue] = useState(0);
	const [tabs, setTabs] = useState([]);
	const [rows, setRows] = useState({});
	const [selectedTab, setSelectedTab] = useState(0);
	const [openModalFixedValue, setOpenModalFixedValue] = useState(false);
	const [commentsList, setCommentsList] = useState([]);
	const [openInsertCommentModal, setOpenInsertCommentModal] = useState(false);
	const [openViewCommentModal, setOpenViewCommentModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [open, setOpen] =  useState(false);
	const [startAt, setStartAt] = useState(0);
	const [estabelecimentos, setEstabelecimentos] = useState([]);
	const [withEstabelecimento, setWithEstabelecimento] = useState(false);
	const [valueEstabelecimentos, setValueEstabelecimentos] = useState([]);
	const [estabelecimentosSelected, setEstabelecimentosSelected] = useState([]);
	const [plataformasRevisadas, setPlataformasRevisadas] = useState([]);
	const [openSaveConciliacao, setOpenSaveConciliacao] = useState(false);
	const [openModalDeleteConciliacao, setOpenModalDeleteConciliacao] = useState(false);
	const [monthsFiltered, setMonthsFiltered] = useState([]);
	const [monthsAll, setMonthsAll] = useState([]);
	const [previousMonths, setPreviousMonths] = useState([]);
	const [openConciliacaoModal, setOpenConciliacaoModal] = useState(false);
	const [monthsSaveFiltered, setMonthsSaveFiltered] = useState([]);
	const [monthsDeleteFiltered, setMonthsDeleteFiltered] = useState([]);
	const [mode, setMode] = useState("salvarConciliacao");
	const [revisada, setRevisada] = useState(false);
	const [openModalSendConciliacao ,setOpenModalSendConciliacao] = useState(false);
    const [scrollPosition, setScrollPosition] = useState({});
	const [isChange, setIsChange] = useState(false);

	const storeId = useSelector(state => state.StoreReducer.storeId);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);

	const emptyFunction = useCallback(() => null, []);

	const handleChange = useCallback((event, newTabIdx) => {
		setSelectedTab(newTabIdx);
		dispatch(changeScrollPositionDataGrid({}));
	}, []);
	
	// Quando trocar de storeId, pegar os dados da primeira plataforma da loja
	useEffect(() => {
		if(storeId !== 0){
			getMesesCompetencia();
			setEstabelecimentos([]);
			getConciliacao(monthsFiltered, [], null);
			setIsChange(true);
		}
	}, [storeId]);

	//  Quando trocar de plataforma, pegar os dados de dela
	useEffect(() => {
		if (isChange) {
			getConciliacao(monthsFiltered, [], tabs[selectedTab]);
		}
	}, [selectedTab]);

	useEffect(() => {
		const mesesRevisados = plataformasRevisadas.find(item => Object.keys(item)[0] === tabs[selectedTab])?.[tabs[selectedTab]] || [];
        const optionsSave = previousMonths.filter(month => !mesesRevisados.includes(month.value));
        setMonthsSaveFiltered(optionsSave);
		setMonthsDeleteFiltered(mesesRevisados.map(month => ({label: month, value: month})));
    }, [plataformasRevisadas, rows, openSaveConciliacao, selectedTab]);

	const getConciliacao = useCallback((months, estabelecimentos, platform=tabs[selectedTab]) => {
		setLoading(true);
		let dataRequest = {
			loja_id: storeId,
			months: months,
			estabelecimentos: estabelecimentos ? estabelecimentos : []
		};
		if (platform != null){
			dataRequest.platform = platform;
		}
		api.GetConciliacao(dataRequest).then(response => {
			let dataResponse = response.data;
			setTabs(dataResponse.plataformas);
			setRows(dataResponse.conciliacao);
			setWithEstabelecimento(dataResponse.conciliacao[0].with_estabelecimento);
			setEstabelecimentos(dataResponse.estabelecimentos);
			setValueEstabelecimentos(dataResponse?.estabelecimentos_selected);
			setEstabelecimentosSelected(dataResponse?.estabelecimentos_selected?.map((option) => option.value));
			setPlataformasRevisadas(dataResponse.plataformas_revisadas);
			setPreviousMonths(dataResponse.months_selected);
			setLoading(false);
		}).catch((error) => {
			if (error.response.status === 404) {
				dispatch(showSnackMessage({message: error.response.data, severity: "info"}));
			} else {
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			}
			setLoading(false);
			setTabs([]);
			setRows({});
		});
	}, [storeId, tabs[selectedTab], monthsFiltered, estabelecimentosSelected]);

	const getMesesCompetencia = useCallback(() => {
        let dataRequest = {
            lojas: [storeId]
        };
        api.GetMesesCompetencia(dataRequest).then(response => {
            let dataResponse = response.data;
            setMonthsAll(dataResponse);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    }, []);

	const handleOpenModal = useCallback((column, periodo, mesConciliacao, isRevisada) => {
		const periodoCaptured = rows.find(item => item.periodo === mesConciliacao);
		const findRow = periodoCaptured.data.find(item => item.periodo.value === periodo);

		const periodoValue = findRow.periodo.value;
		const parts = periodoValue.split("/");
		const ano = mesConciliacao.split("/")[1];
		const dia = parts[0];
		const mes = parts[1];
		const dateFixed = `${ano}-${mes}-${dia}`;

		setRevisada(isRevisada);
		setDateFixed(dateFixed);
		setFieldId(column);
		setPreviousValue(findRow[column].value);
		setComment(findRow[column].comments);
		setFixedValue(findRow[column].is_fixed ? findRow[column].value : 0);
		setCommentsList(findRow[column].comments);
		
		if (isAdmin) {
			setOpenModal(true);
		}
	}, [isAdmin, rows]);

	const getValorAntigo = (valorAntigo) => {
		if (valorAntigo === "-") {
			return 0;
		} else if (typeof valorAntigo === "string") {
			return parseFloat(valorAntigo);
		}
	};

	const sendMetadata = useCallback((metadata, fixedValue, comment) => {
		setLoadingModal(true);
		const dataRequest = {
			loja_id: storeId,
			data: dateFixed,
			plataforma: tabs[selectedTab],
			campo: fieldId,
			valor_antigo: getValorAntigo(previousValue), 
			tipo: metadata,
			valor_fixo: fixedValue,
			comentario: comment,
			estabelecimento: estabelecimentosSelected
		};
		api.FixConciliacaoField(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModalFixedValue(false);
			setOpenInsertCommentModal(false);
			setOpenViewCommentModal(false);
			dispatch(showSnackMessage({message: "Alterações salvas com sucesso"}));
			getConciliacao(monthsFiltered, estabelecimentosSelected, tabs[selectedTab]);
		}).catch(() => {
			setLoadingModal(false);
			setOpenModalFixedValue(false);
			setOpenInsertCommentModal(false);
			setOpenViewCommentModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, [storeId, dateFixed, tabs, selectedTab, fieldId, previousValue]);

	const delMetadata = useCallback((type, comentario_id) => {
		setLoadingModal(true);
		const dataRequest = {
			loja_id: storeId,
			data: dateFixed,
			plataforma: tabs[selectedTab],
			campo: fieldId,
			tipo: type,
			comentario_id,
			estabelecimento: estabelecimentosSelected
		};

		api.DeleteConciliacaoField(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModalFixedValue(false);
			setOpenViewCommentModal(false);
			dispatch(showSnackMessage({message: "Deletado com sucesso"}));
			getConciliacao(monthsFiltered, [], tabs[selectedTab]);
		}).catch(() => {
			setLoadingModal(false);
			setOpenModalFixedValue(false);
			setOpenViewCommentModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, [storeId, dateFixed, tabs, selectedTab, fieldId]);

	const editComment = useCallback((comment, commentId) => {
		setLoadingModal(true);
		setIsEdit(false);
		const dataRequest = {
			comentario: comment,
			comentario_id: commentId
		};
		api.editCommentConciliacao(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			dispatch(showSnackMessage({message: "Comentário editado com sucesso"}));
			getConciliacao(monthsFiltered, [], tabs[selectedTab]);
		}).catch(() => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, []);

	const handleMonthYear = useCallback((data) => {
		getConciliacao(data, estabelecimentosSelected, tabs[selectedTab]);
	}, [estabelecimentosSelected, storeId, tabs, selectedTab]);


	const changeOpen = () => {
		setStartAt(0);
		setOpen(!open);
	};

	const SendIconMemo = useMemo(() => <SendIcon/>);

	const handleOpenModalSendConciliacao = () => {
		setOpenModalSendConciliacao(true);
	};

	const saveConciliacao = useCallback((_month, _estabelecimentos, mode) => {
		setLoading(true);
		const dataRequest = {
			loja_id: storeId,
			month: _month,
			plataforma: tabs[selectedTab],
			estabelecimentos: _estabelecimentos,
		};
		if (mode === "salvarConciliacao"){
			api.PostSaveConciliacao(dataRequest).then(() => {
				dispatch(showSnackMessage({message: "Conciliação salva com sucesso"}));
				setLoading(false);
				setOpenSaveConciliacao(false);
				getConciliacao([_month], [_estabelecimentos[0]], tabs[selectedTab]);
			}).catch((error) => {
				let msgState = error?.response?.data?.msg;
				setLoading(false);
				setOpenSaveConciliacao(false);
				dispatch(showSnackMessage({message: msgState || "Algo deu errado tente novamente", severity: "error"}));
			});
		} else {
			api.DeleteSaveConciliacao(dataRequest).then(() => {
				dispatch(showSnackMessage({message: "Conciliação deletada com sucesso"}));
				setLoading(false);
				setOpenSaveConciliacao(false);
				getConciliacao([_month], [_estabelecimentos[0]], tabs[selectedTab]);
			}).catch(() => {
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
				setLoading(false);
				setOpenSaveConciliacao(false);
			});
		}
	}, [storeId, tabs, selectedTab, estabelecimentosSelected]);

	const handleClickTab = (idx) => {
		if (estabelecimentosSelected?.length > 1) {
			dispatch(showSnackMessage({message: "Selecione apenas um estabelecimento para salvar a conciliação", severity: "warning"}));
		} else {
			if (idx === selectedTab) {
				if (plataformasRevisadas.length > 0) {
					setOpenConciliacaoModal(true);
				} else {
					setOpenSaveConciliacao(true);
				}
			}
		}
	};

	return (
		<div className="main">
			{
				!!isAdmin && (
					<React.Fragment>
						<GenericModal
							isConciliacao
							openModal={openModal}
							dateFixed={dateFixed}
							setOpenModal={setOpenModal}
							fixedValue={fixedValue}
							setFixedValue={setFixedValue}
							setOpenModalFixedValue={setOpenModalFixedValue}
							openModalFixedValue={openModalFixedValue}
							openViewCommentModal={openViewCommentModal}
							openInsertCommentModal={openInsertCommentModal}
							setOpenViewCommentModal={setOpenViewCommentModal}
							setOpenInsertCommentModal={setOpenInsertCommentModal}
							commentsList={commentsList}
							comment={comment}
							handleDetalharValor={emptyFunction}
							setComment={setComment}
							editComment={editComment}
							loadingModal={loadingModal}
							delMetadata={delMetadata}
							sendMetadata={sendMetadata}
							isEdit={isEdit}
							setIsEdit={setIsEdit}
							fieldId={fieldId}
							component="conciliacao"
							revisada={revisada}
							estabelecimentosSelected={estabelecimentosSelected}
						/>
						<SaveConciliacao
							openSaveConciliacao={openSaveConciliacao}
							setOpenSaveConciliacao={setOpenSaveConciliacao}
							handleSaveConciliacao={saveConciliacao}
							selectedPlataforma={tabs[selectedTab]}
							estabelecimentos={estabelecimentos}
							monthsSaveFiltered={monthsSaveFiltered}
							mode={mode}
							setMode={setMode}
							monthsDeleteFiltered={monthsDeleteFiltered}
							plataformasRevisadas={plataformasRevisadas}
						/>
						<ConciliacaoModal 
							plataformasRevisadas={plataformasRevisadas}
							openConciliacaoModal={openConciliacaoModal}
							setOpenConciliacaoModal={setOpenConciliacaoModal}
							setOpenSaveConciliacao={setOpenSaveConciliacao}
							openSaveConciliacao={openSaveConciliacao}
							openModalDeleteConciliacao={openModalDeleteConciliacao}
							setOpenModalDeleteConciliacao={setOpenModalDeleteConciliacao}
							setMode={setMode}
						/>
						<SendConciliacaoModal
							openModalSendConciliacao={openModalSendConciliacao}
							setOpenModalSendConciliacao={setOpenModalSendConciliacao}
							monthsFiltered={monthsFiltered}
							platform={tabs[selectedTab]}
							estabelecimentosSelected={estabelecimentosSelected}
						/>
					</React.Fragment>
				)
			}
			{loading ? (
				<React.Fragment>
					<div style={{display: "flex"}}>
						{
							[...Array(2).keys()].map((i,d) =>{
								return(
									<Skeleton sx={{marginRight: 5}} key={d} height={50} width={180}/>
								);
							})
						}
					</div>
					<div style={{display: "flex"}}>
						{
							[...Array(5).keys()].map((i,d) =>{
								return(
									<Skeleton sx={{marginRight: 5}} key={d} height={50} width={120}/>
								);
							})
						}
					</div>
					{
						[...Array(10).keys()].map((i,d) =>{
							return(
								<Skeleton height={50} key={d}/>
							);
						})
					}
				</React.Fragment>
			) : (
				storeId === 0 ?
					(
						<SelectStore/>
					) : (
						<React.Fragment>
							<LastModified title="Portais"/>
							<Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}} data-testid="conciliacao-table">
								<Grid container sx={{display: "flex", alignItems: "center", marginBottom: 1}}>
									{withEstabelecimento && (
										<Grid item sx={{paddingRight: 2}}>
											<Grid container sx={{justifyContent: "start", display: "flex"}}>
												<FormControl className="filter-estabelecimentos" >
													<FilterWithTag
														options={estabelecimentos}
														setOptions={setEstabelecimentosSelected}
														testIdAll={""}
														previousValue={valueEstabelecimentos}
														optionFilter
														handleSubmit={null}
														monthsFiltered={null}
														limitSelection={null}
														testId={"filter-estabelecimentos"}
														widthSetting={"355px"}
													/>
												</FormControl>
											</Grid>
										</Grid>
									)}
									<Grid item sx={{paddingRight: 2}} className="filter-date" >
										<FilterWithTag
											isConciliacao
											optionFilter
											data-testid="filter-with-tag"
											options={monthsAll}
											setOptions={setMonthsFiltered}
											previousValue={previousMonths}
											widthSetting={"350px"}
										/>
									</Grid>
									<Grid item sx={{marginRight: 2}}>
										<GrayButton
											onClick={() => handleMonthYear(monthsFiltered)}
											title="filtrar"
											startIcon={<FilterListOutlinedIcon/>}
											testId="filtrar"
										/>
									</Grid>
									<Grid item xs>
										<Grid container spacing={GRID_BUTTONS_SPACING} sx={{justifyContent: "end", display: "flex"}}>
											{
												isAdmin && (
												<Grid item>
													<GrayButton
														onClick={handleOpenModalSendConciliacao}
														title="Enviar Conciliação"
														startIcon={SendIconMemo}
														testId="btn-open-modal-email"
														className="enviar-conciliacao-btn"
													/>
												</Grid>
												)
											}
											<Grid item>
												<IconButton style={{background: "#ECC94B", borderRadius: "4px"}} onClick={() => changeOpen()}>
													<LightbulbIcon style={{color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px"}}/>
												</IconButton>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={4} sx={{marginTop: 2, marginBottom: 1}}>
										<Grid container spacing={2}>
											<Grid item>
												<Grid container sx={{display: "flex", alignItems: "center", gap: 1, justifyContent: "center"}}>
													<Grid item sx={{display: "flex", alignItems: "center"}}>
														<CircleIcon className="circles" style={{ color: YELLOW_INFO_UX }}/>
													</Grid>
													<Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center", color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
														<a>Valor Manual</a>
													</Grid>
												</Grid>
											</Grid>
											<Grid item>
												<Grid container sx={{display: "flex", alignItems: "center", gap: 1, justifyContent: "center"}}>
													<Grid item sx={{display: "flex", alignItems: "center"}}>
														<CircleIcon className="circles" style={{ color: PURPLE_INFO_UX, }}/>
													</Grid>
													<Grid item sx={{...styleDefaultText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
														<a>Comentário</a>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={6}>
										<Tour
											steps={steps}
											isOpen={open}
											onRequestClose={changeOpen}
											accentColor={DASH_BAR_COLOR}
											startAt={startAt}
											rounded={5}
											showNavigation={false}
											inViewThreshold={60}
											className={temaEscuro && "tour"}
											badgeContent={(curr, tot) => `${curr}/${tot}`}
										/>
									</Grid>
								</Grid>
								<Grid container sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 2}}>
									<Grid item>
										<Tabs
										className="plataformas-tabs"
										data-testid="conciliacao-tabs"
										value={selectedTab}
										onChange={handleChange}
										aria-label="basic tabs example"
										TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
										>
											{tabs.map((value, idx) =>{
												return (
													<Tab 
														label={value}
														key={idx}
														onClick={() => handleClickTab(idx)}
														iconPosition={"end"}
														sx={{
															borderRadius: "150px",
															minHeight: "40px",
															color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
															backgroundColor: selectedTab === idx ? YELLOW_BG_UX : "transparent",
															"&.Mui-selected": {
																color: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX,
															},
														}}
													/>
												);
											})
											}
										</Tabs>
									</Grid>
								</Grid>
								<Box sx={{ width: "100%", marginTop: 2}} className="all-conciliacao-accordions" >
									<ConciliacaoAccordion
										rows={rows}
										setLoading={setLoading}
										handleOpenModal={handleOpenModal}
										plataformasRevisadas={plataformasRevisadas}
										tabs={tabs}
										selectedTab={selectedTab}
										openConciliacaoModal={openModal}
										setOpenConciliacaoModal={setOpenModal}
										openModal={openModal}
										setScrollPosition={setScrollPosition}
										scrollPosition={scrollPosition}
										estabelecimentosSelected={estabelecimentosSelected}
										monthsFiltered={previousMonths}
									/>
								</Box>
							</Paper>
						</React.Fragment>
					)
			)}
		</div>
	);
}
