import React from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import {
	WHITE_THEME_BLACK,
    formatterCurrency,
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    paperDash,
    DASH_AXIS_COLOR,
    PAPER_PADDING_THEME,
    LINE_TABLE,
    BLACK_TABLE,
    PURPLE_INFO_UX,
    DASH_KPI_POSITIVE_COLOR,
    BLACK_BUTTON,
    DASH_KPI_NEGATIVE_COLOR} from "../../../shared/utils";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import FaturamentoArrow from "./FaturamentoArrow";
import Box from "@mui/material/Box";

const FaturamentoIfood = ({valorBruto,
    recebidosLoja,
    descontosAcrescimos,
    promocoesCusteadasPelaLoja,
    taxaComissoesTotal,
    taxaPedidoMinimo,
    taxaEntregaParceira,
    taxaIfoodRefeicao,
    entregasSolicitadas,
    pedidosCancelados,
    debitoCanceladoParcialmente,
    comissaoIfood,
    comissaoIfoodCancelado,
    comissaoTransacao,
    comissaoTransacaoCancelado,
    selectedMonth,
    valorLiquidoTotal,
    setOpenFaturamentoIFood,
    valoresPagosClienteDevidoIFood,
    valorPedidosViaLojaCount,
    promocoesCusteadasPeloIFood,
    promocoesCusteadasPeloIFoodCount,
    outrosValores,
    recebidosLojaPerc,
    promocoesCusteadasPelaLojaPerc,
    taxaComissoesTotalPerc,
    taxaPedidoMinimoPerc,
    taxaEntregaParceiraPerc,
    taxaIfoodRefeicaoPerc,
    entregasSolicitadasPerc,
    pedidosCanceladosPerc,
    debitoCanceladoParcialmentePerc,
    comissaoIfoodPerc,
    comissaoIfoodCanceladoPerc,
    comissaoTransacaoPerc,
    comissaoTransacaoCanceladoPerc,
    outrosValoresPerc,
    valoresPagosClienteDevidoIFoodPerc,
    promocoesCusteadasPeloIFoodPerc,
    estabelecimentos}) => {

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const styles = {
        container: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            padding: ".5rem 1.5rem"
        },
        arrowValue: {
            fontWeight: "bold",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            gap: ".3rem"
        },
        arrowValueStrong: {
            fontSize: "14px",
            fontWeight: "300",
            color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_BUTTON
        },
        titleValue: {
            fontWeight: "bold",
            fontSize: "24px",
            display: "flex",
            alignItems: "center",
            gap: ".3rem"
        },
        titleValueStrong: {
            fontSize: "20px",
            fontWeight: "300",
            color: temaEscuro ? WHITE_ESCRITA_THEME : BLACK_BUTTON
        },
        subtitle: {
            marginTop: 0,
            color: temaEscuro ? DASH_AXIS_COLOR : BLACK_TABLE
        },
        details: {
            marginTop: 0,
            color: temaEscuro ? DASH_AXIS_COLOR : BLACK_TABLE,
            padding: "0 1.5rem",
            marginBottom: ".5rem"
        }
    };

    const formatResumoIfoodPercentage = (value, percentage) => {
        return (
            <span>
                {formatterCurrency(value, 2)} <strong style={{...styles.titleValueStrong}}>| {percentage}%</strong>
            </span>
        );
    };

    const formatResumoIfoodPercentageArrow = (value, percentage) => {
        return (
            <span>
                {formatterCurrency(value, 2)} <strong style={{...styles.arrowValueStrong}}>| {percentage}%</strong>
            </span>
        );
    };

    return (
        <Grid container gap={2} sx={{display: "flex", flexDirection: "column", backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE, padding: "1.5rem", borderRadius: "8px", width: "90%", margin: "1rem auto"}}>
            <Grid item sx={{marginBottom: ".5rem", color: temaEscuro ? WHITE_THEME_BLACK : PAPER_PADDING_THEME}}>
                <h1>Faturamento Aplicativo Ifood</h1>
                <Grid sx={{fontWeight: "normal", display: "flex", fontSize: "18px"}}>
                    <Box sx={{marginRight: ".4rem", textDecoration: "underline", color: PURPLE_INFO_UX, "&:hover": {cursor: "pointer"}}} onClick={() => setOpenFaturamentoIFood(false)}>
                        Resumo Ifood
                    </Box>
                    <Box>
                         / Faturamento Aplicativo Ifood
                    </Box>
                </Grid>
                <p>Estabelecimento(s): <strong>{estabelecimentos.join(", ")}</strong></p>
                <p>Período: <strong>{selectedMonth}</strong></p>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{display: "flex", justifyContent: "space-between", alignItems: "center", height: "100%", padding: ".5rem 1.5rem"}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Valor bruto das vendas</h2>
                            <p style={{...styles.subtitle}}>Valor total de itens vendidos somados aos valores pagos pelo cliente ao iFood</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue}}>
                            {formatterCurrency(valorBruto, 2)}
                        </span>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{...styles.container}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Valores pagos pelo cliente devidos ao iFood</h2>
                            <p style={{...styles.subtitle}}>Taxa de pedido mínimo e outros valores</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: valoresPagosClienteDevidoIFood > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(valoresPagosClienteDevidoIFood, valoresPagosClienteDevidoIFoodPerc)}
                        </span>
                        </Grid>
                    </Grid>
                    <Grid sx={{...styles.container}}>
                        <FaturamentoArrow
                            title="Taxa de Entrega Parceira iFood"
                            value={taxaEntregaParceira}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(taxaEntregaParceira, taxaEntregaParceiraPerc)}
                        </span>
                    </Grid>
                    <Grid sx={{...styles.container, paddingBottom: "1.5rem"}}>
                        <FaturamentoArrow
                            title="Taxa de pedido mínimo"
                            value={taxaPedidoMinimo}
                        />
                        <span style={{...styles.arrowValue}}>
                        {formatResumoIfoodPercentageArrow(taxaPedidoMinimo, taxaPedidoMinimoPerc)}
                        </span>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{...styles.container}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Promoções</h2>
                            <p style={{...styles.subtitle}}>Valor total de incentivos promocionais</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: promocoesCusteadasPelaLoja > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(promocoesCusteadasPelaLoja, promocoesCusteadasPelaLojaPerc)}
                        </span>
                        </Grid>
                    </Grid>
                    <Grid sx={{...styles.container}}>
                        <FaturamentoArrow
                            title="Promoções custeadas pela loja"
                            value={promocoesCusteadasPelaLoja}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(promocoesCusteadasPelaLoja, promocoesCusteadasPelaLojaPerc)}
                        </span>
                    </Grid>
                    <Divider sx={{margin: "1rem 1.5rem"}} />
                    <Grid sx={{paddingBottom: "1rem"}}>
                        <p style={{...styles.details}}>
                            {promocoesCusteadasPeloIFoodCount} pedidos tiveram promoções custeadas pelo iFood no valor de {formatterCurrency(promocoesCusteadasPeloIFood, 2)}  | {promocoesCusteadasPeloIFoodPerc}%
                        </p>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{...styles.container}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Total recebido via loja</h2>
                            <p style={{...styles.subtitle}}>Valor total que sua loja já recebeu</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: -recebidosLoja > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(-recebidosLoja, recebidosLojaPerc)}
                        </span>
                        </Grid>
                    </Grid>
                    <Grid sx={{...styles.container, paddingBottom: "1.5rem"}}>
                        <FaturamentoArrow
                            title={`Recebido via loja (${valorPedidosViaLojaCount} pedidos)`}
                            value={-recebidosLoja}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(-recebidosLoja, recebidosLojaPerc)}
                        </span>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{...styles.container}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Taxas e comissões</h2>
                            <p style={{...styles.subtitle}}>Valor bruto total das comissões e taxas do iFood</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: taxaComissoesTotal > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(taxaComissoesTotal, taxaComissoesTotalPerc)}
                        </span>
                        </Grid>
                    </Grid>
                    <Grid sx={{...styles.container}}>
                        <FaturamentoArrow
                            title="Comissão pela transação do pagamento"
                            value={comissaoTransacao}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(comissaoTransacao, comissaoTransacaoPerc)}
                        </span>
                    </Grid>
                    <Grid sx={{...styles.container}}>
                        <FaturamentoArrow
                            title="Comissão do iFood"
                            value={comissaoIfood}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(comissaoIfood, comissaoIfoodPerc)}
                        </span>
                    </Grid>
                    <Grid sx={{...styles.container, paddingBottom: "1.5rem"}}>
                        <FaturamentoArrow
                            title="Taxa de serviço iFood Refeição"
                            value={taxaIfoodRefeicao}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(taxaIfoodRefeicao, taxaIfoodRefeicaoPerc)}
                        </span>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{...styles.container}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Serviços logísticos</h2>
                            <p style={{...styles.subtitle}}>Serviços logísticos contratados pela sua loja</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: entregasSolicitadas > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(entregasSolicitadas, entregasSolicitadasPerc)}
                        </span>
                        </Grid>
                    </Grid>
                    <Grid sx={{...styles.container, paddingBottom: "1.5rem"}}>
                        <FaturamentoArrow
                            title="Total das entregas que você solicitou"
                            value={entregasSolicitadas}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(entregasSolicitadas, entregasSolicitadasPerc)}
                        </span>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{...styles.container}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Cancelamentos e reembolsos</h2>
                            <p style={{...styles.subtitle}}>Valor bruto total de pedidos cancelados e seus devidos reembolsos e descontos</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: pedidosCancelados > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(pedidosCancelados, pedidosCanceladosPerc)}
                        </span>
                        </Grid>
                    </Grid>
                    <Grid sx={{...styles.container}}>
                        <FaturamentoArrow
                            title="Reembolso de pedidos cancelados"
                            value={pedidosCancelados}
                        />
                        <span style={{...styles.arrowValue}}>
                            {formatResumoIfoodPercentageArrow(pedidosCancelados, pedidosCanceladosPerc)}
                        </span>
                    </Grid>
                    <Divider sx={{margin: "1rem 1.5rem"}} />
                    <Grid sx={{paddingBottom: "1rem"}}>
                        <p style={{...styles.details}}>Comissão do iFood no valor de {formatterCurrency(comissaoIfoodCancelado, 2)} | {comissaoIfoodCanceladoPerc}%</p>
                        <p style={{...styles.details}}>Comissão pela transação do pagamento no valor {formatterCurrency(comissaoTransacaoCancelado, 2)} | {comissaoTransacaoCanceladoPerc}%</p>
                        <p style={{...styles.details}}>Débito de pedidos cancelados parcialmente no valor de {formatterCurrency(debitoCanceladoParcialmente, 2)} | {debitoCanceladoParcialmentePerc}%</p>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{display: "flex", justifyContent: "space-between", alignItems: "center", height: "100%", padding: ".5rem 1.5rem"}}>
                        <Grid item>
                            <h2 style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME}), marginBottom: ".5rem"}}>Outros valores</h2>
                            <p style={{...styles.subtitle}}>Lançamentos gerais</p>
                        </Grid>
                        <Grid item>
                        <span style={{...styles.titleValue, color: outrosValores > 0 ? DASH_KPI_POSITIVE_COLOR : DASH_KPI_NEGATIVE_COLOR}}>
                            {formatResumoIfoodPercentage(outrosValores, outrosValoresPerc)}
                        </span>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={8} sx={{margin: "1rem auto"}}>
                <Paper sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                    <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "start", height: "100%", padding: ".5rem", gap: 3}}>
                        <Grid>
                            <Grid item sx={{marginBottom: ".3rem"}}>
                                <span style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>Valor bruto</span>
                            </Grid>
                            <Grid item sx={{fontWeight: "bold"}}>
                                <span>
                                    {formatterCurrency(valorBruto, 2)}
                                </span>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item>
                                <span style={{fontSize: "30px", ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>-</span>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item sx={{marginBottom: ".3rem"}}>
                                <span style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>Recebido via loja</span>
                            </Grid>
                            <Grid item sx={{fontWeight: "bold"}}>
                                <span>
                                    {formatterCurrency(recebidosLoja, 2)}
                                </span>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item>
                                <span style={{fontSize: "30px", ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>+</span>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item sx={{marginBottom: ".3rem"}}>
                                <span style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>Descontos ou acréscimos</span>
                            </Grid>
                            <Grid item sx={{fontWeight: "bold"}}>
                                <span>
                                    {formatterCurrency(descontosAcrescimos, 2)}
                                </span>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item>
                                <span style={{fontSize: "30px", ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>=</span>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item sx={{marginBottom: ".3rem"}}>
                                <span style={{...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>Valor líquido total</span>
                            </Grid>
                            <Grid item sx={{fontWeight: "bold"}}>
                                <span>
                                    {formatterCurrency(valorLiquidoTotal, 2)}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default React.memo(FaturamentoIfood);