import React, {useState, useEffect, useMemo} from "react";
import Paper from "@mui/material/Paper";
import ReactECharts from "echarts-for-react";
import Box from "@mui/material/Box";
import {
	DASH_AXIS_COLOR,
	DASH_LABEL_COLOR,
	DASH_LINESTYLE_COLOR,
	paperDash, spanDash,
	fontFamily, grid, legend,
	seriesColor, formatterThousand, formatterPercentage, changePosition,
	WHITE_THEME_BLACK, BORDER_BUTTON, GRAY_LABEL_UX, BLUE_THEME, LINE_TABLE, 
	TEXT_THEME
} from "../../shared/utils";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeOpenComponent } from "../../actions/StoreActions";


const BarLine = (props) => {
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const {data, testId} = props;
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const title = "R$";

	const barLineFormatter = (params) => {
		return formatterThousand(params.data);
	};

	const lineFormatter = (params) => {
		return formatterPercentage(params.data);
	};


	const axisLabel = {
		fontFamily,
		color: temaEscuro ? WHITE_THEME_BLACK : DASH_AXIS_COLOR,
		formatter: formatterThousand
	};


	const splitLine = {
		show: true,
		lineStyle: {
			color: temaEscuro ? TEXT_THEME : DASH_LINESTYLE_COLOR,
			type: "dashed"
		}
	};
	const YAxis = [
		{
			type: "value",
			splitLine,
			axisLabel
		},
		{
			name: "%",
			type: "value",
			max: 100,
			splitLine,
			axisLabel,
			nameTextStyle: {
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_AXIS_COLOR,
			}
		}
	];

	const series = {
		data: [],
		type: "bar",
		smooth: true,
		symbol: "circle",
		emphasis: {
			focus: "series"
		},
		label: {
			show: true,
			position: "top",
			color: temaEscuro ? WHITE_THEME_BLACK : DASH_LABEL_COLOR,
			formatter: barLineFormatter,
			fontFamily
		},
	};


	const [options, setOptions] = useState({
		grid,
		legend:{
			...legend,
			textStyle: {
				color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
			},
			inactiveColor: temaEscuro ? BORDER_BUTTON : "#ccc"
		},
		xAxis: {
			type: "category",
			color: DASH_LABEL_COLOR,
			data: [],
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			axisLabel: {
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_AXIS_COLOR
			}
		},
		yAxis: YAxis,
		series: [],
		title: {
			text: title,
			left: "left",
			textStyle: {
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_LABEL_COLOR,
				fontFamily,
				fontSize: 12,
				fontWeight: 400
			}
		},
		tooltip: {
			trigger: "axis",
			axisPointer: {
				type: "shadow"
			},
			fontFamily,
		},
	});
	const [key, setKey] = useState();

	useEffect(() => {
		let newOptions = {...options};
		newOptions.xAxis.data = data.data;
		let position = "top";

		data?.series?.map((item, index) => {
			let newSeries = {...series};
			let label = {...newSeries.label};

			label.position = position;
			newSeries.color = seriesColor[index];
			newSeries.name = item.name;
			newSeries.type = item.type;
			newSeries.data = item.data;
			if("yAxisIndex" in item){
				let newLabel = {...newSeries.label};
				newSeries.yAxisIndex =  item.yAxisIndex;
				// newLabel.color = "#ffffff";
				newLabel.formatter = lineFormatter;
				newSeries.label = newLabel;
			}
			newSeries.label = label;
			newOptions.series.push(newSeries);
			position = changePosition(position);
		});

		setOptions(newOptions);
		setKey(Math.random());
	}, [data]);

	const handleClick = (params) => {
		const innerText = params.event.event.currentTarget.offsetParent.innerText;

		// columnName = X axis
		const columnName = innerText.split("\n")[0];

		dispatch(changeOpenComponent(true));
		navigate("/dre", { state: { monthRedirectDashboard: columnName, lineRedirectDashboard: props.title }});
	};

	const getChart = useMemo(() => {
		return(
			<ReactECharts
				key={key}
				option={options}
				notMerge={true}
				onEvents={{
					click: handleClick, // Register the click event handler
				}}
			/>
		);
	}, [options]);

	return(
		<Paper data-testid={testId} sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}} className={props.className}>
			<Box sx={{mb: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
				<span style={{...spanDash, ...(temaEscuro && {color:  LINE_TABLE})}}>{props.title}</span>
				<ButtonOpenTour onClick={props.onClick}/>
			</Box>
			{getChart}
		</Paper>
	);
};

export default React.memo(BarLine);