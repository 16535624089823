import React from "react";
import Grid from "@mui/material/Grid";
import {
	GRAY_LABEL_UX, 
	WHITE_ESCRITA_THEME, 
	BLUE_THEME, 
	GRAY_BG_UX, 
	WHITE_TABLE, 
	BORDER_BUTTON,
	translateAutocomplete
} from "../../shared/utils";
import {useSelector} from "react-redux";
import searchStore from "../../assets/images/search_store.png";
import searchStoreDark from "../../assets/images/search_store_dark.png";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";


const SelectMarca = (props) => {
const {marcas, setMarcaId} = props;
const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

const handleChangeMarca = (newValue) => {
	setMarcaId(newValue.value);
};

	return(
		<div style={styles.center}>
			<Grid container rowSpacing={5} sx={{alignItems: "center"}} data-testid="select-marca-fullwidth">
				<Grid item xs={12} sx={{paddingTop: "0 !important"}}>
					<img src={temaEscuro ? searchStoreDark : searchStore} />
				</Grid>
				<Grid item xs={12} >
					<span style={{...styles.text, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Selecione uma marca para visualizar os dados</span>
				</Grid>
				<Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
					<Autocomplete
						size="small"
						noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
						data-testid="select-marca-fullwidth-input"
						disablePortal
						disableClearable
						getOptionLabel={(option) => option.label}
						onChange={(event, newValue) => {
							handleChangeMarca(newValue);
						}}
						PaperComponent={({ children }) => (
							<Paper
								style={{
									backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
									color: temaEscuro ? "#FFF" : "#4A5568",
									fontWeight: "500"
								}}
							>
								{children}
							</Paper>
						)}
						options={marcas ? marcas : []}
						sx={{backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE, width: "50%"}}
						renderInput={(params) =>
							<TextField {...params} 
							label="Marca" 
							InputLabelProps={{
								style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON}}}
							/>
						}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

// Create styles
const styles = {
	center: {
		textAlign: "center",
		margin: "auto",
		width: "70%",
		// margin is 30px
		height: "calc(100vh - 60px)",
		display: "flex",
		alignItems: "center"
	},
	text: {
		color: GRAY_LABEL_UX,
		fontWeight: 600,
		fontSize: "24px",
		lineHeight: "32px"
	}
};

export default SelectMarca;