import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { YELLOW_BG_UX } from "../../shared/utils";

export default function ButtonOpenTour(props) {
    return (
        <InfoIcon 
            onClick={props.onClick} 
            style={{ 
                color: props?.isYellow ? YELLOW_BG_UX :"#bdbdbd", 
                fontSize: "15px", 
                paddingLeft: "5px", 
                cursor: "pointer" 
            }}
        />
    );
}