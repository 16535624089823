import React from "react";
import Button from "@mui/material/Button";
import { styleYellowButton } from "../../shared/utils";

const YellowButton = (props) => {
    return (
        <Button
            className={props?.className}
            type={props.type ? props.type : "button"}
            variant="contained"
            data-testid={props?.testId}
            onClick={props.onClick}
            style={!props?.disabled ? styleYellowButton : null}
            startIcon={props?.startIcon}
            endIcon={props?.endIcon}
            disabled={props?.disabled}
        >
            {props.title}
        </Button>
    );
};

export default React.memo(YellowButton);