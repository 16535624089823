import React, {useState, useEffect, useCallback} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import api from "../../../axios";
import Skeleton from "@mui/material/Skeleton";
import {useDispatch} from "react-redux";
import {showSnackMessage} from "../../../actions/SnackActions";
import SelectStore from "../../../components/SelectStore/SelectStore";
import {
    getLastMonth,
    getLastMonthYear,
    YELLOW_BG_UX,
    GRAY_LABEL_UX,
    WHITE_THEME_BLACK,
    ROW_MAIN, 
	DASH_BAR_COLOR,
    PAPER_PADDING_THEME,
	RED_TABLE,
	paperStyle,
} from "../../../shared/utils";
import FormControl from "@mui/material/FormControl";
import LastModified from "../../../components/LastModified/LastModified";
import Tour from "reactour";
import { useSelector } from "react-redux";
import FilterYear from "../../../components/Filter/FilterYear";
import FilterWithTag from "../../../components/Filter/FilterWithTag";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { steps } from "./TourConciliacaoTaxa";
import DataGrid from "../../../components/DataGrid/DataGrid";

export default function ConciliacaoTaxa() {

	const dispatch = useDispatch();

    const storeId = useSelector(state => state.StoreReducer.storeId);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);

	const [loading, setLoading] = useState(false);
    const [ano, setAno] = useState(getLastMonthYear());
	const [mes, setMes] = useState(getLastMonth());
    const [estabelecimentos, setEstabelecimentos] = useState([]);
	const [withEstabelecimento, setWithEstabelecimento] = useState(true);
	const [valueEstabelecimentos, setValueEstabelecimentos] = useState([]);
	const [estabelecimentosSelected, setEstabelecimentosSelected] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
	const [rows, setRows] = useState({});
	const [rowsFormatted, setRowsFormatted] = useState([]);
	const [startAt, setStartAt] = useState(0);
	const [open, setOpen] = useState(false);

    useEffect(() => {
		if(storeId !== 0){
			getConciliacaoTaxa(ano, mes, [], tabs[selectedTab]);
        }
    }, [storeId]);

	useEffect(() => {
		if (rows?.data?.length > 0) {
			setRowsFormatted(formatRows(rows.data));
		}
	}, [rows]);

	useEffect(() => {
		if (tabs.length > 0 && rows.length === 1) {
			let platform = rows[0].platform.toLowerCase();
			let tabSelected = tabs.findIndex(tab => tab.toLowerCase().includes(platform));
			if (tabSelected !== -1) {
                const updatedTabs = tabs.filter((_, index) => index === tabSelected);
                setSelectedTab(0);
                setTabs(updatedTabs);
            }
			setRowsFormatted(formatRows(rows[0].data));
		} else {
			if (rows.length > 0) {
				const validTab = selectedTab >= 0 && selectedTab < rows.length ? selectedTab : 0;
				setRowsFormatted(formatRows(rows[validTab].data));
			}
		}
	}, [rows, selectedTab]);

	const handleChange = (event, newTabIdx) => {
		setSelectedTab(newTabIdx);
	};

	const renderCellWithIndicators = (params) => {
		const value = params.value;
		const isNegative = !isNaN(parseFloat(value)) && parseFloat(value) < 0;
	
		return (
			<span style={{ color: isNegative ? RED_TABLE : "inherit", fontWeight: isNegative ? "bold" : "normal" }}>
				{value}
			</span>
		);
	};

    const getConciliacaoTaxa = (ano, mes, estabelecimentos, platform=tabs[selectedTab]) => {
		setLoading(true);
		let dataRequest = {
			loja_id: storeId,
			ano: ano,
            mes: mes,
			estabelecimentos: estabelecimentos,
		};
		if (platform != null){
			dataRequest.platform = platform;
		}
		api.GetConciliacaoTaxa(dataRequest).then(response => {
			let dataResponse = response.data;
            setTabs(dataResponse.plataformas_taxas);
			setRows(dataResponse.conciliacao);
			setWithEstabelecimento(dataResponse.conciliacao[0].with_estabelecimento);
			setEstabelecimentos(dataResponse.estabelecimentos);
			setValueEstabelecimentos(dataResponse?.estabelecimentos_selected);
			setEstabelecimentosSelected(dataResponse?.estabelecimentos_selected?.map((option) => option.value));
			setLoading(false);
		}).catch((error) => {
			if (error.response.status === 404) {
				dispatch(showSnackMessage({message: error.response.data, severity: "info"}));
			} else {
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			}
			setLoading(false);
			setTabs([]);
			setRowsFormatted([]);
			setRows({});
		});
	};

    const handleMonthYear = useCallback((ano, mes) => {
		getConciliacaoTaxa(ano, mes, estabelecimentosSelected, tabs[selectedTab]);
		setAno(ano);
		setMes(mes);
	}, [estabelecimentosSelected, storeId, tabs, selectedTab]);

	const columns = [
        { field: "bandeira", headerName: "Bandeira", flex: 1, headerAlign: "center", align: "center"},
        { field: "taxa_realizada", headerName: "Taxa Realizada", flex: 1, headerAlign: "center", align: "center", renderCell: renderCellWithIndicators },
        { field: "taxa_contrato", headerName: "Taxa Contrato", flex: 1, headerAlign: "center", align: "center", renderCell: renderCellWithIndicators },
        { field: "diferenca", headerName: "Diferença", flex: 1, headerAlign: "center", align: "center", renderCell: renderCellWithIndicators }
    ];

	const formatRows = (data) => {
        return data.map((row, index) => {
			const formattedRow = {};
			Object.keys(row).forEach((key) => {
				let value = row[key].value;
				if (row[key].percentage) {
					if (typeof value === "number") {
						value = `${value.toFixed(2)}%`;
						formattedRow[key] = value;
						
					} else {
						formattedRow[key] = value;
					}
				} else {
					formattedRow[key] = value;
				}
			});
			formattedRow.id = index;
			return formattedRow;
		});
    };

	const changeOpen = () => {
		setStartAt(0);
		setOpen(!open);
	};
    
    return (
		<div className="main">
			{
				!!isAdmin && (
					<React.Fragment>
                        <Tour
                            steps={steps}
                            isOpen={open}
                            onRequestClose={changeOpen}
                            accentColor={DASH_BAR_COLOR}
                            startAt={startAt}
                            rounded={5}
                            showNavigation={false}
                            inViewThreshold={60}
                            className={temaEscuro && "tour"}
                            badgeContent={(curr, tot) => `${curr}/${tot}`}
                        />
					</React.Fragment>
				)
			}
			{loading ? (
				<React.Fragment>
					<div style={{display: "flex"}}>
						{
							[...Array(2).keys()].map((i,d) =>{
								return(
									<Skeleton sx={{marginRight: 5}} key={d} height={50} width={180}/>
								);
							})
						}
					</div>
					<div style={{display: "flex"}}>
						{
							[...Array(5).keys()].map((i,d) =>{
								return(
									<Skeleton sx={{marginRight: 5}} key={d} height={50} width={120}/>
								);
							})
						}
					</div>
					{
						[...Array(10).keys()].map((i,d) =>{
							return(
								<Skeleton height={50} key={d}/>
							);
						})
					}
				</React.Fragment>
			) : (
				storeId === 0 ?
					(
						<SelectStore/>
					) : (
						<React.Fragment>
							<LastModified title="Conciliação - Taxa"/>
							<Paper sx={{...paperStyle,  backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}} data-testid="conciliacao-table">
                                <Grid container sx={{display: "flex", alignItems: "center", marginBottom: 1}}>
                                    {withEstabelecimento && (
										<Grid item sx={{paddingRight: 2}}>
											<Grid container sx={{justifyContent: "start", display: "flex"}}>
												<FormControl>
													<FilterWithTag
														options={estabelecimentos}
														setOptions={setEstabelecimentosSelected}
														testIdAll={""}
														previousValue={valueEstabelecimentos}
														optionFilter
														handleSubmit={null}
														monthsFiltered={null}
														limitSelection={null}
														testId={"filter-estabelecimentos"}
														widthSetting={"340px"}
													/>
												</FormControl>
											</Grid>
										</Grid>
									)}
                                    <Grid item sx={{marginRight: 2}}>
                                        <FilterYear
                                            marginBottom={0}
                                            handleSubmit={handleMonthYear}
                                            prevYear={ano}
                                            prevMonth={mes}
                                            optionFilter={true}
                                        />
                                    </Grid>
                                    <Grid item xs sx={{display: "flex", justifyContent: "flex-end"}}>
                                        <IconButton style={{background: "#ECC94B", borderRadius: "4px"}} onClick={() => changeOpen()}>
                                            <LightbulbIcon style={{color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px"}}/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 2}}>
									<Grid item>
										<Tabs
                                            data-testid="conciliacao-tabs"
                                            value={selectedTab}
                                            onChange={handleChange}
                                            aria-label="basic tabs example"
                                            TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
										>
                                            {tabs.map((value, idx) =>{
                                                return (
                                                    <Tab 
                                                        label={value}
                                                        key={idx}
                                                        iconPosition={"end"}
                                                        sx={{
                                                            borderRadius: "150px",
                                                            minHeight: "40px",
                                                            color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
                                                            backgroundColor: selectedTab === idx ? YELLOW_BG_UX : "transparent",
                                                            "&.Mui-selected": {
                                                                color: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX,
                                                            },
                                                        }}
                                                    />
                                                );
                                            })}
                                        </Tabs>
                                    </Grid>
                                </Grid>
								<Grid container>
									<Grid item sx={{ height: 650, width: "100%", marginTop: 2, borderRadius: 2 }}>
										<DataGrid
											columns={columns}
											data={rowsFormatted}
											row={(row) => row.id}
										/>
									</Grid>
								</Grid>
                            </Paper>
                       </React.Fragment>
                    )
            )}
        </div>
    );
}