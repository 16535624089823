import React, { useState, useEffect } from "react";
import {useLocation} from "react-router-dom";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import api from "../../axios";
import { format,
        paperStyle,
        negativeElItem,
        styleField,
        RED_TABLE,
        PAPER_PADDING_THEME,
        formatterCurrency,
        formatterDayMonthYear,
        formatterPercentage,
        formatterValueNull
    } from "../../shared/utils";
import Header from "../../components/Titles/Header";
import Grid from "@mui/material/Grid";
import PathName from "../../components/Titles/PathName";
import DataGrid from "../../components/DataGrid/DataGrid";
import {useSelector} from "react-redux";


export default function DetalhaIfood() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [categoria, setCategoria] = useState("");
    const [valorTotal, setValorTotal] = useState(0);
    const [periodo, setPeriodo] = useState("");
    const negativeStyle = valorTotal < 0 ? {color: RED_TABLE} : {};
    const [table, setTable] = useState("");
    const [auxLojas, setAuxLojas] = useState();
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const dataRequest = {
            lojas: query.get("lojas"),
            mes: query.get("columnId"),
            categoria: query.get("fieldId"),
            table: query.get("table"),
        };
        setAuxLojas(dataRequest);
        if (dataRequest.lojas && dataRequest.lojas.length > 1) {
            const lojasSplit = dataRequest.lojas.split(",").map(Number);
            dataRequest.lojas = lojasSplit;
        }
        setTable(query.get("table"));
        explodeCategoryIfood(dataRequest);
    }, []);

    const explodeCategoryIfood = (dataRequest) => {
        setLoading(true);
        api.explodeCategoryIfood(dataRequest)
          .then((response) => {
            const newData = response.data.objects.map((item, index) => {
                return { ...item, id: index };
            });
            setData(newData);
            setCategoria(response.data.categoria);
            setValorTotal(response.data.total);
            setPeriodo(response.data.periodo);
            setLoading(false);
        })
        .catch(() => {
            setLoading(false);
        });
    };

    // Control visible columns here
    const initialColumns = {
        ...data.initialState,
        columns: {
            ...data.initialState?.columns,
            columnVisibilityModel: {
            "codigo_pedido": false,
            "incentivo_rest": false,
            "valor_ocorrencia": false,
            "formas_pagamento": false,
            "tipo_lancamento": false
            },
        },
    };

    const columns = [
        {
            field: "codigo_pedido",
            hide: true,
            headerName: "Código do Pedido",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "data_venda",
            headerName: "Data da Venda",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: params => formatterDayMonthYear(params)
        },
        {
            field: "data_pgto_antecipado",
            headerName: "Data Pagamento Antecipado",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: params => formatterDayMonthYear(params)
        },
        {
            field: "data_pgto",
            headerName: "Data Pagamento",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: params => formatterDayMonthYear(params)
        },
        { 
            field: "valor_venda",
            headerName: "Valor (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "taxa_entrega",
            headerName: "Taxa Entrega (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "venda_sem_frete",
            headerName: "Venda sem Frete (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "comissoes",
            headerName: "Comissões (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "incentivo_rest",
            headerName: "Incentivo (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "taxa_entrega_ifood",
            headerName: "Taxa Entrega Ifood (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "receita_liquida",
            headerName: "Receita Liquída (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "incentivo_ifood",
            headerName: "Incentivo Ifood (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "recebido_restaurante",
            headerName: "Recebido Restaurante (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "ocorrencias",
            headerName: "Ocorrencias (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "total_recebido",
            headerName: "Total Recebido (R$)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "comissao_porcentagem",
            headerName: "Comissão (%)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterPercentage(params),
        },
        { 
            field: "conf_receber",
            headerName: "Conferência (Receber)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "formas_pagamento",
            headerName: "Forma de Pagamento",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "tipo_lancamento",
            headerName: "Tipo do Lançamento",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "total_recebido_entrega_zero",
            headerName: "Total Recebido (Sem entrega)",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "taxa_servico",
            headerName: "Taxa de Serviço",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
        { 
            field: "motivo_ocorrencia",
            headerName: "Motivo Ocorrência",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
            valueFormatter: (params) => formatterValueNull(params),
        },
        { 
            field: "valor_ocorrencia",
            headerName: "Valor Ocorrência",
            flex: 1,
            type: "number",
            align:"center",
            headerAlign: "center",
        }
    ];

    const columnsDREConsolidada = [
        {
            field: "loja__nome",
            headerName: "Loja",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        ...columns
    ];

    return (
        <div className="main">
            <Header title={`Detalhando informações sobre a categorização: ${categoria}`}/>
            {loading ? (
                <Skeleton variant="rectangular" width={"100%"} height={"80vh"} />
            ) : (      
                <Paper sx={{...paperStyle, backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}} data-testid="detalha-ifood">
                    <Grid container>
                        <Grid item xs={12} sx={{...styleField, marginLeft: 0}}>
                            <PathName firstPath={table} secondPath={`${categoria}`}/>
                        </Grid>
                    </Grid>
                    <Grid item sx={{...styleField, marginLeft: 0}}>
                            Valor Total: <b style={{...negativeStyle}}>R$ {negativeElItem(valorTotal)}{format(valorTotal)}</b>
                        </Grid>
                    <Grid item sx={{...styleField, marginLeft: 0}}>
                        Período: <b>{periodo}</b>
                    </Grid>
                    <Grid sx={{ height: "calc(100vh - 160px - 95px)", width: "100%"}}>
                        <DataGrid
                            columns={auxLojas.lojas?.length > 1 ? columnsDREConsolidada : columns}
                            data={data}
                            row={(row) => (`${row.codigo_pedido}-${row.valor}`)}
                            initialColumns={initialColumns}
                        />
                    </Grid>
                </Paper>
            )}
        </div>
    );
}

