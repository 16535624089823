export const steps = [
    {
        selector: ".filter-estabelecimentos",
        content: "Filtra o(s) estabelecimento(s) apresentado(s) no PDV."
    },
    {
        selector: ".filter-date",
        content: "Filtra os meses que serão apresentados no PDV."
    },
    {
        selector: ".select-portal",
        content: "Mostra os portais disponíveis para o PDV."
    },
    {
        selector: ".select-canal-venda",
        content: "Mostra os canais de venda disponíveis para o PDV."
    },
    {
        selector: ".all-conciliacao-accordions",
        content: "Mostra os PDVs separados por mês. Para inserir comentários ou fixar valores, clique 2 vezes na célula."
    },
];