import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";
import { useSelector } from "react-redux";
import {
	DASH_KPI_COLOR,
	DASH_KPI_DATA_COLOR,
	DASH_KPI_NEGATIVE_COLOR,
	DASH_KPI_POSITIVE_COLOR,
	dashLeftTitle,
	formatterCurrency,
	paperDash,
	BLUE_THEME,
	WHITE_ESCRITA_THEME,
	WHITE_THEME_BLACK,
    BLUE_INFO_UX,
} from "../../shared/utils";

const EXMKPI = (props) => {
	const {data, title, testId, isTour, isTotal, handleClick, selectedKPI} = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	return(
		<Paper
			className={props.className}
			onClick={handleClick}
			sx={{
				...paperDash,
				...dashLeftTitle,
				backgroundColor : temaEscuro && BLUE_THEME,
				backgroundImage: temaEscuro && "none",
				borderLeft: selectedKPI && `0.35rem solid ${isTotal ? (temaEscuro ? WHITE_THEME_BLACK : DASH_KPI_DATA_COLOR) : data < 0 ? DASH_KPI_NEGATIVE_COLOR : DASH_KPI_POSITIVE_COLOR}`,
				"&:hover": {cursor: "pointer", outline: "1px solid"}
		}}>
			<Grid container rowSpacing={2} data-testid={testId}>
				<Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
					<span style={{...styles.title, ...(temaEscuro && {color: WHITE_ESCRITA_THEME})}}>{title}</span>
					{
						isTour && <ButtonOpenTour onClick={props.onClick}/>
					}
				</Grid>
				<Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
					<span 
                        style={{
                            ...styles.subTitle,
                            ...(data < 0 ? styles.negative : styles.positive),
                            ...(isTotal && (temaEscuro ? {color: WHITE_THEME_BLACK} : {color: DASH_KPI_DATA_COLOR}))
                        }}
                    >
                        {formatterCurrency(data, 2, false)}
					</span>
				</Grid>
			</Grid>
		</Paper>
	);
};

// Create styles
const styles = {
	title: {
		fontSize: "16px",
		fontWeight: 500,
		color: DASH_KPI_COLOR
	},
	subTitle: {
		fontSize: "20px",
		fontWeight: 600,
		color: DASH_KPI_DATA_COLOR
    },
	positive: {
		color: DASH_KPI_POSITIVE_COLOR
	},
	negative: {
		color: DASH_KPI_NEGATIVE_COLOR
	},
    total: {
        color: BLUE_INFO_UX
    },
};

export default React.memo(EXMKPI);