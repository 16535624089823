import React, { useState, useCallback, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import api from "../../axios";
import { GRAY_LABEL_UX, WHITE_ESCRITA_THEME } from "../../shared/utils";
import LastModified from "../../components/LastModified/LastModified";
import FilterLojas from "../../components/Filter/FilterLojas";
import SelectConsolidado from "../../components/SelectStore/SelectConsolidado";
import GrayButton from "../../components/Buttons/GrayButton";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Line from "../../components/Dash/Line";
import Bar from "../../components/Dash/Bar";
import StackedBar from "../../components/Dash/StackedBar";
import { useDispatch } from "react-redux";
import { showSnackMessage } from "../../actions/SnackActions";
import MercadoModal from "../../components/Modal/Comparativo/MercadoModal";
import { changeSegmentos } from "../../actions/StoreActions";

export default function Comparativo() {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
	const [previousMonths, setPreviousMonths] = useState([]);
	const [monthOptions, setMonthOptions] = useState([]);
    const [lojasSelected, setLojasSelected] = useState([]);
    const [openModalFilter, setOpenModalFilter] = useState(false);
    const [isCategoryOutros, setIsCategoryOutros] = useState(false);
    const [openMercadoModal, setOpenMercadoModal] = useState(false);
    const [segmentosFiltered, setSegmentosFiltered] = useState([]);
    const [monthsDisplay, setMonthsDisplay] = useState(false);
	const storeId = useSelector(state => state.StoreReducer.storeId);
    const segmentos = useSelector(state => state.StoreReducer.segmentos);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [optionsFatLiqTotalLine, setOptionsFatLiqTotalLine] = useState({});
    const [optionsFatLiqTotalMediaBar, setOptionsFatLiqTotalMediaBar] = useState({});
    const [optionsTicketMedioGeralBar, setOptionsTicketMedioGeralBar] = useState({});
    const [optionsTicketMedioAppsBar, setOptionsTicketMedioAppsBar] = useState({});
    const [optionsTicketMedioAdqsBar, setOptionsTicketMedioAdqsBar] = useState({});
    const [optionsCMVPercentageLine, setOptionsCMVPercentageLine] = useState({});
    const [optionsCMOPercentageLine, setOptionsCMOPercentageLine] = useState({});
    const [optionsCMVComposicaoStackedBar, setOptionsCMVComposicaoStackedBar] = useState({});
    const [optionsCMVOPercentageStackedBar, setOptionsCMVOPercentageStackedBar] = useState({});
    const [optionsTaxasCartoesAppsBar, setOptionsTaxasCartoesAppsBar] = useState({});
    const [optionsFreteEntregasBar, setOptionsFreteEntregasBar] = useState({});
    const [optionsImpostosVendasBar, setOptionsImpostosVendasBar] = useState({});
    const [optionsMargContPercLine, setOptionsMargContPercLine] = useState({});
    const [optionsCustoVariavelCompStackedBar, setOptionsCustoVariavelCompStackedBar] = useState({});
    const [optionsCustosFixosPercLine, setOptionsCustosFixosPercLine] = useState({});
    const [optionsCustosFixosCompStackedBar, setOptionsCustosFixosCompStackedBar] = useState({});
    const [optionsLucroOpPercLine, setOptionsLucroOpPercLine] = useState({});
    const [optionsLucroOpBar, setOptionsLucroOpBar] = useState({});
    const [optionsPontoEquilibrioBar, setOptionsPontoEquilibrioBar] = useState({});
    const [filteredMonths, setFilteredMonths] = useState([]);
    const [isMercado, setIsMercado] = useState(false);
    const [canCallGetMeses, setCanCallGetMeses] = useState(true);

    useEffect(() => {
        getSegmentos();
	}, []);

    const handleClickAway = (lojas) => {
		if (lojas.length) {
            
            setMonthsDisplay(true);

            if (canCallGetMeses) {
                const dataRequest = {
                    lojas
                };
                
                api.GetMesesCompetencia(dataRequest).then((response) => {
                    
                    let dataResponse = response.data;
                    
                    setFilteredMonths(dataResponse);

                    setCanCallGetMeses(false);
                });
            }
			
		}
	};

    const handleFilterLojas = useCallback((months, lojas, segmentos=[]) => {
		if (months.length > 0 && lojas.length > 0){
			setLoading(true);
			setLojasSelected(lojas);
			getComparativo(months, lojas, segmentos);
		} else {
			setLoading(false);
			dispatch(showSnackMessage({message: "Selecione pelo menos uma loja e um mês", severity: "error"}));
		}
	}, []);

    const getSegmentos = () => {
        if (!segmentos.length){
            api.GetSegmentos().then(response => {
                dispatch(changeSegmentos(response.data));
            }).catch((error) => {
                dispatch(showSnackMessage({message: error.data.message, severity: "error"}));
            });
        }
    };

    const getComparativo = (monthsFilter, lojasFilter, segmentosFilter) => {
		let lojasList = [];
		if (lojasFilter.length === 0) {
			lojasList = [storeId];
		} else {
			lojasList = lojasFilter;
		}
		setLoading(true);
		const dataRequest = {
			lojas: lojasList,
			months: monthsFilter,
            segmentos: segmentosFilter,
		};
		api.getComparativo(dataRequest).then(response => {
			let data = response.data;
            let dataComp = data.comparativo;
            let _months = [];
            data.months.map((month) => {
                _months.push({ value: month, label: month });
            });
            setMonthOptions(_months);
            setPreviousMonths(data.filtered_months);
            setOptionsFatLiqTotalLine(dataComp.fat_liq_total);
            setOptionsFatLiqTotalMediaBar(dataComp.fat_liq_total_media);
            setOptionsTicketMedioGeralBar(dataComp.ticket_medio_geral);
            setOptionsTicketMedioAppsBar(dataComp.ticket_medio_apps);
            setOptionsTicketMedioAdqsBar(dataComp.ticket_medio_adqs);
            setOptionsCMVPercentageLine(dataComp.cmv_porcentagem);
            setOptionsCMOPercentageLine(dataComp.cmo_porcentagem);
            setOptionsCMVComposicaoStackedBar(dataComp.cmv_composicao);
            setOptionsCMVOPercentageStackedBar(dataComp.cmvo_porcentagem);
            setOptionsTaxasCartoesAppsBar(dataComp.taxas_cartoes_apps);
            setOptionsFreteEntregasBar(dataComp.frete_entregas);
            setOptionsImpostosVendasBar(dataComp.impostos_vendas);
            setOptionsMargContPercLine(dataComp.marg_cont_perc);
            setOptionsCustoVariavelCompStackedBar(dataComp.custo_variavel_composicao);
            setOptionsCustosFixosPercLine(dataComp.custos_fixos_perc);
            setOptionsCustosFixosCompStackedBar(dataComp.custos_fixos_composicao);
            setOptionsLucroOpPercLine(dataComp.lucro_op_perc);
            setOptionsLucroOpBar(dataComp.lucro_op);
            setOptionsPontoEquilibrioBar(dataComp.ponto_equilibrio);

            setSegmentosFiltered(data?.segmentos);

            setOpenModalFilter(false);
            setIsCategoryOutros(false);

            setCanCallGetMeses(true);

            if (dataComp.cmvo_porcentagem.data.includes("Mercado")) {
                setIsMercado(true);
            }
            else {
                setIsMercado(false);
            }

			setLoading(false);

		}).catch((error) => {
			setLoading(false);
            if (error.status === 400) {
                setIsCategoryOutros(true);
                dispatch(showSnackMessage({message: error.data.message, severity: "error"}));
            }
		});
	};

    const handleOpenMercadoModal = () => {
		setOpenMercadoModal(true);
	};

    return (
        <div className="main">
            <FilterLojas
                openModalFilter={openModalFilter}
                setOpenModalFilter={setOpenModalFilter}
                handleFilterLojas={handleFilterLojas}
                previousMonths={previousMonths}
                monthOptions={monthOptions}
                setMonthOptions={setMonthOptions}
                segmentos={segmentos}
                isComparativo
                handleClickAway={handleClickAway}
                monthsDisplay={monthsDisplay}
                filteredMonths={filteredMonths}
                setCanCallGetMeses={setCanCallGetMeses}
                
            />
            <MercadoModal
                isMercado={isMercado}
				openModal={openMercadoModal}
				setOpenModal={setOpenMercadoModal}
				segmentos={segmentosFiltered}
			/>
            <LastModified title="Comparativo" />
                <Grid container sx={{display: "flex", alignItems: "center"}}>
                    <Grid item xs={6} sx={{padding: "16px 16px 16px 0"}}>
                        <GrayButton
                            testId="btn-filtrar"
                            onClick={() => setOpenModalFilter(true)}
                            title="Filtrar"
                            startIcon={<FilterListOutlinedIcon/>}
                        />
					</Grid>
                    {segmentosFiltered?.length > 0 && (
                        <Grid item xs={6} sx={{display: "flex", justifyContent: "end"}}>
                            <GrayButton title={"Mercado"} onClick={() => handleOpenMercadoModal()} />
                        </Grid>
                    )}
                </Grid>
            {loading ? (
                <Grid container spacing={2} rowSpacing={4}>
                {[...Array(2).keys()].map((i, d) => {
                    return (
                        <Grid item xs={2} key={d}>
                            <Skeleton variant="rectangular" height={50} />
                        </Grid>
                    );
                })}
                <Grid item xs={8} />
                {[...Array(4).keys()].map((i, d) => {
                    return (
                        <Grid item xs={3} key={d}>
                            <Skeleton variant="rectangular" height={100} />
                        </Grid>
                    );
                })}
                <Grid item xs={8}>
                    <Skeleton variant="rectangular" height={300} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rectangular" height={300} />
                </Grid>
                {[...Array(2).keys()].map((i, d) => {
                    return (
                        <Grid item xs={6} key={d}>
                            <Skeleton variant="rectangular" height={300} />
                        </Grid>
                    );
                })}
                {[...Array(3).keys()].map((i, d) => {
                    return (
                        <Grid item xs={4} key={d}>
                            <Skeleton variant="rectangular" height={300} />
                        </Grid>
                    );
                })}
            </Grid>
            ) : (
                lojasSelected.length < 1 ? 
                    (
                        <SelectConsolidado/>
                    ) : (
                        isCategoryOutros ? 
						(
							<div style={{...styles.center}} data-testid="comparativo_other">
								<Grid container rowSpacing={5}>
									<Grid item xs={12}>
										<WarningAmberIcon style={{fontSize: "150px", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}/>
									</Grid>
									<Grid item xs={12}>
										<a style={{...styles.text, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Dashboard comparativa não disponível para a loja selecionada</a>
									</Grid>
								</Grid>
							</div>
						) : (
                            <Grid container rowSpacing={2} data-testid="comparativo">
                                <Grid item xs={12}>
                                    <Grid container spacing={2} rowSpacing={4}>
                                        <Grid item xs={8}>
                                            <Line title="Faturamento Líquido Total por Loja (R$)" hideLabel notRedirect testId="faturamento-liquido-line" data={optionsFatLiqTotalLine} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Bar title="Média Mensal do Faturamento Líquido Total (R$)" notRedirect multiColors testId="faturamento-liquido-media-bar" data={optionsFatLiqTotalMediaBar} />
                                        </Grid>


                                        <Grid item xs={4}>
                                            <Bar title="Ticket Médio Geral (R$)" notRedirect multiColors testId="ticket-medio-geral-bar" data={optionsTicketMedioGeralBar} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Bar title="Ticket Médio Apps (R$)" notRedirect multiColors testId="ticket-medio-apps-bar" data={optionsTicketMedioAppsBar} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Bar title="Ticket Médio Adquirentes (R$)" notRedirect multiColors testId="ticket-medio-adqs-bar" data={optionsTicketMedioAdqsBar} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Line title="CMV (%)" hideLabel notRedirect testId="cmv-percentage-line" percentage data={optionsCMVPercentageLine} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Line title="CMO (%)" hideLabel notRedirect testId="cmo-percentage-line" percentage data={optionsCMOPercentageLine} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <StackedBar title="CMV Composição (%)" notRedirect testId="cmv-composicao-stacked-bar" percentage data={optionsCMVComposicaoStackedBar} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <StackedBar title="CMV + CMO (%)" notRedirect testId="cmvo-percentage-stacked-bar" percentage data={optionsCMVOPercentageStackedBar} />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Bar title="Taxas de Cartões Apps (%)" notRedirect multiColors testId="taxas-cartoes-apps-bar" percentage data={optionsTaxasCartoesAppsBar} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Bar title="Frete Entregas (%)" notRedirect multiColors testId="frete-entregas-bar" percentage data={optionsFreteEntregasBar} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Bar title="Impostos sobre Vendas (%)" notRedirect multiColors testId="impostos-vendas-bar" percentage data={optionsImpostosVendasBar} />
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Line title="Margem de Contribuição (%)" hideLabel notRedirect testId="marg-cont-perc-line" percentage data={optionsMargContPercLine} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <StackedBar title="Composição do Custo Variável (%)" notRedirect testId="custo-variavel-composicao-stacked-bar" percentage data={optionsCustoVariavelCompStackedBar} />
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Line title="Custo Fixo (%)" hideLabel notRedirect testId="custos-fixos-perc-line" percentage data={optionsCustosFixosPercLine} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <StackedBar title="Composição do Custo Fixo (%)" notRedirect testId="custo-fixo-composicao-stacked-bar" percentage data={optionsCustosFixosCompStackedBar} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Line title="Lucro Operacional (%)" hideLabel notRedirect testId="lucro-operacional-perc-line" percentage data={optionsLucroOpPercLine} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Bar title="Média do Lucro Operacional (R$)" notRedirect multiColors testId="lucro-operacional-bar" data={optionsLucroOpBar} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Bar title="Ponto de Equilíbrio (R$)" notRedirect multiColors testId="ponto-equilibrio-bar" data={optionsPontoEquilibrioBar} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    )
                
            )}
            

        </div>
    );
}

const styles = {
	center: {
		textAlign: "center",
		margin: "auto",
		width: "70%",
		// margin is 30px
		height: "calc(100vh - 60px)",
		display: "flex",
		alignItems: "center"
	},
	text: {
		color: GRAY_LABEL_UX,
		fontWeight: 600,
		fontSize: "24px",
		lineHeight: "32px"
	}
};
