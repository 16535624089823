import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItems from "./listItems";
import {Link} from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {Outlet} from "react-router-dom";
import api from "../../axios";
import {
	changeToken,
	changeEmail,
	changeIsAdmin,
	logoutUser,
	changeName,
	changeResetarSenha,
	changeTema,
	changeImagePerfil,
	changeDeviceToken,
	changeIsCommonUser,
	changeIsOperator,
	changeIsManager,
} from "../../actions/AuthActions";
import {
	changeStore,
	changeLojas, changeModified
} from "../../actions/StoreActions";
import Loading from "../Loading/Loading";
import ListItemButton from "@mui/material/ListItemButton";
import {Tooltip} from "@mui/material";
import VoxelLogo from "../../assets/images/VoxelLogo.png";
import VoxelEscuro from "../../assets/images/voxelescuro.png";
import VoxelLogoAlt from "../../assets/images/VoxelLogoAlt.png";
import Avatar from "@mui/material/Avatar";
import {
	GRAY_LABEL_UX,
	GRAY_LABEL_UX_THEME,
	BLACK_TABLE_PERFIL,
	avatarStyle,
	DASH_BAR_COLOR,
	BLUE_THEME,
	LINE_TABLE,
	GRAY_DATE_UX,
	GRAY_BG_UX,
	BACK_PERFIL,
	BORDER_BUTTON,
	enumCommonUser,
	enumManager,
	enumOperator,
	translateAutocomplete,
	WHITE_TABLE,
	VERY_WEAK_YELLOW_COMPARATIVO
} from "../../shared/utils";
import "./NavBar.css";
import ReactGA from "react-ga4";
import Tour from "reactour";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { steps } from "./TourNavbar";
import Paper from "@mui/material/Paper";
import UpdateNewsModal from "../Modal/UpdateNewsModal";
import FirstLoginModal from "../Modal/FirstLoginModal";
import {getFirebaseToken} from "../../firebase";
import {showSnackMessage} from "../../actions/SnackActions";
import CategoriasModal from "../Modal/CategoriasModal";
import Alert from "@mui/material/Alert";
import MenuMobile from "./MenuMobile";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open"})(
	({ theme, open, temaescuro }) => ({
		"& .MuiDrawer-paper": {
			backgroundColor: (temaescuro === "true") ? BLUE_THEME : LINE_TABLE,
			position: "relative",
			whiteSpace: "nowrap",
			width: drawerWidth,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			boxSizing: "border-box",
			...(!open && {
				overflowX: "hidden",
				transition: theme.transitions.create("width", {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				width: theme.spacing(7),
				[theme.breakpoints.up("sm")]: {
					width: theme.spacing(9),
				},
			}),
		},
	}),
);


const NavBar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [open, setOpen] = useState(true);
	const [openTour, setOpenTour] =  useState(false);
	const [startAt, setStartAt] = useState(0);
	const [store, setStore] = useState();
	const [loadingLojas, setLoadingLojas] = useState(true);
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const isDesktop = useSelector(state => state.AuthReducer.isDesktop);

	const excludedSelectors = [".button_2", ".button_12", ".button_13", ".button_14"];
	const filteredSteps = isAdmin ? steps : steps.filter(step => !excludedSelectors.includes(step.selector));
	const [stores, setStores] = useState([]);
	const [isPerfil, setIsPerfil] = useState(false);
	const [openCategoriasModal, setOpenCategoriasModal] = useState(false);
	const [openIncorrectLogin, setOpenIncorrectLogin] = useState(false);
	const [openUpdateModal, setOpenUpdateModal] = useState(false);
	const [openFirstLoginModal, setOpenFirstLoginModal] = useState(false);
	const [isFirstMonth, setIsFirstMonth] = useState(false);
	const [openSubItemInfo, setOpenSubItemInfo] = useState(false);
	const [openSubItemConc, setOpenSubItemConc] = useState(false);
	const [openSubItemArq, setOpenSubItemArq] = useState(false);
	const [incorrectLogins, setIncorrectLogins] = useState([]);
	const [mesesIfood, setMesesIfood] = useState(false);

	const token = Cookies.get("tk");
	const email = useSelector(state => state.AuthReducer.email);
	const name = useSelector(state => state.AuthReducer.name);
	const storeId = useSelector(state => state.StoreReducer.storeId);
	const imagePerfil = useSelector(state => state.AuthReducer.imagePerfil);
	const lojas = useSelector(state => state.StoreReducer.lojas);

	// IOS does not support the Notification API https://caniuse.com/?search=Notification
	const isNotificationSupported = () => (
		"Notification" in window && "serviceWorker" in navigator && "PushManager" in window
	);

	useEffect(() => {
		checkNotificationPermission();
		checkLogged();
		getLojas();
	}, []);

	useEffect(() => {
		let path = location.pathname;
		let title = path.replace("/", "");
		ReactGA.send({ hitType: "pageview", page: path, title: title });
		ReactGA.event({
			category: title,
			action: `Acessou a página ${path}`,
			label: name,
		});
	}, [location]);

	useEffect(() => {
        const query = new URLSearchParams(location.search);
        let storeIdLocation = parseInt(query.get("store_id"));
        if (storeIdLocation != 0 && storeIdLocation != null) {
            let storeObj = stores.filter(loja => {
                return loja.value === storeIdLocation;
            });
            setStore(storeObj[0]);
        }
        let storeObj = stores.filter(loja => {
            return loja.value === storeId;
        });
        if(storeObj.length > 0){
            setStore(storeObj[0]);

            if (isAdmin) {
                getCategorias();
				getIncorrectLogins();
            }
			getMesesIfood();
        }
    }, [storeId]);

	const getMesesIfood = () => {
		let dataRequest = {
			"loja_id": storeId
		};

		api.GetMesesIfood(dataRequest).then((response) => {
			setMesesIfood(response.data);
		}).catch(() => {
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde.", severity: "error"}));
		});
	};

	const getCategorias = () => {
		api.GetPlanoContas(storeId).then(response => {
			let dataResponse = response.data;

			if (dataResponse.nao_classificados.length) {
				setOpenCategoriasModal(true);
			}
		});
	};

	const getIncorrectLogins = () => {
		let dataRequest = {
			loja_id: storeId
		};
		setOpenIncorrectLogin(false);
		api.GetIncorrectLogins(dataRequest).then(response => {
			let dataResponse = response.data;

			setIncorrectLogins(dataResponse);
			if (dataResponse.length > 0) {
				setOpenIncorrectLogin(true);
			}
		}).catch(() => {
			dispatch(showSnackMessage({
				message: "Não foi possível pegar os logins incorretos!",
				severity: "error"
			}));
		});
	};

	const checkNotificationPermission = () => {
		if(isNotificationSupported()){
			if (Notification.permission !== "granted") {
				dispatch(showSnackMessage({
					message: "Por favor, aceite a permissão para receber notificações nesse site!",
					severity: "warning"
				}));
				Notification.requestPermission().then(()=> {
					dispatch(showSnackMessage({
						message: "Permissão habilitada com sucesso!",
						severity: "success"
					}));
				});
			}
		}

	};

	const initals = () => {
		let nameSplit = name.split(" ").map((word) => word[0]).join("");
		return nameSplit;
	};

	const initalName = () => {
		let nameSplit = name.split(" ");
		return nameSplit[0];
	};

	const getLojas = () => {
		if(token){
			setLoadingLojas(true);
			api.GetLojas().then(response => {
				setLoadingLojas(false);
				let data = response.data;
				setStores(data);
				dispatch(changeLojas(data));
			}).catch(() => {
				setStores([]);
				dispatch(changeLojas([]));
				setLoadingLojas(false);
			});
		}
	};

	const updateDeviceToken = (token) => {
		const data = {
			device_token: token
		};
		api.PutUpdateDeviceToken(data).then(response => {
			dispatch(changeDeviceToken(response.data.device_token));
		}).catch(() => {
			// error
		});
	};

	const getDeviceToken = (previousToken = null) => {
		getFirebaseToken().then((currentToken) => {
			if(currentToken){
				dispatch(changeDeviceToken(currentToken));
				if(token?.length && previousToken !== currentToken) {
					updateDeviceToken(currentToken);
				}
			}
		}).catch((e) => {
			// eslint-disable-next-line no-console
			console.error(`error when trying to get device token: ${e}`);
		});
	};

	const checkLogged = () =>{
		if (token?.length) {
			api.UserInfo().then(response => {
				let resetarSenha = response.data.resetar_senha;
				let isAdmin = response.data.admin;
				let email = response.data.email;
				let name = response.data.nome;
				let deviceToken = response.data.device_token;
				let lojaPadrao = response.data.loja_padrao;
				let lojaNome = response.data.loja_nome;
				let _temaEscuro = response.data.tema_escuro;
				let image_perfil = response.data.s3_path_image;
				let updatesModal = response.data.mostrar_atualizacoes;
				let firstLogin = response.data.first_login;
				let firstMonth = response.data.first_month;
				let userGroup = response.data.user_group;

				if (userGroup.includes(enumCommonUser)) {
					dispatch(changeIsCommonUser(true));
				}
				if (userGroup.includes(enumOperator)) {
					dispatch(changeIsOperator(true));
				}
				if (userGroup.includes(enumManager)) {
					dispatch(changeIsManager(true));
				}

				if (firstLogin === true) {
					setIsFirstMonth(firstMonth);
					handleFirstLogin(firstMonth);
				}

				ReactGA.set({ userId: email });
				ReactGA.gtag("set", "user_properties", {
					name: name,
				});
				if(deviceToken != null){
					getDeviceToken(deviceToken);
				}else{
					getDeviceToken();
				}
				dispatch(changeName(name));
				dispatch(changeEmail(email));
				dispatch(changeIsAdmin(isAdmin));
				dispatch(changeTema(_temaEscuro));
				dispatch(changeImagePerfil(image_perfil));
				setOpenUpdateModal(updatesModal);
				dispatch(changeToken(token));
				if (lojaPadrao != null) {
					dispatch(changeStore(lojaPadrao));
					setStore({label: lojaNome, value: lojaPadrao});
				}
				if(resetarSenha){
					dispatch(changeResetarSenha(resetarSenha));
					navigate("/mudar_senha");
				}

			}).catch(() => {
				logout();
			});
		}
		else{
			getDeviceToken();
			dispatch(logoutUser());
		}
	};

	const handleFirstLogin = () => {
		setOpenFirstLoginModal(true);
	};

	const handleRedirectFirstLogin = () => {
		setOpenFirstLoginModal(false);
		navigate("/insights");
	};

	const handleChange = (obj) => {
		dispatch(changeModified(""));
		dispatch(changeStore(obj.value));
	};

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const logout = () =>{
		api.Logout().then(() => {
			dispatch(logoutUser());
			window.location.reload();
		}).catch(async () => {
			dispatch(logoutUser());
			window.location.reload();
		});
	};

	const disableButtonStyle = () => {
		setIsPerfil(true);
	};

	const changeOpen = () => {
		setStartAt(0);
		setOpenTour(!openTour);
		setOpenSubItemInfo(true);
		setOpenSubItemArq(true);
		setOpenSubItemConc(true);
	};

	return (
		token  ? (
			<React.Fragment>
				{
					isDesktop ? (
						<Box sx={{ display: "flex", height: "100vh"}}>
							<Drawer variant="permanent" open={open} temaescuro={temaEscuro?.toString()} data-testid="navbar">
								<Tour
									steps={filteredSteps}
									isOpen={openTour}
									onRequestClose={changeOpen}
									accentColor={DASH_BAR_COLOR}
									startAt={startAt}
									rounded={5}
									{...( temaEscuro ? {className: "tour"} : {})}
									badgeContent={(curr, tot) => `${curr}/${tot}`}
								/>
								<CategoriasModal
									openModal={openCategoriasModal}
									setOpenModal={setOpenCategoriasModal}
								/>
								<UpdateNewsModal
									openModal={openUpdateModal}
									setOpenModal={setOpenUpdateModal}
								/>
								<FirstLoginModal
									openModal={openFirstLoginModal}
									setOpenModal={setOpenFirstLoginModal}
									isFirstMonth={isFirstMonth}
									handleTutorial={changeOpen}
									handleRedirect={handleRedirectFirstLogin}
								/>
								{
									openIncorrectLogin && (
										<Alert sx={{position: "fixed", right: 0, margin:"0 35%", backgroundColor: BLUE_THEME, color: VERY_WEAK_YELLOW_COMPARATIVO, borderRadius: "10px"}} severity="warning" onClose={() => setOpenIncorrectLogin(false)}>
											Login ou senha incorretos para a(s) plataforma(s): {incorrectLogins.join(", ")}.
										</Alert>
									)
								}
								<Box sx={{...styles.box, marginY: 1}}>
									{
										open ? (
											// #botao tour e botao fecha
											<React.Fragment>
												<img src={temaEscuro ? VoxelEscuro : VoxelLogo} />
												<IconButton onClick={toggleDrawer} data-testid="icon-drawer" style={{...styles.drawerButton, ...styles.drawerBtOpen, color: temaEscuro && GRAY_DATE_UX}}>
													<ChevronLeftIcon />
												</IconButton>
												<IconButton  style={{...styles.drawerButtonTour, ...styles.drawerBtOpen, padding: "5px", color: temaEscuro && GRAY_DATE_UX}} onClick={changeOpen}>
													<LightbulbIcon style={{ fontSize: "14px"}}/>
												</IconButton>
											</React.Fragment>
										) : (
											<React.Fragment>
												<img src={VoxelLogoAlt} />
												<IconButton onClick={toggleDrawer} style={{...styles.drawerButton, ...styles.drawerBtClosed, color: temaEscuro && GRAY_DATE_UX}}>
													<ChevronRightIcon />
												</IconButton>
											</React.Fragment>
										)
									}
								</Box>
								<Box
									sx={{...styles.box, padding: 1, marginY: 2}}
									style={{visibility: open ? "visible" : "hidden" }} //muda cor loja quadrado
								>
									<FormControl fullWidth >
										{loadingLojas ? (
											<Loading />
										) : (
											lojas.length ? (
												<Autocomplete
													size="small"
													disablePortal
													disableClearable
													data-testid="navbar-change-store"
													PaperComponent={({ children }) => (
														<Paper
															style={{
																backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
																color: temaEscuro ? WHITE_TABLE : BLUE_THEME,
																fontWeight: "500",
															}}
														>
															{children}
														</Paper>
													)}
													onChange={(event, newValue) => {
														handleChange(newValue);
													}}
													isOptionEqualToValue={(option, value) => option.value === value.value}
													value={store || null}
													options={lojas}
													noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
													renderInput={(params) =>
														<TextField
															{...params}
															label="Loja"
															InputLabelProps={{
																style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON}}}
														/>}
												/>
											) : (
												<p>Nenhuma loja encontrada</p>
											)

										)
										}
									</FormControl>
								</Box>
								<Box>
									<List component="nav">
										<ListItems
											open={open}
											isDesktop={true}
											isPerfil={isPerfil}
											setIsPerfil={setIsPerfil}
											className={filteredSteps.map((x) => {return x.selector.replace(".", "");})}
											setOpenSubItemInfo={setOpenSubItemInfo}
											setOpenSubItemConc={setOpenSubItemConc}
											setOpenSubItemArq={setOpenSubItemArq}
											openSubItemArq={openSubItemArq}
											openSubItemConc={openSubItemConc}
											openSubItemInfo={openSubItemInfo}
											mesesIfood={mesesIfood}
										/>
									</List>
								</Box>
								<Box sx={{...styles.box, flexGrow: 1, alignItems: "end"}}>
									{
										name == null ? (
											<Loading />
										) : (
											<Tooltip title={email}>
												<Link to="/perfil" className="text-link" onClick={disableButtonStyle} role="item">
													<ListItemButton style={{...styles.perfil, background: temaEscuro ? BACK_PERFIL : "rgba(9, 36, 75, 0.03)"}}>
														<Grid container sx={{alignItems: "center"}} className="button_19">
															<Grid item xs={3}>
																<Avatar sx={avatarStyle}>
																	{
																		imagePerfil !== "" ?
																			(
																				<img src={imagePerfil} alt="image" style={{maxHeight: "50px", maxWidth: "50px"}}/>
																			) : (
																				<React.Fragment>
																					{initals()}
																				</React.Fragment>
																			)
																	}
																</Avatar>
															</Grid>
															{
																open && (
																	<React.Fragment>
																		<Grid item xs={7}>
																			<Grid container sx={{...styles.ellipsis, color: temaEscuro ? GRAY_LABEL_UX_THEME : GRAY_LABEL_UX,}}>
																				<span style={{...styles.nameText}}>
																					{initalName()}
																				</span>
																				<span style={{...styles.emailText}}>{email}</span>
																			</Grid>
																		</Grid>
																		<Grid item xs={2} sx={{display: "flex", justifyContent: "end", color: temaEscuro ? GRAY_LABEL_UX_THEME : GRAY_LABEL_UX }}>
																			<LogoutIcon onClick={() => logout()}/>
																		</Grid>
																	</React.Fragment>
																)
															}

														</Grid>
													</ListItemButton>
												</Link>
											</Tooltip>
										)
									}
								</Box>
							</Drawer>
							<Box
								component="main"
								sx={{
									backgroundColor: (theme) =>
										theme.palette.mode === (temaEscuro ? "temaEscuro" : "light")
											? GRAY_LABEL_UX_THEME
											: BLACK_TABLE_PERFIL,
									flexGrow: 1,
									overflowX: "auto",
									overflowY: "unset"
								}} //MUDA O FUNDO DE TODAS AS ABAS
							>
								<Outlet />
							</Box>
						</Box>
					) : (
						<MenuMobile 
							store={store} 
							lojas={lojas}
							loadingLojas={loadingLojas}
							setOpenSubItemInfo={setOpenSubItemInfo}
							setOpenSubItemConc={setOpenSubItemConc}
							setOpenSubItemArq={setOpenSubItemArq}
							openSubItemArq={openSubItemArq}
							openSubItemConc={openSubItemConc}
							openSubItemInfo={openSubItemInfo}
							isPerfil={isPerfil}
							setIsPerfil={setIsPerfil}
							filteredSteps={filteredSteps}
						/>
					)
				}
				
			</React.Fragment>
		) : (
			<Outlet />
		)
	);
};

const styles = {
	box: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	drawerButton: {
		padding: "2px",
		background: "#ECC94B",
		position: "fixed",
		zIndex: 1
	},
	drawerButtonTour:{
		position: "fixed",
		background: "#ECC94B",
		marginTop: "60px",
		marginLeft: "1.5px",
		zIndex: 1
	},
	drawerBtOpen: {
		// navbar's width is 240px and button is 28px (28/2 = 14) -> 240 - 14 = 226
		left: "226px",
		animation: "append-animate .2s linear"
	},
	drawerBtClosed: {
		// navbar's width is 72px and button is 28px (28/2 = 14) -> 72 - 14 = 58
		left: "58px",
		animation: "close-animate .2s linear"
	},
	perfil: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		height: "80px",
		background: "rgba(9, 36, 75, 0.03)", //OK
	},
	emailText: {
		fontSize: 12,
		fontWeight: 500,
		textOverflow: "ellipsis",
		overflow: "hidden"
	},
	nameText: {
		fontSize: "1rem",
		fontWeight: 500,
		lineHeight: 1.5,
		textOverflow: "ellipsis",
		overflow: "hidden"
	},
	ellipsis: {
		textOverflow: "ellipsis"
	}
};

export default NavBar;