export const steps = [
    {
        selector: ".datacaixa",
        content: "Filtra a seleção dos meses para análise do fluxo de caixa."
    },
    {
        selector: "[data-testid='btn-filter-fluxo']",
        content: "Filtra os meses de competência do Fluxo de Caixa."
    },
    {
        selector: ".liquidez",
        content: "Liquidez seca é o indicador que tem a função de medir a capacidade da empresa em cumprir suas obrigações financeiras no curto prazo."
    },
    {
        selector: "[data-testid='btn-export-fluxo']",
        content: "Para baixar o relatório no formato Excel."
    },
    {
        selector: ".competencia",
        content: "Possibilita filtrar lançamentos de determinada competência."
    }
];