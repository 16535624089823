import React  from "react";
import {styleText, smallPaperStyle, GRAY_LABEL_UX} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {BLUE_THEME, WHITE_ESCRITA_THEME, WHITE_THEME_BLACK} from "../../shared/utils";


const InfoModal = (props) => {
    const {openModal, setOpenModal, infoTitle, information} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return(
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="info-modal"
            >
                <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, pt: 3}}>
                    <Grid item xs={10} className="start">
                        <span style={{...styleText, fontWeight: "700", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>{infoTitle}</span>
                    </Grid>
                    <Grid sx={{marginTop: 5}}>
                        <p style={{...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>{information}</p>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );                  
};

export default InfoModal;