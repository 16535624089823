export const steps = [
    {
        selector: ".marca", 
        content: "Filtra a marca das lojas com plataformas habilitadas para download."
    },
    {
        selector: ".arquivo", 
        content: "Importa arquivos de plataformas para o sistema."
    },
    {
        selector: "[data-testid='button-resolve-problems']", 
        content: "Processa, busca ou deleta os arquivos importados do sistema."
    }
];