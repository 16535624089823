import React, {useEffect} from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {format, getClassName, negativeEl, RED_TABLE, stylesRow, WALPAPPER_THEME} from "../../shared/utils";
import "./Table.css";
import TooltipTable from "./TooltipTable";
import {useSelector} from "react-redux";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";

const TableHeader = (props) => {
	let {title, rows, columns, backgroundColor, color, fontWeight, handleOpenModal, isCollapse, className, open, setOpen, isTour, zIndexDRE} = props;
	const defaultStyle = {top: 57, zIndex: zIndexDRE};
	const fontWeightStyle = fontWeight != null ? fontWeight : "normal";
	const style = {backgroundColor, color, fontWeight: fontWeightStyle};
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	const openComponent = useSelector(state => state.StoreReducer.openComponent);

	useEffect(() => {
		if (setOpen) {
			setOpen(openComponent);
		}
	}, [openComponent]);

	return(
		<React.Fragment>
			<TableRow className={className}>
				<TableCell
					style={{...defaultStyle, ...style}}
				>
					{
						isTour ? (
							<div style={{...styles.flexCenter}}>
								{title}
								<ButtonOpenTour onClick={props.onClick}/>
							</div>
						) : (
							<React.Fragment>
								{title}
							</React.Fragment>
						)
					}
				</TableCell>
				{rows
					.map((row, idx) => {
						return (
							<React.Fragment key={idx}>
								{columns.map((column, index) => {
									if(column.id !== "periodo") {
										const data = row[column.id];
										const negativeStyle = data?.value < 0 ? {color: RED_TABLE} : {};
										const cursorStyle = column.id !== "total" ? {cursor: "pointer"} : {};
										return (
											<TooltipTable key={index} comments={data?.comments || []}>
												<TableCell
													key={index}
													style={{...defaultStyle, ...style, ...cursorStyle, ...negativeStyle}}
													align="center"
													className={getClassName(data)}
												>
													{
														negativeEl(data)
													}
													{
														<div
															data-testid="row-table-header"
															onClick={() => column.id !== "total" && handleOpenModal(data, index-1, title, data?.comments || [])}>
															{format(data?.value)}
														</div>
													}
												</TableCell>
											</TooltipTable>
										);
									}
									return null;
								})}
							</React.Fragment>
						);
					})
				}
				{ isCollapse &&
					(
						<TableCell sx={{...defaultStyle, backgroundColor: temaEscuro && WALPAPPER_THEME, ...stylesRow}} data-testid="open-row" onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</TableCell>
					)
				}
			</TableRow>
			{ open && props.children }
		</React.Fragment>
	);
};

export default TableHeader;

const styles = {
	flexCenter: {
		display: "flex",
		alignItems: "center",
	}
};