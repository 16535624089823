import React, {useState, useEffect, useMemo} from "react";
import Paper from "@mui/material/Paper";
import ReactECharts from "echarts-for-react";
import Box from "@mui/material/Box";
import {
	DASH_AXIS_COLOR, seriesColorDualLine,
	DASH_LABEL_COLOR,
	DASH_LINESTYLE_COLOR,
	formatterThousand,
	paperDash, spanDash, fontFamily,
	legend, formatterCurrency, changePosition,
	BLUE_THEME, WHITE_ESCRITA_THEME, WHITE_THEME_BLACK, TEXT_THEME,
	BORDER_BUTTON, GRAY_LABEL_UX, LINE_TABLE
} from "../../shared/utils";
import ButtonOpenTour from "../ButtonOpenTour/ButtonOpenTour";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeOpenComponent } from "../../actions/StoreActions";


const DualLine = (props) => {
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const {data, testId} = props;
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const formatter = formatterThousand;

	const lineFormatter = (params) => {
		return formatter(params.data);
	};

	const axisFormatter = (value) => {
		return formatter(value);
	};

	const minFunction = (value) => {
		return (value.min - (value.min * 0.05)).toFixed(0);
	};

	const axisLabel = {
		fontFamily,
		color: temaEscuro ? WHITE_THEME_BLACK : DASH_AXIS_COLOR,
		...(formatter != null && {formatter: axisFormatter})
	};

	const splitLine = {
		show: true,
		lineStyle: {
			color: temaEscuro ? TEXT_THEME : DASH_LINESTYLE_COLOR,
			type: "dashed"
		}
	};

	const grid = {
		left: 15,
		right: 10,
		bottom: 32, // padding is 2 (2*8px = 16px)
		top: 32, // padding is 2 (2*8px = 16px)
		containLabel: true
	};

	const YAxis = [
		{
			type: "value",
			min: minFunction,
			splitLine,
			axisLabel,
			name: "Faturamento (R$)",
			nameTextStyle: {
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_AXIS_COLOR,
				fontSize: 12,
				fontWeight: 400,
				align: "center",
			},
		},
		{
			name: "Ticket Médio (R$)",
			nameTextStyle: {
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_AXIS_COLOR,
				fontSize: 12,
				fontWeight: 400,
				align: "center",
			},
			type: "value",
			min: minFunction,
			splitLine,
			axisLabel,
		}
	];

	const series = {
		data: [],
		type: "line",
		smooth: true,
		symbol: "circle",
		emphasis: {
			focus: "series"
		},
		label: {
			show: true,
			position: "top",
			color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_LABEL_COLOR,
			...(formatter != null && {formatter: lineFormatter}),
			fontFamily,
		},
		triggerLineEvent: true,
	};

	const [options, setOptions] = useState({
		grid,
		legend:{
			...legend,
			textStyle: {
				color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
			},
			inactiveColor: temaEscuro ? BORDER_BUTTON : "#ccc"
		},
		xAxis: {
			type: "category",
			color: DASH_LABEL_COLOR,
			data: [],
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			axisLabel: {
				interval: 0,
				fontFamily,
				color: temaEscuro ? WHITE_THEME_BLACK : DASH_LABEL_COLOR
			}
		},
		yAxis: YAxis,
		series: [],
		tooltip: {
			trigger: "axis",
			axisPointer: {
				type: "shadow"
			},
			fontFamily,
		},
	});
	const [key, setKey] = useState();

	useEffect(() => {
		let newOptions = {...options};
		newOptions.xAxis.data = data.data;
		let position = "top";

		data?.series?.map((item, index) => {
			let newSeries = {...series};
			let label = {...newSeries.label};

			label.position = position;
			newSeries.color = seriesColorDualLine[index];
			newSeries.name = item.name;
			newSeries.data = item.data;
			newSeries.tooltip = {
				valueFormatter: formatterCurrency
			};
			if("yAxisIndex" in item){
				let newLabel = {...newSeries.label};
				newSeries.yAxisIndex = item.yAxisIndex;
				newLabel.formatter = lineFormatter;
				newSeries.label = newLabel;
			}
			newSeries.label = label;
			newOptions.series.push(newSeries);
			position = changePosition(position);
		});

		setOptions(newOptions);
		setKey(Math.random());
	}, [data]);

	const handleClick = (params) => {
		const innerText = params.event.event.currentTarget.offsetParent.innerText;

		// columnName = X axis
		const columnName = innerText.split("\n")[0];

		dispatch(changeOpenComponent(true));
		navigate("/dre", { state: { monthRedirectDashboard: columnName, lineRedirectDashboard: props.title }});
	};

	const getChart = useMemo(() => {
		return(
			<ReactECharts
				key={key}
				option={options}
				notMerge={true}
				style={props.isFullHeight ? {height: "100%", width: "100%"} : null}
				onEvents={{
					click: handleClick, // Register the click event handler
				}}
			/>
		);
	}, [options]);


	return(
		<Paper data-testid={testId} sx={{...paperDash, backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}} className={props.className}>
			<Box sx={{mb: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
				<span style={{...spanDash, ...(temaEscuro && {color:  LINE_TABLE})}}>{props.title}</span>
				<ButtonOpenTour onClick={props.onClick}/>
			</Box>
			{getChart}
		</Paper>
	);
};

export default React.memo(DualLine);